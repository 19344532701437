import React, {
  useContext,
} from 'react';
import ProjectsShowContext from '../../ProjectsShowContext';

export default function DetailsTitle() {
  const {
    project,
  } = useContext(ProjectsShowContext);

  const {
    project_data: projectData,
    title: title,
  } = project;

  const {
    subcategoryTitle,
  } = projectData;

  return (
    <h1 className="t-sage-heading-2 details__title">
      {title ? title : subcategoryTitle}
    </h1>
  );
}
