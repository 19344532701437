import React from 'react';
import PropTypes from 'prop-types';

export default function PortfolioItem({
  image,
  onClick,
  title,
}) {
  return (
    <div
      className="portfolio-item"
      onClick={onClick}
      role="presentation"
    >
      {image && (
        <div className="portfolio-item__image-frame">
          <img
            alt={title}
            className="portfolio-item__image"
            src={image}
          />
        </div>
      )}
      <p className="t-sage-heading-6 portfolio-item__title">{title}</p>
    </div>
  );
}

PortfolioItem.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
