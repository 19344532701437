import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@kajabi/sage-react';

export default function QuoteStatus({
  quote,
}) {
  const canceledRegEx = /cancell?ed/;
  const isAccepted = quote.status === 'accepted';
  const isCanceled = canceledRegEx.test(quote.status);
  const isDeclined = quote.status === 'declined';

  return (
    <>
      {!isAccepted && !isCanceled && !isDeclined && <Badge color="draft" value="Pending" />}
      {isAccepted && <Badge color="published" value="Accepted" />}
      {isCanceled && <Badge color="danger" value="Canceled" />}
      {isDeclined && <Badge color="danger" value="Declined" />}
    </>
  );
}

QuoteStatus.propTypes = {
  quote: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};
