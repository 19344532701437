import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Toggle,
} from '@kajabi/sage-react';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

const MESSAGE_BY_USER_TYPE = {
  user: 'Make sure you have reviewed the work and are fully satisifed before marking this project as complete.',
  vendor: 'When you mark a project complete, your client will be notified and will be prompted to leave you a review. You will no longer be able to submit invoices for this project.',
};

export default function CompletionModal({
  active,
  onClose,
  onUpdate,
}) {
  const user = useContext(CurrentUserContext);
  const [isCompleteCheckboxChecked, setIsCompleteCheckboxChecked] = useState(false);

  const handleCompleteCheckboxChange = () => {
    if (isCompleteCheckboxChecked) setIsCompleteCheckboxChecked(false);
    else setIsCompleteCheckboxChecked(true);
  };

  return (
    <Modal
      active={active}
      className="completion-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Complete Project"
      />
      <Modal.Body>
        <div className="completion-modal__content">
          <p className="completion-modal__message">
            {MESSAGE_BY_USER_TYPE[user.type]}
          </p>
          <Toggle
            checked={isCompleteCheckboxChecked}
            className="completion-modal__conditions"
            id="complete"
            label="Mark project complete."
            name="complete"
            onChange={handleCompleteCheckboxChange}
            type="checkbox"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!isCompleteCheckboxChecked}
            onClick={onUpdate}
          >
            Proceed
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

CompletionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
