import React, {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Icon,
} from '@kajabi/sage-react';
import QuoteDetails from '../../../shared/QuoteDetails';
import QuoteReviewModal from '../../../shared/QuoteReviewModal';
import QuoteStatus from '../../../shared/QuoteStatus';
import ConversationsShowContext from '../ConversationsShowContext';
import CurrentQuoteContext from '../../../../contexts/CurrentQuoteContext';
import quoteCancel from '../../../../scripts/quoteCancel';

export default function ChatPaneQuote() {
  const quote = useContext(CurrentQuoteContext);
  const {
    id,
    quote_type: quoteType,
    status,
  } = quote;

  const {
    recipient,
    user,
  } = useContext(ConversationsShowContext);

  const canceledRegEx = /cancell?ed/;
  const isVendor = user.type === 'vendor';
  const isAccepted = status === 'accepted';
  const isCanceled = canceledRegEx.test(status);
  const isDeclined = status === 'declined';
  const isProposal = quoteType === 'proposal';

  const [modalActive, setModalActive] = useState(false);

  const modalClose = () => {
    setModalActive(false);
  };

  const iconHeader = isProposal ? 'rename' : 'payout';
  const textHeader = isProposal ? 'Proposal' : 'Invoice';
  const textReviewButton = isProposal ? 'Review proposal' : 'Review invoice';

  return (
    <>
      <Card
        compact
      >
        <div className="chat-pane-quote__header">
          <div className="chat-pane-quote__type">
            <Icon icon={iconHeader} />
            <p className="t-sage-heading-6">{textHeader}</p>
          </div>
          <QuoteStatus quote={quote} />
        </div>
        <hr className="chat-pane-quote__divider" />
        <QuoteDetails quote={quote} />
        {isVendor && !isAccepted && !isCanceled && !isDeclined && (
          <div className="chat-pane-quote__footer">
            <Button
              color="secondary"
              onClick={() => quoteCancel(id)}
            >
              Cancel
            </Button>
          </div>
        )}
        {!isVendor && !isAccepted && !isCanceled && !isDeclined && (
          <div className="chat-pane-quote__footer">
            <Button
              onClick={() => setModalActive(true)}
            >
              {textReviewButton}
            </Button>
          </div>
        )}
      </Card>
      <QuoteReviewModal
        active={modalActive}
        onClose={modalClose}
        quote={quote}
        vendor={recipient}
      />
    </>
  );
}
