import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Icon,
} from '@kajabi/sage-react';
import Linkifier from './Linkifier';

export default function IconText({
  icon,
  onClick,
  text,
}) {
  const classNames = classnames('icon-text', {
    'icon-text--clickable': onClick,
  });

  return (
    <div
      className={classNames}
      onClick={onClick}
      role="presentation"
    >
      <Icon
        icon={icon}
      />
      <Linkifier>
        <p
          className="t-sage-body-small icon-text__text"
        >
          {text}
        </p>
      </Linkifier>
    </div>
  );
}

IconText.defaultProps = {
  onClick: null,
};

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};
