const app = document.getElementById('app');
const config = JSON.parse(app.getAttribute('data-config'));
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': config.authenticityToken,
};

function testimonial({
  body = null,
  callback,
  method,
  route,
}) {
  fetch(route, {
    method,
    headers,
    body,
  })
    .then((res) => res.json())
    .then((res) => callback(res));
}

export function testimonialsList({ onFetched = () => {} }) {
  testimonial({
    callback: onFetched,
    method: 'GET',
    route: '/api/v1/testimonials',
  });
}

export function testimonialPost(payload, callback = () => { }) {
  testimonial({
    body: JSON.stringify({ testimonial: payload }),
    callback,
    method: 'POST',
    route: '/api/v1/testimonials',
  });
}

export function testimonialUpdate(payload, callback = () => { }) {
  testimonial({
    body: JSON.stringify({ testimonial: payload }),
    callback,
    method: 'PUT',
    route: `/api/v1/testimonials/${payload.id}`,
  });
}

export function testimonialDelete(id, callback = () => { }) {
  testimonial({
    callback,
    method: 'DELETE',
    route: `/api/v1/testimonials/${id}`,
  });
}
