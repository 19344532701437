import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';

export default function DeclineModal({
  active,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      active={active}
      className="declining-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Decline Project"
      />
      <Modal.Body>
        <div className="declining-modal__content">
          <p className="declining-modal__message">If you decline this project, you and the client will not be able to communicate further regarding it. To undo this operation, find this project in the "Declined" section of your dashboard and choose "Undo decline".</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button icon="trash" onClick={onConfirm}>
            Decline
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

DeclineModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
