export default function projectUpdate({
  fetchPath,
  project,
  redirectPath,
  status,
}) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(fetchPath, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      ...project,
      status,
    }),
  })
    .then((res) => res.text())
    .then(() => window.location.replace(redirectPath));
}
