const ATTACHMENT_ICONS = {
  'text/csv': 'excel',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',

  'application/pdf': 'pdf',

  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',

  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'text/*': 'doc',

  'image/*': 'image',

  'audio/*': 'audio',
  'video/*': 'video',
};

const iconByMask = (attachment) => {
  const type = attachment.mimetype.split('/')[0];

  return ATTACHMENT_ICONS[`${type}/*`] || 'doc';
};

const icon = (attachment) => {
  return ATTACHMENT_ICONS[attachment.mimetype] || iconByMask(attachment);
};

export default function iconUrl(attachment) {
  return `/images/svg/mimetype-icons/${icon(attachment)}.svg`;
};
