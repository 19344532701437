import React from 'react';
import {
  Alert,
  Link,
} from '@kajabi/sage-react';

export default function TemplatesAlert() {
  return (
    <Alert
      actions={(
        <Link
          href="mailto:experts@kajabi.com?subject=New Template Submission"
        >
          Submit Now
        </Link>
      )}
      color="info"
      description="We are seeking experts who have designed custom themes and templates and are interested in joining our Kajabi-hosted marketplace. Sound like you? Simply click the following link and send the zip files containing your themes and templates for approval by our team."
    />
  );
}
