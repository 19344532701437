import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
} from '@kajabi/sage-react';
import Info from './Info';
import Items from './Items';
import PlanUpgradeButton from '../../../shared/PlanUpgradeButton';
import PortfolioItemModal from './PortfolioItemModal';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import WorkContext from './WorkContext';

export default function Work() {
  const [currentPortfolioItem, setCurrentPortfolioItem] = useState(null);
  const [portfolioItemModalData, setPortfolioItemModalData] = useState({
    active: false,
    type: 'add',
  });
  const [userData, setUserData] = useState(null);

  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const fetchUserData = () => {
    fetch(`${window.location.origin}/users/${userId}/edit.json`)
      .then((response) => response.json())
      .then((data) => setUserData(data));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const portfolioItemLimit = window.entitlements.portfolioItems.limit;

  const workContextValues = useMemo(() => ({
    currentPortfolioItem,
    fetchUserData,
    portfolioItems: userData && userData.portfolio_items,
    portfolioItemImageConfig: window.uploaderConfig.portfolioItem,
    portfolioItemModalData,
    portfolioItemLimit,
    setCurrentPortfolioItem,
    setPortfolioItemModalData,
    setUserData,
    userData,
  }));

  if (!userData) return false;

  const userPlan = userData.plan || '';
  let displayPlanName = 'Approved';
  if (userPlan === 'growth') displayPlanName = 'Expert+';

  return (
    <WorkContext.Provider value={workContextValues}>
      <section className="work">
        <div className="sage-row">
          <div className="sage-col-4">
            <Info />
          </div>
          <div className="sage-col-8">
            {userPlan === 'basic' && (
              <Alert
                actions={<PlanUpgradeButton />}
                color="info"
                description="If you'd like to increase the amount of work samples you can present to your customers, you will need to upgrade your plan"
                title={`Your current ${displayPlanName} plan allows you to present ${portfolioItemLimit} work sample${portfolioItemLimit > 1 ? 's' : ''}`}
              />
            )}
            <Items />
          </div>
        </div>
        <PortfolioItemModal
          active={portfolioItemModalData.active}
          onClose={() => setPortfolioItemModalData({
            active: false,
            type: null,
          })}
        />
      </section>
    </WorkContext.Provider>
  );
}
