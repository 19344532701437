import React, {
  useContext,
} from 'react';
import uuid from 'react-uuid';
import BriefItem from './BriefItem';
import ProjectsShowContext from '../ProjectsShowContext';

const subcategories = require('../../../../data/categories/subcategories');

export default function Brief() {
  const {
    project,
  } = useContext(ProjectsShowContext);

  const {
    project_data: projectData,
  } = project;

  const {
    subcategoryId,
    subcategoryTitle,
  } = projectData;

  const getItems = (sub) => sub.questions.map((question) => {
    if (question.id === 'description') {
      return false;
    }

    if (question.id === 'preferredLocation' && projectData[question.id] === 'No location preference') {
      return false;
    }

    if (question.id in projectData) {
      return (
        <BriefItem
          answer={projectData[question.id]}
          key={uuid()}
          question={question.promptBrief}
        />
      );
    }

    return false;
  });

  return (
    <section className="brief">
      <p className="t-sage-heading-5">About the project</p>
      <BriefItem
        answer={subcategoryTitle}
        question="Service"
      />
      {getItems(subcategories[subcategoryId])}
      {getItems(subcategories.projectPreferences)}
    </section>
  );
}
