import React from 'react';
import Content from './Content';
import Header from './Header';
import Input from './Input';

export default function ChatPane() {
  return (
    <section className="pane chat-pane">
      <Header />
      <Content />
      <Input />
    </section>
  );
}
