import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import ChatPane from './chat-pane/ChatPane';
import InboxPane from './inbox-pane/InboxPane';
import UserPane from './user-pane/UserPane';
import ConversationsShowContext from './ConversationsShowContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ConversationsShow() {
  const navigate = useNavigate();
  const user = useContext(CurrentUserContext);
  const {
    type: userType,
  } = user;

  const projectId = parseInt(window.location.pathname.split('/')[3], 10);
  let vendorId = parseInt(window.location.pathname.split('/')[5], 10);

  const [conversations, setConversations] = useState([]);
  const [currentRecentProjects, setCurrentRecentProjects] = useState(null);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [project, setProject] = useState(null);
  const [recentMessages, setRecentMessages] = useState(null);
  const [recentProjects, setRecentProjects] = useState([]);
  const [recipient, setRecipient] = useState(null);
  const [quoteType, setQuoteType] = useState('');

  const fetchProject = () => {
    fetch(`${window.location.origin}/projects/${projectId}.json`)
      .then((response) => response.json())
      .then((data) => {
        setProject(data);
      });
  };

  const fetchConversations = () => {
    fetch(`${window.location.origin}/conversations/projects/${projectId}/vendors/${vendorId}.json`)
      .then((response) => response.json())
      .then((data) => {
        const cc = {
          vendorId,
          messages: data.filtered_messages,
          quotes: data.filtered_quotes,
          vendor: data.vendor_proxy,
        };
        setConversations((old) => [...old, cc]);
        setCurrentConversation(cc);
      });
  };

  const fetchRecentProjects = () => {
    fetch(`${window.location.origin}/users/${vendorId}/recent_projects.json`)
      .then((response) => response.json())
      .then((data) => {
        const crp = {
          vendorId,
          recentProjects: data,
        };
        setRecentProjects((old) => [...old, crp]);
        setCurrentRecentProjects(crp);
      });
  };

  const fetchRecentMessages = () => {
    if (userType === 'user') {
      fetch(`${window.location.origin}/projects/${projectId}/conversations.json`)
        .then((response) => response.json())
        .then((data) => setRecentMessages(data));
    }
  };

  const newMessagePosted = () => {
    fetchConversations();
    fetchRecentMessages();
  };

  const changeVendor = (vendor) => {
    vendorId = vendor.id;
    navigate(`/conversations/projects/${projectId}/vendors/${vendorId}`);

    // set recipient to vendor, which changes the layout
    setRecipient(vendor);

    // look for stored conversation, fetch and store if not found
    const cc = conversations.find((convo) => convo.vendorId === vendorId);
    if (!cc) fetchConversations();
    else setCurrentConversation(cc);

    // look for stored recent projects, fetch and store if not found
    const crp = recentProjects.find((rp) => rp.vendorId === vendorId);
    if (!crp) fetchRecentProjects();
    else setCurrentRecentProjects(crp);
  };

  useEffect(() => {
    fetchProject();
    fetchConversations();
  }, []);

  useEffect(() => {
    if (project) {
      fetchRecentMessages();
      fetchRecentProjects();
    }
  }, [project]);

  useEffect(() => {
    if (userType === 'user') {
      if (project && recentMessages && !recipient) {
        const thisVendor = recentMessages.find((c) => c.id === vendorId);
        setRecipient(thisVendor);
      }
    } else if (userType === 'vendor') {
      if (project) {
        setRecipient(project.user);
      }
    }
  }, [project, recentMessages]);

  const conversationsShowContextValues = useMemo(() => ({
    changeVendor,
    currentConversation,
    currentRecentProjects,
    newMessagePosted,
    project,
    recentMessages,
    recipient,
    quoteType,
    setQuoteType,
    user,
    vendorId,
  }));

  return (
    <ConversationsShowContext.Provider value={conversationsShowContextValues}>
      {project && recipient && currentConversation && (
        <div className="layout">
          {userType === 'user' && (
            <div className="layout__pane layout__pane--inbox">
              <InboxPane />
            </div>
          )}
          <div className="layout__pane layout__pane--chat">
            <ChatPane />
          </div>
          <div className="layout__pane layout__pane--user">
            <UserPane />
          </div>
        </div>
      )}
    </ConversationsShowContext.Provider>
  );
}
