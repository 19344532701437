import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../shared/site-footer/SiteFooter';
import Header from '../shared/site-header/SiteHeader';

export default function DefaultLayout({
  children,
  cssScope,
  headerSize,
  showFooter,
  showHeader,
}) {
  return (
    <>
      {showHeader === true && (
        <Header
          size={headerSize}
        />
      )}
      <main className={`main ${cssScope}`}>
        {children}
      </main>
      {showFooter === true && <Footer />}
    </>
  );
}

DefaultLayout.defaultProps = {
  cssScope: '',
  headerSize: 'large',
  showFooter: false,
  showHeader: true,
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  cssScope: PropTypes.string,
  headerSize: PropTypes.string,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
};
