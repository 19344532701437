import plansGraphics from './plansGraphics';

const plansData = [
  {
    id: 1,
    name: 'Basic',
    displayName: 'Approved',
    hidden: false,
    blurb: 'Recommended for new businesses',
    price: '10% commission rate',
    entitlements: [
      {
        text: 'Get an Expert profile',
      },
      {
        text: 'Listed in 1 category of the Expert directory',
      },
      {
        text: '1 work sample in profile',
      },
      {
        text: 'Reviews from Expert Marketplace projects',
      },
    ],
    svg: plansGraphics.basic,
  },
  {
    id: 2,
    name: 'Growth',
    displayName: 'Expert+',
    hidden: false,
    blurb: 'Scale your business',
    price: 99,
    entitlements: [
      {
        boldText: '0% commission rate',
        textColor: 'sage',
      },
      {
        boldText: 'Enhanced profile with more features with up to 6 work samples',
      },
      {
        boldText: 'Priority listing in the directory across 5 categories',
      },
      {
        boldText: 'Exclusive marketing to Kajabi customers',
      },
      {
        boldText: 'Add reviews from clients outside of the marketplace',
      },
    ],
    svg: plansGraphics.growth,
  },
  {
    id: 3,
    name: 'Pro',
    displayName: 'Pro',
    hidden: true,
    blurb: 'Get your business up and running',
    price: 299,
    entitlements: [
      {
        boldText: '0% commission',
        text: 'rate',
      },
      {
        boldText: 'Unlimited',
        text: 'active projects',
      },
      {
        boldText: 'Unlimited',
        text: 'prospective projects',
      },
      {
        boldText: 'Unlimited',
        text: 'service categories',
      },
      {
        text: 'Kajabi Certified',
      },
    ],
    svg: plansGraphics.pro,
  },
];

export default plansData;
