import React, {
  useCallback,
  useContext,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import AdditionalActionsDropdown from '../AdditionalActionsDropdown';
import ProjectsShowContext from '../../ProjectsShowContext';
import ViewDefaultContext from '../ViewDefaultContext';
import isAnyQuoteAccepted from '../../../../../scripts/isAnyQuoteAccepted.ts';

export default function DetailsActions() {
  const {
    project,
    user,
  } = useContext(ProjectsShowContext);

  const {
    closeProjectModalOpen,
    completionModalOpen,
    manualRatingModalOpen,
    reopenProjectModalOpen,
    outstandingInvoiceQuoteModalOpen,
  } = useContext(ViewDefaultContext);

  const {
    filtered_quotes: filteredQuotes,
    ratings,
    status,
  } = project;

  const {
    id: userId,
    type: userType,
  } = user;

  const isProjectOpen = status === 'Open';
  const isProjectClosed = status === 'Closed';
  const isProjectComplete = status === 'Complete';
  const showAdditionalActionsDropdown = userType === 'vendor' && isProjectOpen;

  const isProjectRated = () => {
    let isRated = false;
    ratings.forEach((rating) => {
      if (rating.reviewer_id === userId) {
        isRated = true;
      }
    });
    return isRated;
  };

  const isAnyInvoiceQuoteAccepted = useCallback(() => (
    filteredQuotes.filter(
      (quote) => quote.status === 'accepted' && quote.quote_type === 'invoice',
    ).length > 0
  ));

  const markCompleteModal = useCallback(() => {
    if (isAnyInvoiceQuoteAccepted()) {
      return completionModalOpen;
    }
    return outstandingInvoiceQuoteModalOpen;
  });

  const UserButtons = useCallback(() => {
    const buttons = [];

    if (showAdditionalActionsDropdown) {
      buttons.push(
        <AdditionalActionsDropdown
          project={project}
        />,
      );
    }

    if (userType === 'user' && !isProjectComplete && !isProjectClosed) {
      if (isAnyQuoteAccepted(project)) {
        buttons.push(
          <Button
            color="secondary"
            icon="check-circle"
            key="complete"
            onClick={markCompleteModal()}
          >
            Mark complete
          </Button>,
        );
      } else {
        buttons.push(
          <Button
            color="secondary"
            icon="remove-circle"
            key="close"
            onClick={closeProjectModalOpen}
          >
            Close project
          </Button>,
        );
      }
    }

    if (userType === 'user' && isProjectClosed) {
      buttons.push(
        <Button
          color="secondary"
          icon="refresh"
          key="reopen"
          onClick={reopenProjectModalOpen}
        >
          Reopen project
        </Button>,
      );
    }

    if (
      isAnyQuoteAccepted(project)
      && !isProjectClosed
      && !isProjectComplete
      && userType === 'vendor'
    ) {
      buttons.push(
        <Button
          color="secondary"
          icon="check-circle"
          key="complete"
          onClick={markCompleteModal()}
        >
          Mark complete
        </Button>,
      );
    }

    if (isProjectComplete && !isProjectRated()) {
      buttons.push(
        <Button
          color="secondary"
          icon="star"
          onClick={manualRatingModalOpen}
        >
          Rate this project
        </Button>,
      );
    }

    return <div className="details__actions">{buttons}</div>;
  });

  return (
    <UserButtons />
  );
}
