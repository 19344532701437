import React from 'react';
import Service from './Service';

export default function Services() {
  return (
    <section className="section section--subtle services">
      <div className="container container--large">
        <h2 className="section__title t-sage-heading-2">Popular Expert services</h2>
        <div className="sage-row">
          <div className="sage-col">
            <Service
              img="images/services/web_design.jpg"
              subtitle="100+ Experts"
              title="Website setup & design"
              url="/directory?type=expert&services=site-design%3Bsite-setup%3Bsite-development%3Bcontent-migration%3Bseo"
            />
          </div>
          <div className="sage-col">
            <Service
              img="images/services/marketing.jpg"
              subtitle="100+ Experts"
              title="Marketing and sales"
              url="/directory?type=expert&services=sales-funnels%3Badvertising%3Bmarketing-consulting%3Bemail-marketing"
            />
          </div>
          <div className="sage-col">
            <Service
              img="images/services/admin.jpg"
              subtitle="50+ Experts"
              title="Admin support"
              url="/directory?type=expert&services=va-services%3Bsocial-media%3Baccounting%3Bbusiness-consulting%3Btranscription"
            />
          </div>
          <div className="sage-col">
            <Service
              img="images/services/content.jpg"
              subtitle="50+ Experts"
              title="Content development"
              url="/directory?type=expert&services=content-creation%3Bvideo-editing%3Bcopywriting-editing%3Bpodcast-audio"
            />
          </div>
          <div className="sage-col">
            <Service
              img="images/services/visual_design.jpg"
              subtitle="50+ Experts"
              title="Visual design"
              url="/directory?type=expert&services=branding-logo%3Bebook-document"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
