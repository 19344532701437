import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';

export default function Templates() {
  return (
    <section className="section templates">
      <div className="container container--large">
        <div className="templates__content">
          <div className="templates__text">
            <p className="t-sage-heading-2 templates__title">
              Looking for templates?
            </p>
            <p className="templates__description">
              Our experts have a wide variety of pre-built templates for you to
              use in your projects.
            </p>
          </div>
          <div className="templates__actions">
            <Button>
              View templates
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
