import React, {
  useContext,
} from 'react';
import {
  Badge,
} from '@kajabi/sage-react';
import classnames from 'classnames';
import Avatar from '../../../shared/Avatar';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Profile() {
  const {
    chatData,
  } = useContext(MessagesIndexContext);

  if (!chatData) return false;

  const {
    type: userType,
  } = useContext(CurrentUserContext);

  const isUser = userType === 'user';

  const profileClassNames = classnames(
    'profile',
    {
      'profile--interactive': isUser,
    },
  );

  return (
    <section
      className={profileClassNames}
      onClick={() => {
        if (isUser) window.location = `${window.location.origin}/users/${chatData.vendor.id}`;
      }}
      role="presentation"
    >
      <Avatar
        size="40"
        user={chatData.correspondent}
      />
      <div className="profile__text">
        <p className="t-sage-heading-6 profile__name">{chatData.correspondent.name}</p>
        <p className="t-sage-body-small profile__company">{chatData.correspondent.company_name}</p>
      </div>
      {chatData.correspondent.hired && (
        <Badge
          className="profile__badge"
          color="info"
          isInteractive={false}
          large
          value="Hired"
        />
      )}
    </section>
  );
}
