import React from 'react';

export default function GraphicThreadsNull() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3895_6453)">
        <path d="M98.8307 52.6825C98.8307 79.9055 63.3554 95.4062 38.1829 100.654C11.5334 106.211 0.255615 79.9055 0.255615 52.6825C0.255615 25.4594 22.3237 3.39136 49.5467 3.39136C76.7698 3.39136 98.8307 25.4666 98.8307 52.6825Z" fill="#DCF0FF" />
        <path d="M39.5021 22.9573C38.7135 21.7671 36.8924 21.2079 35.4441 21.7097L0.399026 31.525L0.155256 32.4642C-0.0311542 33.0019 -0.110017 33.9053 0.269972 34.4788L32.3468 84.4941C33.1355 85.6842 34.7558 86.3223 36.2113 85.8205L71.1488 73.8042C71.8586 73.5604 72.4107 72.9367 72.6401 72.3918L73.0703 70.8216L39.5021 22.9573Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M72.7691 69.4379C73.5578 70.628 72.891 71.8182 71.4428 72.3201L36.8135 84.3148C35.3652 84.8167 33.5442 84.2575 32.7555 83.0673L0.678631 33.0521C-0.110027 31.8619 0.50656 30.6001 1.95482 30.091L37.215 18.0245C38.6633 17.5227 39.667 17.9027 40.4557 19.0928L72.7691 69.4379Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M52.9953 29.1159L27.4212 37.9561C26.9767 38.1066 26.396 37.9059 26.1307 37.4972L19.7139 27.725C19.4486 27.3235 19.592 26.8647 20.0365 26.707L45.6105 17.8668C46.0551 17.7162 46.6358 17.917 46.9011 18.3257L53.3179 28.0979C53.5903 28.5065 53.4398 28.9654 52.9953 29.1159Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.9728 28.8895C25.1321 28.5012 24.8573 28.0207 24.3591 27.8163C23.8609 27.6119 23.3278 27.7611 23.1685 28.1494C23.0093 28.5377 23.284 29.0182 23.7823 29.2226C24.2805 29.4269 24.8135 29.2778 24.9728 28.8895Z" fill="#0072EF" />
        <path d="M27.3029 28.1039C27.4622 27.7156 27.1874 27.2351 26.6892 27.0307C26.1909 26.8264 25.6579 26.9755 25.4986 27.3638C25.3393 27.7521 25.6141 28.2326 26.1123 28.437C26.6106 28.6414 27.1436 28.4922 27.3029 28.1039Z" fill="#F49C60" />
        <path d="M29.5932 27.3087C29.7525 26.9204 29.4777 26.4399 28.9794 26.2356C28.4812 26.0312 27.9482 26.1803 27.7889 26.5687C27.6296 26.957 27.9044 27.4375 28.4026 27.6418C28.9008 27.8462 29.4339 27.6971 29.5932 27.3087Z" fill="#09468E" />
        <path d="M34.3327 53.5213L8.93075 62.3543C6.47874 63.2075 5.48217 65.5448 6.70817 67.5523C7.81229 69.359 10.3575 70.2982 12.6374 69.8465L14.8744 73.5031L16.717 68.4915L38.7994 60.8128C41.2514 59.9596 42.248 57.6223 41.022 55.6149C39.796 53.6145 36.7847 52.6682 34.3327 53.5213Z" fill="#DBE371" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.684 52.5463L9.28207 61.3792C6.83006 62.2324 5.83349 64.5697 7.05949 66.5772C8.16361 68.384 10.7088 69.3232 12.9888 68.8715L15.2257 72.528L17.0683 67.5164L39.1507 59.8378C41.6027 58.9846 42.5993 56.6473 41.3733 54.6398C40.1473 52.6395 37.136 51.7002 34.684 52.5463Z" fill="#DBE371" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M50.106 82.7662L51.0452 82.0134L57.8276 70.9722C58.1144 70.456 58.8099 70.1477 59.3619 70.2983L67.1553 72.3488L68.2092 71.5888L68.9477 73.1303C69.0409 73.3525 69.0337 73.625 68.8832 73.8902L61.9071 86.4156C61.6204 86.9318 60.9249 87.2401 60.3728 87.0895L51.3391 84.7092C51.0237 84.6231 50.9161 84.4582 50.7584 84.1571H50.7512L50.106 82.7662Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M50.6723 83.2968L59.706 85.6771C60.2652 85.8276 60.9535 85.5193 61.2403 85.0031L68.2164 72.4778C68.5031 71.9616 67.356 71.7393 66.7968 71.5959L58.6449 69.6028C58.0857 69.4522 57.4404 69.0436 57.1536 69.5598L50.1776 82.0851C49.898 82.6085 50.1131 83.1534 50.6723 83.2968Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M67.2698 74.1841L66.4453 72.1695L67.1623 72.3559L68.2162 71.5959L68.9547 73.1373C69.0479 73.3596 69.0407 73.6321 68.8902 73.8973L67.9509 75.575L67.2698 74.1841Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M56.2429 71.2088L57.1606 69.5669C57.4474 69.0507 58.1429 68.7424 58.6949 68.893L67.7287 71.2733C68.2879 71.4239 68.503 71.9687 68.2162 72.4849L67.2698 74.1913L56.2429 71.2088Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.6037 46.954L6.7227 53.0266L0.814941 43.8925L18.696 37.827L24.6037 46.954Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.9082 46.7102L12.996 45.305L18.4593 38.372C18.5095 38.3074 18.5023 38.2071 18.4306 38.1569C18.3661 38.1067 18.2657 38.1138 18.2155 38.1855L12.6375 45.2619L1.54614 43.8352C1.4601 43.8209 1.38124 43.8854 1.37407 43.9714C1.35973 44.0575 1.42426 44.1363 1.5103 44.1435L12.4224 45.5488L6.9592 52.4818C6.90902 52.5463 6.91619 52.6467 6.98788 52.6969C7.01656 52.7184 7.05241 52.7327 7.08109 52.7327C7.1241 52.7327 7.17429 52.7112 7.20297 52.6754L12.7809 45.5989L23.8723 47.0257C23.8795 47.0257 23.8867 47.0257 23.8938 47.0257C23.9727 47.0257 24.0372 46.9683 24.0444 46.8895C24.0516 46.7963 23.9942 46.7174 23.9082 46.7102Z" fill="white" />
        <path d="M48.3794 71.9797C49.2183 69.1723 46.9951 66.029 43.4138 64.9588C39.8325 63.8886 36.2492 65.2969 35.4103 68.1043C34.5714 70.9117 36.7946 74.055 40.3759 75.1252C43.9572 76.1954 47.5405 74.7871 48.3794 71.9797Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M44.6285 68.6707L41.0795 72.9653L38.2905 68.6205L44.6285 68.6707Z" fill="white" />
        <path d="M57.412 40.1786C57.412 40.1786 53.9133 40.5228 52.6227 40.6518C51.3322 40.7881 47.3531 41.0964 45.9263 40.6518C44.7218 40.279 44.1124 38.9598 44.6573 38.2142C44.6573 38.2142 52.085 36.1995 54.1785 35.8124C56.2649 35.418 56.2649 35.418 56.2649 35.418L57.412 40.1786Z" fill="#F49C60" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M58.3082 39.9492C58.3082 39.9492 56.5803 40.279 55.003 40.4224C54.8954 40.4296 52.9525 39.2609 52.2929 38.5296C51.6333 37.7983 50.8948 36.6512 50.8948 36.6512C52.7015 36.1206 54.6086 35.7048 55.4977 35.5399C57.584 35.1456 57.584 35.1456 57.584 35.1456L58.3082 39.9492Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M63.3342 42.4514C63.3342 42.4514 60.9682 46.7173 60.4376 47.463C59.6848 48.5169 57.7921 48.9758 56.7381 48.4667C56.0857 48.1513 56.0427 47.5562 56.1861 47.212L57.0894 45.5989L58.8316 41.419" fill="#F49C60" />
        <path d="M63.3342 42.4514C63.3342 42.4514 60.9682 46.7173 60.4376 47.463C59.6848 48.5169 57.7921 48.9758 56.7381 48.4667C56.0857 48.1513 56.0427 47.5562 56.1861 47.212L57.0894 45.5989L58.8316 41.419" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.8836 37.361C47.8836 37.361 47.604 37.913 46.4784 38.1926C45.869 38.3432 45.4962 38.3719 45.1592 38.4507C44.8796 38.5153 44.6501 38.5081 44.6501 38.2213C44.6501 38.1138 44.686 38.0349 44.8079 38.0134L47.8836 37.361Z" fill="#DB8C35" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M95.0238 73.5389C95.0238 73.5389 86.7572 68.9431 78.4333 65.9678C75.4364 64.8923 71.2135 65.2795 69.2633 64.6342C66.5891 63.7452 59.9428 61.7879 58.3799 60.6622C57.6557 60.1389 57.7704 59.9955 55.089 58.1529C53.1317 56.805 52.2212 56.6759 51.3465 56.0092C49.719 54.7616 47.4462 52.7542 46.3206 51.7934C45.7111 51.2772 45.2953 50.5603 44.8293 50.295C44.1625 49.9222 43.6463 49.5493 43.3165 49.0618C42.915 48.4667 43.338 47.635 44.3202 47.2837C45.3025 46.9396 46.636 47.0686 47.4964 47.1045C49.4107 47.1762 50.8661 48.1082 52.4506 49.1335C54.0136 50.1516 54.7592 50.5818 56.3724 51.2629C57.0463 51.5497 59.6919 51.8866 60.5021 51.7934C62.0865 51.6214 61.7209 50.7753 61.5273 49.0761C61.2477 46.6313 59.527 44.5808 57.9067 42.559C55.9852 40.1715 52.594 36.9595 50.7299 35.8339C48.4284 35.0309 44.7934 33.5396 43.3308 32.8943C41.7177 32.1845 40.8358 31.5321 40.4701 30.6646C40.1834 29.9906 40.2837 29.245 40.592 28.7431C40.8071 28.3918 41.5097 28.3416 41.9758 28.485C42.7501 28.7288 43.4814 28.6571 43.9976 28.8363C45.0085 29.1876 45.7255 29.3023 46.7292 29.4959C49.1526 29.9548 52.3789 30.7434 53.4687 31.1306C54.9169 31.6396 58.6021 34.063 58.6021 34.063C60.1938 34.1848 63.965 34.1203 64.8612 33.8622C65.7359 33.6041 66.9547 33.4966 67.7577 33.7618C68.4818 33.9984 69.2418 34.3928 69.2418 34.3928C70.5467 34.3784 71.9233 33.6471 73.2281 34.1203C73.5078 34.2207 74.1458 34.6294 74.9417 35.1886C74.9417 35.1886 76.5835 35.2173 77.3148 35.669C78.0461 36.1278 79.7453 38.7304 80.2974 39.4115C81.9105 41.082 87.4957 49.3844 89.116 50.381C90.7363 51.3776 104 57.2925 104 57.2925L95.0238 73.5389Z" fill="#F49C60" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M46.3349 51.7002C46.3349 51.7002 47.2311 49.7143 46.4281 48.8826C46.0696 48.5169 44.098 47.3124 43.5459 47.6852C42.5135 48.3807 43.2591 49.2554 43.2591 49.2554C44.65 50.3452 46.3349 51.7002 46.3349 51.7002Z" fill="#F49C60" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.5387 28.7574C43.2519 28.6857 42.3055 28.4491 41.2444 28.2842C40.8358 28.2197 40.1116 28.8291 40.4343 29.5031C40.6637 29.9835 42.155 30.3706 42.5923 30.4351C43.044 30.5068 43.7753 29.9046 44.2198 29.288C44.4636 28.9582 43.9832 28.865 43.5387 28.7574Z" fill="#F49C60" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M69.2205 34.3856C70.0521 34.7369 72.0883 36.2067 72.0883 36.2067" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M74.9417 35.1743L76.9133 36.5723" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M58.6021 34.0558C58.6021 34.0558 59.9213 34.9592 61.2333 35.6618C62.4235 36.2999 64.5743 37.2176 65.0332 37.361C65.4921 37.4972 67.0479 37.5115 67.0479 37.5115" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M50.9951 35.9128L50.8876 35.8267L44.8508 33.5109L36.3118 39.1176C35.8171 39.4402 35.5661 40.1572 35.7525 40.7021L38.7781 49.5422C38.8857 49.8505 39.0577 49.9437 39.3732 50.0871V50.0943L40.8071 50.6391L41.4882 49.6497L52.0061 42.0858C52.5008 41.7631 52.7517 41.0462 52.5653 40.5013L50.9951 35.9128Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.3111 41.1681L51.8413 36.6082L50.8877 35.8267L46.3207 34.0773L37.7673 39.6911C37.2726 40.0138 37.0217 40.7307 37.2081 41.2756L40.2337 50.1158C40.4201 50.6607 40.9793 50.8399 41.4812 50.5101L52.9526 42.9892C53.6194 42.5231 53.5046 41.713 53.3111 41.1681Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3895_6453">
          <rect width="104" height="101.351" fill="white" transform="translate(0 1.69568)" />
        </clipPath>
      </defs>
    </svg>
  );
}
