import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Tabs as SageTabs,
} from '@kajabi/sage-react';
import Packages from '../packages/Packages';
import Reviews from '../reviews/Reviews';
import Work from '../work/Work';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function Tabs() {
  const {
    ffPackages,
    ffPortfolio,
    userData,
  } = useContext(UsersShowContext);

  const hasPublishedPackages = () => {
    const publishedPackages = userData.packages.filter((item) => item.published);

    return publishedPackages.length > 0;
  };

  const defaultTabId = ffPortfolio && userData.portfolio_items.length ? 'work' : 'reviews'
  const [activeTabId, setActiveTabId] = useState(defaultTabId);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) setActiveTabId(tab);
  }, []);

  const reviewsTab = {
    id: 'reviews',
    label: 'Reviews',
    content: (
      <Reviews />
    ),
  };

  const workTab = {
    id: 'work',
    label: 'Work',
    content: (
      <Work />
    ),
  };

  const packagesTab = {
    id: 'packages',
    label: 'Packages',
    content: (
      <Packages />
    ),
  };

  let resolvedTabs = []
  if (ffPortfolio && userData.portfolio_items.length > 0) resolvedTabs = resolvedTabs.concat(workTab);
  resolvedTabs = resolvedTabs.concat(reviewsTab);
  if (ffPackages && hasPublishedPackages()) resolvedTabs = resolvedTabs.concat(packagesTab);

  return (
    <SageTabs
      className="tabs"
      initialActiveId={activeTabId}
      onClickTab={(e) => {
        setActiveTabId(e);
        navigate(`?tab=${e}`);
      }}
      tabs={resolvedTabs}
    />
  );
}
