import React, {
  useContext,
} from 'react';
import {
  Badge,
} from '@kajabi/sage-react';
import ProjectsShowContext from '../../ProjectsShowContext';
import isAnyQuoteAccepted from '../../../../../scripts/isAnyQuoteAccepted.ts';

export default function DetailsBadge() {
  const {
    project,
  } = useContext(ProjectsShowContext);

  const {
    status,
  } = project;

  const isProjectClosed = status === 'Closed';
  const isProjectComplete = status === 'Complete';

  return (
    <div className="details-badge">
      {isProjectClosed && <Badge value="Closed" />}
      {isProjectComplete && (
        <Badge
          color="published"
          value="Complete"
        />
      )}
      {isAnyQuoteAccepted(project) && !isProjectComplete && (
        <Badge
          className="details__active-badge"
          color="warning"
          value="Active"
        />
      )}
    </div>
  );
}
