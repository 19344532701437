/* eslint-disable no-console */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  FormSection,
} from '@kajabi/sage-react';
import FormActions from './FormActions';
import FormCustomMessage from './FormCustomMessage';
import CheckboxGroup from '../../shared/formik/CheckboxGroup';
import Input from '../../shared/formik/Input';
import RadioGroup from '../../shared/formik/RadioGroup';
import Select from '../../shared/formik/Select';
import Textarea from '../../shared/formik/Textarea';
import ProjectFormContext from '../../../contexts/ProjectFormContext';

const subcategories = require('../../../data/categories/subcategories');

export default function FormQuestionnaire({
  subcategory,
  subtitle,
  title,
}) {
  const subcategoryData = subcategories[subcategory];

  const {
    formStep,
    projectMainDetails,
    projectDetails,
    projectPreferences,
    scrollToTop,
    setClickedSubmit,
    setFormStep,
    setProjectMainDetails,
    setProjectDetails,
    setProjectPreferences,
  } = useContext(ProjectFormContext);

  const valuesPerStep = [projectMainDetails, projectDetails, projectPreferences];
  const settersPerStep = [setProjectMainDetails, setProjectDetails, setProjectPreferences];
  const currentValues = { ...subcategoryData.initialValues, ...valuesPerStep[formStep] };

  return (
    <div className="questionnaire">
      <Formik
        initialValues={currentValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          settersPerStep[formStep](values);
          setFormStep(formStep + 1);
        }}
        validationSchema={subcategoryData.validationSchema}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <div className="questionnaire__content">
            <FormSection
              subtitle={subtitle}
              title={title}
            >
              {subcategoryData.questions.map((question) => (
                <div
                  className="question"
                  key={question.id}
                >
                  {question.type === 'checkbox' && (
                    <CheckboxGroup
                      handleChange={handleChange}
                      name={question.id}
                      options={question.options}
                      title={question.prompt}
                    />
                  )}
                  {question.type === 'input' && (
                    <Input
                      errors={errors[question.id]}
                      handleChange={handleChange}
                      idName={question.id}
                      label={question.prompt}
                      placeholder={question.placeholder}
                      touched={touched[question.id]}
                      value={values[question.id]}
                    />
                  )}
                  {question.type === 'radio' && (
                    <RadioGroup
                      handleChange={handleChange}
                      name={question.id}
                      options={question.options}
                      subtitle={question.message}
                      title={question.prompt}
                    />
                  )}
                  {question.type === 'select' && (
                    <Select
                      handleChange={handleChange}
                      idName={question.id}
                      label={question.prompt}
                      options={question.options}
                      value={values[question.id]}
                    />
                  )}
                  {question.type === 'textarea' && (
                    <Textarea
                      className={question.id === 'description' && 'form-textarea--description'}
                      customMessage={question.id === 'description' && (
                        <FormCustomMessage
                          maxLength={5000}
                          minLength={100}
                          characterCount={values[question.id].length}
                        />
                      )}
                      errors={errors[question.id]}
                      handleChange={handleChange}
                      idName={question.id}
                      label={question.prompt}
                      placeholder={question.placeholder}
                      touched={touched[question.id]}
                      value={values[question.id]}
                    />
                  )}
                </div>
              ))}
            </FormSection>
            <FormActions
              nextButtonText="Continue"
              onNextClick={() => {
                handleSubmit();
                scrollToTop();
                if (formStep === 0) setClickedSubmit(true);
              }}
              onPrevClick={() => {
                setFormStep(formStep - 1);
                scrollToTop();
              }}
              loading={false}
              prevButtonDisabled={formStep === 0}
            />
          </div>
        )}
      </Formik>
    </div>
  );
}

FormQuestionnaire.propTypes = {
  subcategory: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
