import React, {
  useContext,
} from 'react';
import {
  Tag,
} from '@kajabi/sage-react';
import ExpertsDirectoryIndexContext from './ExpertsDirectoryIndexContext';
import {
  categories,
} from '../../../data/categories/categories';
import withActiveCategoriesOnly from '../../../scripts/withActiveCategoriesOnly';

export default function ExpertsFiltersTags() {
  const {
    categoriesFilter,
    removeCategory,
    updateCategoriesFilter,
  } = useContext(ExpertsDirectoryIndexContext);

  const categoryName = (slug) => {
    let name = '';
    withActiveCategoriesOnly(categories).forEach((category) => {
      withActiveCategoriesOnly(category.subcategories).forEach((subcategory) => {
        if (subcategory.slug === slug) name = subcategory.title;
      });
    });
    return name;
  };

  return (
    <div className="filters-container">
      {categoriesFilter.map((slug) => (
        <Tag
          key={slug}
          dismissAttributes={{ onClick: () => removeCategory(slug) }}
          showDismiss
          value={categoryName(slug)}
        />
      ))}
      {categoriesFilter.length > 1 && (
        <Tag
          className="filters-container__clear-all"
          dismissAttributes={{ onClick: () => updateCategoriesFilter([]) }}
          showDismiss
          value="Clear all"
        />
      )}
    </div>
  );
}
