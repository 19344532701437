import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Icon,
} from '@kajabi/sage-react';
import QuoteDetails from '../shared/QuoteDetails';
import QuoteReviewModal from '../shared/QuoteReviewModal';
import QuoteStatus from '../../../shared/QuoteStatus';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import quoteCancel from '../../../../scripts/quoteCancel';

export default function Quote({
  quote,
  vendor,
}) {
  const {
    type: userType,
  } = useContext(CurrentUserContext);

  const {
    id,
    quote_type: quoteType,
    status,
  } = quote;

  const canceledRegEx = /cancell?ed/;
  const isVendor = userType === 'vendor';
  const isAccepted = status === 'accepted';
  const isCanceled = canceledRegEx.test(status);
  const isDeclined = status === 'declined';
  const isProposal = quoteType === 'proposal';

  const [modalActive, setModalActive] = useState(false);

  const modalClose = () => {
    setModalActive(false);
  };

  const iconHeader = isProposal ? 'rename' : 'payout';
  const textHeader = isProposal ? 'Proposal' : 'Invoice';
  const textReviewButton = isProposal ? 'Review proposal' : 'Review invoice';

  return (
    <>
      <Card
        compact
      >
        <div className="quote__header">
          <div className="quote__type">
            <Icon icon={iconHeader} />
            <p className="t-sage-heading-6">{textHeader}</p>
          </div>
          <QuoteStatus quote={quote} />
        </div>
        <hr className="quote__divider" />
        <QuoteDetails
          quote={quote}
        />
        {isVendor && !isAccepted && !isCanceled && !isDeclined && (
          <div className="quote__footer">
            <Button
              color="secondary"
              onClick={() => quoteCancel(id)}
            >
              Cancel
            </Button>
          </div>
        )}
        {!isVendor && !isAccepted && !isCanceled && !isDeclined && (
          <div className="quote__footer">
            <Button
              onClick={() => setModalActive(true)}
            >
              {textReviewButton}
            </Button>
          </div>
        )}
      </Card>
      {vendor && (
        <QuoteReviewModal
          active={modalActive}
          onClose={modalClose}
          quote={quote}
          vendor={vendor}
        />
      )}
    </>
  );
}

Quote.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.number,
    quote_type: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({}).isRequired,
};
