import React, {
  useContext,
} from 'react';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import TimelineCard from './TimelineCard';

export default function TimelineClosed() {
  const project = useContext(CurrentProjectContext);

  return (
    <TimelineCard
      classNames="closed"
      icon="info-circle"
      message="closed this project"
      timestamp={project.updated_at}
      userName={project.user.name}
    />
  );
}
