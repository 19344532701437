import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import uuid from 'react-uuid';
import TimelineBrief from './TimelineBrief';
import TimelineClosed from './TimelineClosed';
import TimelineComplete from './TimelineComplete';
import TimelineExperts from './TimelineExperts';
import TimelineQuote from './TimelineQuote';
import TimelineQuoteStatus from './TimelineQuoteStatus';
import TimelineRating from './TimelineRating';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';

export default function Timeline() {
  const project = useContext(CurrentProjectContext);
  const user = useContext(CurrentUserContext);
  const [allObjects, setAllObjects] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const tempObjects = [];
    project.ratings.forEach((rating) => {
      tempObjects.push({
        type: 'rating',
        data: rating,
      });
    });
    project.filtered_quotes.forEach((quote) => {
      tempObjects.push({
        type: 'quote',
        data: quote,
      });
    });
    tempObjects.push({
      type: 'project',
      data: project,
    });

    tempObjects.sort((a, b) => Date.parse(a.data.updated_at) - Date.parse(b.data.updated_at));
    tempObjects.reverse();
    setAllObjects(tempObjects);
  }, []);

  useEffect(() => {
    if (allObjects) {
      allObjects.forEach((obj) => {
        if (obj.type === 'quote') {
          fetch(`${window.location.origin}/users/${obj.data.vendor_id}.json`)
            .then((response) => response.json())
            .then((data) => setVendors((old) => [...old, data]));
        }
      });
    }
  }, [allObjects]);

  return (
    <section className="timeline">
      <p className="t-sage-heading-4">Timeline</p>
      <hr className="timeline__hr" />
      <div className="timeline__content">
        {allObjects.map((obj) => {
          if (obj.type === 'project' && project.status === 'Complete') {
            return (
              <TimelineComplete
                key={uuid()}
              />
            );
          }
          if (obj.type === 'project' && project.status === 'Closed') {
            return (
              <TimelineClosed
                key={uuid()}
              />
            );
          }
          if (obj.type === 'rating') {
            return (
              <TimelineRating
                key={uuid()}
                rating={obj.data}
              />
            );
          }
          if (obj.type === 'quote' && vendors.length === project.filtered_quotes.length) {
            let thisVendor;
            vendors.forEach((v) => {
              if (obj.data.vendor_id === v.id) thisVendor = v;
            });
            if (obj.data.updated_at !== obj.data.created_at) {
              return (
                <Fragment key={uuid()}>
                  <TimelineQuoteStatus
                    quote={obj.data}
                    vendor={thisVendor}
                  />
                  <TimelineQuote
                    quote={obj.data}
                    vendor={thisVendor}
                  />
                </Fragment>
              );
            }
            return (
              <TimelineQuote
                key={uuid()}
                quote={obj.data}
                vendor={thisVendor}
              />
            );
          }
          return false;
        })}
        {user.type === 'user' && <TimelineExperts />}
        <TimelineBrief />
      </div>
    </section>
  );
}
