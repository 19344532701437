const app = document.getElementById('app');
const config = JSON.parse(app.getAttribute('data-config'));
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': config.authenticityToken,
};

function portfolioItem({
  body = null,
  callback,
  method,
  route,
}) {
  fetch(route, {
    method,
    headers,
    body,
  })
    .then((res) => res.text())
    .then((res) => callback(res));
}

export function portfolioItemPost(payload, callback = () => { }) {
  portfolioItem({
    body: JSON.stringify({ portfolio_item: payload }),
    callback,
    method: 'POST',
    route: '/portfolio_items',
  });
}

export function portfolioItemUpdate(payload, callback = () => { }) {
  portfolioItem({
    body: JSON.stringify({ portfolio_item: payload }),
    callback,
    method: 'PUT',
    route: `/portfolio_items/${payload.id}`,
  });
}

export function portfolioItemDelete(id, callback = () => { }) {
  portfolioItem({
    callback,
    method: 'DELETE',
    route: `/portfolio_items/${id}`,
  });
}
