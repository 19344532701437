import React from 'react';

export default function ExpertLegalBlurb() {
  return (
    <pds-box align-items="center" class="legal-blurb" size="10" size-md="5">
      <p>
        A note about the directory. Please note that the Experts listed in our
        directory are independent third parties not affiliated with Kajabi.
        Kajabi does not endorse or sponsor any Expert or listing.
      </p>
    </pds-box>
  );
}
