import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProjectFormContext from '../../../contexts/ProjectFormContext';

export default function FormCustomMessage({
  characterCount,
  maxLength,
  minLength,
}) {
  const {
    clickedSubmit,
  } = useContext(ProjectFormContext);

  const errorUnder = (characterCount < minLength) && clickedSubmit;

  const classNamesHint = classnames('form-custom-message__hint', {
    'form-custom-message__error': errorUnder,
  });

  const classNamesCharacterCount = classnames(
    'form-custom-message__character-count',
    {
      'form-custom-message__error': characterCount > maxLength,
    },
  );

  return (
    <div className="form-custom-message">
      {minLength && (
        <div
          className={classNamesHint}
        >
          {`Minimum of ${minLength} characters`}
        </div>
      )}
      {(characterCount || maxLength) && (
        <div
          className={classNamesCharacterCount}
        >
          {`${characterCount}/${maxLength}`}
        </div>
      )}
    </div>
  );
}

FormCustomMessage.defaultProps = {
  characterCount: 0,
  maxLength: null,
  minLength: null,
};

FormCustomMessage.propTypes = {
  characterCount: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};
