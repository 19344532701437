export default function ratingPost(
  payload,
  callback = () => {},
) {
  const {
    projectId,
    rating,
    review,
  } = payload;
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(`/projects/${projectId}/ratings`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      rating,
      review,
    }),
  })
    .then((res) => res.text())
    .then(() => callback());
}
