import React, {
  useContext,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import AppContext from '../../AppContext';

export default function PlanUpgradeButton() {
  const {
    setPlanUpgradeModalOpen,
  } = useContext(AppContext);

  return (
    <Button
      onClick={() => setPlanUpgradeModalOpen(true)}
    >
      Upgrade your plan
    </Button>
  );
}
