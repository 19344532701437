import React, {
  useContext,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import WithLoadingEffect from '../../shared/WithLoadingEffect';
import ExpertsDirectoryIndexContext from './ExpertsDirectoryIndexContext';
import pluralize from '../../../scripts/pluralize';

export default function Heading() {
  const {
    loading,
    preferred,
    setIsFilterDrawerActive,
    setPageNumber,
    setPreferred,
    setSortBy,
    sortBy,
    total,
  } = useContext(ExpertsDirectoryIndexContext);

  return (
    <div className="experts-heading">
      <h3 className="experts-heading__title">
        <WithLoadingEffect loading={loading}>
          {total}
          {' '}
          {pluralize('Expert', total)}
        </WithLoadingEffect>
      </h3>
      <div className="sage-checkbox">
        <input
          className="sage-checkbox__input"
          id="preferred"
          type="checkbox"
          checked={preferred}
          onChange={(e) => {
            setPreferred(e.target.checked);
            setPageNumber(1);
          }}
        />
        <label
          className="sage-checkbox__label"
          htmlFor="preferred"
        >
          Preferred Experts
        </label>
      </div>
      <div className="sage-select">
        <select className="sage-select__field sort-by" onChange={(e) => setSortBy(e.target.value)}>
          <option value="time_on_platform" selected={sortBy === 'time_on_platform'}>Sort by: Time on platform</option>
          <option value="recommended" selected={sortBy === 'recommended'}>Sort by: Recommended</option>
        </select>
        <i className="sage-select__arrow" aria-hidden="true" />
      </div>
      <Button
        color="secondary"
        onClick={() => setIsFilterDrawerActive(true)}
      >
        Filter Experts
      </Button>
    </div>
  );
}
