import React from 'react';
import {
  Dropdown,
  Icon,
  Link,
} from '@kajabi/sage-react';

export default function Templates() {
  const items = [
    {
      id: 'website',
      label: 'Website Templates',
      onClick: () => { window.open('https://templates.kajabi.com/collections/website-templates', '_blank'); },
    },
    {
      id: 'landing',
      label: 'Landing Page Templates',
      onClick: () => { window.open('https://templates.kajabi.com/collections/landing-page-templates', '_blank'); },
    },
    {
      id: 'product',
      label: 'Product Templates',
      onClick: () => { window.open('https://templates.kajabi.com/collections/product-templates', '_blank'); },
    },
  ];

  return (
    <section className="templates">
      <div className="templates__content templates__content--desktop">
        <Dropdown
          className="dropdown templates-dropdown"
          label={(
            <div className="templates-dropdown__trigger">
              Templates
              <Icon
                adjacentType="body"
                icon="caret-down"
              />
            </div>
      )}
          triggerButtonSubtle
        >
          <Dropdown.ItemList
            items={items}
          />
        </Dropdown>
      </div>
      <div className="templates__content templates__content--mobile">
        <Link
          href="https://templates.kajabi.com"
          removeUnderline
          style={Link.COLORS.SECONDARY}
          target="_blank"
        >
          Templates
        </Link>
      </div>
    </section>
  );
}
