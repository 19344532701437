import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Tabs as SageTabs,
} from '@kajabi/sage-react';
import Details from './Details';
import Packages from './packages/Packages';
import Testimonials from './testimonials/Testimonials';
import Plans from './plans/Plans';
import Services from './Services';
import Work from './work/Work';
import UsersEditContext from '../../../contexts/UsersEditContext';

export default function Tabs() {
  const {
    ffPortfolio,
    ffPackages,
    ffPlans,
    ffTestimonials,
  } = useContext(UsersEditContext);

  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState('details');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('tab')) setActiveTabId(urlParams.get('tab'));
  }, []);

  const detailsTab = {
    id: 'details',
    label: 'Details',
    content: (
      <Details />
    ),
  };

  const servicesTab = {
    id: 'services',
    label: 'Services',
    content: (
      <Services />
    ),
  };

  const workTab = {
    id: 'work',
    label: 'Work',
    content: (
      <Work />
    ),
  };

  const plansTab = {
    id: 'plans',
    label: 'Plans',
    content: (
      <Plans />
    ),
  };

  const packagesTab = {
    id: 'packages',
    label: 'Packages',
    content: (
      <Packages />
    ),
  };

  const testimonialsTab = {
    id: 'testimonials',
    label: 'Testimonials',
    content: (
      <Testimonials />
    ),
  };

  let resolvedTabs = [detailsTab, servicesTab];
  if (ffPortfolio) resolvedTabs = [...resolvedTabs, workTab];
  if (ffPlans) resolvedTabs = [...resolvedTabs, plansTab];
  if (ffPackages) resolvedTabs = [...resolvedTabs, packagesTab];
  if (ffTestimonials && window.entitlements.testimonialCreate.enabled) {
    resolvedTabs = [...resolvedTabs, testimonialsTab];
  }

  return (
    <SageTabs
      className="tabs"
      initialActiveId={activeTabId}
      onClickTab={(e) => {
        setActiveTabId(e);
        navigate(`?tab=${e}`);
      }}
      tabs={resolvedTabs}
    />
  );
}
