import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import Input from '../../../shared/formik/Input';
import Textarea from '../../../shared/formik/Textarea';
import Uploader from '../shared/Uploader';
import validations from '../../../../data/shared/validations';
import {
  portfolioItemPost,
  portfolioItemUpdate,
} from '../../scripts/portfolioItem';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import WorkContext from './WorkContext';

export default function PortfolioItemModal({
  active,
  onClose,
}) {
  const {
    id,
  } = useContext(CurrentUserContext);

  const {
    currentPortfolioItem,
    fetchUserData,
    portfolioItemImageConfig,
    portfolioItemModalData,
  } = useContext(WorkContext);

  const {
    type,
  } = portfolioItemModalData;

  const blankInitialValues = {
    description: '',
    title: '',
  };

  const [image, setImage] = useState(null);
  const [initialValues, setInitialValues] = useState(blankInitialValues);

  const modalTitleString = `${type === 'edit' ? 'Edit' : 'Add'} project`;

  const resetValues = () => {
    setInitialValues(blankInitialValues);
    setImage(null);
  };

  const setValues = () => {
    if (type === 'add') {
      resetValues();
    } else if (type === 'edit') {
      setInitialValues({
        description: currentPortfolioItem.description,
        title: currentPortfolioItem.title,
      });
      setImage(currentPortfolioItem.image);
    }
  };

  useEffect(() => {
    if (portfolioItemModalData.active) {
      setValues();
    } else {
      resetValues();
    }
  }, [portfolioItemModalData]);

  return (
    <Modal
      active={active}
      className="portfolio-item-modal"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, {
          resetForm,
          setSubmitting,
        }) => {
          setSubmitting(false);
          onClose();
          if (type === 'add') {
            portfolioItemPost(
              {
                ...values,
                user_id: id,
                image,
              },
              () => fetchUserData(),
            );
          } else if (type === 'edit') {
            portfolioItemUpdate(
              {
                ...values,
                id: currentPortfolioItem.id,
                image,
              },
              () => fetchUserData(),
            );
          }
          resetForm();
        }}
        validationSchema={
      Yup.object({
        title: Yup.string()
          .max(72, validations.sizeStringMaximum(72))
          .required(validations.stringRequired),
        description: Yup.string()
          .required(validations.stringRequired),
      })
    }
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
        }) => (
          <>
            <Modal.Header
              aside={(
                <Button
                  color="secondary"
                  icon="remove"
                  iconOnly
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                  subtle
                />
              )}
              title={modalTitleString}
            />
            <Modal.Body>
              <Input
                errors={errors.title}
                handleChange={handleChange}
                idName="title"
                label="Title"
                placeholder="A short title describing the project"
                touched={touched.title}
                value={values.title}
              />
              <Textarea
                errors={errors.description}
                handleChange={handleChange}
                idName="description"
                label="Description"
                placeholder="Describe the work you did on this project..."
                touched={touched.description}
                value={values.description}
              />
              <Uploader
                asset={image}
                config={portfolioItemImageConfig}
                onSuccess={((payload) => {
                  const file = payload.files[0];
                  setImage(`https://s3.amazonaws.com/${file.bucket}/${file.key}`);
                })}
              />
              <Button.Group
                align="end"
                gap="sm"
              >
                <Button
                  color="secondary"
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Button.Group>
            </Modal.Body>
          </>
        )}
      </Formik>
    </Modal>
  );
}

PortfolioItemModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
