import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';
import Linkifier from '../../../shared/Linkifier';

export default function UserPaneAboutItem({
  entry,
  icon,
  isLink,
  string,
}) {
  const resolvedString = string || entry;
  if (entry) {
    return (
      <div className="t-sage-body-small user-pane-about__item">
        <Icon icon={icon} />
        {isLink && <Linkifier>{resolvedString}</Linkifier>}
        {!isLink && <p>{resolvedString}</p>}
      </div>
    );
  }
}

UserPaneAboutItem.defaultProps = {
  entry: null,
  isLink: false,
  string: null,
};

UserPaneAboutItem.propTypes = {
  entry: PropTypes.node,
  icon: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  string: PropTypes.string,
};
