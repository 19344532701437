export default function undoDeclineProject(
  projectRefusalId
) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(`/api/v1/project_refusals/${projectRefusalId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.text())
    .then(() => window.location.replace('/projects'));
}
