import React from 'react';
import FAQQuestion from '../../../shared/landing-pages/FAQQuestion';

export default function FAQQuestions() {
  return (
    <div className="faq-questions">
      <div className="faq-questions__item">
        <FAQQuestion
          label="How will Kajabi market Expert+ members to their customers?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">Kajabi promotes the Experts Marketplace in a variety of marketing to customers at different points in their Kajabi journey. Marketing for the Experts Marketplace drives Kajabi customers to landing pages where Experts on the Expert+ Plan are prominently displayed compared to Experts on the Approved Plan.</p>
        </FAQQuestion>
      </div>
      <div className="faq-questions__item">
        <FAQQuestion
          label="When can I upgrade my plan?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">You can change your plan from Approved to Expert+ at anytime.</p>
        </FAQQuestion>
      </div>
      <div className="faq-questions__item">
        <FAQQuestion
          label="How long will it take for my profile to be upgraded?"
        >
          <p className="t-sage-body t-sage--color-charcoal-200">When you subscribe to the Expert+ Plan, your profile will be upgraded in 24 hours or less.</p>
        </FAQQuestion>
      </div>
    </div>
  );
}
