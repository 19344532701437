import React from 'react';
import PropTypes from 'prop-types';

export default function Testimonial({
  client,
  expert,
  review,
  user,
}) {
  return (
    <div className="testimonial">
      <a className="testimonial__expert" href={`https://experts.kajabi.com/users/${user}/reviews`}>{expert}</a>
      <img
        alt="5 Stars"
        className="testimonial__image"
        src="images/stars.png"
      />
      <p className="testimonial__review">{review}</p>
      <p className="testimonial__client">{client}</p>
    </div>
  );
}

Testimonial.propTypes = {
  client: PropTypes.string.isRequired,
  expert: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};
