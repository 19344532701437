import withAuthStatusChecked from './withAuthStatusChecked';

export default function messageMarkAsRead(id, callback = null) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(`/messages/${id}/mark_read`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      message: {
        read: true,
      },
    }),
  }).then((res) => withAuthStatusChecked(res))
    .then((res) => res.text())
    .then(() => {
      if (callback) callback();
    })
    .catch(() => { });
}
