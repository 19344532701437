export default function vendorApplicationPost(payload) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  return fetch('/apply', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      finalData: payload,
    }),
  }).then((res) => res.text());
}
