import React from 'react';
import CTA from './CTA';
import FAQ from './FAQ';
import Features from './Features';
import Hero from './Hero';
import PlansList from './PlansList';
import Questionnaire from './Questionnaire';

export default function VendorApplicationsNew() {
  return (
    <>
      <Hero />
      <Features />
      <PlansList />
      <CTA />
      <Questionnaire />
      <FAQ />
    </>
  );
}
