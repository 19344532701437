import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@kajabi/sage-react';
import Avatar from '../../shared/Avatar';
import IconText from '../../shared/IconText';
import Rating from '../../shared/Rating';
import pluralize from '../../../scripts/pluralize';

function ProjectsStats({ projectsCount }) {
  if (!projectsCount) return <span className="expert-stats-text__font">No projects</span>;

  return (
    <div>
      <span className="expert-stats__value">{projectsCount}</span>
      <span className="expert-stats-text__font">{` ${pluralize('Project', projectsCount)}`}</span>
    </div>
  );
}

ProjectsStats.propTypes = {
  projectsCount: PropTypes.number.isRequired,
};

function RatingsStats({ ratingsCount, ratingsTotal }) {
  if (!ratingsCount) return <span>No reviews</span>;

  return (
    <Rating
      count={ratingsCount}
      forDirectory
      total={ratingsTotal}
    />
  );
}

RatingsStats.propTypes = {
  ratingsCount: PropTypes.number.isRequired,
  ratingsTotal: PropTypes.number.isRequired,
};

const expertUrl = (slug, id, plan) => (plan === 'growth' && slug ? `/${slug}` : `/users/${id}`);

export default function Expert({
  expert,
}) {
  const {
    company_name: companyName,
    id,
    location,
    name,
    ratings_count: ratingsCount,
    ratings_total: ratingsTotal,
    projects_count: projectsCount,
    show_preferred_badge: showPreferredBadge,
    slug,
    plan,
  } = expert;

  const hasProjectOrReview = Boolean(ratingsCount || projectsCount);

  return (
    <a href={expertUrl(slug, id, plan)} className="expert-card">
      <div className="expert-card__content">
        {showPreferredBadge && (
          <div className="expert-card__preferred">
            <Badge
              className="expert-card__preferred-badge"
              color="published"
              value={<IconText icon="check" text="Preferred" />}
            />
          </div>
        )}
        <div className="expert-card__body">
          <div className="expert-card__avatar">
            <Avatar
              className="profile-header__avatar"
              size={null}
              user={expert}
            />
          </div>
          <div className="expert-card__information">
            <div className="expert-card__name">{name}</div>
            <div className="expert-card__location">
              <IconText
                icon="location"
                text={location}
              />
            </div>
          </div>
        </div>
        {companyName && <div className="expert-card__company">{companyName}</div>}
      </div>
      {hasProjectOrReview && (
        <div className="expert-card__footer">
          <div className="expert-card__footer--left">
            <ProjectsStats projectsCount={projectsCount} />
          </div>
          <div className="expert-card__footer--right">
            <RatingsStats ratingsCount={ratingsCount} ratingsTotal={ratingsTotal} />
          </div>
        </div>
      )}
    </a>
  );
}

Expert.propTypes = {
  expert: PropTypes.shape({
    company_name: PropTypes.string,
    id: PropTypes.number,
    location: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    ratings_count: PropTypes.number,
    ratings_total: PropTypes.number,
    plan: PropTypes.string,
    projects_count: PropTypes.number,
    show_preferred_badge: PropTypes.bool,
  }).isRequired,
};
