import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import Tabs from './Tabs';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import UsersEditContext from '../../../contexts/UsersEditContext';
import userServicesPost from '../../../scripts/userServicesPost';
import userSettingsPost from '../../../scripts/userSettingsPost';

export default function UsersEdit() {
  // Feature flag(s)
  const ffPlans = window.globalFeatureFlags.plans.enabled;
  const ffPackages = window.globalFeatureFlags.packages.enabled;
  const ffPortfolio = window.globalFeatureFlags.expertProfilePortfolio.enabled;
  const ffTestimonials = window.globalFeatureFlags.testimonials.enabled;

  const {
    id,
  } = useContext(CurrentUserContext);
  const [user, setUser] = useState(null);
  const [categories, setCategories] = useState(null);
  const [userCategories, setUserCategories] = useState(null);
  const [contextValues, setContextValues] = useState(null);

  function triggerSavedToast() {
    sessionStorage.setItem(
      'toastData',
      JSON.stringify({
        icon: 'check-circle-filled',
        title: 'Saved',
      }),
    );
  }

  const onServicesSave = (values) => {
    const subcategoryIds = [];
    categories.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        values.forEach((value) => {
          if (value === subcategory.name) subcategoryIds.push(subcategory.id);
        });
      });
    });
    userServicesPost({
      callback: () => window.location.replace(window.location.href),
      subcategories: subcategoryIds,
      userId: user.id,
    });
    triggerSavedToast();
  };

  const onUserSave = (values) => {
    userSettingsPost({
      about: values.about,
      avatar: values.avatar,
      companyName: values.companyName,
      headline: values.headline,
      kajabiTenure: values.kajabiTenure,
      languages: values.languages,
      location: values.location,
      paypalEmail: values.paypalEmail,
      schedulingLink: values.schedulingLink,
      slug: values.slug,
      website: values.website,
    });
    triggerSavedToast();
  };

  useEffect(() => {
    fetch(`${window.location.origin}/users/${id}/edit.json`)
      .then((response) => response.json())
      .then((data) => setUser(data));
  }, []);

  useEffect(() => {
    fetch(`${window.location.origin}/categories.json`)
      .then((response) => response.json())
      .then((data) => setCategories(data));
  }, []);

  useEffect(() => {
    fetch(`${window.location.origin}/users/${id}/services.json`)
      .then((response) => response.json())
      .then((data) => setUserCategories(data));
  }, []);

  useEffect(() => {
    if (user && categories && userCategories) {
      setContextValues({
        about: user.about || '',
        avatarImageConfig: window.uploaderConfig.avatar,
        avatar: user.avatar,
        categories,
        companyName: user.company_name || '',
        ffPlans,
        ffPackages,
        ffPortfolio,
        ffTestimonials,
        headline: user.headline || '',
        kajabiTenure: user.kajabi_tenure || 1,
        languages: user.languages || [],
        location: user.location || 'United States',
        onServicesSave,
        onUserSave,
        paypalEmail: user.paypal_email || '',
        schedulingLink: user.scheduling_link || '',
        slug: user.slug || '',
        userCategories,
        website: user.website || '',
      });
    }
  }, [user, categories, userCategories]);

  if (contextValues) {
    return (
      <UsersEditContext.Provider value={contextValues}>
        <div className="container container--medium">
          <header className="header">
            <h1 className="t-sage-heading-1 header__title">Edit Profile</h1>
            <Button
              className="header__button"
              color="secondary"
              icon="preview-on"
              onClick={() => {
                window.location = `${window.location.origin}/users/${user.id}`;
              }}
            >
              View Profile
            </Button>
          </header>
          <Tabs />
        </div>
      </UsersEditContext.Provider>
    );
  }
}
