import React, {
  useContext,
} from 'react';
import TimelineCard from './TimelineCard';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import ProjectBrief from '../../../../shared/ProjectBrief';

export default function TimelineBrief() {
  const project = useContext(CurrentProjectContext);

  return (
    <TimelineCard
      classNames="brief"
      icon="star"
      message="posted a project brief"
      timestamp={project.created_at}
      userAvatar={project.user.picture}
      userName={project.user.name}
    >
      <ProjectBrief />
    </TimelineCard>
  );
}
