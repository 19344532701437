/* eslint-disable no-param-reassign */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Attachment from '../shared/Attachment';
import Avatar from '../../../shared/Avatar';
import Linkifier from '../../../shared/Linkifier';
import Quote from './Quote';
import Timestamp from '../../../shared/Timestamp';
import iconUrl from '../../../../scripts/iconUrl.ts';
import systemUser from '../../../../data/shared/systemUser';
import truncated from '../../../../scripts/truncated';

export default function Message({
  message,
  project,
  vendor,
}) {
  const isSystem = !message.sender;

  const messageUser = useCallback(() => {
    if (isSystem) return systemUser;
    return message.sender || systemUser;
  });

  return (
    <div className="message">
      <div className="message__avatar">
        <Avatar
          size="32"
          user={messageUser()}
        />
      </div>
      <div className={classnames('message__content', { 'message__content--system': isSystem })}>
        <div className="message__header">
          <p className="t-sage-body-small message__name">{messageUser()?.name}</p>
          <Timestamp
            timestamp={message.created_at}
          />
        </div>
        <div className="message__body">
          {message.quote && !isSystem ? (
            <Quote
              project={project}
              quote={message.quote}
              vendor={vendor}
            />
          ) : (
            <Linkifier
              tagName="div"
            >
              {message.content}
            </Linkifier>
          )}
          <div className="message__attachments">
            {message.attachments.map((attachment) => (
              <Attachment
                filename={truncated(attachment.filename)}
                url={attachment.url}
                key={attachment.id}
                src={iconUrl(attachment)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

Message.propTypes = {
  message: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.shape({
      extension: PropTypes.string,
      filename: PropTypes.string,
      id: PropTypes.number,
      mimetype: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    content: PropTypes.node,
    created_at: PropTypes.string.isRequired,
    quote: PropTypes.shape({}),
    read: PropTypes.bool.isRequired,
    sender: PropTypes.shape({
      name: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      user_type: PropTypes.string.isRequired,
    }),
  }).isRequired,
  project: PropTypes.shape({}).isRequired,
  vendor: PropTypes.shape({}).isRequired,
};
