/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@kajabi/sage-react';

export default function UserPaneProject({
  category,
  id,
  status,
}) {
  const isProjectClosed = status === 'Closed';
  const isProjectOpen = status === 'Open';
  const isProjectComplete = status === 'Complete';

  let resolvedStatus = status;
  if (isProjectOpen) resolvedStatus = 'Active';

  let badgeColor = 'info';
  if (isProjectComplete) badgeColor = 'published';
  if (isProjectClosed) badgeColor = 'danger';

  return (
    <div
      className={`user-pane-project ${isProjectClosed && 'user-pane-project--disabled'}`}
      onClick={() => {
        if (!isProjectClosed) window.location.replace(`${window.location.origin}/projects/${id}`);
      }}
      role="presentation"
    >
      <div
        className="t-sage-body-small user-pane-project__category"
      >
        {category}
      </div>
      <Badge
        className="user-pane-project__badge"
        color={badgeColor}
        value={resolvedStatus}
      />
    </div>
  );
}

UserPaneProject.propTypes = {
  category: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};
