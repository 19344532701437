import React, {
  useContext,
} from 'react';
import FormQuestionnaire from './FormQuestionnaire';
import ProjectNewContext from '../../../contexts/ProjectNewContext';

export default function FormDetails() {
  const {
    subcategory,
  } = useContext(ProjectNewContext);

  return (
    <div className="questionnaire">
      <div>
        <FormQuestionnaire
          subcategory={subcategory.id}
          subtitle="Add details to help the expert understand your requirements and provide better price and timeline estimates for the project."
          title="Your project details"
        />
      </div>
    </div>
  );
}
