import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';

export default function ReopenProjectModal({
  active,
  onClose,
  onUpdate,
}) {
  return (
    <Modal
      active={active}
      className="close-project-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="tick"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Reopen this closed project?"
      />
      <Modal.Body>
        <p>
          Reopening this project will allow you to access and continue your conversations.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onUpdate();
              onClose();
            }}
          >
            Reopen project
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

ReopenProjectModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
