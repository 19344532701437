import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@kajabi/sage-react';
import scrollToTop from '../../../scripts/scrollToTop';
import AvailableProjects from './AvailableProjects';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { categories } from '../../../data/categories/categories';
import WithLoadingEffect from '../../shared/WithLoadingEffect';
import withActiveCategoriesOnly from '../../../scripts/withActiveCategoriesOnly';

const itemsPerPage = 10;

export default function ProjectsBrowse() {
  const user = useContext(CurrentUserContext);
  const {
    firstName,
    id,
  } = user;

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const servicesParam = searchParams.get('services');
  const badgeParam = searchParams.get('badge');
  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(pageParam ? Number(pageParam) : 1);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState(
    servicesParam ? servicesParam.split(';') : [],
  );
  const [badgeFilter, setBadgeFilter] = useState(badgeParam || '');
  const [expertCategories, setExpertCategories] = useState([]);
  const updateCategoriesFilter = useCallback((newList) => {
    setCategoriesFilter(newList);
    setPageNumber(1);
  });
  const removeCategory = useCallback((category) => {
    updateCategoriesFilter(categoriesFilter.filter((cat) => cat !== category));
  });
  const addCategory = useCallback((category) => {
    updateCategoriesFilter([...categoriesFilter, category]);
  });

  const fetchExpertCategories = useCallback(() => {
    fetch(`${window.location.origin}/users/${id}/services.json`)
      .then((response) => {
        if (response.ok || response.status == 304) {
          return response.json()
        } else {
          setLoadingFilters(false)
          throw new Error(`Request Error Fetching Categories: ${response.status}, ${response.statusText}`)
        }
      })
      .then(
        (data) => data
          .filter((service) => service.active)
          .reduce((accumulator, service) => {
            accumulator[service.subcategory.id] = service.subcategory.slug;
            return accumulator;
          }, {}),
      )
      .then((expertServiceIdSlugs) => categories.flat().map((category) => {
        const filteredSubcategories = category
          .subcategories.filter(
            (subcategory) => Object.values(expertServiceIdSlugs).includes(subcategory.slug),
          )
          .map((subcategory) => ({
            ...subcategory,
            id: Object.keys(expertServiceIdSlugs).find(
              (key) => expertServiceIdSlugs[key] === subcategory.slug,
            ) || subcategory.id,
          }));

        // Return the category only if expert has at least one subcategory
        return filteredSubcategories.length > 0 ? {
          ...category,
          subcategories: filteredSubcategories,
        } : null;
      }).filter((category) => category !== null))
      .then((expertServiceCaterories) => {
        setExpertCategories(expertServiceCaterories);
      })
      .then(() => setLoadingFilters(false));
  });

  useEffect(() => {
    fetchExpertCategories();
  }, []);

  const fetchProjects = useCallback(() => {
    setLoadingProjects(true);

    let filters = `page=${pageNumber}&per_page=${itemsPerPage}&scoped_to=available`;
    if (categoriesFilter.length > 0) {
      filters += `&services=${categoriesFilter.join(';')}`;
    }
    if (badgeFilter) {
      filters += `&badge=${badgeFilter}`;
    }

    fetch(`${window.location.origin}/projects.json?${filters}`)
      .then((response) => {
        setLoadingProjects(false);
        return response.json();
      })
      .then((data) => {
        setAvailableProjects(data.result || []); // in case of error set empty array
        setTotal(data.total || 0); // in case of error set to 0
      })
      .catch(() => setLoadingProjects(false));
  });

  useEffect(() => {
    const params = {};
    if (pageNumber > 1) params.page = pageNumber;
    if (categoriesFilter.length > 0) params.services = categoriesFilter.join(';');
    if (badgeFilter) params.badge = badgeFilter;

    setSearchParams(params);

    fetchProjects();
  }, [pageNumber, categoriesFilter, badgeFilter]);

  return (
    <section className="section">
      <div className="container container--large">
        <p className="t-sage-heading-2 greeting">{`Welcome back, ${firstName}.`}</p>
        <div className="available-projects__header">
          <p className="t-sage-heading-4">Projects you might like</p>
          <p className="t-sage--color-charcoal-200">Browse jobs that match your experience to a client’s hiring preferences. Ordered by most relevant.</p>
        </div>
        <div className="sage-row">
          <div className="sage-col-3 experts-filters">
            <p className="t-sage-heading-5">Approved categories</p>
            <ul className="sage-list available-filters">
              <WithLoadingEffect loading={loadingFilters} lines={10}>
                {withActiveCategoriesOnly(expertCategories).map((category) => (
                  <li key={category.id}>
                    <h4 className="sage-list__title">{category.title}</h4>
                    <ul className="sage-list">
                      {withActiveCategoriesOnly(category.subcategories).map((subcategory) => (
                        <li
                          className="sage-checkbox"
                          key={subcategory.id}
                        >
                          <input
                            className="sage-checkbox__input"
                            id={subcategory.id}
                            type="checkbox"
                            checked={categoriesFilter.includes(subcategory.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                addCategory(subcategory.id);
                              } else {
                                removeCategory(subcategory.id);
                              }
                            }}
                          />
                          <label
                            className="sage-checkbox__label"
                            htmlFor={subcategory.id}
                          >
                            {subcategory.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </WithLoadingEffect>
            </ul>
            <div className="available-filters--button">
              {categoriesFilter.length > 0 && (
                <Button
                  color="secondary"
                  onClick={() => updateCategoriesFilter([])}
                >
                  Clear all
                </Button>
              )}
            </div>
          </div>
          <div className="sage-col">
            <div className="sage-row sage-row--align-end">
              <div className="sage-col-5">
                <div className="sage-select">
                  <select className="sage-select__field" onChange={(e) => setBadgeFilter(e.target.value)}>
                    <option value="" selected={badgeFilter === ''}>Filter Projects</option>
                    <option value="new" selected={badgeFilter === 'new'}>New Projects</option>
                    <option value="hot" selected={badgeFilter === 'hot'}>Hot Projects</option>
                    <option value="low_competition" selected={badgeFilter === 'low_competition'}>Low Competition</option>
                    <option value="time_sensitive" selected={badgeFilter === 'time_sensitive'}>Time Sensitive</option>
                    <option value="large_budget" selected={badgeFilter === 'large_budget'}>Large Budget</option>
                  </select>
                  <i className="sage-select__arrow" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="sage-row">
              <AvailableProjects
                loading={loadingProjects}
                onPageNumberChange={(newPageNumber) => {
                  setPageNumber(newPageNumber);
                  setSearchParams({
                    page: newPageNumber,
                  });
                  scrollToTop();
                }}
                pageNumber={pageNumber}
                perPage={itemsPerPage}
                projects={availableProjects}
                total={total}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
