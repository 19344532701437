import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import Avatar from '../Avatar';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function DropdownUser() {
  const user = useContext(CurrentUserContext);

  function getUserString() {
    let userString = 'Client';
    if (user.type === 'vendor') userString = 'Expert';
    return userString;
  }

  return (
    <div className="dropdown-user">
      <Avatar user={user} />
      <div className="dropdown-user__text">
        <p className="t-sage-body-small dropdown-user__name">{user.name}</p>
        <p className="
          t-sage-body-xsmall
          t-sage--color-charcoal-400
          dropdown-user__designation"
        >
          {getUserString()}
        </p>
      </div>
      {(user.type === 'vendor') && (
        <Icon
          color="primary"
          icon="check"
        />
      )}
    </div>
  );
}
