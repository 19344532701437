import {
  languages,
  locations,
} from '../../../lib/user_options.json';

export const optionsLanguages = languages;

export const optionsLocations = locations;

export const optionsLocationsPreference = [
  'No location preference',
  ...optionsLocations,
];

const options = {
  languages: optionsLanguages,
  locations: optionsLocations,
};

export default options;
