import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';

export default function CloseProjectModal({
  active,
  onClose,
  onUpdate,
}) {
  return (
    <Modal
      active={active}
      className="close-project-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Close project?"
      />
      <Modal.Body>
        <p>
          {`Experts will no longer be able to message you about
            this project. You can always create a new project.`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id="close-project-modal-button"
            onClick={() => {
              onUpdate();
              onClose();
            }}
          >
            Close project
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

CloseProjectModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
