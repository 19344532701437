import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';

export default function PlanEntitlement({
  boldText,
  text,
  textColor,
}) {
  return (
    <li className={`entitlement ${textColor ? `t-sage--color-${textColor}` : ''}`}>
      <Icon
        adjacentType="body-sm"
        icon="check-circle"
      />
      <div className="entitlement__text">
        {boldText ? (
          <>
            <span className="entitlement__bold-text">{`${boldText} `}</span>
            <span>{text}</span>
          </>
        ) : text}
      </div>
    </li>
  );
}

PlanEntitlement.defaultProps = {
  boldText: null,
  textColor: null,
};

PlanEntitlement.propTypes = {
  boldText: PropTypes.string,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};
