import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

export default function timeAgo(date) {
  const parsedDate = Date.parse(date);
  const resolvedDate = new Date(parsedDate);
  const tA = new TimeAgo('en-US');
  return tA.format(resolvedDate);
}
