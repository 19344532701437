/* eslint-disable consistent-return */
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';
import UIIconStar from '../../../shared/UIIconStar';

export default function RatingScale({
  onSetRating,
}) {
  const [rating, setRating] = useState(null);

  useEffect(() => {
    if (rating) {
      return;
    }

    const icons = document.getElementsByClassName('rating-scale__icon');
    const iconContainers = document.getElementsByClassName('rating-scale');
    let icon;

    function handleMouseOver(e) {
      for (let i = 0; i < icons.length; i += 1) {
        icon = icons[i];
        const thisSVGPath = icon.querySelector('svg path');
        if (e.currentTarget.getAttribute('id') >= icon.getAttribute('id')) {
          thisSVGPath.style.fill = '#FFC505';
        } else {
          thisSVGPath.style.fill = 'grey';
        }
      }
    }

    function handleMouseLeave() {
      for (let i = 0; i < icons.length; i += 1) {
        icon = icons[i];
        const thisSVGPath = icon.querySelector('svg path');
        thisSVGPath.style.fill = 'grey';
      }
    }

    function handleClick(e) {
      const val = parseInt(e.currentTarget.getAttribute('id'), 10) + 1;
      setRating(val);
      onSetRating(val);
    }

    for (let i = 0; i < iconContainers.length; i += 1) {
      const iconContainer = iconContainers[i];
      iconContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    for (let i = 0; i < icons.length; i += 1) {
      icon = icons[i];
      icon.addEventListener('mouseover', handleMouseOver);
      icon.addEventListener('click', handleClick);
    }

    return () => {
      for (let i = 0; i < icons.length; i += 1) {
        icon = icons[i];
        icon.removeEventListener('mouseover', handleMouseOver);
        icon.removeEventListener('click', handleClick);
      }
      for (let i = 0; i < iconContainers.length; i += 1) {
        const iconContainer = iconContainers[i];
        iconContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [rating]);

  return (
    <div className="rating-scale">
      <div className="rating-scale__icon" id={0}>
        <UIIconStar color="grey" size="24" />
      </div>
      <div className="rating-scale__icon" id={1}>
        <UIIconStar color="grey" size="24" />
      </div>
      <div className="rating-scale__icon" id={2}>
        <UIIconStar color="grey" size="24" />
      </div>
      <div className="rating-scale__icon" id={3}>
        <UIIconStar color="grey" size="24" />
      </div>
      <div className="rating-scale__icon" id={4}>
        <UIIconStar color="grey" size="24" />
      </div>
      {rating && (
        <Button
          className="t-sage-body-xsmall rating-scale__edit"
          icon="pen"
          onClick={() => {
            setRating(null);
            onSetRating(null);
          }}
          subtle
        >
          Edit
        </Button>
      )}
    </div>
  );
}

RatingScale.propTypes = {
  onSetRating: PropTypes.func.isRequired,
};
