/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Hero as HeroTemplate } from '../shared/landing-pages/Hero';

export default function Hero() {
  return (
    <HeroTemplate
      className="welcome"
      descriptionDesktop={(
        <span>
          Join our brand new marketplace, where Kajabi Heroes hire experienced
          Kajabi service providers to level up their business.
        </span>
      )}
      descriptionMobile={(
        <span>
          Join our brand new marketplace, where Kajabi Heroes hire experienced
          Kajabi service providers to level up their business.
        </span>
      )}
      imgSrc="images/user_hero.png?v=2"
      secondaryLinkHref={`${window.location.origin}/apply`}
      secondaryLinkText="Become a Kajabi Expert"
      titleDesktop={(
        <div>
          Find a vetted <span>Kajabi Expert</span> or <span>Coach</span> to jumpstart your business
        </div>
      )}
      titleMobile={(
        <div>
          Find a vetted <span>Kajabi Expert</span> or <span>Coach</span> to jumpstart your business
        </div>
      )}
      useActionButtonPrimary
    />
  );
}
