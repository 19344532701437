import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';
import WorkContext from '../work/WorkContext';

export default function AddButton({
  disabled,
}) {
  const {
    setPortfolioItemModalData,
  } = useContext(WorkContext);

  return (
    <Button
      className="info__add-button"
      disabled={disabled}
      icon="add"
      onClick={() => setPortfolioItemModalData({
        active: true,
        type: 'add',
      })}
    >
      Add new project
    </Button>
  );
}

AddButton.defaultProps = {
  disabled: false,
};

AddButton.propTypes = {
  disabled: PropTypes.bool,
};
