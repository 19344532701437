import React from 'react';
import {
  Breadcrumbs as SageBreadcrumbs,
} from '@kajabi/sage-react';

export default function Breadcrumbs() {
  return (
    <SageBreadcrumbs
      className="breadcrumbs"
      items={
        [
          {
            href: '/projects/browse',
            label: 'All projects',
          },
        ]
      }
    />
  );
}
