/* eslint-disable no-console */
import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Modal,
  Button,
} from '@kajabi/sage-react';
import PackagesContext from './PackagesContext';
import {
  packageDelete,
  packageUpdate,
} from '../../scripts/package';

function ToggleStatus({ item }) {
  const {
    fetchPackages,
  } = useContext(PackagesContext);

  if (item.published) {
    return (
      <div
        className="item__action item__action--unpublish"
        onClick={() => {
          packageUpdate(
            {
              id: item.id,
              published: false,
            },
            () => fetchPackages(),
          );
        }}
        role="presentation"
      >
        <Icon
          adjacentType="body-sm"
          icon="lock"
        />
        Unpublish
      </div>
    );
  }

  return (
    <div
      className="item__action item__action--publish"
      onClick={() => {
        packageUpdate(
          {
            id: item.id,
            published: true,
          },
          () => fetchPackages(),
        );
      }}
      role="presentation"
    >
      <Icon
        adjacentType="body-sm"
        icon="world"
      />
      Publish
    </div>
  );
}

function ConfirmDeleteModal({
  active,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      active={active}
      className="confirm-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Delete package"
      />
      <Modal.Body>
        <div className="confirm-modal__content">
          <p className="confirm-modal__message">Are you sure you want to delete this package?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button icon="trash" onClick={onConfirm}>
            Delete
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

export default function Item({
  item,
}) {
  const {
    fetchPackages,
    setCurrentPackage,
    setPackageModalData,
  } = useContext(PackagesContext);

  const {
    id,
    image,
    price,
    title,
  } = item;

  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <div
      className="item"
    >
      {image && (
        <div className="item__image-frame">
          <img
            alt={title}
            className="item__image"
            src={image}
          />
        </div>
      )}
      <div className="item__content">
        <div className="item__text">
          <p className="t-sage-heading-6 item__title">{title}</p>
        </div>
        <div className="item__text">
          <p className="t-sage-heading-6 item__title">
            {`$${price}`}
          </p>
        </div>
        <div className="item__actions">
          <div
            className="item__action item__action--edit"
            onClick={() => {
              setCurrentPackage(item);
              setPackageModalData({
                active: true,
                type: 'edit',
              });
            }}
            role="presentation"
          >
            <Icon
              adjacentType="body-sm"
              icon="pen"
            />
            Edit
          </div>
          <div
            className="item__action item__action--delete"
            onClick={() => setConfirmDelete(true)}
            role="presentation"
          >
            <Icon
              adjacentType="body-sm"
              icon="trash"
            />
            Delete
          </div>
          <ToggleStatus item={item} />
        </div>
      </div>

      <ConfirmDeleteModal
        active={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => packageDelete(id, () => fetchPackages())}
      />
    </div>
  );
}

const itemProps = {
  item: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.number,
    published: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
};

Item.propTypes = { ...itemProps };
ToggleStatus.propTypes = { ...itemProps };

ConfirmDeleteModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
