import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useCookies,
} from 'react-cookie';
import {
  useParams,
} from 'react-router-dom';
import {
  Alert as SageAlert,
} from '@kajabi/sage-react';
import CloseProjectModal from './CloseProjectModal';
import CompletionModal from './CompletionModal';
import Details from './details/Details';
import OutstandingInvoiceQuoteModal from './OutstandingInvoiceQuoteModal';
import QuoteReviewModal from '../../../shared/QuoteReviewModal';
import RatingModal from './RatingModal';
import ReopenProjectModal from './ReopenProjectModal';
import Threads from './threads/Threads';
import Timeline from './timeline/Timeline';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import ProjectsShowContext from '../ProjectsShowContext';
import ViewDefaultContext from './ViewDefaultContext';
import projectUpdate from '../../../../scripts/projectUpdate';
import ratingPost from '../../../../scripts/ratingPost';
import MutedAlert from '../../../messages/index/chat-pane/MutedAlert';

function ClientLikesYourWorkAlert({ project }) {
  const { proposed_to_vendor_id: proposedToVendorId } = project;
  const { isVendor } = useContext(CurrentUserContext);
  const [cookies, setCookie] = useCookies(['dismissedAlerts']);
  const dismissedAlertKey = `DismissedAlert-ClientLikesYourWork-${project.id}`;

  if (!isVendor || !proposedToVendorId || !!cookies[dismissedAlertKey]) return null;

  return (
    <SageAlert
      color="info"
      description="This client has directly messaged you about working on their project. Only you and this client can see this project."
      dismissable
      onDismiss={() => setCookie(dismissedAlertKey, true)}
      title="A client likes your work!"
    />
  );
}

ClientLikesYourWorkAlert.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    proposed_to_vendor_id: PropTypes.number,
  }).isRequired,
};

export default function ViewDefault() {
  const { id } = useParams();
  const {
    project,
  } = useContext(ProjectsShowContext);
  const { mutedAt } = useContext(CurrentUserContext);
  const isMuted = (
    !!(mutedAt) && project.filtered_messages.length === 0 && project.filtered_quotes.length === 0
  );
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
  const [isCloseProjectModalOpen, setIsCloseProjectModalOpen] = useState(false);
  const [isManualRatingModalOpen, setIsManualRatingModalOpen] = useState(false);
  const [isOutstandingInvoiceQuoteModalOpen, setOutstandingInvoiceQuoteModalOpen] = useState(false);
  const [isProjectComplete, setIsProjectComplete] = useState(false);
  const [isReopenProjectModalOpen, setIsReopenProjectModalOpen] = useState(false);
  const [isSequenceRatingModalOpen, setIsSequenceRatingModalOpen] = useState(false);

  const closeProjectModalClose = () => setIsCloseProjectModalOpen(false);
  const closeProjectModalOpen = () => setIsCloseProjectModalOpen(true);
  const completionModalClose = () => setIsCompletionModalOpen(false);
  const completionModalOpen = () => setIsCompletionModalOpen(true);
  const manualRatingModalClose = () => setIsManualRatingModalOpen(false);
  const manualRatingModalOpen = () => setIsManualRatingModalOpen(true);
  const outstandingInvoiceQuoteModalOpen = () => setOutstandingInvoiceQuoteModalOpen(true);
  const outstandingInvoiceQuoteModalClose = () => setOutstandingInvoiceQuoteModalOpen(false);
  const reopenProjectModalClose = () => setIsReopenProjectModalOpen(false);
  const reopenProjectModalOpen = () => setIsReopenProjectModalOpen(true);
  const sequenceRatingModalClose = () => setIsSequenceRatingModalOpen(false);
  const sequenceRatingModalOpen = () => setIsSequenceRatingModalOpen(true);

  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [quote, setQuote] = useState(null);
  const [vendor, setVendor] = useState(null);
  const viewDefaultValues = useMemo(() => ({
    closeProjectModalOpen,
    completionModalOpen,
    manualRatingModalOpen,
    outstandingInvoiceQuoteModalOpen,
    reopenProjectModalOpen,
    setIsQuoteModalOpen,
    setQuote,
    setVendor,
  }));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('review')) setIsManualRatingModalOpen(true);
  }, []);

  function updateProjectWithStatus({ status }) {
    projectUpdate({
      fetchPath: `/projects/${id}`,
      project,
      redirectPath: `/projects/${id}`,
      status,
    });
  }

  return (
    <ViewDefaultContext.Provider value={viewDefaultValues}>
      <div className="view-default">
        <div className="spacer">
          <ClientLikesYourWorkAlert project={project} />
          <Details />
          {isMuted ? <MutedAlert /> : <Threads />}
          <Timeline />
          <CloseProjectModal
            active={isCloseProjectModalOpen}
            onClose={closeProjectModalClose}
            onUpdate={() => {
              sessionStorage.setItem('toastData', JSON.stringify({
                icon: 'check-circle-filled',
                title: 'This project has been closed',
              }));
              updateProjectWithStatus({
                redirectPath: `/projects/${id}`,
                status: 'Closed',
              });
              closeProjectModalClose();
            }}
          />
          <ReopenProjectModal
            active={isReopenProjectModalOpen}
            onClose={reopenProjectModalClose}
            onUpdate={() => {
              sessionStorage.setItem('toastData', JSON.stringify({
                icon: 'check-circle-filled',
                title: 'This project has been reopened',
              }));
              updateProjectWithStatus({
                redirectPath: `/projects/${id}`,
                status: 'Open',
              });
              reopenProjectModalClose();
            }}
          />
          <CompletionModal
            active={isCompletionModalOpen}
            onClose={completionModalClose}
            onUpdate={() => {
              completionModalClose();
              sequenceRatingModalOpen();
              setIsProjectComplete(true);
            }}
          />
          <RatingModal
            active={isSequenceRatingModalOpen}
            onClose={() => {
              sequenceRatingModalClose();
              if (isProjectComplete) {
                sessionStorage.setItem('toastData', JSON.stringify({
                  icon: 'check-circle-filled',
                  title: 'This project has been marked complete',
                }));
                updateProjectWithStatus({
                  redirectPath: '/projects?tab=completed',
                  status: 'Complete',
                });
              }
            }}
            onUpdate={(payload) => ratingPost(payload)}
          />
          <RatingModal
            active={isManualRatingModalOpen}
            onClose={manualRatingModalClose}
            onUpdate={(payload) => {
              ratingPost(
                payload,
                () => window.location.replace(window.location.pathname),
              );
            }}
          />
          <OutstandingInvoiceQuoteModal
            active={isOutstandingInvoiceQuoteModalOpen}
            onClose={outstandingInvoiceQuoteModalClose}
          />
          {quote && vendor && (
            <QuoteReviewModal
              active={isQuoteModalOpen}
              onClose={() => setIsQuoteModalOpen(false)}
              quote={quote}
              vendor={vendor}
            />
          )}
        </div>
      </div>
    </ViewDefaultContext.Provider>
  );
}
