import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import {
  Drawer,
} from '@kajabi/sage-react';
import ExpertsDirectoryIndexContext from './ExpertsDirectoryIndexContext';
import {
  categories,
} from '../../../data/categories/categories';
import withActiveCategoriesOnly from '../../../scripts/withActiveCategoriesOnly';
import {
  optionsLanguages,
  optionsLocations,
} from '../../../data/shared/options';

export default function ExpertsFiltersDrawer() {
  const {
    addCategory,
    categoriesFilter,
    isFilterDrawerActive,
    languageFilter,
    locationFilter,
    nameFilter,
    removeCategory,
    setIsFilterDrawerActive,
    updateLanguageFilter,
    updateLocationFilter,
    updateNameFilter,
  } = useContext(ExpertsDirectoryIndexContext);

  const [nameValue, setNameValue] = useState(nameFilter);

  const handleInputNameChange = (event) => {
    setNameValue(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateNameFilter(nameValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [nameValue, 500]);

  return (
    <Drawer
      active={isFilterDrawerActive}
      className="experts-filters-drawer"
      onExit={() => setIsFilterDrawerActive(false)}
      title="Filter"
    >
      <div className="experts-filters-drawer">
        <div className="experts-filters">
          <h3 className="experts-filters__title">By Name</h3>
          <input
            className="sage-input__field"
            type="text"
            placeholder="Name"
            onChange={handleInputNameChange}
            value={nameValue}
          />
        </div>
      </div>

      <div className="experts-filters-drawer">
        <div className="experts-filters">
          <h3 className="experts-filters__title">By Language</h3>
          <div className="sage-select">
            <select
              className="sage-select__field language"
              value={languageFilter}
              onChange={(e) => { updateLanguageFilter(e.target.value); }}
            >
              <option key="any" value="">Any Languages</option>
              {optionsLanguages.map((language) => (
                <option key={language} value={language}>{language}</option>
              ))}
            </select>
            <i className="sage-select__arrow" aria-hidden="true" />
          </div>
        </div>
      </div>

      <div className="experts-filters-drawer">
        <div className="experts-filters">
          <h3 className="experts-filters__title">By Location</h3>
          <div className="sage-select">
            <select
              className="sage-select__field location"
              value={locationFilter}
              onChange={(e) => { updateLocationFilter(e.target.value); }}
            >
              <option key="any" value="">Any Locations</option>
              {optionsLocations.map((location) => (
                <option key={location} value={location}>{location}</option>
              ))}
            </select>
            <i className="sage-select__arrow" aria-hidden="true" />
          </div>
        </div>
      </div>

      <div className="experts-filters-drawer">
        <div className="experts-filters">
          <h3 className="experts-filters__title">By Service</h3>
          <ul className="sage-list">
            {withActiveCategoriesOnly(categories).map((category, index) => (
              <li key={category.id}>
                <h4 className={`sage-list__title sage-list__title--${index}`}>{category.title}</h4>
                <ul className="sage-list">
                  {withActiveCategoriesOnly(category.subcategories).map((subcategory) => (
                    <li
                      className="sage-checkbox"
                      key={subcategory.id}
                    >
                      <input
                        className="sage-checkbox__input"
                        id={subcategory.slug}
                        type="checkbox"
                        checked={categoriesFilter.includes(subcategory.slug)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addCategory(subcategory.slug);
                          } else {
                            removeCategory(subcategory.slug);
                          }
                        }}
                      />
                      <label
                        className="sage-checkbox__label"
                        htmlFor={subcategory.slug}
                      >
                        {subcategory.title}
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Drawer>
  );
}
