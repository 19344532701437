import React, {
  useContext,
  useId,
} from 'react';
import Breadcrumb from './Breadcrumb';
import ProjectFormContext from '../../../contexts/ProjectFormContext';

const STEPS = ['Project', 'Details', 'Preferences', 'Review'];

export default function FormBreadcrumbs() {
  const {
    formStep,
  } = useContext(ProjectFormContext);

  return (
    <div className="breadcrumbs form__breadcrumbs">
      {
        STEPS.map(
          (name, index) => (
            <Breadcrumb
              showIcon={index !== 0}
              subtle={formStep !== index}
              title={`${index + 1}. ${name}`}
              key={useId()}
            />
          ),
        )
      }
    </div>
  );
}
