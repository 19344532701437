import React, {
  useContext,
} from 'react';
import Item from './Item';
import WorkContext from './WorkContext';

export default function Items() {
  const {
    portfolioItems,
  } = useContext(WorkContext);

  return (
    <section className="items">
      {portfolioItems && portfolioItems.length > 0 ? (
        <div className="items__grid">
          {portfolioItems.map((item) => (
            <Item item={item} />
          ))}
        </div>
      ) : (
        <p className="items__null-message">You haven’t added any work to your profile yet.</p>
      )}
    </section>
  );
}
