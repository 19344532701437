import React, {
  useContext,
} from 'react';
import GraphicHireAnExpert from './GraphicHireAnExpert';
import ProjectNewContext from '../../../contexts/ProjectNewContext';

export default function Hire() {
  const {
    subcategory,
  } = useContext(ProjectNewContext);

  return (
    <section className="hire">
      <div className="container">
        <div className="hire__content">
          <div className="hire__text">
            <h1 className="t-sage-heading-2 hire__title">Hire a Kajabi expert</h1>
            {subcategory
              ? (
                <>
                  <p className="t-sage-heading-5 hire__subcategory">{subcategory.title}</p>
                  <p className="hire__description">{subcategory.description}</p>
                </>
              )
              : (
                <p className="hire__description">Describe the work you want done and get matched with trusted experts who can help.</p>
              )}
          </div>
          <div className="hire__graphic">
            <GraphicHireAnExpert />
          </div>
        </div>
      </div>
    </section>
  );
}
