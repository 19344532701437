import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import MessagesDeclinedAlert from './MessagesDeclinedAlert';
import uuid from 'react-uuid';
import Thread from './Thread';
import ThreadsNull from './ThreadsNull';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import messageMarkAsRead from '../../../../../scripts/messageMarkAsRead';
import systemUser from '../../../../../data/shared/systemUser';
import AppContext from '../../../../../AppContext';

export default function Threads() {
  const {
    globalState,
  } = useContext(AppContext);

  const project = useContext(CurrentProjectContext);
  const user = useContext(CurrentUserContext);
  const [mostRecentMessages, setMostRecentMessages] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const tempMessages = [];
    const vendorIds = new Set(project.filtered_messages.map((message) => message.vendor_id));

    vendorIds.forEach((vendorId) => {
      const messagesPerVendor = [];
      project.filtered_messages.forEach((message) => {
        if (message.vendor_id === vendorId && message.sender_id) {
          messagesPerVendor.push(message);
        }
      });
      const lastMessage = messagesPerVendor[messagesPerVendor.length - 1];
      if (lastMessage) {
        tempMessages.push(lastMessage);
        tempMessages.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
        tempMessages.reverse();
      }
    });

    if (user.type === 'vendor') {
      tempMessages.forEach((message, index) => {
        if (message.vendor_id !== user.id) tempMessages.splice(index, 1);
      });
    }

    setMostRecentMessages(tempMessages);
  }, []);

  useEffect(() => {
    if (mostRecentMessages) {
      mostRecentMessages.forEach((message) => {
        fetch(`${window.location.origin}/users/${message.sender_id}.json`)
          .then((response) => response.json())
          .then((data) => setUsers((old) => [...old, data]));
      });
    }
  }, [mostRecentMessages]);

  if (user.type === 'vendor' && project.refused && project.available_for_hire) {
    return (
      <section className="threads">
        <p className="t-sage-heading-4 threads__title">Messages</p>
        <MessagesDeclinedAlert />
      </section>
    );
  }

  return (
    <section className="threads">
      <p className="t-sage-heading-4 threads__title">Messages</p>
      <div className="threads__list">
        {mostRecentMessages.length === 0 && (
          <ThreadsNull />
        )}
        {mostRecentMessages.length > 0
          && (users.length === mostRecentMessages.length)
          && mostRecentMessages.map((message) => {
            const realUser = users.find((u) => message.sender_id === u.id);

            const thisUser = realUser || systemUser;
            return (
              <Thread
                href={`/conversations/projects/${project.id}/vendors/${message.vendor_id}`}
                key={uuid()}
                message={message.content}
                onClick={() => {
                  if (message.read === null || message.read === false) {
                    messageMarkAsRead(
                      message.id,
                      () => { globalState.checkForUnreadMessages = true; },
                    );
                  }
                }}
                quoteId={message.quote_id}
                read={message.read}
                senderId={message.sender_id}
                timestamp={message.created_at}
                user={thisUser}
              />
            );
          })}
      </div>
    </section>
  );
}
