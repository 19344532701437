import plansGraphics from '../../../../data/shared/plansGraphics';

const chartData = {
  sections: [
    {
      features: [
        'Commission rate',
        'Active projects',
        'Prospective projects',
        'Service categories',
      ],
    },
    {
      title: 'Profile experience',
      features: [
        'Hire from profile',
        'Portfolio work samples',
        'Manual Ratings and reviews',
        'Custom profile URL',
        'Website link in profile',
        'Scheduling link in profile',
      ],
    },
    {
      title: 'Marketplace marketing',
      features: [
        'Directory listing',
      ],
    },
  ],
  plans: [
    {
      id: 1,
      name: 'Basic',
      displayName: 'Approved',
      hidden: false,
      price: 'Free',
      svg: plansGraphics.basic,
      entitlements: [
        [
          '10%',
          '3',
          '30',
          '1',
        ],
        [
          'check',
          '1',
          '-',
          '-',
          '-',
          '-',
        ],
        [
          'check',
        ],
      ],
    },
    {
      id: 2,
      name: 'Growth',
      displayName: 'Expert+',
      hidden: false,
      price: 99,
      svg: plansGraphics.growth,
      entitlements: [
        [
          '0%',
          '15',
          '100',
          '6',
        ],
        [
          'check',
          '6',
          'Unlimited',
          'check',
          'check',
          'check',
        ],
        [
          'check',
        ],
      ],
    },
    {
      id: 3,
      name: 'Pro',
      displayName: 'Pro',
      hidden: true,
      price: 299,
      svg: plansGraphics.pro,
      entitlements: [
        [
          '0%',
          'Unlimited',
          'Unlimited',
        ],
        [
          'check',
          'Unlimited',
          'Unlimited',
          'check',
          'check',
          'check',
        ],
        [
          'check',
        ],
      ],
    },
  ],
};

export default chartData;
