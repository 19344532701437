/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, {
  useId,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

export default function CheckboxGroupItem({
  disabled,
  field,
  index,
  onChange,
  optionString,
}) {
  const uniqueId = useId();

  return (
    <div className="sage-checkbox" key={uuid()}>
      <input
        {...field}
        checked={field.value.includes(optionString)}
        className="sage-checkbox__input"
        disabled={disabled}
        id={`${uniqueId}-${index}`}
        onChange={(evt) => {
          field.onChange(evt);
          if (onChange) onChange();
        }}
        type="checkbox"
        value={optionString}
      />
      <label
        className="sage-checkbox__label"
        htmlFor={`${uniqueId}-${index}`}
      >
        {optionString}
      </label>
    </div>
  );
}

CheckboxGroupItem.defaultProps = {
  disabled: false,
  onChange: null,
};

CheckboxGroupItem.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func,
  optionString: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};
