import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import uuid from 'react-uuid';
import Message from './Message';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Messages() {
  const {
    currentMessage,
    getMessagesData,
    messagesData,
  } = useContext(MessagesIndexContext);

  const [messages, setMessages] = useState(null);

  useEffect(() => {
    if (messagesData) {
      setMessages(messagesData.map((conversation) => (
        <Message
          active={currentMessage && currentMessage.id === conversation.latest_message.id}
          message={conversation.latest_message}
          key={uuid()}
          onUpdate={getMessagesData}
          project={conversation.project}
          userToRender={conversation.userToRender}
        />
      )));
    }
  }, [messagesData, currentMessage]);

  if (messagesData) {
    return (
      <section className="messages">
        {messages}
      </section>
    );
  }
}
