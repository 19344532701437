import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import TimelineCard from './TimelineCard';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';

export default function TimelineQuoteStatus({
  quote,
  vendor,
}) {
  const project = useContext(CurrentProjectContext);

  const isProposal = quote.quote_type === 'proposal';
  const textCanceled = isProposal ? 'a proposal' : 'an invoice';
  const textStatus = isProposal ? 'a proposal from' : 'an invoice from';

  if (quote.status === 'canceled') {
    return (
      <TimelineCard
        classNames="quote-status"
        icon="delete-circle"
        message={`${quote.status} ${textCanceled}`}
        timestamp={quote.updated_at}
        userName={vendor.name}
      />
    );
  }
  if (quote.status === 'declined') {
    return (
      <TimelineCard
        classNames="quote-status"
        icon="delete-circle"
        message={`${quote.status} ${textCanceled}`}
        timestamp={quote.updated_at}
        userName={project.user.name}
      />
    );
  }
  return (
    <TimelineCard
      classNames="quote-status"
      icon="check-circle"
      message={`${quote.status} ${textStatus}`}
      recipientName={vendor.name}
      timestamp={quote.updated_at}
      userName={project.user.name}
    />
  );
}

TimelineQuoteStatus.propTypes = {
  quote: PropTypes.shape({
    status: PropTypes.string,
    quote_type: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
