import React, {
  useContext,
} from 'react';
import uuid from 'react-uuid';
import InboxPaneMessage from './InboxPaneMessage';
import ConversationsShowContext from '../ConversationsShowContext';

export default function InboxPaneMessages() {
  const {
    project,
    recentMessages,
  } = useContext(ConversationsShowContext);

  const vendorId = project.vendor_id;

  return (
    <section className="inbox-pane-messages">
      {!vendorId && recentMessages.map((vendor) => (
        <InboxPaneMessage
          key={uuid()}
          vendor={vendor}
        />
      ))}
      {vendorId && (
        <InboxPaneMessage
          vendor={recentMessages.find((vendor) => vendor.id === vendorId)}
        />
      )}
    </section>
  );
}
