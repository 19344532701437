import React, {
  useContext,
} from 'react';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import TimelineCard from './TimelineCard';

export default function Experts() {
  const project = useContext(CurrentProjectContext);

  return (
    <TimelineCard
      classNames="experts"
      icon="kajabi"
      iconBackgroundColor="primary"
      iconColor="white"
      message="Your job has been sent to the best matched experts"
      timestamp={project.created_at}
    >
      <p className="t-sage-body-small">You should receive responses from them soon. You can discuss the details of the project, including scope and price, and decide whether to move forward.</p>
    </TimelineCard>
  );
}
