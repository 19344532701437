import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Header() {
  const {
    setPaneOpenInbox,
  } = useContext(MessagesIndexContext);
  return (
    <header className="header">
      <p className="t-sage-heading-2 header__title">Messages</p>
      <div
        className="header__close"
        onClick={() => setPaneOpenInbox(false)}
        role="presentation"
      >
        <Icon icon="move-left" />
      </div>
    </header>
  );
}
