import React from 'react';
import { Alert } from '@kajabi/sage-react';

export default function MutedAlert() {
  const text = (
    <>
      Your account has been restricted. Contact
      {' '}
      <a href="mailto:experts@kajabi.com">experts@kajabi.com</a>
      {' '}
      with any questions.
    </>
  );
  return (
    <Alert
      color={Alert.COLORS.WARNING}
      description={text}
      small
    />
  );
}
