import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';

export default function Attachment({
  filename,
  src,
  url,
}) {
  return (
    <div className="attachment">
      <span className="attachment__icon">
        <img
          alt={filename}
          src={src}
        />
      </span>
      <span className="t-sage-body-small attachment__filename">{filename}</span>
      <Button
        color="secondary"
        download={filename}
        href={url}
        icon="download"
        iconOnly
        subtle
      />
    </div>
  );
}

Attachment.propTypes = {
  filename: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
