/* eslint-disable no-console */
import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  Icon,
} from '@kajabi/sage-react';
import FormActions from './FormActions';
import ProjectFormContext from '../../../contexts/ProjectFormContext';

function ReviewItem({
  text,
}) {
  return (
    <div className="review-item">
      <Icon
        adjacentType="body"
        className="review-item__icon"
        color="primary-300"
        icon="check"
      />
      {text}
    </div>
  );
}

ReviewItem.propTypes = {
  text: PropTypes.string.isRequired,
};

export default function FormReview() {
  const {
    formStep,
    setFormStep,
    submitProject,
  } = useContext(ProjectFormContext);

  return (
    <>
      <FormSection
        title="Review and submit"
        subtitle="When you submit your project you will be matched with an expert best suited to meet your needs shortly."
      >
        <div className="review__content">
          <p className="t-sage-heading-6">When you submit your project for matching:</p>
          <ReviewItem text="Your project will be matched with experts best suited to meet your needs." />
          <ReviewItem text="The matched experts will review your job and contact you to discuss details and next steps." />
          <ReviewItem text="You're not committed to anything at this point. You can choose to work with one of the matched experts or close the job." />
        </div>
      </FormSection>
      <FormActions
        nextButtonText="Submit"
        onNextClick={() => {
          submitProject();
        }}
        onPrevClick={() => {
          setFormStep(formStep - 1);
        }}
        showTermsCTA
      />
    </>
  );
}
