import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import uuid from 'react-uuid';
import {
  Toast,
} from '@kajabi/sage-react';
import { proxy } from 'valtio';
import DefaultLayout from './components/layouts/Layout';
import ConversationsShow from './components/conversations/show/ConversationsShow';
import ExpertsDirectoryIndex from './components/pages/directory/ExpertsDirectoryIndex';
import HTTPStatus from './components/http_status/HTTPStatus';
import MessagesIndex from './components/messages/index/MessagesIndex';
import Onboarding from './components/onboarding/Onboarding';
import ProjectsBrowse from './components/projects/browse/ProjectsBrowse';
import ProjectsIndex from './components/projects/index/ProjectsIndex';
import ProjectsNew from './components/projects/new/ProjectsNew';
import ProjectsShow from './components/projects/show/ProjectsShow';
import UsersEdit from './components/users/edit/UsersEdit';
import UsersShow from './components/users/show/new/UsersShow';
import VendorApplicationsNew from './components/vendor_applications/new/VendorApplicationsNew';
import VendorTerms from './components/vendor_terms/VendorTerms';
import WelcomeIndex from './components/welcome/WelcomeIndex';
import PlanUpgradeModal from './components/shared/PlanUpgradeModal';
import ConfigContext from './contexts/ConfigContext';
import AppContext from './AppContext';
import CurrentUserContext from './contexts/CurrentUserContext';

function withDisallowedForSuspended(disallowForSuspended, child) {
  const { suspended } = useContext(CurrentUserContext);

  if (disallowForSuspended && suspended) return <Navigate to="/" />;

  return child;
}

export default function App() {
  const {
    recipient,
  } = useContext(ConfigContext);

  const [fetchResult404, setFetchResult404] = useState(false);
  const [loginRef, setLoginRef] = useState(null);
  const [planUpgradeModalOpen, setPlanUpgradeModalOpen] = useState(false);
  const [toastData, setToastData] = useState(null);
  const globalState = proxy({ checkForUnreadMessages: true });

  const conversationPageTitle = recipient
    ? `Conversation with ${recipient.name}`
    : null;

  useEffect(() => {
    setToastData(JSON.parse(sessionStorage.getItem('toastData')));
    sessionStorage.removeItem('toastData');
  }, []);

  const page404 = {
    cssScope: 'http-status',
    element: <HTTPStatus
      description="This page is not available"
      title="404"
    />,
    path: '/*',
  };

  const pages = [
    {
      cssScope: 'welcome',
      element: <WelcomeIndex />,
      path: '/',
      showFooter: true,
    },
    {
      cssScope: 'pages',
      element: <ExpertsDirectoryIndex />,
      path: '/directory',
      showFooter: true,
    },
    {
      cssScope: 'vendor-applications-new',
      element: <VendorApplicationsNew />,
      path: '/apply',
      showFooter: true,
      disallowForSuspended: true,
    },
    {
      cssScope: 'conversations-show',
      element: <ConversationsShow />,
      headerSize: 'extra-large',
      path: '/conversations/projects/:projects_id/vendors/:vendors_id',
      title: conversationPageTitle,
      disallowForSuspended: true,
    },
    {
      cssScope: 'messages-index',
      element: <MessagesIndex />,
      headerSize: 'extra-large',
      path: '/messages',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-browse',
      element: <ProjectsBrowse />,
      path: '/projects/browse',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-index',
      element: <ProjectsIndex />,
      path: '/projects',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-show',
      element: <ProjectsShow />,
      path: '/projects/:id',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-new',
      element: <ProjectsNew />,
      path: '/projects/new',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-new',
      element: <ProjectsNew />,
      path: '/projects/new/:cat',
      disallowForSuspended: true,
    },
    {
      cssScope: 'projects-new',
      element: <ProjectsNew />,
      path: '/projects/new/:cat/:subcat',
      disallowForSuspended: true,
    },
    {
      cssScope: 'users-edit',
      element: <UsersEdit />,
      path: '/users/:id/edit',
      disallowForSuspended: true,
    },
    {
      cssScope: 'onboarding',
      element: <Onboarding />,
      path: '/welcome-video',
      showHeader: false,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/users/:id',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/users/:id/about',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/users/:id/reviews',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/users/:id/work',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/users/:id/packages',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'vendor-terms',
      element: <VendorTerms />,
      path: '/agreement',
      showHeader: false,
    },
    {
      cssScope: 'http-status',
      element: <HTTPStatus
        description="You are not authorized to access this page"
        title="403"
      />,
      path: '/unauthorized',
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug/about',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug/hire',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug/reviews',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug/work',
      disallowForSuspended: true,
      showFooter: true,
    },
    {
      cssScope: 'users-show',
      element: <UsersShow />,
      path: '/:slug/packages',
      disallowForSuspended: true,
      showFooter: true,
    },
    page404,
  ];

  if (fetchResult404) {
    pages.splice(0, pages.length);
    pages.push(
      page404,
    );
  }

  const globalAppValues = useMemo(() => ({
    setPlanUpgradeModalOpen,
    fetchResult404,
    loginRef,
    setFetchResult404,
    setLoginRef,
    setToastData,
    globalState,
  }));

  return (
    <AppContext.Provider value={globalAppValues}>
      <Router>
        <Routes>
          {pages.map((page) => {
            const {
              cssScope,
              element,
              headerSize,
              path,
              showFooter,
              showHeader,
              disallowForSuspended = false,
            } = page;
            return (
              <Route
                element={(
                  <DefaultLayout
                    cssScope={cssScope}
                    headerSize={headerSize}
                    showFooter={showFooter || false}
                    showHeader={showHeader}
                  >
                    {withDisallowedForSuspended(disallowForSuspended, element)}
                    {toastData && (
                      <Toast
                        icon={toastData.icon}
                        isActive
                        key={toastData.key}
                        title={toastData.title}
                        type={toastData.type}
                      />
                    )}
                    {window.globalFeatureFlags.plans.enabled && (
                      <PlanUpgradeModal
                        active={planUpgradeModalOpen}
                        onClose={() => setPlanUpgradeModalOpen(false)}
                      />
                    )}
                  </DefaultLayout>
                )}
                key={uuid()}
                path={path}
              />
            );
          })}
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}
