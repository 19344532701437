/* eslint-disable import/prefer-default-export */
export const averagePrice = [
  'Under $1,000',
  '$1,000-$5,000',
  '$5,000-$10,000',
  '$10,000-$20,000',
  '$20,000-$50,000',
  'Over $50,000',
];

export const templatesForSale = [
  'Yes',
  'No',
];

export const typicalClients = [
  'New entrepreneur just starting out',
  'Experienced entrepreneur who has been in business for years',
  'Large business',
];
