import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import {
  Alert,
} from '@kajabi/sage-react';
import { useSearchParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Package from './Package';
import PackageModal from './PackageModal';
import PackageNull from './PackageNull';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function PackagesList() {
  const {
    setCurrentPackageIndex,
    userData,
  } = useContext(UsersShowContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const handleItemClick = (index) => {
    setCurrentPackageIndex(index);
    setModalOpen(true);
  };

  const [packages, setPackages] = useState([]);
  const fetchPackages = () => {
    const { id } = userData;
    fetch(`${window.location.origin}/api/v1/vendor/${id}/packages.json`)
      .then((response) => response.json())
      .then(
        (data) => {
          setPackages(
            data
              .filter((item) => item.published)
              .sort((a, b) => a.price - b.price),
          );
        },
      );
  };

  useEffect(() => {
    const packageIdRaw = searchParams.get('packageId');
    const packageId = packageIdRaw ? parseInt(packageIdRaw, 10) : null;
    if (searchParams.get('openModal') === 'select-package' && packageId) {
      packages.forEach((item, index) => {
        if (item.id === parseInt(packageId, 10)) {
          setCurrentPackageIndex(index);
          setModalOpen(true);
        }
      });
    }
  }, [packages]);

  useState(() => {
    fetchPackages();
  }, []);

  return (
    <div className="packages">
      {packages.length === 0 && <PackageNull />}
      {packages.length > 0 && (
        <>
          {!userData.available_for_hire && (
            <Alert
              color={Alert.COLORS.WARNING}
              small
              title="This expert is currently unable to accept new projects."
            />
          )}
          <div className="sage-row">
            {packages.map((item, index) => (
              <div className="sage-col-4">
                <Package
                  image={item.image}
                  key={uuid()}
                  onClick={() => userData.available_for_hire && handleItemClick(index)}
                  title={item.title}
                  price={item.price}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {packages.length > 0 && (
        <PackageModal
          active={modalOpen}
          key={uuid()}
          onClose={() => {
            setModalOpen(false);
          }}
          packages={packages}
        />
      )}
    </div>
  );
}
