import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import uuid from 'react-uuid';
import ChatPaneMessage from './ChatPaneMessage';
import ChatPaneProjectBrief from './ChatPaneProjectBrief';
import ConversationsShowContext from '../ConversationsShowContext';
import CurrentQuoteContext from '../../../../contexts/CurrentQuoteContext';

export default function ChatPaneContent() {
  const {
    currentConversation,
    recipient,
    user,
  } = useContext(ConversationsShowContext);

  const slider = useRef(null);

  useEffect(() => {
    const el = slider.current;
    el.scrollTop = el.scrollHeight - el.offsetHeight;
  });

  const messageUser = useCallback((message) => {
    if (!message.sender_id) return null; // system message
    if (message.sender_id === user.id) return user;
    return recipient;
  });

  return (
    <section className="chat-pane-content" ref={slider}>
      <ChatPaneProjectBrief
        className="brief brief--conversations"
      />
      {currentConversation.messages.map((message) => {
        let quoteData;
        const isQuote = message.quote_id !== null;
        currentConversation.quotes.forEach((quote) => {
          if (message.quote_id === quote.id) quoteData = quote;
        });

        if (quoteData?.status !== 'new' && message.sender_id == null) {
          return null;
        }

        return (
          <Fragment key={uuid()}>
            <CurrentQuoteContext.Provider value={quoteData}>
              <ChatPaneMessage
                content={message.content}
                isQuote={isQuote}
                timestamp={message.created_at}
                user={messageUser(message)}
                attachments={message.attachments}
              />
            </CurrentQuoteContext.Provider>
          </Fragment>
        );
      })}
    </section>
  );
}
