import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Icon,
} from '@kajabi/sage-react';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ActionsDropdown({
  onOpenDocumentHistoryModal,
}) {
  const user = useContext(CurrentUserContext);

  const sharedItems = [
    {
      id: 'openDocumentHistoryModal',
      label: 'Proposals & invoices',
      onClick: () => onOpenDocumentHistoryModal(),
    },
  ];

  function getFinalItems() {
    let finalItems = [];
    if (user.type === 'user') finalItems = [...sharedItems];
    if (user.type === 'vendor') finalItems = [...sharedItems];
    return finalItems;
  }

  return (
    <Dropdown
      align="right"
      className="dropdown"
      customTrigger={(
        <div className="actions-dropdown__icon">
          <Icon
            circular
            icon="dot-menu-horizontal"
          />
        </div>
      )}
      label=""
    >
      <Dropdown.ItemList items={getFinalItems()} />
    </Dropdown>
  );
}

ActionsDropdown.propTypes = {
  onOpenDocumentHistoryModal: PropTypes.func.isRequired,
};
