import React from 'react';

export default function PortfolioNull() {
  return (
    <div className="portfolio-null">
      <p className="t-sage-heading-5 portfolio-null__title">No projects yet.</p>
      <p className="portfolio-null__desc">It looks like this Expert hasn’t added any projects to their profile yet.</p>
    </div>
  );
}
