import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpandableCard,
} from '@kajabi/sage-react';

export default function FAQQuestion({
  children,
  label,
}) {
  return (
    <ExpandableCard
      alignArrowRight
      triggerLabel={label}
    >
      {children}
    </ExpandableCard>
  );
}

FAQQuestion.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};
