import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
} from '@kajabi/sage-react';

export default function DocumentHistoryTabs({
  initialTabId,
  onSetActiveTab,
}) {
  const [activeTabId, setActiveTabId] = useState(initialTabId);

  return (
    <Tabs
      initialActiveId={activeTabId}
      onClickTab={(e) => {
        setActiveTabId(e);
        onSetActiveTab(e);
      }}
      tabs={[
        {
          id: 'proposals',
          label: 'Proposals',
        },
        {
          id: 'invoices',
          label: 'Invoices',
        },
      ]}
    />
  );
}

DocumentHistoryTabs.propTypes = {
  initialTabId: PropTypes.string.isRequired,
  onSetActiveTab: PropTypes.func.isRequired,
};
