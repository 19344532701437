import React, {
  useEffect,
  useRef,
} from 'react';
import FormAlert from './FormAlert';
import FormQuestionnaire from './FormQuestionnaire';
import getAbsoluteHeight from '../../../scripts/getAbsoluteHeight';

export default function FormProject() {
  const questionnaireRef = useRef(null);
  const formAlert = useRef(null);

  useEffect(() => {
    const refEl = questionnaireRef.current;
    refEl.querySelector('.sage-form-section__info').appendChild(formAlert.current);

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        const parentTop = refEl.getBoundingClientRect().top;
        const descTop = refEl.querySelector('#description').getBoundingClientRect().top;
        const titleHeight = getAbsoluteHeight(refEl.querySelector('.sage-form-section__title'));
        const subtitleHeight = getAbsoluteHeight(refEl.querySelector('.sage-form-section__subtitle'));
        const calcTop = descTop - parentTop - titleHeight - subtitleHeight - 1;
        formAlert.current.style.marginTop = `${calcTop}px`;
      } else {
        formAlert.current.style.marginTop = '0px';
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  return (
    <div className="questionnaire">
      <div ref={questionnaireRef}>
        <FormQuestionnaire
          subcategory="projectMainDetails"
          subtitle="Provide the essential details about your project. This crucial information will enable us to connect you with the most suitable expert for your specific requirements."
          title="Your project brief"
        />
      </div>
      <div
        className="form-alert__container"
        ref={formAlert}
      >
        <FormAlert
          description={'Enter as many details as you can. With the help of AI, we’ll add some polish to produce a complete project brief optimized for our Experts.\n\nDon’t worry, you’ll still be able to edit the results!'}
          title="✨ Adding AI magic"
        />
      </div>
    </div>
  );
}
