import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Textarea,
} from '@kajabi/sage-react';
import Linkifier from '../../../../shared/Linkifier';
import SkeletonLoader from '../../../../shared/SkeletonLoader';
import ProjectsShowContext from '../../ProjectsShowContext';

export default function DetailsBrief() {
  const {
    brief,
    briefUpdateProxy,
    project,
    user,
  } = useContext(ProjectsShowContext);

  const {
    vendor_id: vendorId,
  } = project;

  const {
    type: userType,
  } = user;

  const [isEditing, setIsEditing] = useState(false);
  const [briefText, setBriefText] = useState(null);

  const isProjectClosed = project.status === 'Closed';
  const isProjectComplete = project.status === 'Complete';
  const isVendor = userType === 'vendor';

  useEffect(() => {
    if (brief) {
      setBriefText(brief);
    }
  }, [brief]);

  if (brief) {
    return (
      <div className="details-brief">
        {isEditing && (
          <>
            <Textarea
              className="details-brief__textarea"
              onChange={(e) => setBriefText(e.target.value)}
              value={briefText}
            />
            <Button
              color="secondary"
              onClick={() => {
                setIsEditing(false);
                briefUpdateProxy(briefText);
              }}
            >
              Save changes
            </Button>
          </>
        )}
        {!isEditing && (
          <>
            <div className="details-brief__description">
              <Linkifier>
                {briefText}
              </Linkifier>
            </div>
            {!vendorId && !isProjectClosed && !isProjectComplete && !isVendor && (
              <Button
                onClick={() => setIsEditing(true)}
              >
                Edit description
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return <SkeletonLoader />;
}
