export default function userSettingsPost({
  about,
  avatar,
  companyName,
  headline,
  kajabiTenure,
  languages,
  location,
  paypalEmail,
  schedulingLink,
  slug,
  website,
}) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/user/preferences', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      about,
      avatar,
      companyName,
      headline,
      kajabiTenure,
      languages,
      location,
      paypalEmail,
      schedulingLink,
      slug,
      website,
    }),
  })
    .then((res) => res.text())
    .then(() => window.location.replace(window.location.href));
}
