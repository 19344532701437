import React from 'react';
import FAQQuestions from './FAQQuestions';

export default function FAQ() {
  return (
    <section className="section">
      <div className="container container--large">
        <div className="faq__content">
          <p className="t-sage-heading-2 section__title">Frequently asked questions</p>
          <FAQQuestions />
        </div>
      </div>
    </section>
  );
}
