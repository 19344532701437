import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import RatingScale from './RatingScale';
import Textarea from '../../../shared/formik/Textarea';
import CurrentProjectContext from '../../../../contexts/CurrentProjectContext';

export default function RatingModal({
  active,
  onClose,
  onUpdate,
}) {
  const project = useContext(CurrentProjectContext);
  const [ratingValue, setRatingValue] = useState(null);

  return (
    <Modal
      active={active}
      className="rating-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        subheader="Tell us about your experience with the person you worked with."
        title="Rate your experience"
      />
      <Formik
        initialValues={{
          description: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            onUpdate({
              projectId: project.id,
              rating: ratingValue,
              review: values.description,
            });
            onClose();
          }, 400);
        }}
        validationSchema={Yup.object({
          description: Yup.string()
            .required('Required')
            .min(25, 'Must be at least 25 characters'),
        })}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <Modal.Body>
              <div className="rating-modal__item">
                <p className="t-sage-heading-6 rating-modal__heading">
                  How satisfied are you with the results of this project?
                </p>
                <RatingScale
                  onSetRating={(rating) => setRatingValue(rating)}
                />
              </div>
              <div className="rating-modal__item">
                <Textarea
                  errors={errors.description}
                  handleChange={handleChange}
                  idName="description"
                  label="Anything else you’d like to share?"
                  placeholder="Enter a description..."
                  touched={touched.description}
                  value={values.description}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group gap="sm">
                <Button
                  color="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || ratingValue === null}
                  onClick={handleSubmit}
                >
                  Submit review
                </Button>
              </Button.Group>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

RatingModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
