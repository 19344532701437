export default function withAuthStatusChecked(res) {
  const promise = new Promise((resolve, reject) => {
    if (res.status === 401) {
      window.location.reload();
      reject(res);
    } else {
      resolve(res);
    }
  });

  return promise;
}
