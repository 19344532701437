import React, {
  useContext,
} from 'react';
import Avatar from '../../../shared/Avatar';
import CurrentProjectContext from '../../../../contexts/CurrentProjectContext';

export default function Bio() {
  const project = useContext(CurrentProjectContext);
  const { user } = project;

  return (
    <section className="bio">
      <Avatar
        size="40"
        user={user}
      />
      <div className="bio__text">
        <p className="t-sage-heading-6">{user.name}</p>
      </div>
    </section>
  );
}
