import React from 'react';
import Breadcrumbs from '../../shared/Breadcrumbs';
import DetailsBrief from './DetailsBrief';
import DetailsHeader from './DetailsHeader';
import DetailsAlert from './DetailsAlert';

export default function Details() {
  const USE_AI_BRIEF = window.featureFlags.aiBrief.enabled;
  const GENERATE_AI_BRIEF = window.featureFlags.aiBrief.generateAIBrief;

  return (
    <section className="details">
      {USE_AI_BRIEF && GENERATE_AI_BRIEF && <DetailsAlert />}
      <Breadcrumbs />
      <DetailsHeader />
      <DetailsBrief />
    </section>
  );
}
