import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';
import TermsCallToAction from './TermsCallToAction';

export default function FormActions({
  loading,
  nextButtonText,
  onNextClick,
  onPrevClick,
  prevButtonDisabled,
  prevButtonText,
  showTermsCTA,
}) {
  return (
    <section className="form-actions">
      <Button.Group
        align="space-between"
        borderTop
      >
        <Button
          color="secondary"
          disabled={prevButtonDisabled}
          onClick={onPrevClick}
        >
          {prevButtonText}
        </Button>
        {showTermsCTA && <TermsCallToAction />}
        <Button
          loading={loading}
          onClick={onNextClick}
        >
          {nextButtonText}
        </Button>
      </Button.Group>
    </section>
  );
}

FormActions.defaultProps = {
  nextButtonText: 'Continue',
  prevButtonText: 'Go back',
  prevButtonDisabled: false,
  showTermsCTA: false,
};

FormActions.propTypes = {
  loading: PropTypes.bool.isRequired,
  nextButtonText: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  prevButtonDisabled: PropTypes.bool,
  prevButtonText: PropTypes.string,
  showTermsCTA: PropTypes.bool,
};
