import React from 'react';
import InboxPaneHeader from './InboxPaneHeader';
import InboxPaneMessages from './InboxPaneMessages';

export default function InboxPane() {
  return (
    <section className="pane inbox-pane">
      <InboxPaneHeader />
      <InboxPaneMessages />
    </section>
  );
}
