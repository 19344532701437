import React, { useContext } from 'react';
import {
  Breadcrumbs as SageBreadcrumbs,
} from '@kajabi/sage-react';
import ConversationsShowContext from '../ConversationsShowContext';

export default function ChatPaneBreadcrumbs() {
  const {
    project: {
      id: projectId,
    },
  } = useContext(ConversationsShowContext);
  const url = `/projects/${projectId}`;

  return (
    <SageBreadcrumbs
      className="chat-pane-breadcrumbs"
      items={
        [
          {
            href: url,
            label: 'Back',
          },
        ]
      }
    />
  );
}
