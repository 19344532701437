import React, {
  useContext,
  useState,
} from 'react';
import classnames from 'classnames';
import {
  Button,
} from '@kajabi/sage-react';
import QuoteSubmitModal from '../../../shared/QuoteSubmitModal';
import CurrentProjectContext from '../../../../contexts/CurrentProjectContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import ProjectsShowContext from '../ProjectsShowContext';

export default function Proposal() {
  const {
    PROPOSAL_CREATE,
  } = useContext(ProjectsShowContext);

  const project = useContext(CurrentProjectContext);

  const {
    id: projectId,
  } = project;

  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const [quoteSubmitModalOpen, setQuoteSubmitModalOpen] = useState(false);

  const classNames = classnames('proposal', {
    'proposal--disabled': !PROPOSAL_CREATE,
  });

  return (
    <>
      <section
        className={classNames}
        onClick={() => {
          if (PROPOSAL_CREATE) setQuoteSubmitModalOpen(true);
        }}
        role="presentation"
      >
        <div className="sage-row">
          <div className="sage-col-9">
            <p className="t-sage-heading-6 proposal__title">Send a proposal right now</p>
            <p className="proposal__text">
              You’ll still be able to message this client after sending a proposal
            </p>
            <Button
              className="proposal__submit-button"
              color="secondary"
              disabled={!PROPOSAL_CREATE}
            >
              Submit proposal
            </Button>
          </div>
          <div className="sage-col-3">
            <img
              alt=""
              src="/images/svg/proposal-icon.svg"
            />
          </div>
        </div>
      </section>
      <QuoteSubmitModal
        active={quoteSubmitModalOpen}
        onClose={() => setQuoteSubmitModalOpen(false)}
        project={project}
        redirectUrl={`/conversations/projects/${projectId}/vendors/${userId}`}
        quoteType="proposal"
      />
    </>
  );
}
