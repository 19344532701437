import React, {
  useContext,
  useState,
} from 'react';
import uuid from 'react-uuid';
import PortfolioItem from './PortfolioItem';
import PortfolioModal from './PortfolioModal';
import PortfolioNull from './PortfolioNull';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function PortfolioItems() {
  const {
    currentPortfolioItemIndex,
    setCurrentPortfolioItemIndex,
    userData,
  } = useContext(UsersShowContext);

  const [modalOpen, setModalOpen] = useState(false);

  const handleItemClick = (index) => {
    setCurrentPortfolioItemIndex(index);
    setModalOpen(true);
  };

  const handleModalPaginationClick = (indexModifier) => {
    setCurrentPortfolioItemIndex(currentPortfolioItemIndex + indexModifier);
  };

  return (
    <div className="portfolio-items">
      {userData.portfolio_items.length === 0 && <PortfolioNull />}
      {userData.portfolio_items.length > 0 && (
        <div className="sage-row">
          {userData.portfolio_items.map((item, index) => (
            <div className="sage-col-4">
              <PortfolioItem
                image={item.image}
                key={uuid()}
                onClick={() => handleItemClick(index)}
                title={item.title}
              />
            </div>
          ))}
        </div>
      )}
      {userData.portfolio_items.length > 0 && (
        <PortfolioModal
          active={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          onPaginationClick={(indexModifier) => handleModalPaginationClick(indexModifier)}
        />
      )}
    </div>
  );
}
