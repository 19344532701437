import React, {
  useContext,
  useState,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import ActionsDropdown from '../ActionsDropdown';
import ChatPaneBreadcrumbs from './ChatPaneBreadcrumbs';
import DocumentHistoryModal from '../document-history-modal/DocumentHistoryModal';
import QuoteSubmitModal from '../../../shared/QuoteSubmitModal';
import ConversationsShowContext from '../ConversationsShowContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

export default function ChatPaneHeader() {
  const {
    isVendor,
    mutedAt,
  } = useContext(CurrentUserContext);

  const {
    project,
    recipient,
    setQuoteType,
  } = useContext(ConversationsShowContext);

  const isMuted = (
    !!(mutedAt) && project.filtered_messages.length === 0 && project.filtered_quotes.length === 0
  );

  const isAllowed = isVendor && !isMuted && !project.user.suspended;
  const isQuoteAccepted = project.vendor_id !== null;
  const quoteType = isQuoteAccepted ? 'invoice' : 'proposal';
  const buttonText = isQuoteAccepted ? 'Submit invoice' : 'Submit proposal';
  const [documentHistoryModalOpen, setDocumentHistoryModalOpen] = useState(false);
  const [quoteSubmitModalOpen, setQuoteSubmitModalOpen] = useState(false);

  return (
    <section className="chat-pane-header">
      {isVendor && <ChatPaneBreadcrumbs />}
      <div className="chat-pane-header__content">
        <p className="t-sage-heading-5 chat-pane-header__title">{recipient.name}</p>
        {isAllowed && (
          <>
            <Button
              onClick={() => {
                setQuoteType(quoteType);
                setQuoteSubmitModalOpen(true);
              }}
            >
              {buttonText}
            </Button>
            <Button
              onClick={() => {
                setQuoteType('proposal');
                setQuoteSubmitModalOpen(true);
              }}
            >
              Submit anther proposal
            </Button>
          </>
        )}
        {isVendor && isMuted && (
          <Button onClick={() => setQuoteSubmitModalOpen(true)}>Submit invoice</Button>
        )}
        <ActionsDropdown
          onOpenDocumentHistoryModal={() => setDocumentHistoryModalOpen(true)}
        />
      </div>
      <QuoteSubmitModal
        active={quoteSubmitModalOpen}
        onClose={() => setQuoteSubmitModalOpen(false)}
        project={project}
        quoteType={quoteType}
      />
      <DocumentHistoryModal
        active={documentHistoryModalOpen}
        onClose={() => setDocumentHistoryModalOpen(false)}
      />
    </section>
  );
}
