import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Hint,
  Link,
} from '@kajabi/sage-react';
import ChatPaneInputForm from './ChatPaneInputForm';
import ConversationsShowContext from '../ConversationsShowContext';

const isClosedProject = (project) => project.status === 'Closed';
const isCompleteProject = (project) => project.status === 'Complete';
const isMatchedByAnotherVendor = (project, user) => (user.type === 'vendor' && project.vendor_id !== null && project.vendor_id !== user.id);

const MESSAGES = {
  vendor: {
    unmatched: 'Messaging is no longer available because this client has accepted another Expert’s proposal.',
    closed: 'Messaging is no longer available because this client has closed this project.',
    complete: 'Messaging is no longer available because this client has marked this project complete.',
  },
  user: {
    unmatched: 'Messaging is no longer available with this expert because you selected another expert.',
    closed: 'This project has been closed. Reopen to send a message or start a new project.',
    complete: 'Messaging is no longer available because this project has been marked complete.',
  },
};

const closedConversationMessage = (project, { vendor, user }) => {
  if (isMatchedByAnotherVendor(project, vendor)) {
    return MESSAGES[user.type]?.unmatched;
  }
  if (isClosedProject(project)) {
    return MESSAGES[user.type]?.closed;
  }
  if (isCompleteProject(project)) {
    return MESSAGES[user.type]?.complete;
  }

  return null;
};

function SuspendedAlert({ user }) {
  const userType = user.user_type === 'vendor' ? 'Expert' : 'user';
  const text = (
    <>
      {`This ${userType} is no longer available. Contact`}
      {' '}
      <a href="mailto:experts@kajabi.com">experts@kajabi.com</a>
      {' '}
      with any questions.
    </>
  );
  return (
    <Alert
      color={Alert.COLORS.DEFAULT}
      description={text}
      small
    />
  );
}

SuspendedAlert.propTypes = {
  user: PropTypes.shape({
    user_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default function ChatPaneInput() {
  const {
    project,
    user,
    currentConversation: { vendor },
  } = useContext(ConversationsShowContext);

  const closedMessage = closedConversationMessage(project, { vendor, user });

  if (closedMessage) {
    const actions = user.type === 'vendor' ? (
      <Link href="/projects/browse" suppressDefaultClass>Browse new projects</Link>
    ) : (
      <Link href="/projects/new" suppressDefaultClass>Start a new project</Link>
    );

    return (
      <Alert
        actions={actions}
        color={Alert.COLORS.DEFAULT}
        description={closedMessage}
        small
      />
    );
  }

  if (vendor.suspended) return <SuspendedAlert user={vendor} />;
  if (project.user.suspended) return <SuspendedAlert user={project.user} />;

  return (
    <div className="chat-pane-input">
      <Hint
        content="For your protection, remember to keep conversations in the Kajabi Experts platform."
        icon="info-circle"
      />
      <ChatPaneInputForm />
    </div>
  );
}
