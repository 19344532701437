import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Textarea,
} from '@kajabi/sage-react';
import CurrentProjectContext from '../../../../contexts/CurrentProjectContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import ProjectsShowContext from '../ProjectsShowContext';
import messagePost from '../../../../scripts/messagePost';

export default function MessageInput({
  isDynamicSize,
  placeholder,
}) {
  const {
    MESSAGE_CREATE,
  } = useContext(ProjectsShowContext);

  const {
    id: projectId,
    vendor_proxy: vendorProxy,
  } = useContext(CurrentProjectContext);

  const {
    id: userId,
    type: userType,
  } = useContext(CurrentUserContext);

  const [content, setContent] = useState('');
  const input = useRef(null);

  const vendorId = userType === 'vendor' ? userId : vendorProxy.id;
  const redirectUrl = `${window.location.origin}/conversations/projects/${projectId}/vendors/${vendorId}`;

  useEffect(() => {
    if (input) {
      input.current.querySelector('textarea').focus();
    }
  }, [input]);

  if (isDynamicSize) {
    useLayoutEffect(() => {
      const textarea = input.current.querySelector('textarea');
      textarea.style.height = 'inherit';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }, [content]);
  }

  const handleClick = () => {
    messagePost(
      {
        content,
        project_id: projectId,
        sender_id: userId,
        vendor_id: vendorId,
      },
      () => window.location.replace(redirectUrl), // Callback
    );
  };

  return (
    <section className="message-input" ref={input}>
      <Textarea
        className="message-input__input"
        disabled={!MESSAGE_CREATE}
        id="message-input"
        onChange={(e) => setContent(e.target.value)}
        placeholder={placeholder}
        value={content}
      />
      <div className="message-input__actions">
        <Button
          onClick={handleClick}
          disabled={!content}
          icon="send-message"
        >
          Send
        </Button>
      </div>
    </section>
  );
}

MessageInput.defaultProps = {
  isDynamicSize: true,
  placeholder: 'Write a message...',
};

MessageInput.propTypes = {
  isDynamicSize: PropTypes.bool,
  placeholder: PropTypes.string,
};
