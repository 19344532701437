export default function quotePost(
  payload,
  callback = () => { },
) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/quotes', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      quote: payload,
    }),
  })
    .then((res) => res.text())
    .then((res) => callback(res));
}
