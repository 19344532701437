/* eslint-disable react/style-prop-object */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@kajabi/sage-react';
import ActionButtonPrimary from './ActionButtonPrimary';

export function Hero({
  buttonHref,
  buttonNewTab,
  buttonText,
  className,
  descriptionDesktop,
  descriptionMobile,
  imgSrc,
  scrollButton,
  secondaryLinkHref,
  secondaryLinkText,
  titleDesktop,
  titleMobile,
  useActionButtonPrimary,
}) {
  return (
    <section className={`hero hero--${className}`}>
      <div className="container container--large">
        <div className="sage-row sage-row--valign-center sage-row--align-space-between">
          <div className="sage-col-6">
            <div className="hero__text">
              <h1 className="t-sage-heading-1 hero__title hero__title--large hero__title--desktop">
                {titleDesktop}
              </h1>
              <h1 className="t-sage-heading-1 hero__title hero__title--mobile">
                {titleMobile}
              </h1>
              <p className="hero__description hero__description--desktop">
                {descriptionDesktop}
              </p>
              <p className="hero__description hero__description--mobile">
                {descriptionMobile}
              </p>
              <div className="hero__actions">
                {!useActionButtonPrimary && scrollButton && (
                  <pds-button
                    onClick={() => {
                      document.querySelector('#app').scrollTo({
                        top: document
                          .querySelector('.cta')
                          .getBoundingClientRect().top,
                        left: 0,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    {buttonText}
                  </pds-button>
                )}
                {!useActionButtonPrimary && !scrollButton && (
                  <pds-button
                    onClick={() => {
                      if (buttonNewTab) window.open(buttonHref, '_blank');
                      else window.location.replace(buttonHref);
                    }}
                  >
                    {buttonText}
                  </pds-button>
                )}
                {useActionButtonPrimary && <ActionButtonPrimary />}
                {secondaryLinkHref && secondaryLinkText && (
                  <Link
                    href={secondaryLinkHref}
                    icon="launch"
                    iconPosition="right"
                    rel="noreferrer"
                    removeUnderline
                    style="secondary"
                  >
                    {secondaryLinkText}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="sage-col-6 t-sage--align-center">
            <img
              alt="Hire a Kajabi Expert"
              className="hero__image"
              src={imgSrc}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.defaultProps = {
  buttonHref: null,
  buttonNewTab: false,
  buttonText: null,
  className: null,
  descriptionDesktop: null,
  descriptionMobile: null,
  imgSrc: null,
  scrollButton: false,
  secondaryLinkHref: null,
  secondaryLinkText: null,
  titleDesktop: null,
  titleMobile: null,
  useActionButtonPrimary: false,
};

Hero.propTypes = {
  buttonHref: PropTypes.string,
  buttonNewTab: PropTypes.bool,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  descriptionDesktop: PropTypes.node,
  descriptionMobile: PropTypes.node,
  imgSrc: PropTypes.string,
  scrollButton: PropTypes.bool,
  secondaryLinkHref: PropTypes.string,
  secondaryLinkText: PropTypes.string,
  titleDesktop: PropTypes.node,
  titleMobile: PropTypes.node,
  useActionButtonPrimary: PropTypes.bool,
};
