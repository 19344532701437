import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Dot,
} from '@kajabi/sage-react';
import Avatar from '../../../../shared/Avatar';
import Timestamp from '../../../../shared/Timestamp';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';

export default function Thread({
  active,
  disabled,
  href,
  message,
  onClick,
  quoteId,
  read,
  senderId,
  timestamp,
  user,
}) {
  const project = useContext(CurrentProjectContext);
  const currentUser = useContext(CurrentUserContext);

  let quote;
  project.filtered_quotes.forEach((thisQuote) => {
    if (quoteId === thisQuote.id) quote = thisQuote;
  });

  const threadContent = (
    <>
      <div className="thread__media">
        <Avatar
          size="40"
          user={user}
        />
      </div>
      <div className="thread__content">
        <div className="thread__header">
          <span className="t-sage-heading-6 thread__username">{user.name}</span>
          <Timestamp
            timestamp={timestamp}
          />
        </div>
        {message && (
          <LinesEllipsis
            className="t-sage-body-small thread__body"
            text={message.replaceAll(/\n+/g, '\n')}
            maxLine="3"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        )}
        {quote && (
          <span className="t-sage-body-small thread__body">
            {quote.quote_type === 'proposal' ? 'Sent a proposal' : 'Sent an invoice'}
          </span>
        )}
      </div>
    </>
  );

  if (disabled) {
    return (
      <div className="thread thread--disabled">
        {threadContent}
      </div>
    );
  }
  return (
    <a
      className="thread"
      href={href}
      onClick={onClick}
    >
      {(read === false || read === null) && (senderId !== currentUser.id) && (
      <Dot className="thread__unread-indicator" />
      )}
      {threadContent}
      {active === true && <div className="thread__active-indicator" />}
    </a>
  );
}

Thread.defaultProps = {
  active: false,
  message: null,
  quoteId: null,
  read: false,
};

Thread.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  quoteId: PropTypes.number,
  read: PropTypes.bool,
  senderId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
