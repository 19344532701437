import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import DocumentHistoryChoice from './DocumentHistoryChoice';
import DocumentHistoryTabs from './DocumentHistoryTabs';
import ConversationsShowContext from '../ConversationsShowContext';

export default function DocumentHistoryModal({
  active,
  onClose,
}) {
  const {
    project,
  } = useContext(ConversationsShowContext);

  const [activeTabId, setActiveTabId] = useState('proposals');
  const proposals = [];
  const invoices = [];

  project.filtered_quotes.forEach((quote) => {
    if (quote.quote_type === 'proposal') proposals.push(quote);
    else if (quote.quote_type === 'invoice') invoices.push(quote);
  });

  function quoteList(arr, type) {
    if (arr.length === 0) {
      return (
        <p className="document-history-modal__null">
          {`You haven't sent any ${type}s yet`}
        </p>
      );
    }
    return arr.map((quote) => (
      <DocumentHistoryChoice
        key={uuid()}
        quote={quote}
      />
    ));
  }

  return (
    <Modal
      active={active}
      className="document-history-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
      )}
        className="document-history-modal__header"
        title="Document History"
      >
        <div className="document-history-modal__tabs">
          <DocumentHistoryTabs
            initialTabId={activeTabId}
            onSetActiveTab={(activeTab) => {
              setActiveTabId(activeTab);
            }}
          />
        </div>
      </Modal.Header>
      <hr className="document-history-modal__hr" />
      <div className="document-history-modal__content">
        {activeTabId === 'proposals' && quoteList(proposals, 'proposal')}
        {activeTabId === 'invoices' && quoteList(invoices, 'invoice')}
      </div>
      <hr className="document-history-modal__hr" />
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

DocumentHistoryModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
