import React from 'react';
import Header from './Header';
import Messages from './Messages';

export default function InboxPane() {
  return (
    <section className="pane inbox-pane">
      <Header />
      <Messages />
    </section>
  );
}
