import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Linkifier from '../../../shared/Linkifier';

const subcategories = require('../../../../data/categories/subcategories');

function BriefItem({
  isLink,
  label,
  value,
}) {
  return (
    <div className="brief-item">
      <p className="brief-item__label t-sage-heading-6">{label}</p>
      {isLink
        ? (
          <a
            className="brief-item__value"
            href={value}
          >
            {value}
          </a>
        )
        : (
          <div className="brief-item__value">
            <Linkifier>
              {value}
            </Linkifier>
          </div>
        )}
    </div>
  );
}

BriefItem.defaultProps = {
  isLink: false,
};

BriefItem.propTypes = {
  isLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default function Brief({
  project,
}) {
  if (!project) return false;

  const projectData = project.project_data;

  const {
    subcategoryId,
    subcategoryTitle,
  } = projectData;

  const getAnswers = (questions) => (questions || []).map((question) => {
    const answer = projectData[question.id];
    if (!answer) return false;

    let resolvedValue = '';
    if (Array.isArray(answer)) {
      answer.forEach((answerPart, idx) => {
        if (idx === 0) resolvedValue += answerPart;
        else resolvedValue += `, ${answerPart}`;
      });
    } else {
      resolvedValue = answer;
    }
    return (
      <BriefItem
        key={uuid()}
        label={question.prompt}
        value={resolvedValue}
      />
    );
  });

  return (
    <div className="brief">
      <BriefItem
        key={uuid()}
        label="Service"
        value={subcategoryTitle}
      />
      {getAnswers(subcategories[subcategoryId]?.questions, projectData)}
      {getAnswers(subcategories.projectPreferences.questions, projectData)}
    </div>
  );
}

Brief.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    project_data: PropTypes.shape.isRequired,
    title: PropTypes.string,
  }).isRequired,
};
