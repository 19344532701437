import React from 'react';

export default function TermsCallToAction() {
  return (
    <div className="terms">
      <p className="terms__text">
        {'By submitting this project brief, you agree to the Kajabi Experts '}
        <a
          className="terms__link"
          href="https://kajabi.com/policies/experts-marketplace-terms"
          rel="nofollow noreferrer"
          target="_blank"
        >
          Marketplace Terms
        </a>
      </p>
    </div>
  );
}
