import React, {
  useContext,
  useState,
} from 'react';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Button,
  FormSection,
} from '@kajabi/sage-react';
import CheckboxGroup from '../../shared/formik/CheckboxGroup';
import CustomFormMessage from '../../shared/CustomFormMessage';
import Input from '../../shared/formik/Input';
import Select from '../../shared/formik/Select';
import Textarea from '../../shared/formik/Textarea';
import PlanUpgradeButton from '../../shared/PlanUpgradeButton';
import Uploader from '../../shared/Uploader';
import options from '../../../data/shared/options';
import validations from '../../../data/shared/validations';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import UsersEditContext from '../../../contexts/UsersEditContext';
import userSlugAvailability from '../../../scripts/userSlugAvailability';

export default function Details() {
  const {
    about,
    avatar,
    avatarImageConfig,
    companyName,
    headline,
    kajabiTenure,
    languages,
    location,
    onUserSave,
    paypalEmail,
    slug,
    schedulingLink,
    website,
  } = useContext(UsersEditContext);

  const {
    picture,
    plan,
    id,
  } = useContext(CurrentUserContext);

  const [avatarImageUrl, setAvatarImageUrl] = useState(null);
  const [userSlugAvailable, setUserSlugAvailable] = useState(null);
  const [userSlugEmpty, setUserSlugEmpty] = useState(null);
  const [userSlugInvalid, setUserSlugInvalid] = useState(null);
  const [userSlugTooLong, setUserSlugTooLong] = useState(null);
  const [userSlugTooShort, setUserSlugTooShort] = useState(null);
  const [userSlugMatches, setUserSlugMatches] = useState(true);

  const validate = (values) => {
    const potentialSlug = values.slug;
    if (potentialSlug === slug) setUserSlugMatches(true);
    else setUserSlugMatches(false);
    if (potentialSlug.match(validations.matchingRegExSlug)) setUserSlugInvalid(false);
    else setUserSlugInvalid(true);
    if (potentialSlug.length > 30) setUserSlugTooLong(true);
    else setUserSlugTooLong(false);
    if (potentialSlug.length < 3) setUserSlugTooShort(true);
    else setUserSlugTooShort(false);
    if (potentialSlug.length === 0) {
      setUserSlugEmpty(true);
      setUserSlugTooShort(false);
    } else {
      setUserSlugEmpty(false);
    }
    userSlugAvailability(
      potentialSlug,
      (data) => setUserSlugAvailable(data),
    );
  };

  const nullSet = (value) => ((value === '') ? null : value);

  return (
    <section className="details">
      <Formik
        initialValues={{
          about,
          companyName,
          headline,
          kajabiTenure,
          languages,
          location,
          paypalEmail,
          slug,
          schedulingLink,
          website,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onUserSave({
            ...values,
            avatar: avatarImageUrl || avatar,
            companyName: nullSet(values.companyName),
            headline: nullSet(values.headline),
            paypalEmail: nullSet(values.paypalEmail),
            schedulingLink: nullSet(values.schedulingLink),
            slug: nullSet(values.slug),
            website: nullSet(values.website),
          });
        }}
        validate={validate}
        validationSchema={
          Yup.object({
            about: Yup.string(),
            companyName: Yup.string(),
            headline: Yup.string()
              .max(72, validations.sizeStringMaximum(72)),
            kajabiTenure: Yup.number()
              .typeError(validations.typeNumber),
            languages: Yup.array()
              .min(1, validations.sizeCheckboxMinimumOne),
            location: Yup.string(),
            paypalEmail: Yup.string()
              .email(validations.matchingInvalidEmail),
            slug: Yup.string(),
            schedulingLink: Yup.string()
              .matches(validations.matchingRegExURL, validations.matchingInvalidWebsite),
            website: Yup.string()
              .matches(validations.matchingRegExURL, validations.matchingInvalidWebsite),
          })
        }
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <FormSection
              title="Basic information"
              subtitle="This information will be displayed on your public profile."
            >
              {(!window.entitlements.customUrlCreate.enabled
                || !window.entitlements.websiteLinkShow
                || !window.entitlements.schedulingLinkShow)
                && (
                  <Alert
                    actions={<PlanUpgradeButton />}
                    color="info"
                    title="The disabled features below require a plan upgrade"
                  />
                )}
              <Uploader
                asset={avatarImageUrl || avatar || picture}
                config={avatarImageConfig}
                onSuccess={((payload) => {
                  const file = payload.files[0];
                  setAvatarImageUrl(`https://s3.amazonaws.com/${file.bucket}/${file.key}`);
                })}
              />
              <Input
                errors={errors.companyName}
                handleChange={handleChange}
                idName="companyName"
                label="Company name"
                placeholder="Nimble Solutions Inc"
                touched={touched.companyName}
                value={values.companyName}
              />
              <Input
                disabled={!window.entitlements.websiteLinkShow.enabled}
                errors={errors.website}
                handleChange={handleChange}
                idName="website"
                label="Website"
                placeholder="Enter your website"
                touched={touched.website}
                value={values.website}
              />

              <div className="details__slug-validation">
                <div className="details__slug-validation-input">
                  <Input
                    disabled={!window.entitlements.customUrlCreate.enabled || plan !== 'growth'}
                    errors={errors.slug}
                    handleChange={handleChange}
                    idName="slug"
                    label="Experts profile URL"
                    placeholder="nimblesolutions"
                    prefix={`${window.location.host}/`}
                    touched={touched.slug}
                    value={plan === 'growth' ? values.slug : `users/${id}`}
                  />
                </div>
                {userSlugAvailable
                    && !userSlugEmpty
                    && !userSlugInvalid
                    && !userSlugMatches
                    && !userSlugTooLong
                    && !userSlugTooShort
                    && (
                      <CustomFormMessage
                        icon="check"
                        message="Username is available"
                        type="success"
                      />
                    )}
                {!userSlugAvailable
                    && !userSlugEmpty
                    && !userSlugInvalid
                    && !userSlugMatches
                    && !userSlugTooLong
                    && !userSlugTooShort
                    && (
                      <CustomFormMessage
                        message="Username is not available"
                        type="danger"
                      />
                    )}
                {userSlugInvalid && (
                  <CustomFormMessage
                    message={validations.matchingInvalidSlug}
                    type="danger"
                  />
                )}
                {userSlugTooLong && (
                  <CustomFormMessage
                    message={validations.sizeStringMaximum(30)}
                    type="danger"
                  />
                )}
                {userSlugTooShort && (
                  <CustomFormMessage
                    message={validations.sizeStringMinimum(3)}
                    type="danger"
                  />
                )}
              </div>
              <Input
                errors={errors.headline}
                handleChange={handleChange}
                idName="headline"
                label="Headline"
                placeholder="Sales Funnel Specialist"
                touched={touched.headline}
                value={values.headline}
              />
              <Textarea
                errors={errors.about}
                handleChange={handleChange}
                idName="about"
                label="About"
                placeholder="Hello! I am a professional web designer and developer..."
                touched={touched.about}
                value={values.about}
              />
              <Input
                errors={errors.kajabiTenure}
                handleChange={handleChange}
                idName="kajabiTenure"
                label="Years using Kajabi"
                placeholder="Enter number of years..."
                touched={touched.kajabiTenure}
                value={values.kajabiTenure}
              />
              <Select
                handleChange={handleChange}
                idName="location"
                label="Where are you located?"
                options={options.locations}
                value={values.location}
              />
              <Input
                disabled={!window.entitlements.schedulingLinkShow.enabled}
                errors={errors.schedulingLink}
                handleChange={handleChange}
                idName="schedulingLink"
                label="Scheduling link (optional)"
                placeholder="Enter a calendar URL..."
                touched={touched.schedulingLink}
                value={values.schedulingLink}
              />
              <Input
                errors={errors.paypalEmail}
                handleChange={handleChange}
                idName="paypalEmail"
                label="PayPal email address (optional)"
                placeholder="hello@example.com"
                touched={touched.paypalEmail}
                value={values.paypalEmail}
              />
              <CheckboxGroup
                handleChange={handleChange}
                name="languages"
                options={options.languages}
                title="What languages do you offer services in?"
              />
            </FormSection>
            <Button.Group
              align="end"
            >
              <Button
                disabled={isSubmitting
                  || ((!userSlugAvailable
                    || userSlugInvalid
                    || userSlugTooLong
                    || userSlugTooShort
                  ) && !userSlugMatches)}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Button.Group>
          </>
        )}
      </Formik>
    </section>
  );
}
