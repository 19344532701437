export default function userAcceptTerms() {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/user/accept_terms', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.text())
    .then(() => window.location.replace('/projects'));
}
