import React from 'react';
import {
  Icon,
} from '@kajabi/sage-react';

export default function NullPane() {
  return (
    <section className="pane null-pane">
      <div className="null-pane__content">
        <Icon
          adjacentType="h5"
          icon="arrow-left"
        />
        <p className="t-sage-heading-5 null-pane__message">Select a message to continue the conversation.</p>
      </div>
    </section>
  );
}
