import React, {
  useContext,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import uuid from 'react-uuid';
import CategoryBreadcrumbs from './CategoryBreadcrumbs';
import CategoryChoice from './CategoryChoice';
import ProjectNewContext from '../../../contexts/ProjectNewContext';
import { categories } from '../../../data/categories/categories';
import withActiveCategoriesOnly from '../../../scripts/withActiveCategoriesOnly';

export default function CategorySelect() {
  const {
    category,
    setCategory,
    setSubcategory,
  } = useContext(ProjectNewContext);

  const navigate = useNavigate();

  return (
    <section className="category-select">
      <div className="container">
        <div className="category-select__header">
          <p className="t-sage-heading-4">Choose a service to get started</p>
          <CategoryBreadcrumbs />
        </div>
        <div className="category-select__list">
          {category
            ? withActiveCategoriesOnly(category.subcategories).map((thisSubcategory) => (
              <CategoryChoice
                isActive={thisSubcategory.active}
                key={uuid()}
                onClick={() => {
                  navigate(`./${thisSubcategory.slug}`);
                  setSubcategory(thisSubcategory);
                }}
                title={thisSubcategory.title}
              />
            ))
            : withActiveCategoriesOnly(categories).map((thisCategory) => (
              <CategoryChoice
                description={thisCategory.description}
                isActive={thisCategory.active}
                key={uuid()}
                onClick={() => {
                  navigate(`./${thisCategory.slug}`);
                  setCategory(thisCategory);
                }}
                title={thisCategory.title}
              />
            ))}
        </div>
      </div>
    </section>
  );
}
