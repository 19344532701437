/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import {
  optionsLanguages,
  optionsLocationsPreference,
} from '../shared/options';
import {
  validationCheckboxMinimumOneSelected,
  validationMaximumCharacters,
  validationStringRequired,
} from '../shared/validations';
import {
  sharedBusinessStage,
  sharedDeliveryTime,
  sharedDescription,
} from './shared';

// Project Preferences - This is universal amongst all new projects
export const projectPreferences = {
  initialValues: {
    budget: '$1,000 - $2,499',
    preferredLanguages: ['English'],
    preferredLocation: 'No location preference',
  },
  validationSchema: Yup.object({
    budget: Yup.string(),
    preferredLanguages: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    preferredLocation: Yup.string(),
  }),
  questions: [
    {
      id: 'budget',
      message: 'Kajabi has experts available for projects of all sizes. This information helps us ensure that the correct experts are notified about your project.',
      options: [
        'Less than $1,000 USD',
        '$1,000 - $2,499',
        '$2,500 - $4,999',
        '$5,000 - $9,999',
        '$10,000 - $19,999',
        '$20,000 or more',
        'Whatever it takes',
      ],
      prompt: 'What would you expect to pay for this project?',
      promptBrief: 'Budget',
      type: 'radio',
    },
    {
      id: 'preferredLanguages',
      options: optionsLanguages,
      prompt: 'What language do you prefer the expert to communicate in?',
      promptBrief: 'Language',
      type: 'checkbox',
    },
    {
      id: 'preferredLocation',
      options: optionsLocationsPreference,
      prompt: 'Do you prefer to work with experts located in a certain country?',
      promptBrief: 'Preferred location',
      type: 'select',
    },
  ],
};

// These fields are used on the very first step of the wizard
export const projectMainDetails = {
  initialValues: {
    title: '',
    description: sharedDescription.initialValue,
  },
  validationSchema: Yup.object({
    title: Yup.string().max(80, validationMaximumCharacters(80)),
    description: sharedDescription.validation,
  }),
  questions: [
    {
      id: 'title',
      placeholder: 'Enter a title...',
      prompt: 'Give your project a title.',
      promptBrief: 'Project title',
      type: 'input',
    },
    {
      id: sharedDescription.id,
      placeholder: sharedDescription.placeholder,
      prompt: 'Add any details or links that will help the expert understand what you need.',
      promptBrief: sharedDescription.promptBrief,
      type: sharedDescription.type,
    },
  ],
};

// Website setup and design subcategories
export const contentMigration = {
  initialValues: {
    contentType: [],
    platform: '',
  },
  validationSchema: Yup.object({
    contentType: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    platform: Yup.string().required(validationStringRequired),
  }),
  questions: [
    {
      id: 'contentType',
      options: [
        'Website',
        'Blog posts',
        'Community (e.g., migrate Facebook Group content to Kajabi Communities)',
        'Course or other product',
      ],
      prompt: 'What kind of content are you looking to migrate?',
      promptBrief: 'Type of content',
      type: 'checkbox',
    },
    {
      id: 'platform',
      placeholder: 'Enter a platform name...',
      prompt: 'What platform are you migrating from?',
      promptBrief: 'Platform',
      type: 'input',
    },
  ],
};

export const customCoding = {
  initialValues: {
    developmentTasks: [],
  },
  validationSchema: Yup.object({
    developmentTasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'developmentTasks',
      options: [
        'Custom coding for your existing website',
        'Custom app development',
        'Integration with another system or systems',
        'Technical troubleshooting',
        'Other',
      ],
      prompt: 'Select the development tasks you need done.',
      promptBrief: 'Development tasks',
      type: 'checkbox',
    },
  ],
};

export const expertSiteSetup = {
  initialValues: {
    expertGuidance: [],
    businessStage: sharedBusinessStage.initialValue,
  },
  validationSchema: Yup.object({
    expertGuidance: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    businessStage: sharedBusinessStage.validation,
  }),
  questions: [
    {
      id: 'expertGuidance',
      options: [
        'Website setup guidance',
        'Products setup guidance',
        'Offers, coupons and checkout setup',
        'Funnels, forms, events and automations',
        'Managing contacts, members and affiliates',
      ],
      prompt: 'Select the type of expert guidance you need.',
      promptBrief: 'Type of guidance',
      type: 'checkbox',
    },
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
  ],
};

export const searchEngineOptimization = {
  initialValues: {
    seoTasks: [],
    seoHelp: 'No. I’d like an SEO audit and recommended next steps.',
  },
  validationSchema: Yup.object({
    seoTasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    seoHelp: Yup.string(),
  }),
  questions: [
    {
      id: 'seoTasks',
      options: [
        'Optimize your web pages for search engines',
        'Acquire inbound links',
        'Guidance for bringing traffic to your website',
      ],
      prompt: 'Select the SEO tasks that you need done',
      promptBrief: 'SEO tasks',
      type: 'checkbox',
    },
    {
      id: 'seoHelp',
      options: [
        'No. I’d like an SEO audit and recommended next steps.',
        'Yes. I have an idea of what I want and I need someone to make it happen.',
      ],
      prompt: 'Do you know what kind of SEO help you want?',
      promptBrief: 'Do you know what kind of SEO help you want?',
      type: 'radio',
    },
  ],
};

export const websiteLandingPageDesign = {
  initialValues: {
    kajabiTenure: 'New to Kajabi — under one year',
    hasWebsite: 'Yes, I already have a website set up and live for customers',
    websiteUrl: '',
    websiteOnOtherPlatform: 'Kajabi',
    websiteTasks: ['Select, set up and customize website theme'],
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    kajabiTenure: Yup.string(),
    hasWebsite: Yup.string(),
    websiteUrl: Yup.string(),
    websiteOnOtherPlatform: Yup.string(),
    websiteTasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: 'kajabiTenure',
      options: [
        'New to Kajabi — under one year',
        'Moderately experienced — been using Kajabi for a couple of years',
        'Very experienced',
      ],
      prompt: 'Are you a new Kajabi user or an old pro?',
      promptBrief: 'User type',
      type: 'radio',
    },
    {
      id: 'hasWebsite',
      options: [
        'Yes, I already have a website set up and live for customers',
        'No, I don’t yet have a website set up and live for customers',
      ],
      prompt: 'Do you already have a website for your business?',
      promptBrief: 'Existing website?',
      type: 'radio',
    },
    {
      id: 'websiteUrl',
      placeholder: 'Enter a URL...',
      prompt: 'What is the URL of the website or landing page you’re looking for help with?',
      promptBrief: 'URL',
      type: 'input',
    },
    {
      id: 'websiteOnOtherPlatform',
      options: [
        'Kajabi',
        'Another platform',
        'I don’t have a website yet',
      ],
      prompt: 'Is your existing website on Kajabi or another platform?',
      promptBrief: 'Platform',
      type: 'radio',
    },
    {
      id: 'websiteTasks',
      options: [
        'Select, set up and customize website theme',
        'Design and set up landing pages',
        'Customize my existing website theme',
        'Other',
      ],
      prompt: 'What website set up tasks do you need help with?',
      promptBrief: 'Website design tasks',
      type: 'checkbox',
    },
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

// Marketing and sales subcategories
export const advertising = {
  initialValues: {
    typeOfHelp: 'No. I’d like expert advice on strategy and next steps.',
    adPlatforms: [],
    monthlyBudget: '$500 - $1,000 per month',
  },
  validationSchema: Yup.object({
    typeOfHelp: Yup.string(),
    adPlatforms: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    monthlyBudget: Yup.string(),
  }),
  questions: [
    {
      id: 'typeOfHelp',
      options: [
        'No. I’d like expert advice on strategy and next steps.',
        'Yes. I have an idea of what I want and I need someone to make it happen.',
      ],
      prompt: 'Do you know what kind of advertising help you want?',
      promptBrief: 'Do you know what kind of advertising help you want?',
      type: 'radio',
    },
    {
      id: 'adPlatforms',
      options: [
        'Meta (Facebook, Instagram)',
        'TikTok',
        'Pinterest',
        'Twitter',
        'Google, Bing and other search engines',
        'Other',
        'I’m not sure',

      ],
      prompt: 'Select the platforms you’re looking to advertise on.',
      promptBrief: 'Ad platforms',
      type: 'checkbox',
    },
    {
      id: 'monthlyBudget',
      options: [
        'Less than $250 per month',
        '$250 - $500 per month',
        '$500 - $1,000 per month',
        '$1,000 - $10,000 per month',
        'More than $10,000 per month',
        'I don’t know ',
      ],
      prompt: 'What is your monthly budget for advertising?',
      promptBrief: 'Monthly budget for ads',
      type: 'radio',
    },
  ],
};

export const emailMarketing = {
  initialValues: {
    emailMarketingTasks: [],
    emailPlatforms: [],
    monthlyBudget: '$500 - $1,000 per month',
  },
  validationSchema: Yup.object({
    emailMarketingTasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    emailPlatforms: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    monthlyBudget: Yup.string(),
  }),
  questions: [
    {
      id: 'emailMarketingTasks',
      options: [
        'Email campaign setup',
        'Email marketing content',
        'Custom email templates',
        'Email automation',
        'Email marketing guidance',
      ],
      prompt: 'Select the email marketing tasks that you need done.',
      promptBrief: 'Email marketing tasks',
      type: 'checkbox',
    },
    {
      id: 'emailPlatforms',
      options: [
        'Kajabi',
        'ActiveCampaign',
        'Mailchimp',
        'AWeber',
        'ConvertKit',
        'Other',
        'I would like suggestions',
      ],
      prompt: 'What email platforms do you currently use?',
      promptBrief: 'Email platforms',
      type: 'checkbox',
    },
    {
      id: 'monthlyBudget',
      options: [
        'Less than $250 per month',
        '$250 - $500 per month',
        '$500 - $1,000 per month',
        '$1,000 - $10,000 per month',
        'More than $10,000 per month',
        'I don’t know',
      ],
      prompt: 'What is your monthly budget for advertising?',
      promptBrief: 'Monthly budget for ads',
      type: 'radio',
    },
  ],
};

export const marketingAndLaunchStrategy = {
  initialValues: {
    marketingAreas: [],
    businessStage: sharedBusinessStage.initialValue,
  },
  validationSchema: Yup.object({
    marketingAreas: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    businessStage: sharedBusinessStage.validation,
  }),
  questions: [
    {
      id: 'marketingAreas',
      options: [
        'Positioning',
        'Sales strategy',
        'Social media marketing',
        'Email marketing',
        'Content marketing',
      ],
      prompt: 'Select the marketing and sales areas you need guidance for.',
      promptBrief: 'Marketing areas',
      type: 'checkbox',
    },
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
  ],
};

export const salesFunnels = {
  initialValues: {
    contentStatus: 'I’m just getting started.',
    businessStage: sharedBusinessStage.initialValue,
  },
  validationSchema: Yup.object({
    contentStatus: Yup.string(),
    businessStage: sharedBusinessStage.validation,
  }),
  questions: [
    {
      id: 'contentStatus',
      options: [
        'My content is in good shape — need effective implementation.',
        'I have a rough first draft of my content.',
        'I have ideas but haven’t yet organized them.',
        'I’m just getting started.',
      ],
      prompt: 'Do you have content ready for your sales funnel or are you starting from scratch?',
      promptBrief: 'Content status',
      type: 'radio',
    },
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
  ],
};

// Admin support subcategories
export const accounting = {
  initialValues: {
    businessStage: sharedBusinessStage.initialValue,
    expectedDuration: 'Short term',
  },
  validationSchema: Yup.object({
    businessStage: sharedBusinessStage.validation,
    expectedDuration: Yup.string(),
  }),
  questions: [
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
    {
      id: 'expectedDuration',
      options: [
        'Short term',
        'A few months',
        '3 - 6 months',
        'More than 6 months',
      ],
      prompt: 'What is your expected duration for this project?',
      promptBrief: 'Expected duration',
      type: 'radio',
    },
  ],
};

export const businessStrategy = {
  initialValues: {
    businessStage: sharedBusinessStage.initialValue,
    title: '',
    description: sharedDescription.initialValue,
  },
  validationSchema: Yup.object({
    businessStage: sharedBusinessStage.validation,
    title: Yup.string().max(80, validationMaximumCharacters(80)),
    description: sharedDescription.validation,
  }),
  questions: [
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
  ],
};

export const socialMedia = {
  initialValues: {
    tasks: [],
    platforms: [],
  },
  validationSchema: Yup.object({
    tasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    platforms: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'tasks',
      options: [
        'Social media content creation',
        'Social media account management ',
        'Social media ad management',
        'Audience research',
        'Meta pixel setup',
        'Other',
      ],
      prompt: 'Select the social media management tasks you need done.',
      promptBrief: 'Tasks',
      type: 'checkbox',
    },
    {
      id: 'platforms',
      options: [
        'Instagram',
        'Facebook',
        'YouTube',
        'TikTok',
        'Twitter',
        'Pinterest',
        'LinkedIn',
        'Other',
      ],
      prompt: 'Select the platforms you’re hiring help for.',
      promptBrief: 'Platforms',
      type: 'checkbox',
    },
  ],
};

export const transcription = {
};

export const virtualAssistant = {
  initialValues: {
    tasks: [],
  },
  validationSchema: Yup.object({
    tasks: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'tasks',
      options: [
        'Customer support',
        'Contact management',
        'Content migration',
        'Content management',
        'Data entry',
        'Documentation',
        'Bookkeeping tasks',
        'Site optimization',
        'Other',
      ],
      prompt: 'Select the type of tasks you’d like help with.',
      promptBrief: 'Tasks',
      type: 'checkbox',
    },
  ],
};

// Content development subcategories

export const contentCreation = {
  initialValues: {
    contentType: [],
    businessStage: sharedBusinessStage.initialValue,
  },
  validationSchema: Yup.object({
    contentType: Yup.array().min(1, validationCheckboxMinimumOneSelected),
    businessStage: sharedBusinessStage.validation,
  }),
  questions: [
    {
      id: 'contentType',
      options: [
        'Website content',
        'Blog posts',
        'Course content',
        'Email marketing content',
        'Social media content',
        'Overall content strategy',
        'Other',
      ],
      prompt: 'Select the type of content help you need.',
      promptBrief: 'Type of content help',
      type: 'checkbox',
    },
    {
      id: sharedBusinessStage.id,
      options: sharedBusinessStage.options,
      prompt: sharedBusinessStage.prompt,
      promptBrief: sharedBusinessStage.promptBrief,
      type: sharedBusinessStage.type,
    },
  ],
};

export const copywriting = {
  initialValues: {
    contentType: [],
  },
  validationSchema: Yup.object({
    contentType: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'contentType',
      options: [
        'Websites and landing pages',
        'Course content',
        'eBooks and documents',
        'Blog posts',
        'Emails',
        'Other',
      ],
      prompt: 'Select the type of content you’d like writing help with.',
      promptBrief: 'Content type',
      type: 'checkbox',
    },
  ],
};

export const podcasting = {
  initialValues: {
    contentType: [],
  },
  validationSchema: Yup.object({
    contentType: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'contentType',
      options: [
        'Podcast production',
        'Voiceovers or narration',
        'Audio advertisements',
        'Audiobook production',
        'Other',
      ],
      prompt: 'Select the type of audio content you’d like help with.',
      promptBrief: 'Type of audio content',
      type: 'checkbox',
    },
  ],
};

export const videoEditing = {
  initialValues: {
    helpType: [],
  },
  validationSchema: Yup.object({
    helpType: Yup.array().min(1, validationCheckboxMinimumOneSelected),
  }),
  questions: [
    {
      id: 'helpType',
      options: [
        'Editing existing videos',
        'Creating new videos',
        'Other',
      ],
      prompt: 'Select the type of video help you need.',
      promptBrief: 'Type of video help',
      type: 'checkbox',
    },
  ],
};

// Visual design and branding subcategories
export const branding = {
  initialValues: {
    examples: '',
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    examples: Yup.string().required(validationStringRequired),
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: 'examples',
      placeholder: 'Share some examples...',
      prompt: 'Share some examples of businesses that inspire you.',
      promptBrief: 'Examples',
      type: 'textarea',
    },
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

export const ebooks = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

// Coaching subcategories
export const implementation = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

export const business = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

export const salesMarketing = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

export const highTicket = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};

export const newBusiness = {
  initialValues: {
    deliveryTime: sharedDeliveryTime.initialValue,
  },
  validationSchema: Yup.object({
    deliveryTime: sharedDeliveryTime.validation,
  }),
  questions: [
    {
      id: sharedDeliveryTime.id,
      options: sharedDeliveryTime.options,
      prompt: sharedDeliveryTime.prompt,
      promptBrief: sharedDeliveryTime.promptBrief,
      type: sharedDeliveryTime.type,
    },
  ],
};
