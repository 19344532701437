import React from 'react';
import PropTypes from 'prop-types';
import UIIconComment from './UIIconComment';

export default function ModalAlert({
  description,
  title,
}) {
  return (
    <div className="modal-alert">
      <div className="modal-alert__header">
        <UIIconComment />
        <div className="modal-alert__title">{title}</div>
      </div>
      <div className="modal-alert__description">{description}</div>
    </div>
  );
}

ModalAlert.defaultProps = {
  description: null,
};

ModalAlert.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};
