import React from 'react';

export default function Hero() {
  return (
    <section className="hero">
      <h1 className="t-sage-heading-3 hero__title">Get 0% commission and exclusive marketing opportunities with the Expert+ Plan.</h1>
      <p className="hero__description">Plus, an upgraded profile.</p>
    </section>
  );
}
