import React from 'react';
import PropTypes from 'prop-types';
import {
  Input as SageInput,
} from '@kajabi/sage-react';

export default function Input({
  disabled,
  errors,
  handleChange,
  idName,
  inputType,
  label,
  placeholder,
  prefix,
  touched,
  value,
}) {
  return (
    <SageInput
      disabled={disabled}
      hasError={!!errors && touched}
      id={idName}
      inputType={inputType}
      label={label}
      message={errors && touched ? errors : null}
      name={idName}
      onChange={handleChange}
      placeholder={placeholder}
      prefix={prefix}
      value={value}
    />
  );
}

Input.defaultProps = {
  disabled: false,
  errors: null,
  inputType: null,
  prefix: null,
  placeholder: null,
  touched: null,
};

Input.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  idName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
