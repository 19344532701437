import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ConfigContext from '../../../contexts/ConfigContext';

export default function ActionButtonPrimary({ variant }) {
  const navigate = useNavigate();
  const { authenticityToken, loginPath, user } = useContext(ConfigContext);
  const buttonText = 'Start a project';

  if (!user) {
    return (
      <form action={loginPath} method="post">
        <pds-button variant={variant} type="submit">
          {buttonText}
        </pds-button>
        <input
          name="authenticity_token"
          type="hidden"
          value={authenticityToken}
        />
      </form>
    );
  }

  return (
    <pds-button
      onClick={() => {
        if (user.type === 'user') navigate('/projects/new');
        else {
          window.location.replace(`${window.location.origin}/projects/browse`);
        }
      }}
      variant={variant}
    >
      {buttonText}
    </pds-button>
  );
}

ActionButtonPrimary.defaultProps = {
  variant: 'primary',
};

ActionButtonPrimary.propTypes = {
  variant: PropTypes.string,
};
