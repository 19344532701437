import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';

export default function LogOut() {
  return (
    <Button
      color="secondary"
      onClick={() => window.location.replace('/auth/logout')}
      subtle
    >
      Log Out
    </Button>
  );
}
