import React, {
  useContext,
} from 'react';
import UserPaneAbout from './UserPaneAbout';
import UserPaneBio from './UserPaneBio';
import UserPaneProjects from './UserPaneProjects';
import ConversationsShowContext from '../ConversationsShowContext';

export default function UserPane() {
  const {
    user,
  } = useContext(ConversationsShowContext);
  return (
    <section className="pane user-pane">
      <UserPaneBio />
      {user.type === 'user' && <UserPaneAbout />}
      <UserPaneProjects />
    </section>
  );
}
