import React, {
  useContext,
} from 'react';
import uuid from 'react-uuid';
import UserPaneProject from './UserPaneProject';
import ConversationsShowContext from '../ConversationsShowContext';

export default function Projects() {
  const {
    currentRecentProjects,
  } = useContext(ConversationsShowContext);

  if (currentRecentProjects) {
    return (
      <section className="user-pane-projects">
        <p className="t-sage-heading-6 user-pane-projects__title">Recent projects</p>
        <div className="user-pane-projects__list">
          {currentRecentProjects.recentProjects.length === 0 && (
            <p>No projects together yet</p>
          )}
          {currentRecentProjects.recentProjects.length > 0
            && currentRecentProjects.recentProjects.map((proj) => (
              <UserPaneProject
                category={proj.subcategory.name}
                id={proj.id}
                key={uuid()}
                status={proj.status}
              />
            ))}
        </div>
      </section>
    );
  }
}
