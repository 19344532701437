/* eslint-disable no-console */
export default function briefUpdate(
  brief,
  callback,
) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));
  const pathname = window.location.pathname.split('/');
  const projectId = pathname[pathname.length - 1];

  fetch(`/projects/${projectId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      project: {
        brief,
      },
    }),
  }).then(() => {
    callback(brief);
  });
}
