import React, { useContext } from 'react';
import uuid from 'react-uuid';
import ReviewsItem from './ReviewsItem';
import UsersShowContext from '../../../../../contexts/UsersShowContext';
import {
  testimonialsList,
} from '../../../scripts/testimonial';

const ratingToReview = (rating) => ({
  client: rating.reviewer_name,
  createdAt: new Date(rating.rating.created_at),
  content: rating.rating.review,
  rating: rating.rating.rating,
  title: rating.category_name,
});

const testimonialToReview = (testimonial) => ({
  client: `${testimonial.reviewer_given_name} ${testimonial.reviewer_family_name}`,
  content: testimonial.content,
  createdAt: new Date(testimonial.created_at),
  rating: testimonial.rating,
  title: testimonial.title,
});

export default function ReviewsList() {
  const {
    reviewsWithTestimonials: reviews,
  } = useContext(UsersShowContext);

  return (
    <section className="reviews-list">
      {reviews.length > 0 && reviews.map((rating) => (
        <ReviewsItem
          key={uuid()}
          ratingObject={rating}
        />
      ))}
    </section>
  );
}
