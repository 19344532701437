import React, {
  useContext,
} from 'react';
import uuid from 'react-uuid';
import {
  Badge,
  Label,
} from '@kajabi/sage-react';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function Services() {
  const {
    userData,
  } = useContext(UsersShowContext);

  return (
    <section className="services">
      <div className="services__content">
        <Label.Group>
          {userData.filtered_services.map((service) => (
            <Badge
              className="services__service"
              key={uuid()}
              onClick={() => {
                window.location.replace(
                  `${window.location.origin}/directory?type=expert&services=${service.subcategorySlug}`,
                );
              }}
              value={service.subcategoryName}
            />
          ))}
        </Label.Group>
      </div>
    </section>
  );
}
