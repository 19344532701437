import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Badge,
  Icon,
} from '@kajabi/sage-react';

export default function CategoryChoice({
  description,
  isActive,
  onClick,
  title,
}) {
  const classNames = classnames(
    'category-choice',
    {
      'category-choice--coming-soon': !isActive,
    },
  );

  return (
    <div
      className={classNames}
      onClick={isActive && onClick}
      role="presentation"
    >
      <div className="category-choice__text">
        <p className="t-sage-heading-5 category-choice__title">{title}</p>
        {description && (
          <p className="t-sage-body-small category-choice__description">{description}</p>
        )}
      </div>
      {isActive
        ? (
          <Icon
            className="category-choice__icon"
            icon="caret-right"
          />
        )
        : (
          <Badge
            className="category-choice__badge"
            value="Coming soon"
          />
        )}
    </div>
  );
}

CategoryChoice.defaultProps = {
  description: null,
  isActive: true,
};

CategoryChoice.propTypes = {
  description: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
