import React, { useContext } from 'react';
import AddButton from './AddButton';
import PackagesContext from './PackagesContext';

export default function Info() {
  const { packages } = useContext(PackagesContext);

  const PACKAGES_ENABLED = window.globalFeatureFlags.packages.enabled;
  const PACKAGES_LIMIT = window.entitlements.packageCreate.amount;
  const maxPackagesAdded = PACKAGES_LIMIT <= packages.length;

  return (
    <div className="info">
      <h3 className="info__title">Your packages</h3>
      <p className="info__description">
        {`Manage packages to sell your services and products.
        You can add no more than ${PACKAGES_LIMIT} packages.`}
      </p>
      {PACKAGES_ENABLED && !maxPackagesAdded && <AddButton />}
    </div>
  );
}
