import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import Info from './Info';
import List from './List';
import PackageModal from './PackageModal';
import PackagesContext from './PackagesContext';

export default function Packages() {
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [packageModalData, setPackageModalData] = useState({
    active: false,
    type: 'add',
  });

  const fetchPackages = () => {
    fetch(`${window.location.origin}/api/v1/packages.json`)
      .then((response) => response.json())
      .then((data) => setPackages(data));
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const packagesContextValues = useMemo(() => ({
    currentPackage,
    fetchPackages,
    packageImageConfig: window.uploaderConfig.package,
    packageModalData,
    packages,
    setCurrentPackage,
    setPackageModalData,
  }));

  return (
    <PackagesContext.Provider value={packagesContextValues}>
      <section className="packages">
        <div className="sage-row">
          <div className="sage-col-4">
            <Info />
          </div>
          <div className="sage-col-8">
            <List />
          </div>
        </div>
      </section>
      <PackageModal
        active={packageModalData.active}
        onClose={() => setPackageModalData({
          active: false,
          type: null,
        })}
      />
    </PackagesContext.Provider>
  );
}
