import React, { useContext } from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import TestimonialsContext from './TestimonialsContext';

export default function AddButton() {
  const { setTestimonialModalData } = useContext(TestimonialsContext);

  return (
    <Button
      className="info__add-button"
      icon="add"
      onClick={
        () => setTestimonialModalData({
          active: true,
          type: 'add',
        })
      }
    >
      Add new testimonial
    </Button>
  );
}
