/* eslint no-use-before-define: ["error", { "functions": false }] */

import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CurrentProjectContext from '../../../contexts/CurrentProjectContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import ProjectsShowContext from './ProjectsShowContext';
import ViewDefault from './view-default/ViewDefault';
import ViewVendorPrompt from './view-vendor-prompt/ViewVendorPrompt';
import briefUpdate from '../../../scripts/briefUpdate';
import useApiFetch from '../../../scripts/useApiFetch.tsx';

export default function ProjectsShow() {
  const user = useContext(CurrentUserContext);

  const {
    type: userType,
  } = user;

  const [brief, setBrief] = useState(null);
  const [isAiBrief, setIsAiBrief] = useState(true);
  const [project, setProject] = useState(null);
  const { apiFetch } = useApiFetch();

  const MESSAGE_CREATE = window.entitlements.messageCreate.enabled;
  const PROPOSAL_CREATE = window.entitlements.proposalCreate.enabled;

  const briefUpdateProxy = (briefData) => {
    briefUpdate(
      briefData,
      (response) => {
        project.brief = briefData;
        setBrief(response);
      },
    );
  };

  const aiBriefInterval = 1000;
  const aiBriefTimeout = 10000;
  let aiBriefTicker = 0;

  function resolveBrief(data) {
    const originalProjectDescription = data.project_data.description;
    if (data.brief) {
      setBrief(data.brief);
    } else if (userType === 'user') {
      aiBriefTicker += aiBriefInterval;
      if (aiBriefTicker === aiBriefTimeout) {
        setBrief(originalProjectDescription);
        setIsAiBrief(false);
        briefUpdate(
          originalProjectDescription,
        );
      } else {
        setTimeout(fetchProject, aiBriefInterval);
      }
    } else if (userType === 'vendor') {
      setBrief(originalProjectDescription);
    }
  }

  function fetchProject() {
    apiFetch(`${window.location.href}.json`).then((data) => {
      setProject(data);
      resolveBrief(data);
    }).catch(() => {});
  }

  useEffect(() => {
    fetchProject();
  }, []);

  const messages = project?.filtered_messages || [];

  const projectsShowContextValues = useMemo(() => ({
    brief,
    briefUpdateProxy,
    isAiBrief,
    MESSAGE_CREATE,
    project,
    PROPOSAL_CREATE,
    setBrief,
    user,
  }));

  return (
    <div className="container">
      {project && (
        <ProjectsShowContext.Provider value={projectsShowContextValues}>
          <CurrentProjectContext.Provider value={project}>
            {(userType === 'vendor' && messages.length === 0)
              ? (
                <ViewVendorPrompt />
              ) : (
                <ViewDefault />
              )}
          </CurrentProjectContext.Provider>
        </ProjectsShowContext.Provider>
      )}
    </div>
  );
}
