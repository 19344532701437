import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Linkifier from '../../../shared/Linkifier';
import ConversationsShowContext from '../ConversationsShowContext';

const subcategories = require('../../../../data/categories/subcategories');

function BriefItem({
  isLink,
  label,
  value,
}) {
  return (
    <div className="brief-item">
      <p className="brief-item__label t-sage-heading-6">{label}</p>
      {isLink
        ? (
          <a
            className="brief-item__value"
            href={value}
          >
            {value}
          </a>
        )
        : (
          <div className="brief-item__value">
            <Linkifier>
              {value}
            </Linkifier>
          </div>
        )}
    </div>
  );
}

BriefItem.defaultProps = {
  isLink: false,
};

BriefItem.propTypes = {
  isLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default function ChatPaneProjectBrief({
  className,
}) {
  const {
    project,
  } = useContext(ConversationsShowContext);

  const projectData = project.project_data;
  const {
    subcategoryId,
    subcategoryTitle,
  } = projectData;

  const getAnswers = (questions) => (questions || []).map((question) => {
    if (!(question.id in projectData)) {
      return false;
    }

    const answer = projectData[question.id];
    let resolvedValue = '';
    if (Array.isArray(answer)) {
      answer.forEach((answerPart, idx) => {
        if (idx === 0) resolvedValue += answerPart;
        else resolvedValue += `, ${answerPart}`;
      });
    } else {
      resolvedValue = answer;
    }

    return (
      <BriefItem
        key={uuid()}
        label={question.prompt}
        value={resolvedValue}
      />
    );
  });

  return (
    <div className={className}>
      <BriefItem
        key={uuid()}
        label="Service"
        value={subcategoryTitle}
      />
      {getAnswers(subcategories[subcategoryId]?.questions)}
      {getAnswers(subcategories.projectPreferences.questions)}
    </div>
  );
}

ChatPaneProjectBrief.defaultProps = {
  className: null,
};

ChatPaneProjectBrief.propTypes = {
  className: PropTypes.string,
};
