import React from 'react';

export default function GraphicProjectStatusComplete() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 42.3041C0 14.7011 26.4252 3.95651 54.021 3.95651C81.6167 3.95651 104 26.3326 104 53.9356C104 81.5385 82.4236 99.64 54.8206 99.64C27.2249 99.64 0 69.9071 0 42.3041Z" fill="#DCF0FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M81.3041 45.3864L78.7306 44.6813L76.3026 42.3622C75.343 41.4463 74.805 40.4648 74.6378 39.3235L74.1652 36.1394C73.8308 33.8785 71.8463 31.9593 69.1419 31.2905L65.3399 30.3527C63.9732 30.0183 62.8754 29.4149 61.9231 28.4917L59.2842 25.9182C57.4086 24.0935 54.5662 23.3738 52.0872 24.1008L48.5978 25.1258C47.3474 25.4966 46.1043 25.5039 44.7376 25.1549L40.9428 24.1808C38.2458 23.4829 35.6214 24.2389 34.3202 26.0927L32.4809 28.6952C31.8194 29.6258 30.9106 30.2437 29.653 30.5999L26.149 31.5886C25.7129 31.7121 25.313 31.8721 24.9422 32.0684L23.3357 31.116L22.7395 34.6491C22.5723 35.2234 22.5288 35.8413 22.6305 36.4883L23.1467 39.6725C23.3284 40.8138 23.0958 41.8025 22.427 42.7257L21.4237 44.0997L20.4787 43.5254L19.8244 47.0003C19.5627 48.4615 20.137 50.039 21.4891 51.3258L24.1572 53.8701C25.1168 54.7861 25.6546 55.7675 25.8218 56.9089L26.2944 60.093C26.6288 62.3539 28.6134 64.273 31.3178 64.9419L35.1198 65.8797C36.4865 66.2141 37.5843 66.8174 38.5366 67.7407L41.1755 70.3142C43.0511 72.1389 45.8934 72.8585 48.3724 72.1316L51.8619 71.1065C53.1123 70.7358 54.3553 70.7285 55.722 71.0775L59.5169 72.0516C62.2139 72.7495 64.8383 71.9934 66.1396 70.1397L67.9788 67.5371C68.6404 66.6066 69.549 65.9887 70.8066 65.6325L74.3107 64.6438C76.4043 64.055 77.7129 62.5937 77.8656 60.8054L78.389 57.6867L77.4511 57.4323L77.3131 56.5599C77.1313 55.4186 77.3639 54.4299 78.0327 53.5067L79.9083 50.9259C80.2936 50.3952 80.5335 49.8137 80.6425 49.2103C80.6498 49.2175 80.6571 49.2175 80.6571 49.2175L81.3041 45.3864Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.6189 20.3569L45.4137 21.3311C46.7731 21.68 48.0234 21.6727 49.2738 21.302L52.7633 20.277C55.2423 19.55 58.092 20.2624 59.9603 22.0944L62.5992 24.6679C63.5443 25.5911 64.6492 26.1945 66.0159 26.5289L69.8179 27.4667C72.5223 28.1355 74.5142 30.0547 74.8413 32.3156L75.3139 35.4997C75.4811 36.641 76.019 37.6224 76.9786 38.5384L79.6466 41.0828C81.5439 42.893 81.9147 45.2774 80.5771 47.1094L78.7016 49.6901C78.0255 50.6134 77.7928 51.602 77.9818 52.7434L78.498 55.9275C78.8615 58.1884 77.473 60.1294 74.9795 60.8345L71.4754 61.8232C70.2178 62.1794 69.3018 62.7901 68.6475 63.7279L66.8083 66.3304C65.4997 68.1769 62.8827 68.9329 60.1856 68.2423L56.3909 67.2682C55.0314 66.9193 53.7811 66.9265 52.5307 67.2973L49.0412 68.3223C46.5623 69.0493 43.7125 68.3368 41.8442 66.5049L39.2053 63.9314C38.2603 63.0082 37.1553 62.4048 35.7886 62.0704L31.9866 61.1326C29.2823 60.4638 27.2903 58.5446 26.9632 56.2837L26.4907 53.0996C26.3235 51.9583 25.7855 50.9768 24.8259 50.0608L22.158 47.5165C20.2606 45.7063 19.8898 43.3219 21.2274 41.4899L23.103 38.9092C23.779 37.9859 24.0117 36.9972 23.8227 35.8559L23.3065 32.6718C22.943 30.4109 24.3315 28.4699 26.825 27.7647L30.3291 26.7761C31.5867 26.4198 32.5027 25.8092 33.157 24.8714L34.9962 22.2689C36.3048 20.4151 38.9219 19.6591 41.6189 20.3569Z" fill="#FFF2D2" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M60.9712 32.461C60.9712 32.461 55.9115 33.4933 55.4099 34.5111L48.8599 47.9382L39.94 45.5537L39.4166 48.2653C39.373 48.4688 39.3439 48.6142 39.3294 48.7015C39.3003 48.9268 39.293 49.1158 39.293 49.2758C39.3002 50.1045 39.94 50.9405 40.9578 51.3476L48.5836 54.3791C49.2161 54.6335 49.9285 54.6771 50.5464 54.5172C51.1571 54.35 51.6442 53.9793 51.8841 53.4995L60.2079 36.4302C60.3314 36.1685 60.4332 35.9504 60.4841 35.6233L60.9712 32.461Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M56.0207 31.1379L48.5911 46.3679L43.3715 44.2888C42.0484 43.7581 40.5727 44.1579 40.0711 45.1757C39.5768 46.1934 40.2528 47.4438 41.5759 47.9672L49.2018 50.9987C49.8342 51.2531 50.5467 51.2968 51.1646 51.1368C51.7753 50.9696 52.2623 50.5989 52.5022 50.1191L60.826 33.0499C61.3203 32.0321 60.6443 30.7817 59.3212 30.2583C57.9908 29.7276 56.5151 30.1274 56.0207 31.1379Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
