import React from 'react';
import PropTypes from 'prop-types';

export default function FormAlert({
  description,
  title,
}) {
  return (
    <div className="form-alert">
      <div className="t-sage-heading-6 form-alert__title">{title}</div>
      <div className="t-sage-body-small form-alert__description">
        {description}
      </div>
    </div>
  );
}

FormAlert.defaultProps = {
  description: null,
};

FormAlert.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};
