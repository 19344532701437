import React from 'react';

export default function CTA() {
  return (
    <section className="section cta">
      <div className="container container--large">
        <p className="t-sage-heading-2 cta__title">Interested in joining our network of Kajabi Experts?</p>
        <p className="cta__text">Fill out the form below to apply to be part of our marketplace.</p>
      </div>
    </section>
  );
}
