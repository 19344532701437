export default function dateToText({
  rawDateObject,
  onlyDate,
  onlyTime,
  prettyDate,
  prettyDateFull,
  prettyDateNoDay,
}) {
  const timestampObj = Date.parse(rawDateObject);
  const dateObject = new Date(timestampObj);
  const dateString = dateObject.toDateString();
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const hoursStr = hours > 12 ? hours - 12 : hours;
  const minutes = dateObject.getMinutes();
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const suffix = hours >= 12 ? 'pm' : 'am';

  const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  let returnValue = `${dateString} ${hoursStr}:${minutesStr}${suffix}`;
  if (onlyDate) returnValue = `${dateString}`;
  if (onlyTime) returnValue = `${hoursStr}:${minutesStr}${suffix}`;
  if (prettyDate) returnValue = `${monthsShort[month]} ${day}, ${year}`;
  if (prettyDateFull) returnValue = `${monthsLong[month]} ${day}, ${year}`;
  if (prettyDateNoDay) returnValue = `${monthsLong[month]}, ${year}`;

  return returnValue;
}
