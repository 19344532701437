/* eslint-disable no-console */
import React, {
  useContext,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {
  Icon,
  Modal,
  Button,
} from '@kajabi/sage-react';

import TestimonialsContext from './TestimonialsContext';
import {
  testimonialDelete,
} from '../../scripts/testimonial';
import UIIconStar from '../../../shared/UIIconStar';

const STATUS_PARAMS = {
  approved: {
    icon: 'check',
    text: 'Approved',
  },
  pending: {
    icon: 'clock',
    text: 'Waiting for approval',
  },
  rejected: {
    icon: 'remove',
    text: 'Rejected',
  },
};

function TestimonialStatus({ status }) {
  const state = STATUS_PARAMS[status];

  if (!state) return null;

  return (
    <div className={`testimonial__approval-status--${status}`}>
      <Icon
        adjacentType="body-sm"
        icon={state.icon}
      />
      &nbsp;
      {state.text}
    </div>
  );
}

export default function Testimonial({
  item,
}) {
  const {
    fetchTestimonials,
    setCurrentTestimonial,
    setTestimonialModalData,
  } = useContext(TestimonialsContext);

  const {
    id,
    title,
    content,
    rating,
    reviewer_email: email,
    reviewer_given_name: firstName,
    reviewer_family_name: lastName,
  } = item;
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <div
      className="testimonial"
    >
      <p className="t-sage-heading-6">{title}</p>
      <div className="testimonial__approval-status">
        <TestimonialStatus status={item.status} />
      </div>
      <p className="t-sage-body testimonial__content">{content}</p>
      <div className="rating">
        <UIIconStar />
        <p className="t-sage-body-small rating-text">
          <span className="rating__total-text">
            <span>{rating}</span>
          </span>
        </p>
      </div>
      <div className="testimonial__reviewer">
        <span className="rating__total-text">
          By
          <span className="rating__total-text-count">{`${firstName} ${lastName} <${email}>`}</span>
        </span>
      </div>
      <div className="testimonial__actions">
        <div
          className="testimonial__action testimonial__action--edit"
          onClick={() => {
            setCurrentTestimonial(item);
            setTestimonialModalData({
              active: true,
              type: 'edit',
            });
          }}
          role="presentation"
        >
          <Icon
            adjacentType="body-sm"
            icon="pen"
          />
          Edit
        </div>
        <div
          className="testimonial__action testimonial__action--delete"
          onClick={() => setConfirmDelete(true)}
          role="presentation"
        >
          <Icon
            adjacentType="body-sm"
            icon="trash"
          />
          Delete
        </div>
      </div>

      <ConfirmDeleteModal
        active={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => testimonialDelete(id, () => fetchTestimonials())}
      />
    </div>
  );
}

function ConfirmDeleteModal({
  active,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      active={active}
      className="confirm-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Delete testimonial"
      />
      <Modal.Body>
        <div className="confirm-modal__content">
          <p className="confirm-modal__message">Are you sure you want to delete this testimonial?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button icon="trash" onClick={onConfirm}>
            Delete
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

Testimonial.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    rating: PropTypes.number,
    reviewer_given_name: PropTypes.string,
    reviewer_family_name: PropTypes.string,
    reviewer_email: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

TestimonialStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

ConfirmDeleteModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
