import React from 'react';
import PortfolioItems from './PortfolioItems';

export default function Work() {
  return (
    <section className="work">
      <p className="t-sage-heading-3 work__title">Featured work</p>
      <PortfolioItems />
    </section>
  );
}
