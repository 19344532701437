import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import Linkifier from './Linkifier';
import dateToText from '../../scripts/dateToText';
import numberDelimiter from '../../scripts/numberDelimiter';
import ConversationsShowContext from '../conversations/show/ConversationsShowContext';
import CurrentProjectContext from '../../contexts/CurrentProjectContext';

function QuoteDetail({
  title,
  value,
}) {
  return (
    <div className="quote-details__item">
      <span className="quote-details__title">{title}</span>
      <span className="quote-details__value">{value}</span>
    </div>
  );
}

QuoteDetail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function QuoteDetails({
  quote,
  showTitle,
}) {
  let activeProject;
  const currentProjectContext = useContext(CurrentProjectContext);
  if (currentProjectContext) activeProject = currentProjectContext;
  const conversationsShowContext = useContext(ConversationsShowContext);
  if (conversationsShowContext) {
    const {
      project,
    } = conversationsShowContext;
    activeProject = project;
  }
  const subcategoryName = activeProject.project_data.subcategoryTitle;

  const [useEllipses, setUseEllipses] = useState(true);

  const isProposal = quote.quote_type === 'proposal';

  const textTitle = isProposal ? 'Proposal details' : 'Invoice details';
  const textAmount = isProposal ? 'Estimated total price' : 'Amount';
  const textDue = isProposal ? 'Estimated completion date' : 'Payment due';

  return (
    <div className="quote-details">
      {showTitle && <p className="quote-details__small-heading">{textTitle}</p>}
      <QuoteDetail
        title="Created"
        value={dateToText({
          rawDateObject: quote.created_at,
        })}
      />
      <QuoteDetail
        title="Service"
        value={subcategoryName}
      />
      <QuoteDetail
        title={textAmount}
        value={`$${numberDelimiter(quote.price)}`}
      />
      <QuoteDetail
        title={textDue}
        value={quote.estimated_delivery}
      />
      <hr className="quote__divider" />
      <p className="quote-details__small-heading">Description</p>
      <div className="t-sage-body-small quote-details__description">
        <Linkifier>
          {useEllipses
            ? (
              <div
                onClick={() => setUseEllipses(false)}
                role="presentation"
              >
                <LinesEllipsis
                  text={quote.description}
                  maxLine="2"
                  ellipsis={<p className="quote-details__ellipses">Show more</p>}
                  trimRight
                  basedOn="words"
                />
              </div>
            )
            : quote.description}
        </Linkifier>
      </div>
    </div>
  );
}

QuoteDetails.defaultProps = {
  showTitle: true,
};

QuoteDetails.propTypes = {
  quote: PropTypes.shape({
    created_at: PropTypes.string,
    description: PropTypes.string,
    estimated_delivery: PropTypes.string,
    price: PropTypes.number,
    quote_type: PropTypes.string,
  }).isRequired,
  showTitle: PropTypes.bool,
};
