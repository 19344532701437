import React from 'react';
import DetailsActions from './DetailsActions';
import DetailsBadge from './DetailsBadge';
import DetailsTitle from './DetailsTitle';

export default function DetailsHeader() {
  return (
    <div className="details__header">
      <div className="details__title-and-status">
        <DetailsTitle />
        <DetailsBadge />
      </div>
      <DetailsActions />
    </div>
  );
}
