import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import { packageAccept } from '../../../scripts/package';
import AppContext from '../../../../../AppContext';
import Avatar from '../../../../shared/Avatar';
import UsersShowContext from '../../../../../contexts/UsersShowContext';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';

const flashError = (message) => {
  sessionStorage.setItem(
    'toastData',
    JSON.stringify({
      type: 'danger',
      icon: 'lock',
      title: message,
    }),
  );
};

const flashSuccess = (message) => {
  sessionStorage.setItem(
    'toastData',
    JSON.stringify({
      icon: 'check-circle-filled',
      title: message,
    }),
  );
};

export default function PackageModal({
  active,
  onClose,
  packages,
}) {
  const navigate = useNavigate();
  const [useEllipses, setUseEllipses] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const { loginRef } = useContext(AppContext);
  const setSearchParams = useSearchParams()[1];

  const { isVendor, isLoggedIn } = useContext(CurrentUserContext);
  const {
    currentPackageIndex,
    userData,
  } = useContext(UsersShowContext);

  const data = packages[currentPackageIndex];

  const selectPackage = () => {
    if (isLoggedIn) {
      setIsAccepting(true);
      packageAccept(data.id, (response) => {
        if (response.errors) {
          flashError('There was an error accepting this package.');
          navigate(0); // reload
        } else {
          const projectId = response.id;
          // we need to reload the page to get config from the server, so can't use navigate here
          flashSuccess('The expert has received a project for the given package!');
          window.location.href = `/projects/${projectId}`;
        }
      });
    } else {
      // remember the url to redirect back after login
      setSearchParams({ openModal: 'select-package', packageId: data.id });
      loginRef.current.click();
    }
  };

  return (
    <Modal
      active={active}
      allowScroll
      className="package-modal"
      onExit={onClose}
      size="lg"
    >
      <Modal.Header
        aside={(
          <Button
            className="btn-kajabi-blue"
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title={data.title}
      >
        <div className="package-modal__subheader">
          <div className="package-modal__bio">
            <Avatar
              size="24"
              user={userData}
            />
            <p className="t-sage-body-small package-modal__byline">
              <span>by&nbsp;</span>
              <span className="package-modal__name">{userData.name}</span>
            </p>
          </div>
          <div className="package-modal__price">
            <p className="t-sage-heading-6 package-modal__price-text">
              $
              {data.price}
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="package-modal__body">
          {useEllipses ? (
            <div
              onClick={() => setUseEllipses(false)}
              role="presentation"
            >
              <LinesEllipsis
                text={data.description}
                maxLine="2"
                ellipsis={<p className="t-sage-heading-6 package-modal__ellipses">Read more</p>}
                trimRight
                basedOn="words"
              />
            </div>
          ) : (data.description)}
        </div>
        <div className="package-modal__images">
          {data.image
            ? <img alt={data.title} src={data.image} />
            : <p className="package-modal__no-images">No images associated with this package</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group
          align="space-between"
          className="package-modal__footer-actions"
          gap="sm"
        >
          <div className="package-modal__select-instructions">
            By selecting this package, we will automatically issue an invoice for the amount of $
            {data.price}
          </div>
          <Button
            color="primary"
            onClick={selectPackage}
            disabled={isVendor || isAccepting}
          >
            Select
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

PackageModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.number,
    title: PropTypes.string,
  })).isRequired,
};
