export default function projectPost(payload, callback) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/projects', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    callback(res);
    res.text();
  });
}
