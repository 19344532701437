import React, {
  useContext,
} from 'react';
import Testimonial from './Testimonial';
import TestimonialsContext from './TestimonialsContext';

export default function List() {
  const {
    testimonials,
  } = useContext(TestimonialsContext);

  return (
    <section className="testimonials">
      {testimonials && testimonials.length > 0 ? (
        <div className="testimonials__grid">
          {testimonials.map((item) => (
            <Testimonial key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <p className="testimonials__null-message">You haven't added any testimonials yet.</p>
      )}
    </section>
  );
}
