import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';

export default function Linkifier({
  children,
  tagName,
}) {
  const linkifyOptions = {
    rel: 'noreferrer',
    target: '_blank',
  };

  return (
    <Linkify
      options={linkifyOptions}
      tagName={tagName}
    >
      {children}
    </Linkify>
  );
}

Linkifier.defaultProps = {
  children: null,
  tagName: null,
};

Linkifier.propTypes = {
  children: PropTypes.node,
  tagName: PropTypes.string,
};
