import React from 'react';
import PropTypes from 'prop-types';

export default function HTTPStatus({
  description,
  title,
}) {
  return (
    <section className="status">
      <div className="status__content">
        <div className="status__text">
          <h1 className="status__title">{title}</h1>
          <p className="t-sage-heading-4">{description}</p>
        </div>
      </div>
    </section>
  );
}

HTTPStatus.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
