/* eslint-disable no-console */
import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';
import WorkContext from './WorkContext';
import {
  portfolioItemDelete,
} from '../../scripts/portfolioItem';

export default function Item({
  item,
}) {
  const {
    fetchUserData,
    setCurrentPortfolioItem,
    setPortfolioItemModalData,
  } = useContext(WorkContext);

  const {
    id,
    image,
    title,
  } = item;

  return (
    <div
      className="item"
    >
      {image && (
        <div className="item__image-frame">
          <img
            alt={title}
            className="item__image"
            src={image}
          />
        </div>
      )}
      <div className="item__content">
        <div className="item__text">
          <p className="t-sage-heading-6 item__title">{title}</p>
        </div>
        <div className="item__actions">
          <div
            className="item__action item__action--edit"
            onClick={() => {
              setCurrentPortfolioItem(item);
              setPortfolioItemModalData({
                active: true,
                type: 'edit',
              });
            }}
            role="presentation"
          >
            <Icon
              adjacentType="body-sm"
              icon="pen"
            />
            Edit
          </div>
          <div
            className="item__action item__action--delete"
            onClick={() => {
              console.log('boom');
              portfolioItemDelete(
                id,
                () => fetchUserData(),
              );
            }}
            role="presentation"
          >
            <Icon
              adjacentType="body-sm"
              icon="trash"
            />
            Delete
          </div>
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};
