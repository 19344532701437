import React, {
  useContext,
} from 'react';
import {
  useCookies,
} from 'react-cookie';
import {
  Alert,
} from '@kajabi/sage-react';
import ProjectsShowContext from '../../ProjectsShowContext';

export default function DetailsAlert() {
  const {
    brief,
    isAiBrief,
    project,
    user,
  } = useContext(ProjectsShowContext);

  const {
    type: userType,
  } = user;

  const [cookies, setCookies] = useCookies(['dismissedAlerts']);
  const dismissedAlertKey = `DismissedAlert-ProjectBrief-${project.id}`;
  const isVendor = userType === 'vendor';

  const ALERT_OPTIONS = {
    ai: {
      color: 'success',
      description: 'Your project brief has been optimized with some AI magic, and is now available for Experts to pitch. Feel free to edit.',
    },
    fallback: {
      color: 'warning',
      description: 'Your project brief was not generated with AI; showing your original description instead. This project is now available for Experts to pitch. Feel free to edit.',
    },
  };

  const resolvedAlertData = isAiBrief ? ALERT_OPTIONS.ai : ALERT_OPTIONS.fallback;

  if (cookies[dismissedAlertKey]) return false;

  if (brief && !isVendor) {
    return (
      <Alert
        className="details-alert"
        color={resolvedAlertData.color}
        description={resolvedAlertData.description}
        dismissable
        onDismiss={() => setCookies(dismissedAlertKey, true)}
        title="Project posted!"
      />
    );
  }
}
