import React from 'react';
import PropTypes from 'prop-types';
import GraphicProjectStatusActive from './GraphicProjectStatusActive';
import GraphicProjectStatusComplete from './GraphicProjectStatusComplete';

export default function NullState({
  filter,
}) {
  let title = 'You don’t have any projects yet';
  if (filter === 'closed') title = 'You haven’t closed any projects';
  if (filter === 'archived') title = 'You haven’t archived any projects';
  if (filter === 'declined') title = 'You haven’t declined any projects';
  if (filter === 'completed') title = 'You haven’t completed any projects';
  if (filter === 'prospective') title = 'You don’t have any prospective projects';

  return (
    <div className="null-state">
      {(filter === 'active' || filter === 'closed') && <GraphicProjectStatusActive />}
      {filter === 'completed' && <GraphicProjectStatusComplete />}
      <div className="null-state__text">
        <p className="t-sage-heading-4">{title}</p>
        <p>
          <span className="null-state__status">{`${filter} `}</span>
          <span>projects will display here.</span>
        </p>
      </div>
    </div>
  );
}

NullState.propTypes = {
  filter: PropTypes.string.isRequired,
};
