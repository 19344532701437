export default function quoteCancel(id) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(`/quotes/${id}/cancel`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
  })
    .then((res) => res.text())
    .then(() => window.location.replace(window.location.href));
}
