import React, {
  useContext,
} from 'react';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';
import TimelineCard from './TimelineCard';

export default function TimelineComplete() {
  const project = useContext(CurrentProjectContext);

  return (
    <TimelineCard
      classNames="complete"
      icon="check"
      message="marked the project as complete"
      timestamp={project.updated_at}
      userName={project.user.name}
    />
  );
}
