import React, {
  useContext,
} from 'react';
import Plan from './Plan';
import plansData from '../../../../data/shared/plansData';
import PlansContext from './PlansContext';

export default function PlansList() {
  const {
    currentPlan,
  } = useContext(PlansContext);

  return (
    <section className="plans-list">
      <div className="sage-row">
        {!plansData[0].hidden && (
          <div className="sage-col-5">
            <Plan
              data={plansData[0]}
              isCurrentPlan={currentPlan === plansData[0].name.toLowerCase()}
            />
          </div>
        )}
        {!plansData[1].hidden && (
          <div className="sage-col-7">
            <Plan
              data={plansData[1]}
              isCurrentPlan={currentPlan === plansData[1].name.toLowerCase()}
            />
          </div>
        )}
        {!plansData[2].hidden && (
          <div className="sage-col">
            <Plan
              data={plansData[2]}
              isCurrentPlan={currentPlan === plansData[2].name.toLowerCase()}
            />
          </div>
        )}
      </div>
    </section>
  );
}
