import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import uuid from 'react-uuid';
import messageMarkAsRead from '../../../../scripts/messageMarkAsRead';
import useApiFetch from '../../../../scripts/useApiFetch.tsx';
import Brief from './Brief';
import Message from './Message';
import AppContext from '../../../../AppContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';

export default function ChatPaneContent() {
  const { globalState } = useContext(AppContext);

  const {
    chatData,
    currentConversation,
    currentMessageQuoteId,
    messagesData,
    setChatData,
    setCurrentMessage,
    setCurrentMessageQuoteId,
  } = useContext(MessagesIndexContext);

  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const {
    apiFetch,
  } = useApiFetch();

  const fetchChatData = () => {
    apiFetch(`${window.location.origin}/api/v1/projects/${currentConversation.projectId}/conversations/${currentConversation.vendorId}.json`)
      .then((data) => setChatData(data))
      .catch(() => { });
  };

  useEffect(() => {
    if (currentConversation) {
      fetchChatData();
    }
  }, [currentConversation]);

  useEffect(() => {
    if (messagesData) {
      for (let a = 0; a < messagesData.length; a += 1) {
        const thisMessage = messagesData[a].latest_message;
        if (thisMessage.quote && thisMessage.quote.id === currentMessageQuoteId) {
          setCurrentMessage(thisMessage);
          break;
        }
      }
      setCurrentMessageQuoteId(null);
      fetchChatData();
    }
  }, [messagesData]);

  const slider = useRef(null);

  useEffect(() => {
    const el = slider.current;
    el.scrollTop = el.scrollHeight - el.offsetHeight;
  });

  return (
    <section className="chat-pane-content" ref={slider}>
      <Brief project={chatData && chatData.project} />
      <section className="messages">
        {chatData && chatData.messages.map((message) => {
          if (!message.read && message.sender.id !== userId) {
            messageMarkAsRead(
              message.id,
              () => { globalState.checkForUnreadMessages = true; },
            );
          }
          return (
            <Message
              message={message}
              key={uuid()}
              project={chatData.project}
              vendor={chatData && chatData.vendor}
            />
          );
        })}
      </section>
    </section>
  );
}
