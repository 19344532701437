import React from 'react';

export default function GraphicCallToAction() {
  return (
    <svg viewBox="0 0 66 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.0388 67.2803C63.0388 75.7312 44.1062 78.024 28.2526 78.024C12.3991 78.024 5.58594 73.8314 5.58594 67.2803C5.58594 60.7292 18.426 55.4229 34.3451 55.4229C50.1987 55.4884 63.0388 60.7947 63.0388 67.2803Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51.2482 57.3232C53.0825 55.6855 56.9476 51.8203 60.4852 45.5313C64.1538 38.9147 67.1018 26.0091 63.0401 18.934C61.5334 16.3136 59.437 14.6103 57.0131 14.0862C56.4891 13.9552 55.8995 13.8896 55.3099 13.8896C52.4929 13.8896 50.0035 15.2654 48.6933 16.117L52.2309 22.013C53.0825 21.4889 54.2617 21.0303 55.3099 21.0303C55.5719 21.0303 55.7684 21.0303 55.965 21.0958C56.8166 21.2924 57.4717 21.8164 58.0613 22.8646C59.044 24.5679 59.306 27.7124 58.7164 31.4465C58.1923 35.0496 56.9476 38.8492 55.4409 41.4696C52.6239 46.514 49.5449 49.724 47.9727 51.1652L51.2482 57.3232Z" fill="#EFEFED" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 66.8878C1 69.8358 3.48941 72.8493 8.53373 75.1422C18.5569 79.6624 34.869 79.6624 44.9577 75.1422C50.002 72.8493 52.4914 69.9013 52.4914 66.8878V12.645H1V66.8878Z" fill="#EFEFED" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M44.4973 11.3343C39.7805 9.23792 33.4915 8.05873 26.8094 8.05873C20.0618 8.05873 13.7728 9.23792 9.12155 11.3343C6.43561 12.579 4.47028 13.9547 3.42211 15.5269C2.76701 14.6098 2.43945 13.6927 2.43945 12.71C2.43945 10.1551 4.79784 7.66566 9.12155 5.70034C13.8383 3.604 20.1273 2.4248 26.8094 2.4248C33.557 2.4248 39.846 3.604 44.4973 5.70034C48.821 7.66566 51.1794 10.1551 51.1794 12.71C51.1794 13.6927 50.8518 14.6098 50.1967 15.5269C49.083 13.9547 47.1832 12.5135 44.4973 11.3343Z" fill="#C8BFAD" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.05488 11.3341C13.7716 9.23779 20.0607 8.05859 26.7428 8.05859C33.4903 8.05859 39.7794 9.23779 44.4306 11.3341C47.1166 12.5788 49.0819 13.9545 50.1301 15.5268C49.0819 17.0335 47.1166 18.4748 44.4306 19.7195C42.6618 20.5056 40.6965 21.1607 38.6002 21.6848C34.9971 22.5364 31.0009 22.995 26.7428 22.995C22.5501 22.995 18.4884 22.5364 14.8853 21.6848C12.7235 21.1607 10.7582 20.5056 9.05488 19.7195C6.36895 18.4748 4.40364 17.0991 3.35547 15.5268C4.40364 13.9545 6.36895 12.5133 9.05488 11.3341Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M44.9579 4.39018C34.9348 -0.130059 18.6226 -0.130059 8.53395 4.39018C-1.48918 8.91042 -1.48918 16.3131 8.53395 20.8334C18.5571 25.3536 34.8693 25.3536 44.9579 20.8334C55.0465 16.3131 55.0465 8.97593 44.9579 4.39018ZM49.0851 15.3305C48.0369 16.7717 46.2026 18.1474 43.6477 19.3266C42.0099 20.1128 40.1101 20.7023 38.0793 21.2264C34.6727 22.0781 30.8076 22.4711 26.7459 22.4711C22.7498 22.4711 18.8846 22.0126 15.4126 21.2264C13.3817 20.7023 11.4819 20.1128 9.84417 19.3266C7.28925 18.1474 5.45494 16.7717 4.40677 15.3305C3.81717 14.4788 3.48962 13.5617 3.48962 12.6445C3.48962 10.2206 5.7825 7.79673 9.84417 5.96243C14.2989 3.9316 20.3259 2.81793 26.7459 2.81793C33.166 2.81793 39.1274 3.9316 43.6477 5.96243C47.7749 7.79673 50.0022 10.2206 50.0022 12.6445C50.0022 13.5617 49.7402 14.4788 49.0851 15.3305Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.1774 35.6392C31.3989 44.4176 22.4895 53.5236 13.711 62.2365C13.6455 62.1055 13.6455 61.9745 13.6455 61.8434C13.6455 55.6854 13.6455 38.4561 13.6455 36.2288C13.6455 36.1632 13.6455 36.0977 13.6455 36.0322C13.6455 35.7702 13.6455 35.7702 13.9731 35.7702C14.0386 35.7702 14.1041 35.7702 14.1696 35.7702C15.4798 36.2287 21.3758 37.8665 26.8132 37.801C31.0714 37.7355 38.0155 36.6873 39.7843 35.7047C39.9153 35.6392 40.0463 35.6392 40.1774 35.6392Z" fill="#2E91FB" />
      <path d="M40.1111 63.8085C40.1111 63.8085 33.6255 65.8393 29.4984 66.0358C26.0263 66.2324 20.7199 65.7083 18.4271 64.9877C18.3615 64.9222 29.3018 53.9819 29.4984 54.1784C32.9049 57.5194 40.0456 63.6775 40.1111 63.8085Z" fill="#2E91FB" />
      <path d="M1.65527 22.6475V65.95C1.65527 69.466 5.57146 73.2871 11.2856 75.0013C11.5476 70.9396 9.72055 60.1408 8.99993 56.1447C8.34482 52.6071 4.01366 28.3469 1.65527 22.6475Z" fill="white" />
    </svg>
  );
}
