import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import Input from '../../../shared/formik/Input';
import Textarea from '../../../shared/formik/Textarea';
import validations from '../../../../data/shared/validations';
import {
  testimonialPost,
  testimonialUpdate,
} from '../../scripts/testimonial';
import TestimonialsContext from './TestimonialsContext';
import RatingScaleWithDefault from '../../../shared/RatingScaleWithDefault';

export default function TestimonialsModal({
  active,
  onClose,
}) {
  const {
    currentTestimonial,
    fetchTestimonials,
    testimonialModalData,
  } = useContext(TestimonialsContext);

  const {
    type,
  } = testimonialModalData;

  const blankInitialValues = {
    title: '',
    content: '',
    rating: null,
    reviewer_email: '',
    reviewer_given_name: '',
    reviewer_family_name: '',
  };

  const [initialValues, setInitialValues] = useState(blankInitialValues);
  const modalTitleString = `${type === 'edit' ? 'Edit' : 'Add'} testimonial`;

  useEffect(() => {
    if (type === 'add') {
      setInitialValues(blankInitialValues);
    } else if (type === 'edit') {
      setInitialValues({
        title: currentTestimonial.title,
        content: currentTestimonial.content,
        reviewer_given_name: currentTestimonial.reviewer_given_name,
        reviewer_family_name: currentTestimonial.reviewer_family_name,
        reviewer_email: currentTestimonial.reviewer_email,
        rating: currentTestimonial.rating,
      });
    }
  }, [testimonialModalData]);

  return (
    <Modal
      active={active}
      className="testimonial-modal"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, {
          resetForm,
          setSubmitting,
        }) => {
          setSubmitting(false);
          onClose();
          if (type === 'add') {
            testimonialPost(
              { ...values },
              () => fetchTestimonials(),
            );
          } else if (type === 'edit') {
            testimonialUpdate(
              {
                ...values,
                id: currentTestimonial.id,
              },
              () => fetchTestimonials(),
            );
          }
          resetForm();
        }}
        validationSchema={
          Yup.object({
            title: Yup.string()
              .max(100, validations.sizeStringMaximum(100))
              .required(validations.stringRequired),
            content: Yup.string()
              .required(validations.stringRequired),
            reviewer_given_name: Yup.string()
              .required(validations.stringRequired),
            reviewer_family_name: Yup.string()
              .required(validations.stringRequired),
            reviewer_email: Yup.string().email(validations.matchingInvalidEmail)
              .required(validations.stringRequired),
            rating: Yup.number().nullable(false).typeError(validations.stringRequired),
          })
        }
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          touched,
          values,
        }) => (
          <>
            <Modal.Header
              aside={(
                <Button
                  color="secondary"
                  icon="remove"
                  iconOnly
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                  subtle
                />
              )}
              title={modalTitleString}
            />
            <Modal.Body>
              <Input
                errors={errors.title}
                handleChange={handleChange}
                idName="title"
                label="Title"
                placeholder="Enter the testimonial title"
                touched={touched.title}
                value={values.title}
              />
              <Textarea
                errors={errors.content}
                handleChange={handleChange}
                idName="content"
                label="Testimonial"
                placeholder="Enter the testimonial you received from the client"
                touched={touched.content}
                value={values.content}
              />
              <div className="rating-modal__item">
                <p className="t-sage-heading-6 rating-modal__heading">
                  How satisfied was the customer with the results of your work?
                </p>
                <RatingScaleWithDefault
                  rating={values.rating}
                  onSetRating={
                    (rating) => {
                      setFieldValue('rating', rating);
                    }
                  }
                />
                {errors.rating && touched.rating ? (
                  <div className="sage-form-field--error">
                    <div className="sage-input__message">
                      {errors.rating}
                    </div>
                  </div>
                ) : null}
              </div>
              <Input
                errors={errors.reviewer_given_name}
                handleChange={handleChange}
                idName="reviewer_given_name"
                label="Reviewer first name"
                placeholder="Enter the reviewer's first name"
                touched={touched.reviewer_given_name}
                value={values.reviewer_given_name}
              />
              <Input
                errors={errors.reviewer_family_name}
                handleChange={handleChange}
                idName="reviewer_family_name"
                label="Reviewer last name"
                placeholder="Enter the reviewer's last name"
                touched={touched.reviewer_family_name}
                value={values.reviewer_family_name}
              />
              <Input
                errors={errors.reviewer_email}
                handleChange={handleChange}
                idName="reviewer_email"
                label="Reviewer email"
                inputType="email"
                placeholder="An address we can email to verify this testimonial"
                touched={touched.reviewer_email}
                value={values.reviewer_email}
              />
              <Button.Group
                align="end"
                gap="sm"
              >
                <Button
                  color="secondary"
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Button.Group>
            </Modal.Body>
          </>
        )}
      </Formik>
    </Modal>
  );
}

TestimonialsModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
