export default function messagePost(
  payload,
  callback = () => {},
) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/messages', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      message: payload,
    }),
  })
    .then((res) => res.text())
    .then(() => callback());
}
