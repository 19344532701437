import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import UIIconStar from '../../../../shared/UIIconStar';
import TimelineCard from './TimelineCard';

export default function TimelineRating({
  rating,
}) {
  const [revieweeData, setRevieweeData] = useState(null);
  const [reviewerData, setReviewerData] = useState(null);

  useEffect(() => {
    fetch(`${window.location.origin}/users/${rating.reviewee_id}.json`)
      .then((response) => response.json())
      .then((data) => setRevieweeData(data));

    fetch(`${window.location.origin}/users/${rating.reviewer_id}.json`)
      .then((response) => response.json())
      .then((data) => setReviewerData(data));
  }, []);

  const stars = [];
  for (let i = 0; i < rating.rating; i += 1) {
    stars.push(<UIIconStar key={uuid()} size="20px" />);
  }
  for (let i = 0; i < 5 - rating.rating; i += 1) {
    stars.push(<UIIconStar color="#D3D5D9" key={uuid()} size="20px" />);
  }

  if (revieweeData && reviewerData) {
    return (
      <TimelineCard
        classNames="rating"
        icon="star"
        message="left a review for"
        recipientName={revieweeData.name}
        timestamp={rating.updated_at}
        userName={reviewerData.name}
      >
        <div className="rating__item">
          <p className="t-sage-heading-6">How satisfied are you with the results of this project?</p>
          <div className="rating__stars">
            {stars.map((star) => (
              star
            ))}
          </div>
        </div>
        <div className="rating__item">
          <p className="t-sage-heading-6">Anything else you’d like to share?</p>
          <div className="rating__review t-sage-body-small">
            {rating.review}
          </div>
        </div>
      </TimelineCard>
    );
  }
}

TimelineRating.propTypes = {
  rating: PropTypes.shape({
    rating: PropTypes.number,
    review: PropTypes.string,
    reviewee_id: PropTypes.number,
    reviewer_id: PropTypes.number,
    updated_at: PropTypes.string,
  }).isRequired,
};
