import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

export default function SkeletonLoader({ lines }) {
  return (
    <div className="skeleton-loader">
      {Array.from(Array(lines)).map(() => (
        <div className="skeleton-loader__line" key={uuid()} />
      ))}
    </div>
  );
}

SkeletonLoader.defaultProps = {
  lines: 4,
};

SkeletonLoader.propTypes = {
  lines: PropTypes.number,
};
