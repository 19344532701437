import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';
import QuoteDetails from '../shared/QuoteDetails';
import QuoteStatus from '../../../shared/QuoteStatus';
import dateToText from '../../../../scripts/dateToText';

export default function DocumentHistoryChoice({
  quote,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className="document-history-choice"
    >
      <div
        className="document-history-choice__header"
        onClick={() => setIsExpanded(!isExpanded)}
        role="presentation"
      >
        <Icon icon={isExpanded ? 'caret-down' : 'caret-right'} />
        <p className="t-sage-heading-5 document-history-choice__date">
          {dateToText({
            rawDateObject: quote.created_at,
            prettyDate: true,
          })}
        </p>
        <QuoteStatus quote={quote} />
      </div>
      {isExpanded && (
        <div className="document-history-choice__content">
          <QuoteDetails quote={quote} />
        </div>
      )}
    </div>
  );
}

DocumentHistoryChoice.propTypes = {
  quote: PropTypes.shape({
    created_at: PropTypes.string,
  }).isRequired,
};
