import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import uuid from 'react-uuid';
import Pagination from '../../shared/Pagination';
import AvailableProject from './AvailableProject';
import WithLoadingEffect from '../../shared/WithLoadingEffect';

export default function AvailableProjects({
  projects,
  loading,
  pageNumber,
  perPage,
  onPageNumberChange,
  total,
}) {
  return (
    <section className="available-projects">
      <WithLoadingEffect loading={loading} lines={10}>
        {total === 0 && <p className="t-sage-heading-5 list__null">There are no available projects</p>}
        {projects.map((project) => (
          <Fragment
            key={uuid()}
          >
            <AvailableProject
              project={project}
            />
          </Fragment>
        ))}
        {perPage < total && (
          <Pagination
            currentPage={pageNumber}
            hideCounter
            itemsPerPage={perPage}
            itemsTotal={total}
            onClickPage={onPageNumberChange}
          />
        )}
      </WithLoadingEffect>
    </section>
  );
}

AvailableProjects.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
};
