export default function userSlugAvailability(payload, callback) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/users/slug_available', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => callback(data));
}
