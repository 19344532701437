/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext,
} from 'react';
import {
  Dropdown,
  Icon,
  Tooltip,
} from '@kajabi/sage-react';
import Action from '../shared/Action';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Header() {
  const {
    chatData,
    setPaneOpenInbox,
    setPaneOpenUser,
    setQuoteSubmitModalOpen,
    setQuoteType,
  } = useContext(MessagesIndexContext);

  const {
    type: userType,
  } = useContext(CurrentUserContext);

  if (!chatData) return false;

  const quoteString = chatData.project.vendor_id ? 'Send invoice' : 'Send proposal';
  const quoteSubmitDisabled = (quoteString === 'Send proposal' && (!chatData.vendor.available_for_hire));
  const quoteSubmitDisabledTooltipText = 'Your current plan doesn’t allow you any more active projects. To send new proposals, you can complete, close, or archive older projects.';
  const userString = userType === 'user' ? 'Expert info' : 'Client info';
  const isVendor = userType === 'vendor';
  const quoteType = chatData.project.vendor_id ? 'invoice' : 'proposal';

  const itemQuote = {
    icon: 'file',
    id: 'quote',
    label: quoteString,
    disabled: quoteSubmitDisabled,
    onClick: () => {
      setQuoteType(quoteType);
      setQuoteSubmitModalOpen(true);
    },
    tooltip: {
      content: quoteSubmitDisabledTooltipText,
      position: 'left',
    },
  };

  const anotherItemQuote = {
    icon: 'file',
    id: 'quote',
    label: 'Send another Proposal',
    disabled: !chatData.vendor.available_for_hire,
    onClick: () => {
      setQuoteType('proposal');
      setQuoteSubmitModalOpen(true);
    },

    tooltip: {
      content: quoteSubmitDisabledTooltipText,
      position: 'left',
    },
  };

  const itemShowUserPane = {
    borderBefore: userType === 'vendor',
    icon: 'user',
    id: 'user',
    label: userString,
    onClick: () => setPaneOpenUser(true),
  };

  let itemsResolvedTablet;
  if (isVendor) {
    itemsResolvedTablet = chatData.project.vendor_id ? [itemQuote, anotherItemQuote] : [itemQuote];
  } else {
    itemsResolvedTablet = null;
  }

  let itemsResolvedMobile;
  if (isVendor) {
    itemsResolvedMobile = chatData.project.vendor_id
      ? [itemQuote, anotherItemQuote, itemShowUserPane]
      : [itemQuote, itemShowUserPane];
  } else {
    itemsResolvedMobile = [itemShowUserPane];
  }

  const dropdownProps = {
    active: true,
    align: 'right',
    className: 'dropdown dropdown--filter',
    customTrigger: (<Icon icon="dot-menu-horizontal" />),
  };

  return (
    <header className="header">
      <div className="header__content">
        <div
          className="header__mobile-action header__mobile-action--open-inbox"
          onClick={() => setPaneOpenInbox(true)}
          role="presentation"
        >
          <Icon
            adjacentType="h6"
            icon="caret-left"
          />
        </div>
        <div className="header__info">
          <div
            className="header__title"
            onClick={() => { window.location = `${window.location.origin}/projects/${chatData.project.id}`; }}
            role="presentation"
          >
            <p className="t-sage-heading-5">{chatData.project.title || chatData.project.project_data.subcategoryTitle}</p>
            <Icon
              className="header__title-icon"
              icon="launch"
            />
          </div>
        </div>
        {itemsResolvedTablet && (
          <div className="header__dropdown header__dropdown--tablet">
            <Dropdown {...dropdownProps}>
              <Dropdown.ItemList items={itemsResolvedTablet} />
            </Dropdown>
          </div>
        )}
        <div className="header__dropdown header__dropdown--mobile">
          <Dropdown {...dropdownProps}>
            <Dropdown.ItemList items={itemsResolvedMobile} />
          </Dropdown>
        </div>
      </div>
      {userType === 'vendor' && (
        <div className="header__actions">
          <Action
            disabled={quoteSubmitDisabled}
            iconLeft="file"
            onClick={() => {
              setQuoteType(quoteType);
              setQuoteSubmitModalOpen(true);
            }}
            text={quoteString}
          />
          {quoteType === 'invoice' && (
            <Action
              disabled={!chatData.vendor.available_for_hire}
              iconLeft="file"
              onClick={() => {
                setQuoteType('proposal');
                setQuoteSubmitModalOpen(true);
              }}
              text="Send another proposal"
            />
          )}
          {quoteSubmitDisabled && (
            <Tooltip content={quoteSubmitDisabledTooltipText}>
              <Icon
                color="yellow"
                icon="warning"
                size="sm"
              />
            </Tooltip>
          )}
        </div>
      )}
    </header>
  );
}
