const app = document.getElementById('app');
const config = JSON.parse(app.getAttribute('data-config'));
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': config.authenticityToken,
};

function packageItem({
  body = null,
  callback,
  method,
  route,
}) {
  fetch(route, {
    method,
    headers,
    body,
  })
    .then((res) => res.json())
    .then((res) => callback(res));
}

export function packagePost(payload, callback = () => { }) {
  packageItem({
    body: JSON.stringify({ package: payload }),
    callback,
    method: 'POST',
    route: '/api/v1/packages',
  });
}

export function packageAccept(id, callback = () => { }) {
  packageItem({
    callback,
    method: 'POST',
    route: `/api/v1/packages/${id}/accept`,
  });
}

export function packageUpdate(payload, callback = () => { }) {
  packageItem({
    body: JSON.stringify({ package: payload }),
    callback,
    method: 'PUT',
    route: `/api/v1/packages/${payload.id}`,
  });
}

export function packageDelete(id, callback = () => { }) {
  packageItem({
    callback,
    method: 'DELETE',
    route: `/api/v1/packages/${id}`,
  });
}
