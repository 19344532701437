import React from 'react';
import Testimonial from './Testimonial';

export default function Steps() {
  return (
    <section className="section section--flush-bottom">
      <div className="container container--large">
        <p className="section__title t-sage-heading-2">Trust our highly reviewed experts</p>
        <div className="sage-row">
          <div className="sage-col-3">
            <Testimonial
              client="Michelle L."
              expert="Sneha Hiremath"
              review="Sneha is extraordinary!  I love that she explains and shows you exactly what she is doing so that you can understand. This was the best experience!"
              user="38"
            />
            <Testimonial
              client="Evan H."
              expert="Sierra Janisse"
              review="Sierra is true to her word and followed through with everyhing I asked of her plus some. I will not hesitate to use her and her company again."
              user="706"
            />
          </div>
          <div className="sage-col-3">
            <Testimonial
              client="Rebecca T."
              expert="Mathias Media"
              review="Kraig diligently worked to help accomplish my goals and displayed wonderful attention to detail."
              user="54"
            />
            <Testimonial
              client="Jill B."
              expert="Melanie Lynn"
              review="Great audit. Did a great job capturing what I need to do to fix my website."
              user="472"
            />
            <Testimonial
              client="Michelle"
              expert="Joey Mazars"
              review="Joey was very helpful and professional. I would work with him again anytime."
              user="109"
            />
          </div>
          <div className="sage-col-3">
            <Testimonial
              client="Darkanna O."
              expert="Jennifer Williams"
              review="We are absolutely thrilled with the results of our website redesign! Cass & Jen did an amazing job capturing the essence of the brand and bringing it to life in a fresh, modern way."
              user="24"
            />
            <Testimonial
              client="Marrietta M."
              expert="SavvyChic Design"
              review="Working with Joanna was easy and her work is outstanding. I am very happy with the pages that she created. The communication was consistent, easy to understand and very efficient."
              user="23"
            />
          </div>
          <div className="sage-col-3">
            <Testimonial
              client="Camille C."
              expert="Weboxx"
              review="Amazing! They listened to all my concerns and their work is outstanding. I only wished I found them sooner as they would have saved me so much stress on my website."
              user="201"
            />
            <Testimonial
              client="Sharon D."
              expert="Kate MacGregor"
              review="Thanks to Kate MacGregor’s website development services I was able to get my consulting/coaching business off the ground and running! I contacted Kate through Kajabi experts and within 24 hrs she returned my project and it was perfect!"
              user="83"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
