import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  KjUploader,
} from '@kajabi/kj-uploader';
import {
  Button,
  Textarea,
} from '@kajabi/sage-react';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';
import iconUrl from '../../../../scripts/iconUrl.ts';
import messagePost from '../scripts/messagePost';
import truncated from '../../../../scripts/truncated';

export default function ChatPaneInputForm({
  isDynamicSize,
  placeholder,
}) {
  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const {
    currentConversation,
    getMessagesData,
    setCurrentConversation,
    setCurrentMessage,
  } = useContext(MessagesIndexContext);

  const uploaderConfig = window.uploaderConfig.attachments;
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  const input = useRef(null);

  const clearInput = () => {
    setContent('');
    setAttachments([]);
  };

  const focusInput = () => {
    input.current.querySelector('textarea').focus();
  };

  useEffect(() => {
    if (input) focusInput();
  }, [input]);

  if (isDynamicSize) {
    useLayoutEffect(() => {
      const textarea = input.current.querySelector('textarea');
      textarea.style.height = 'inherit';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }, [content]);
  }

  const handleClick = () => {
    messagePost(
      {
        attachments,
        content,
        project_id: currentConversation.projectId,
        sender_id: userId,
        vendor_id: currentConversation.vendorId,
      },
      (res) => {
        clearInput();
        focusInput();
        setCurrentConversation({
          projectId: currentConversation.projectId,
          vendorId: currentConversation.vendorId,
        });
        setCurrentMessage(JSON.parse(res));
        getMessagesData();
      },
    );
  };

  const openUploader = () => {
    const onSuccess = (result) => {
      const newAttachments = result.files.filter((file) => file.isSuccessful).map((file) => ({
        filename: file.filename,
        mimetype: file.mimetype,
        path: file.key,
      }));
      setAttachments([...attachments, ...newAttachments]);
    };

    const uploader = new KjUploader({
      ...uploaderConfig,
      parentNode: document.body,
      onSuccess,
      onComplete: () => {
        uploader.close();
      },
    });
    uploader.open();
  };

  return (
    <section className="message-input" ref={input}>
      <Textarea
        className="message-input__input"
        id="message-input"
        onChange={(e) => setContent(e.target.value)}
        placeholder={placeholder}
        value={content}
      />
      <div className="message-input__attachments">
        {
          attachments.map((attachment, index) => (
            <div key={uuid()} className="message-input__attachment">
              <span className="message-input__attachment-icon">
                <img src={iconUrl(attachment)} alt="" />
              </span>
              <span className="message-input__attachment-filename">{truncated(attachment.filename)}</span>
              <Button
                color="secondary"
                icon="remove"
                iconOnly
                onClick={() => {
                  const newAttachments = [...attachments];
                  newAttachments.splice(index, 1);
                  setAttachments(newAttachments);
                }}
                subtle
              />
            </div>
          ))
        }
      </div>
      <div className="message-input__actions">
        <Button
          color="secondary"
          icon="attach"
          iconOnly
          onClick={openUploader}
          subtle
        />

        <Button
          onClick={handleClick}
          disabled={!content && !attachments.length}
          icon="send-message"
        >
          Send
        </Button>
      </div>
    </section>
  );
}

ChatPaneInputForm.defaultProps = {
  isDynamicSize: true,
  placeholder: 'Write a message...',
};

ChatPaneInputForm.propTypes = {
  isDynamicSize: PropTypes.bool,
  placeholder: PropTypes.string,
};
