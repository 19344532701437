import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import Avatar from '../../../../shared/Avatar';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function PortfolioModal({
  active,
  onClose,
  onPaginationClick,
}) {
  const [useEllipses, setUseEllipses] = useState(true);

  const {
    currentPortfolioItemIndex,
    userData,
  } = useContext(UsersShowContext);

  const data = userData.portfolio_items[currentPortfolioItemIndex];
  const itemsLength = userData.portfolio_items.length;

  return (
    <Modal
      active={active}
      allowScroll
      className="portfolio-modal"
      onExit={onClose}
      size="lg"
    >
      <Modal.Header
        aside={(
          <Button
            className="btn-kajabi-blue"
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title={data.title}
      >
        <div className="portfolio-modal__subheader">
          <div className="portfolio-modal__bio">
            <Avatar
              size="24"
              user={userData}
            />
            <p className="t-sage-body-small portfolio-modal__byline">
              <span>by&nbsp;</span>
              <span className="portfolio-modal__name">{userData.name}</span>
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="portfolio-modal__body">
          {useEllipses ? (
            <div
              onClick={() => setUseEllipses(false)}
              role="presentation"
            >
              <LinesEllipsis
                text={data.description}
                maxLine="2"
                ellipsis={<p className="t-sage-heading-6 portfolio-modal__ellipses">Read more</p>}
                trimRight
                basedOn="words"
              />
            </div>
          ) : (data.description)}
        </div>
        <div className="portfolio-modal__images">
          {data.image
            ? <img alt={data.title} src={data.image} />
            : <p className="portfolio-modal__no-images">No images associated with this portfolio item</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group
          align="space-between"
          className="portfolio-modal__footer-actions"
          gap="sm"
        >
          <Button
            color="secondary"
            disabled={currentPortfolioItemIndex === 0}
            onClick={() => onPaginationClick(-1)}
          >
            Previous
          </Button>
          <Button
            color="secondary"
            disabled={currentPortfolioItemIndex === itemsLength - 1}
            onClick={() => onPaginationClick(1)}
          >
            Next
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

PortfolioModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
};
