import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import About from './about/About';
import CallToAction from './cta/CallToAction';
import Profile from './profile/Profile';
import Tabs from './tabs/Tabs';
import ConfigContext from '../../../../contexts/ConfigContext';
import UsersShowContext from '../../../../contexts/UsersShowContext';
import useApiFetch from '../../../../scripts/useApiFetch.tsx';

const ratingToReview = (rating) => ({
  client: rating.reviewer_name,
  createdAt: new Date(rating.rating.created_at),
  content: rating.rating.review,
  rating: rating.rating.rating,
  title: rating.category_name,
});

const testimonialToReview = (testimonial) => ({
  approved: testimonial.approved,
  client: `${testimonial.reviewer_given_name} ${testimonial.reviewer_family_name}`,
  content: testimonial.content,
  createdAt: new Date(testimonial.created_at),
  rating: testimonial.rating,
  title: testimonial.title,
});

export default function UsersShow() {
  // Feature flags
  const ffPortfolio = window.globalFeatureFlags.expertProfilePortfolio.enabled;
  const ffPackages = window.globalFeatureFlags.packages.enabled;

  const {
    apiFetch,
  } = useApiFetch();

  const {
    userSignedIn,
  } = useContext(ConfigContext);

  const [currentPortfolioItemIndex, setCurrentPortfolioItemIndex] = useState(0);
  const [currentPackageIndex, setCurrentPackageIndex] = useState(0);
  const [isHireTab, setIsHireTab] = useState(false);
  const [userData, setUserData] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const resolvedPath = window.location.href.split('?')[0].replace(/\/$/, '');

  const fetchTestimonials = () => {
    const { id } = userData;
    fetch(`${window.location.origin}/api/v1/vendor/${id}/testimonials.json`)
      .then((response) => response.json())
      .then((data) => { setTestimonials(data); });
  };

  const reviewsWithTestimonials = () => {
    if (!userData) return [];

    const { filtered_ratings: ratings } = userData;

    const result = [
      ...(ratings).map(ratingToReview),
      ...(testimonials).map(testimonialToReview),
    ].sort((a, b) => b.createdAt - a.createdAt);

    return result;
  };

  useEffect(() => {
    const lastPathFragment = window.location.pathname.split('/').pop();
    if (lastPathFragment === 'hire') setIsHireTab(true);
  });

  useEffect(() => {
    apiFetch(`${resolvedPath}.json`).then((data) => setUserData(data)).catch(() => {});
  }, []);

  useEffect(() => {
    if (userData?.suspended) window.location.href = '/';
    if (userData) fetchTestimonials();
  }, [userData]);

  const usersShowData = useMemo(() => ({
    currentPackageIndex,
    currentPortfolioItemIndex,
    ffPackages,
    ffPortfolio,
    isHireTab,
    reviewsWithTestimonials: reviewsWithTestimonials(),
    setCurrentPackageIndex,
    setCurrentPortfolioItemIndex,
    setIsHireTab,
    userData,
  }));

  if (userData && !userData.suspended) {
    return (
      <UsersShowContext.Provider value={usersShowData}>
        <div className="layout">
          <div className="layout__side layout__side--left">
            {!userSignedIn && <CallToAction />}
            <Profile />
          </div>
          <div className="layout__side layout__side--right">
            {userData.user_type === 'vendor' && (
              <>
                <About />
                <Tabs />
              </>
            )}
          </div>
        </div>
      </UsersShowContext.Provider>
    );
  }
}
