import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';

export default function Action({
  disabled,
  iconLeft,
  iconRight,
  onClick,
  text,
}) {
  let className = 'action';
  if (disabled) {
    className = 'action action--disabled';
  }
  return (
    <div
      className={className}
      onClick={disabled ? undefined : onClick}
      role="presentation"
    >
      {iconLeft && <Icon icon={iconLeft} />}
      {text}
      {iconRight && <Icon icon={iconRight} />}
    </div>
  );
}

Action.defaultProps = {
  disabled: false,
  iconLeft: null,
  iconRight: null,
  onClick: null,
};

Action.propTypes = {
  disabled: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};
