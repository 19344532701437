import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Link,
  Modal,
} from '@kajabi/sage-react';

export default function MessagingUnvailableModal({
  active,
  onClose,
}) {
  return (
    <Modal
      active={active}
      className="messaging-unavailable-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Messaging not available"
      />
      <Modal.Body>
        <p style={{ textAlign: 'center' }}>
          <img alt="" src="/images/svg/messaging.svg" />
        </p>
        <p>
          Hello Expert! Only clients are able to contact experts at this time.
          Let us know if you have any&nbsp;
          <Link
            color="secondary"
            href="mailto:experts@kajabi.com"
            style={Link.COLORS.SECONDARY}
          >
            feedback
          </Link>
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button onClick={onClose}>
            Cancel
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

MessagingUnvailableModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
