import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import PlansContext from './PlansContext';

export default function Indicator() {
  const {
    isDisplayPlanMatrix,
    setIsDisplayPlansMatrix,
  } = useContext(PlansContext);

  if (isDisplayPlanMatrix) return false;

  return (
    <section
      className="indicator"
      onClick={() => setIsDisplayPlansMatrix(true)}
      role="presentation"
    >
      <p className="t-sage-heading-5 indicator__text">
        See full plan comparison
      </p>
      <Icon
        adjacentType="h5"
        icon="arrow-down"
      />
    </section>
  );
}
