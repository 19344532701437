/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as SageSelect,
} from '@kajabi/sage-react';

export default function Select({
  handleChange,
  hasError,
  idName,
  label,
  options,
  value,
}) {
  return (
    <SageSelect
      hasError={hasError}
      id={idName}
      label={label}
      name={idName}
      onChange={handleChange}
      options={options}
      value={value}
    />
  );
}

Select.defaultProps = {
  hasError: false,
};

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  idName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
