import React, {
  useContext,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import ProjectNewContext from '../../../contexts/ProjectNewContext';

export default function CategoryBreadcrumbs() {
  const {
    category,
    setCategory,
    setSubcategory,
    subcategory,
  } = useContext(ProjectNewContext);

  const navigate = useNavigate();

  return (
    <div className="breadcrumbs">
      <Breadcrumb
        onClick={() => {
          navigate('/projects/new');
          setSubcategory(null);
          setCategory(null);
        }}
        showIcon={false}
        subtle
        title="All services"
      />
      {category && (
        <Breadcrumb
          onClick={() => setSubcategory(null)}
          title={category.title}
        />
      )}
      {subcategory && (
        <Breadcrumb
          title={subcategory.title}
        />
      )}
    </div>
  );
}
