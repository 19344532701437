import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';

export default function ButtonCheckout({
  children,
  disabled,
  downgrade,
}) {
  const onClickAction = () => {
    if (downgrade) window.location.href = 'mailto:experts@kajabi.com?Subject=Kajabi Experts plan change request';
    else window.location = `https://expert-subscriptions.mykajabi.com/offers/FiK2rLpk/checkout?email=${window.globalVars.userEmail}`;
  };

  return (
    <Button
      className="btn-kajabi-blue chart__action"
      disabled={disabled}
      fullWidth
      onClick={onClickAction}
    >
      {children}
    </Button>
  );
}

ButtonCheckout.defaultProps = {
  children: 'Join now',
  disabled: false,
  downgrade: false,
};

ButtonCheckout.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  downgrade: PropTypes.bool,
};
