import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  escape,
} from 'underscore';
import CallToAction from './CallToAction';
import FAQ from './FAQ';
import FAQCallToAction from '../shared/landing-pages/FAQCallToAction';
import Features from './Features';
import Hero from './Hero';
import Services from './Services';
import Steps from './Steps';
import Templates from './Templates';
import Testimonials from './Testimonials';
import ConfigContext from '../../contexts/ConfigContext';

export default function WelcomeIndex() {
  const {
    authenticityToken,
    loginPath,
  } = useContext(ConfigContext);

  const loginLinkRefHere = useRef(null);
  const USE_INTEGRATED_SIGN_ON = window.featureFlags.integratedSignOn.enabled;

  useEffect(() => {
    if (window.featureFlags.integratedSignOn.enabled === true) {
      if (window.welcomeConfig.userSignedIn !== 'true' && window.welcomeConfig.needsAuth !== 'true') {
        loginLinkRefHere.current.submit();
      }
    }
  }, []);

  return (
    <>
      <form
        action={escape(loginPath)}
        method="post"
        ref={loginLinkRefHere}
      >
        <input
          name="authenticity_token"
          type="hidden"
          value={authenticityToken}
        />
        {USE_INTEGRATED_SIGN_ON && (
          <input
            name="prompt"
            type="hidden"
            value="none"
          />
        )}
      </form>
      <Hero />
      <Features />
      <Services />
      <Steps />
      <CallToAction />
      <Testimonials />
      <FAQ />
      <FAQCallToAction />
    </>
  );
}
