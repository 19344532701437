import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  escape,
} from 'underscore';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import CurrentUserContext from '../../contexts/CurrentUserContext';

export default function PlanUpgradeModal({
  active,
  onClose,
}) {
  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const plansLink = escape(`${window.location.origin}/users/${userId}/edit?tab=plans`);

  return (
    <Modal
      active={active}
      className="plan-upgrade-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="We see you're taking your business seriously"
      />
      <Modal.Body>
        <p>
          This feature is not available on your current plan.
          However, you can upgrade now to unlock this feature and many more.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="quote-review-modal__footer-content">
          <Button.Group
            className="quote-review-modal__actions"
            gap="sm"
          >
            <Button
              color="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                window.location = plansLink;
              }}
            >
              Upgrade plan
            </Button>
          </Button.Group>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

PlanUpgradeModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
