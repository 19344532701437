/* eslint-disable no-nested-ternary */
import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import ButtonCheckout from './ButtonCheckout';
import PlansContext from './PlansContext';
import chartData from './chartData';

export default function Chart() {
  const {
    currentPlan,
    isDisplayPlanMatrix,
  } = useContext(PlansContext);

  if (!isDisplayPlanMatrix) return false;

  return (
    <section className="chart">
      <div className="chart__header">
        <p className="t-sage-heading-3">
          Full plan
          <br />
          comparison
        </p>
      </div>
      <div className="chart__plans">
        <p className="t-sage-heading-5 chart__plans-title">Plan features</p>
        {chartData.plans.map((plan) => {
          const isCurrentPlan = currentPlan === plan.name.toLowerCase();
          if (!plan.hidden) {
            return (
              <div className={`chart__plan ${isCurrentPlan ? 'chart__plan--current' : ''}`}>
                <div className="chart__plan-info">
                  {plan.svg}
                  <p className="t-sage-heading-5 chart__plan-title">{plan.displayName}</p>
                </div>
              </div>
            );
          }
          return false;
        })}
      </div>
      <div className="chart__sections">
        {chartData.sections.map((section, sectionIndex) => (
          <div className="chart__section">
            <p className="t-sage-heading-6 chart__section-title">{section.title}</p>
            <hr className="chart__section-hr" />
            {section.features.map((feature, featureIndex) => (
              <div className="chart__row">
                <p className="t-sage-body-small chart__feature">
                  {feature}
                </p>
                {chartData.plans.map((plan) => {
                  const isCurrentPlan = currentPlan === plan.name.toLowerCase();
                  const ent = plan.entitlements[sectionIndex][featureIndex];
                  if (!plan.hidden) {
                    return (
                      <p className={`t-sage-body-small chart__entitlement ${isCurrentPlan ? 'chart__entitlement--current' : ''}`}>
                        {ent === 'check' ? (
                          <Icon
                            icon="check-circle"
                          />
                        ) : ent}
                      </p>
                    );
                  }
                  return false;
                })}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="chart__actions">
        <div className="chart__actions-spacer" />
        {chartData.plans.map((plan) => {
          const isCurrentPlan = currentPlan === plan.name.toLowerCase();
          let resolvedButtonText = 'Join now';
          if (!isCurrentPlan && currentPlan === 'growth') resolvedButtonText = 'Contact support';
          if (isCurrentPlan) resolvedButtonText = 'Current plan';
          if (!plan.hidden) {
            return (
              <ButtonCheckout
                disabled={isCurrentPlan}
                downgrade={!isCurrentPlan && currentPlan === 'growth'}
              >
                {resolvedButtonText}
              </ButtonCheckout>
            );
          }
          return false;
        })}
      </div>
    </section>
  );
}
