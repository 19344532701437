import React from 'react';
import PropTypes from 'prop-types';
import dateToText from '../../scripts/dateToText';

export default function Timestamp({
  timestamp,
}) {
  return (
    <div className="timestamp">
      <p>
        {dateToText({ rawDateObject: timestamp })}
      </p>
    </div>
  );
}

Timestamp.propTypes = {
  timestamp: PropTypes.string.isRequired,
};
