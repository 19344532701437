import React from 'react';
import Plan from './Plan';
import plansData from '../../../data/shared/plansData';

export default function PlansList() {
  return (
    <section className="section section--flush-bottom plans-list">
      <div className="container">
        <div className="sage-row">
          {!plansData[0].hidden && (
            <div className="sage-col-5">
              <Plan
                data={plansData[0]}
                isCurrentPlan
              />
            </div>
          )}
          {!plansData[1].hidden && (
            <div className="sage-col-7">
              <Plan
                data={plansData[1]}
              />
            </div>
          )}
          {!plansData[2].hidden && (
            <div className="sage-col">
              <Plan
                data={plansData[2]}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
