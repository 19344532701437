import React from 'react';

const plansGraphics = {
  basic: (
    <svg width="28" height="26" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.11298 2.75026C0.580906 1.43546 1.54855 0 2.96693 0H15.8692C16.6841 0 17.4175 0.494379 17.7231 1.24974L20.887 9.06793C21.4191 10.3827 20.4514 11.8182 19.0331 11.8182H6.1308C5.31593 11.8182 4.58254 11.3238 4.27686 10.5684L1.11298 2.75026Z" fill="#B7BCEB" />
    </svg>
  ),
  growth: (
    <svg width="28" height="26" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.11298 9.84108C0.580906 8.52628 1.54855 7.09082 2.96693 7.09082H15.8692C16.6841 7.09082 17.4175 7.5852 17.7231 8.34056L20.887 16.1587C21.4191 17.4735 20.4514 18.909 19.0331 18.909H6.1308C5.31593 18.909 4.58254 18.4146 4.27686 17.6593L1.11298 9.84108Z" fill="#333FA5" />
      <path d="M4.11298 2.75026C3.58091 1.43546 4.54855 0 5.96693 0H18.8692C19.6841 0 20.4175 0.494379 20.7231 1.24974L23.887 9.06793C24.4191 10.3827 23.4514 11.8182 22.0331 11.8182H9.1308C8.31593 11.8182 7.58254 11.3238 7.27686 10.5684L4.11298 2.75026Z" fill="#B7BCEB" />
      <path d="M20.1404 14.2632C20.0602 13.8869 19.1913 13.6191 18.8667 13.6059L9.64221 13.2321C8.42135 13.124 7.45038 12.0405 7.16445 10.9083C7.14839 10.8447 7.17002 10.7768 7.21575 10.7392C7.26224 10.701 7.32659 10.7157 7.36116 10.7691C7.54218 11.0487 8.13297 11.8182 9.15194 11.8182L19.1284 11.8159L20.1404 14.2632Z" fill="#262E75" />
    </svg>
  ),
  pro: (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.11298 16.9321C0.580906 15.6173 1.54855 14.1818 2.96693 14.1818H15.8692C16.6841 14.1818 17.4175 14.6762 17.7231 15.4316L20.887 23.2497C21.4191 24.5645 20.4514 26 19.0331 26H6.1308C5.31593 26 4.58254 25.5056 4.27686 24.7503L1.11298 16.9321Z" fill="#262E75" />
      <path d="M4.11298 9.84108C3.58091 8.52628 4.54855 7.09082 5.96693 7.09082H18.8692C19.6841 7.09082 20.4175 7.5852 20.7231 8.34056L23.887 16.1587C24.4191 17.4735 23.4514 18.909 22.0331 18.909H9.1308C8.31593 18.909 7.58254 18.4146 7.27686 17.6593L4.11298 9.84108Z" fill="#333FA5" />
      <path d="M7.11298 2.75026C6.58091 1.43546 7.54855 0 8.96693 0H21.8692C22.6841 0 23.4175 0.494379 23.7231 1.24974L26.887 9.06793C27.4191 10.3827 26.4514 11.8182 25.0331 11.8182H12.1308C11.3159 11.8182 10.5825 11.3238 10.2769 10.5684L7.11298 2.75026Z" fill="#B7BCEB" />
      <path d="M20.1381 21.3609C20.058 20.9846 19.1892 20.7168 18.8646 20.7036L9.64177 20.3298C8.42113 20.2216 7.45033 19.1382 7.16445 18.006C7.1484 17.9424 7.17001 17.8745 7.21574 17.8369C7.26222 17.7986 7.32657 17.8134 7.36112 17.8667C7.54211 18.1463 8.1328 18.9158 9.15159 18.9158L19.1263 18.9136L20.1381 21.3609Z" fill="#141947" />
      <path d="M23.1404 14.2632C23.0602 13.8869 22.1913 13.6191 21.8667 13.6059L12.6422 13.2321C11.4213 13.124 10.4504 12.0405 10.1645 10.9083C10.1484 10.8447 10.17 10.7768 10.2157 10.7392C10.2622 10.701 10.3266 10.7157 10.3612 10.7691C10.5422 11.0487 11.133 11.8182 12.1519 11.8182L22.1284 11.8159L23.1404 14.2632Z" fill="#262E75" />
    </svg>
  ),
};

export default plansGraphics;
