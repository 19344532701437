import React from 'react';
import PropTypes from 'prop-types';

export default function UIIconStar({
  color,
  size,
  styleColor
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4283 5.30192L10.7082 4.61599L8.59976 0.341782C8.53846 0.237698 8.45106 0.151418 8.34619 0.0914747C8.24132 0.031531 8.12262 0 8.00183 0C7.88104 0 7.76234 0.031531 7.65747 0.0914747C7.5526 0.151418 7.4652 0.237698 7.4039 0.341782L5.29148 4.61599L0.57136 5.30192C0.448268 5.31969 0.332601 5.37154 0.237436 5.45161C0.142271 5.53168 0.0714033 5.63677 0.0328454 5.75501C-0.00571256 5.87326 -0.0104232 5.99993 0.019246 6.1207C0.0489152 6.24148 0.111781 6.35155 0.200736 6.43847L3.61701 9.76745L2.8111 14.4689C2.7901 14.5915 2.80381 14.7176 2.85068 14.8328C2.89756 14.948 2.97573 15.0478 3.07636 15.1208C3.17698 15.1939 3.29605 15.2374 3.4201 15.2464C3.54415 15.2553 3.66824 15.2294 3.77832 15.1715L7.99983 12.9524L12.2213 15.1715C12.3314 15.2294 12.4555 15.2553 12.5796 15.2464C12.7036 15.2374 12.8227 15.1939 12.9233 15.1208C13.0239 15.0478 13.1021 14.948 13.149 14.8328C13.1959 14.7176 13.2096 14.5915 13.1886 14.4689L12.3827 9.76745L15.7989 6.43847C15.8879 6.35164 15.9507 6.24168 15.9805 6.121C16.0102 6.00032 16.0056 5.87372 15.9672 5.75552C15.9288 5.63731 15.8581 5.5322 15.7631 5.45204C15.6681 5.37189 15.5526 5.31989 15.4296 5.30192H15.4283Z" fill={color} style={{ fill: styleColor }} />
    </svg>
  );
}

UIIconStar.defaultProps = {
  color: '#FFC505',
  size: '16',
  styleColor: null,
};

UIIconStar.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  styleColor: PropTypes.string,
};
