/* eslint-disable react/forbid-prop-types */
import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import classnames from 'classnames';
import {
  first,
} from 'underscore';
import {
  Badge,
  Card,
  Icon,
} from '@kajabi/sage-react';
import Avatar from '../../shared/Avatar';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ProjectCard({
  project,
}) {
  const {
    id: userId,
    isClient,
    isVendor,
  } = useContext(CurrentUserContext);

  const {
    brief,
    project_data: projectData,
    proposed_to_vendor_id: proposedToVendorId,
    refused,
    title,
    url: projectUrl,
    vendor_id: vendorId,
    vendors,
    is_package: isPackage,
    is_private: isPrivate,
  } = project;

  const {
    subcategoryTitle,
  } = projectData;

  const redirectToConversation = proposedToVendorId
    && isVendor
    && project.filtered_messages.filter((message) => message.sender_id === userId).length < 1
    && !refused;

  const url = redirectToConversation
    ? `/conversations/projects/${project.id}/vendors/${proposedToVendorId}`
    : projectUrl;

  const classNames = classnames('project-card', {
    'project-card--user': isClient,
    'project-card--vendor': isVendor,
  });

  const truncatedLength = 3;
  const truncatedVendors = first(vendors, truncatedLength);

  return (
    <Card
      className={classNames}
      onClick={() => { window.location = url; }}
    >
      <div className="project-card__info">
        <p className="t-sage-heading-5">
          {title || subcategoryTitle}
          {
            isPackage && (
              <Badge
                className="project-card__badge"
                color="locked"
                value="Package"
              />
            )
          }
          {
            isPrivate && (
              <Badge
                className="project-card__badge"
                color="warning"
                value="Private"
              />
            )
          }
        </p>
        {brief && (
          <p className="t-sage-body-small project-card__brief">
            <LinesEllipsis
              text={brief}
              maxLine="1"
              trimRight
              basedOn="words"
            />
          </p>
        )}
      </div>
      {isClient && (
        <div className="project-card__status">
          <p className="t-sage-heading-5">
            {vendorId ? <span>Hired expert</span> : <span>Conversations</span>}
          </p>
          {vendors && vendors.length === 0 ? (
            <p className="t-sage-body-small project-card__waiting">
              Waiting to hear from experts...
            </p>
          ) : (
            <div className="project-card__vendors">
              <div className="project-card__avatars">
                {truncatedVendors.map((vendor) => (
                  <div className="project-card__avatar">
                    <Avatar
                      size="24"
                      user={vendor}
                    />
                  </div>
                ))}
              </div>
              <p className="t-sage-body-small project-card__names">
                {truncatedVendors.map((vendor, index) => {
                  if (index > 0) return (`, ${vendor.name}`);
                  return (vendor.name);
                })}
                {vendors.length > truncatedLength && (
                  <span className="project-card__more-text">
                    {` +${vendors.length - truncatedLength} more`}
                  </span>
                )}
              </p>
            </div>
          )}
        </div>
      )}
      <Icon
        className="project-card__icon"
        icon="caret-right"
      />
    </Card>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.shape({
    brief: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    filtered_messages: PropTypes.array,
    project_data: PropTypes.shape({
      description: PropTypes.string,
      subcategoryTitle: PropTypes.string,
    }),
    proposed_to_vendor_id: PropTypes.number,
    status: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    vendor_id: PropTypes.number,
    vendors: PropTypes.array,
    is_package: PropTypes.bool,
    is_private: PropTypes.bool,
  }).isRequired,
};
