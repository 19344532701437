import React from 'react';
import Feature from '../shared/Feature';

export default function Features() {
  return (
    <section className="section section--sm section--blue">
      <div className="container container--large">
        <div className="sage-row">
          <div className="sage-col-4">
            <Feature
              description="Focus on what you love and let a specialist handle the rest."
              icon="clock"
              title="Take back your time"
            />
          </div>
          <div className="sage-col-4">
            <Feature
              description="Put your best foot forward and level up with expert help."
              icon="star"
              title="Get professional results"
            />
          </div>
          <div className="sage-col-4">
            <Feature
              description="Hire with the peace of mind that they have Kajabi’s stamp of approval."
              icon="check-circle"
              title="Work with vetted service providers"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
