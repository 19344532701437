/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, {
  useId,
} from 'react';
import PropTypes from 'prop-types';
import {
  ErrorMessage,
  Field,
} from 'formik';
import uuid from 'react-uuid';
import {
  Icon,
} from '@kajabi/sage-react';

export default function RadioGroup({
  name,
  options,
  subtitle,
  title,
}) {
  const uniqueId = useId();

  return (
    <div className="radio-group">
      <div className="radio-group__header">
        {title && (<p className="t-sage-heading-5 radio-group__title">{title}</p>)}
        {subtitle && (<p className="t-sage-heading-6 radio-group__subtitle">{subtitle}</p>)}
      </div>
      <Field
        name={name}
      >
        {({ field }) => options.map((option, index) => (
          <div className="sage-radio" key={uuid()}>
            <input
              {...field}
              className="sage-radio__input"
              type="radio"
              id={`${uniqueId}-${index}`}
              value={option}
              checked={field.value.includes(option)}
            />
            <label
              className="sage-radio__label"
              htmlFor={`${uniqueId}-${index}`}
            >
              {option}
            </label>
          </div>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(msg) => (
          <p className="radio-group__error">
            <Icon icon="danger" />
            {msg}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

RadioGroup.defaultProps = {
  subtitle: null,
  title: null,
};

RadioGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
