import React from 'react';
import ActionButtonPrimary from '../shared/landing-pages/ActionButtonPrimary';

export default function CallToAction() {
  return (
    <section className="container container--large">
      <div className="call-to-action">
        <p className="t-sage-heading-2 call-to-action__title">
          There’s no time like the present
        </p>
        <p className="call-to-action__description">
          Get matched with a vetted expert and get your project underway today.
        </p>
        <div className="call-to-action__actions">
          <ActionButtonPrimary />
        </div>
      </div>
    </section>
  );
}
