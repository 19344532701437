import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

export default function BriefItem({
  answer,
  question,
}) {
  const resolvedAnswer = Array.isArray(answer)
    ? answer.map((answerPart) => (
      <p key={uuid()}>{answerPart}</p>
    ))
    : answer;

  return (
    <div className="brief__item">
      <div className="brief__question">{question}</div>
      <div className="brief__answer">{resolvedAnswer}</div>
    </div>
  );
}

BriefItem.propTypes = {
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  question: PropTypes.string.isRequired,
};
