import React, {
  useContext,
} from 'react';
import Package from './Package';
import PackagesContext from './PackagesContext';

export default function List() {
  const {
    packages,
  } = useContext(PackagesContext);

  return (
    <section className="packages">
      {packages && packages.length > 0 ? (
        <div className="packages__grid">
          {packages.map((item) => (
            <Package key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <p className="packages__null-message">You haven’t added any packages yet.</p>
      )}
    </section>
  );
}
