import React, {
  useContext,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import GraphicThreadsNull from '../GraphicThreadsNull';
import ConfigContext from '../../../../../contexts/ConfigContext';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';

export default function ThreadsNull() {
  const config = useContext(ConfigContext);
  const project = useContext(CurrentProjectContext);

  const userIsCustomer = config.user.type === 'user';

  const title = userIsCustomer
    ? 'Matching you with experts...'
    : 'No messages yet';

  const description = userIsCustomer
    ? 'Mesages from experts will display here.'
    : 'Send this customer a message to show your interest in this project and learn more.';

  return (
    <div className="threads-null">
      <div className="threads-null__image">
        <GraphicThreadsNull />
      </div>
      <div className="threads-null__text">
        <p className="t-sage-heading-5">{title}</p>
        <p className="t-sage-body-small">{description}</p>
      </div>
      {!userIsCustomer && (
        <Button
          onClick={() => window.location.replace(`${window.location.origin}/conversations/projects/${project.id}/vendors/${config.user.id}`)}
        >
          Send message
        </Button>
      )}
    </div>
  );
}
