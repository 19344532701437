import React from 'react';
import PropTypes from 'prop-types';

export default function ReviewsStatistic({
  content,
  hint,
  title,
}) {
  return (
    <div className="reviews-statistic">
      <p className="t-sage-heading-5 reviews-statistic__title">
        {title}
      </p>
      <div className="t-sage-heading-1 reviews-statistic__content">
        {content}
      </div>
      <p className="t-sage-body-small reviews-statistic__hint">
        {hint}
      </p>
    </div>
  );
}

ReviewsStatistic.propTypes = {
  content: PropTypes.node.isRequired,
  hint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
