import React, {
  useContext,
} from 'react';
import Header from './Header';
import Tabs from './Tabs';
import TemplatesAlert from './TemplatesAlert';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ProjectsIndex() {
  const {
    type: userType,
  } = useContext(CurrentUserContext);

  return (
    <div className="container">
      <Header />
      {userType === 'vendor' && <TemplatesAlert />}
      <Tabs />
    </div>
  );
}
