/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@kajabi/sage-react';
import classnames from 'classnames';
import ChatPaneQuote from './ChatPaneQuote';
import Avatar from '../../../shared/Avatar';
import Timestamp from '../../../shared/Timestamp';
import Linkifier from '../../../shared/Linkifier';
import iconUrl from '../../../../scripts/iconUrl';
import systemUser from '../../../../data/shared/systemUser';
import truncated from '../../../../scripts/truncated';

export default function ChatPaneMessage({
  attachments,
  content,
  isQuote,
  timestamp,
  user,
}) {
  const isUserMessage = Boolean(user);
  const isSystemMessage = !isUserMessage;
  const messageUser = useCallback(() => {
    if (isSystemMessage) return systemUser;
    return user || systemUser;
  });

  return (
    <div className="chat-pane-message">
      <div className="chat-pane-message__avatar">
        <Avatar
          size="40"
          user={messageUser()}
        />
      </div>
      <div className={classnames('chat-pane-message__content', { 'chat-pane-message__content--system': isSystemMessage })}>
        <div className="chat-pane-message__header">
          <p className="t-sage-body-small chat-pane-message__name">{messageUser()?.name}</p>
          <Timestamp
            timestamp={timestamp}
          />
        </div>
        <div className="chat-pane-message__body">
          {isQuote && isUserMessage ? (
            <ChatPaneQuote />
          ) : (
            <Linkifier
              tagName="div"
            >
              {content}
            </Linkifier>
          )}

          <div className="message-input__attachments">
            {
              attachments.map((attachment) => (
                <div key={attachment.id} className="message-input__attachment">
                  <span className="message-input__attachment-icon">
                    <img src={iconUrl(attachment)} alt="" />
                  </span>
                  <span className="message-input__attachment-filename">{truncated(attachment.filename)}</span>

                  <Button
                    color="secondary"
                    icon="download"
                    iconOnly
                    download={attachment.filename}
                    href={attachment.url}
                    subtle
                  />
                </div>
              ))
            }
          </div>

        </div>
      </div>
    </div>
  );
}

ChatPaneMessage.defaultProps = {
  content: null,
  isQuote: false,
  user: null,
};

ChatPaneMessage.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    extension: PropTypes.string,
    filename: PropTypes.string,
    id: PropTypes.number,
    mimetype: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
  content: PropTypes.node,
  isQuote: PropTypes.bool,
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
  }),
};
