import React, {
  useContext,
} from 'react';
import uuid from 'react-uuid';
import {
  ProgressBar,
  SageTokens,
} from '@kajabi/sage-react';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function ReviewsStars() {
  const {
    reviewsWithTestimonials: ratings,
  } = useContext(UsersShowContext);

  function getNumStars(starValue) {
    let counter = 0;
    ratings.forEach((rating) => {
      if (rating.rating === starValue) counter += 1;
    });
    const resolvedPercent = ratings.length === 0 ? 0 : (counter / ratings.length) * 100;
    return (
      <div
        className="reviews-stars__item"
        key={uuid()}
      >
        <div className="reviews-stars__value">
          <p className="t-sage-body-xsmall">{starValue}</p>
        </div>
        <div className="reviews-stars__progress">
          <ProgressBar
            color={SageTokens.COLOR_PALETTE.CHARCOAL_300}
            disableTooltip
            percent={resolvedPercent}
          />
        </div>
        <p className="t-sage-body-xsmall reviews-stars__count">{`(${counter})`}</p>
      </div>
    );
  }

  const stars = [];
  for (let i = 1; i < 6; i += 1) {
    stars.push(getNumStars(i));
  }
  stars.reverse();

  return (
    <section className="reviews-stars">
      {stars}
    </section>
  );
}
