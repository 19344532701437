import React, {
  useContext,
} from 'react';
import Actions from './Actions';
import Header from './Header';
import Profile from './Profile';
import Details from './Details';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

export default function UserPane() {
  const {
    type: userType,
  } = useContext(CurrentUserContext);

  const isUser = userType === 'user';

  return (
    <section className="pane user-pane">
      <section className="user-pane__section user-pane__section--border-bottom">
        <Header />
        <Profile />
        {isUser && <Details />}
      </section>
      <section className="user-pane__section">
        <Actions />
      </section>
    </section>
  );
}
