import React from 'react';
import {
  Link,
} from '@kajabi/sage-react';

export default function ActionsAdmin() {
  return (
    <Link
      href="/admin/dashboard"
      removeUnderline
      style={Link.COLORS.SECONDARY}
    >
      Dashboard
    </Link>
  );
}
