import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import ConversationsShowContext from '../ConversationsShowContext';

export default function InboxPaneHeader() {
  const {
    project,
    recentMessages,
  } = useContext(ConversationsShowContext);

  const vendorId = project.vendor_id;

  return (
    <div className="inbox-pane-header">
      <div
        className="inbox-pane-header__breadcrumb"
        onClick={() => window.location.replace(`${window.location.origin}/projects/${project.id}`)}
        role="presentation"
      >
        <Icon icon="arrow-left" />
        <p className="t-sage-heading-6">Project timeline</p>
      </div>
      <div className="inbox-pane-header__subcategory">
        {project.subcategory.name}
      </div>
      <div className="inbox-pane-header__messages">
        <p className="t-sage-heading-5">Messages</p>
        <div className="t-sage-heading-6 inbox-pane-header__messages-count">{vendorId ? 1 : recentMessages.length}</div>
      </div>
    </div>
  );
}
