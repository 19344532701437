import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@kajabi/sage-react';
import QuoteDetails from '../../../../shared/QuoteDetails';
import QuoteStatus from '../../../../shared/QuoteStatus';
import TimelineCard from './TimelineCard';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import ViewDefaultContext from '../ViewDefaultContext';

export default function TimelineQuote({
  quote,
  vendor,
}) {
  const {
    setIsQuoteModalOpen,
    setQuote,
    setVendor,
  } = useContext(ViewDefaultContext);
  const user = useContext(CurrentUserContext);

  const isProposal = quote.quote_type === 'proposal';
  const textMessage = isProposal ? 'sent a proposal' : 'sent an invoice';
  const iconQuote = isProposal ? 'rename' : 'payout';

  return (
    <TimelineCard
      classNames="quote"
      icon={iconQuote}
      message={textMessage}
      timestamp={quote.created_at}
      userName={vendor.name}
    >
      <QuoteDetails
        quote={quote}
        showTitle={false}
      />
      <hr className="quote__hr" />
      {(quote.status === 'new' && user.type === 'user')
        ? (
          <Button
            onClick={() => {
              setIsQuoteModalOpen(true);
              setQuote(quote);
              setVendor(vendor);
            }}
          >
            {`Review ${quote.quote_type}`}
          </Button>
        ) : (<QuoteStatus quote={quote} />
        )}
    </TimelineCard>
  );
}

TimelineQuote.propTypes = {
  quote: PropTypes.shape({
    created_at: PropTypes.string,
    invoice_url: PropTypes.string,
    quote_type: PropTypes.string,
    status: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
