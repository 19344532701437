import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Modal,
} from '@kajabi/sage-react';
import ModalAlert from '../../../shared/ModalAlert';
import QuoteBio from '../../../shared/QuoteBio';
import QuoteDetails from './QuoteDetails';
import quoteAccept from '../../../../scripts/quoteAccept';
import quoteCancel from '../../../../scripts/quoteCancel';

export default function QuoteReviewModal({
  active,
  onClose,
  quote,
  vendor,
}) {
  const [termsAccepted, setTermsAccepted] = useState(true);

  const isProposal = quote.quote_type === 'proposal';
  const textHeader = isProposal ? 'Review proposal' : 'Review invoice';
  const textButtonAccept = isProposal ? 'Accept and hire' : 'Accept invoice';

  return (
    <Modal
      active={active}
      allowScroll
      className="modal-border-scroll quote-review-modal"
      size="lg"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title={textHeader}
      />
      <Modal.Body>
        <div className="quote-review-modal__content">
          <QuoteBio vendor={vendor} />
          <QuoteDetails quote={quote} />
          {!isProposal && (
            <ModalAlert
              description="When you accept this invoice, you will receive an email from Kajabi with a copy of the invoice and a link to pay it."
              title="What happens next?"
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="quote-review-modal__footer-content">
          {isProposal && (
            <div className="quote-review-modal__conditions">
              <Checkbox
                checked={termsAccepted}
                id="terms"
                label="Hire this expert and agree to their terms"
                message="By accepting this proposal, you agree to pay the invoice(s) the expert submits to you through Kajabi Experts for this project."
                name="terms"
                value="I agree to this expert's terms and conditions"
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
            </div>
          )}
          <Button.Group
            className="quote-review-modal__actions"
            gap="sm"
          >
            <Button
              color="secondary"
              onClick={() => {
                quoteCancel(quote.id);
                onClose();
              }}
            >
              Decline
            </Button>
            <Button
              disabled={!termsAccepted && isProposal}
              onClick={() => quoteAccept(quote.id)}
            >
              {textButtonAccept}
            </Button>
          </Button.Group>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

QuoteReviewModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.number,
    quote_type: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({}).isRequired,
};
