import React, {
  useContext,
} from 'react';
import {
  Alert,
} from '@kajabi/sage-react';
import Breadcrumbs from '../shared/Breadcrumbs';
import Brief from './Brief';
import Details from './Details';
import Header from './Header';
import Message from './Message';
import MutedAlert from '../../../messages/index/chat-pane/MutedAlert';
import PlanUpgradeButton from '../../../shared/PlanUpgradeButton';
import Proposal from './Proposal';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import ProjectsShowContext from '../ProjectsShowContext';

export default function ViewVendorPrompt() {
  const activeProjectsLimit = window.entitlements.proposalCreate.limit;
  const prospectiveProjectsLimit = window.entitlements.messageCreate.limit;

  const {
    mutedAt,
    plan,
  } = useContext(CurrentUserContext);

  const {
    MESSAGE_CREATE,
    project,
    PROPOSAL_CREATE,
  } = useContext(ProjectsShowContext);

  const isMuted = (
    !!(mutedAt) && project.filtered_messages.length === 0 && project.filtered_quotes.length === 0
  );

  const userPlan = plan || '';

  const activeProjectsAlertDescription = {
    basic: 'If you’d like to increase the amount of active projects, you will need to upgrade your plan',
    growth: 'To send new proposals, you can complete, close, or archive older projects.',
  };
  const prospectiveProjectsAlertDescription = {
    basic: 'If you’d like to increase the amount of prospective projects, you will need to upgrade your plan',
    growth: 'To send new messages, you can decline or archive older projects.',
  };

  return (
    <div className="view-vendor-prompt">
      <div className="sage-row sage-row--align-space-between">
        <div className="sage-col-7">
          <div className="spacer">
            <Breadcrumbs />
            <Header />
            <Details />
            {isMuted && <MutedAlert />}
            {!isMuted && (
              <div className="view-vendor-prompt__messaging">
                <div className="view-vendor-prompt__message-create">
                  <p className="t-sage-heading-6 view-vendor-prompt__message-title">Send message</p>
                  <div className="view-vendor-prompt__message-create-content">
                    {!MESSAGE_CREATE && (
                    <Alert
                      actions={userPlan === 'basic' && <PlanUpgradeButton />}
                      className="view-vendor-prompt__alert"
                      color="info"
                      description={prospectiveProjectsAlertDescription[userPlan]}
                      title={`Your current plan allows you to have ${prospectiveProjectsLimit} prospective project${prospectiveProjectsLimit > 1 ? 's' : ''} at a time`}
                    />
                    )}
                    <Message />
                  </div>
                </div>
                <div className="view-vendor-prompt__proposal-create">
                  {!PROPOSAL_CREATE && (
                    <Alert
                      actions={userPlan === 'basic' && <PlanUpgradeButton />}
                      className="view-vendor-prompt__alert"
                      color="info"
                      description={activeProjectsAlertDescription[userPlan]}
                      title={`Your current plan allows you to have ${activeProjectsLimit} active project${activeProjectsLimit > 1 ? 's' : ''} at a time`}
                    />
                  )}
                  <Proposal />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sage-col-5">
          <Brief />
        </div>
      </div>
    </div>
  );
}
