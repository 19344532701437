import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import ActionsAdmin from './ActionsAdmin';
import ActionsLoggedOut from './ActionsLoggedOut';
import ActionsUser from './ActionsUser';
import ActionsVendor from './ActionsVendor';
import ActionsVendorMobile from './ActionsVendorMobile';
import Dropdown from '../user-dropdown/Dropdown';
import LogOut from './LogOut';
import ConfigContext from '../../../contexts/ConfigContext';
import SiteHeaderContext from './SiteHeaderContext';

export default function Actions({
  menuHeight,
}) {
  const {
    user,
    userSignedIn,
  } = useContext(ConfigContext);

  const {
    headerActionsRef,
  } = useContext(SiteHeaderContext);

  return (
    <div className="actions__sizer" style={{ height: menuHeight }}>
      <div className="actions" ref={headerActionsRef}>
        {userSignedIn ? (
          <>
            {user.type === 'user' && <ActionsUser />}
            {user.type === 'vendor' && <ActionsVendor />}
            {user.type === 'kjadmin' && <ActionsAdmin />}
            {user.type === 'kjowner' && <ActionsAdmin />}
            <div className="actions__subactions--mobile">
              {user.type === 'vendor' && <ActionsVendorMobile />}
              <LogOut />
            </div>
            <div className="actions__subactions--desktop">
              <Dropdown />
            </div>

          </>
        ) : (
          <ActionsLoggedOut />
        )}
      </div>
    </div>
  );
}

Actions.propTypes = {
  menuHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
