import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Hint,
  Icon,
  Link,
} from '@kajabi/sage-react';
import InputForm from './InputForm';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';

const isClosedProject = (project) => project.status === 'Closed';
const isCompleteProject = (project) => project.status === 'Complete';
const isMatchedByAnotherVendor = (project, user) => (user.user_type === 'vendor' && project.vendor_id !== null && project.vendor_id !== user.id);

const MESSAGES = {
  vendor: {
    unmatched: 'Messaging is no longer available because this client has accepted another Expert’s proposal.',
    closed: 'Messaging is no longer available because this client has closed this project.',
    complete: 'Messaging is no longer available because this client has marked this project complete.',
  },
  user: {
    unmatched: 'Messaging is no longer available with this expert because you selected another expert.',
    closed: 'This project has been closed. Reopen to send a message or start a new project.',
    complete: 'Messaging is no longer available because this project has been marked complete.',
  },
};

const closedConversationMessage = (project, { vendor, user }) => {
  if (isMatchedByAnotherVendor(project, vendor)) {
    return MESSAGES[user.type]?.unmatched;
  }
  if (isClosedProject(project)) {
    return MESSAGES[user.type]?.closed;
  }
  if (isCompleteProject(project)) {
    return MESSAGES[user.type]?.complete;
  }
  return null;
};

function SuspendedAlert({ user }) {
  const userType = user.user_type === 'vendor' ? 'Expert' : 'user';
  const text = (
    <>
      {`This ${userType} is no longer available. Contact`}
      {' '}
      <a href="mailto:experts@kajabi.com">experts@kajabi.com</a>
      {' '}
      with any questions.
    </>
  );
  return (
    <div className="status-alert">
      <Alert
        color={Alert.COLORS.DEFAULT}
        description={text}
        small
      />
    </div>
  );
}

SuspendedAlert.propTypes = {
  user: PropTypes.shape({
    user_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default function Input() {
  const {
    chatData,
  } = useContext(MessagesIndexContext);

  const user = useContext(CurrentUserContext);
  const {
    type: userType,
  } = user;

  const [project, setProject] = useState(null);
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    if (chatData) {
      setProject(chatData.project);
      setVendor(chatData.vendor);
    }
  }, [chatData]);

  if (!chatData) return false;
  if (!project) return false;
  if (!vendor) return false;

  const closedMessage = closedConversationMessage(project, { vendor, user });

  if (closedMessage) {
    const actions = userType === 'vendor' ? (
      <Link
        href="/projects/browse"
      >
        Browse projects
        <Icon
          adjacentType="body-small"
          icon="launch"
          size="sm"
        />
      </Link>
    ) : (
      <Link
        href="/projects/new"
      >
        Start a project
        <Icon
          adjacentType="body-small"
          icon="launch"
          size="sm"
        />
      </Link>
    );

    return (
      <div className="status-alert">
        <div className="status-alert__content">
          <Icon
            color="charcoal-200"
            icon="info-circle-filled"
          />
          <p className="status-alert__message">{closedMessage}</p>
          <div className="status-alert__actions">{actions}</div>
        </div>
      </div>
    );
  }

  if (vendor.suspended) return <SuspendedAlert user={vendor} />;
  if (project.user.suspended) return <SuspendedAlert user={project.user} />;

  return (
    <div className="input">
      <Hint
        content="For your protection, remember to keep conversations in the Kajabi Experts platform."
        icon="info-circle"
      />
      <InputForm />
    </div>
  );
}
