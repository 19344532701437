import React from 'react';
import MessageInput from './MessageInput';

export default function Message() {
  return (
    <section className="message">
      <MessageInput
        isDynamicSize={false}
        placeholder="Get to know your client and their project..."
      />
    </section>
  );
}
