/* eslint-disable no-console */
import React, {
  createElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import FormBreadcrumbs from './FormBreadcrumbs';
import FormDetails from './FormDetails';
import FormPreferences from './FormPreferences';
import FormProject from './FormProject';
import FormReview from './FormReview';
import ProjectFormContext from '../../../contexts/ProjectFormContext';
import ProjectNewContext from '../../../contexts/ProjectNewContext';
import projectPost from '../../../scripts/projectPost';
import scrollToTop from '../../../scripts/scrollToTop';

const FORMS = [FormProject, FormDetails, FormPreferences, FormReview];

export default function Form() {
  const [formStep, setFormStep] = useState(0);
  const [projectMainDetails, setProjectMainDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectPreferences, setProjectPreferences] = useState(null);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const {
    category,
    setNewProjectUrl,
    subcategory,
  } = useContext(ProjectNewContext);

  const submitProject = () => {
    const projectData = {
      categoryId: category.id,
      categorySlug: category.slug,
      categoryTitle: category.title,
      subcategoryId: subcategory.id,
      subcategorySlug: subcategory.slug,
      subcategoryTitle: subcategory.title,
      ...projectMainDetails,
      ...projectDetails,
      ...projectPreferences,
    };
    projectPost(projectData, (res) => {
      setNewProjectUrl(res.url);
      window.location.replace(res.url);
    });
  };

  const formData = useMemo(() => ({
    clickedSubmit,
    formStep,
    projectMainDetails,
    projectDetails,
    projectPreferences,
    scrollToTop,
    setClickedSubmit,
    setFormStep,
    setProjectDetails,
    setProjectMainDetails,
    setProjectPreferences,
    submitProject,
  }));

  return (
    <ProjectFormContext.Provider value={formData}>
      <section className="form">
        <div className="container">
          <FormBreadcrumbs />
          {createElement(FORMS[formStep])}
        </div>
      </section>
    </ProjectFormContext.Provider>
  );
}
