const regex = /calendly/;

export const checkSchedulingLink = (url) => {
  if (regex.test(url)) {
    return 'Schedule a meeting';
  }
  return url;
};

export const clickSchedulingLink = (evt, url) => {
  if (regex.test(url)) {
    evt.preventDefault();
    // eslint-disable-next-line no-undef
    Calendly.showPopupWidget(url);
  }
};
