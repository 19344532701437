/* eslint-disable import/prefer-default-export */
export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  website: '',
  location: 'United States',
  services: [],
  otherServices: '',
  languages: ['English'],
  templatesForSale: 'No',
  experience: '',
  typicalClients: [],
  averagePrice: '$5,000-$10,000',
  additionalInfo: '',
  pitch: '',
};
