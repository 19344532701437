const defaultParams = { maxLength: 30, gap: '...', position: 'middle' };

function truncateInMiddle(text, { maxLengthParam, gapParam }) {
  const startChars = (maxLengthParam - gapParam.length) / 2;
  const restStartIndex = text.length - startChars;

  const firstHalf = text.slice(0, startChars);
  const secondHalf = text.slice(restStartIndex);

  return `${firstHalf}${gapParam}${secondHalf}`;
}

function truncateInEnd(text, { maxLengthParam, gapParam }) {
  const firstHalf = text.slice(0, maxLengthParam - gapParam.length);

  return `${firstHalf}${gapParam}`;
}

export default function truncated(text, params = defaultParams) {
  if (!text) return '';

  const {
    gap: gapParam,
    maxLength: maxLengthParam,
    position: positionParam,
  } = { ...defaultParams, ...params };

  if (text.length <= maxLengthParam) return text;

  switch (positionParam) {
    case 'middle':
      return truncateInMiddle(text, { maxLengthParam, gapParam });
    case 'end':
      return truncateInEnd(text, { maxLengthParam, gapParam });
    default:
      throw new Error(`Unknown position param: ${positionParam}`);
  }
}
