import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

const TITLE = {
  user: 'You have outstanding invoices',
  vendor: 'No invoices have been accepted',
};

const DESCRIPTION = {
  user: 'In order to mark this project complete and submit a review, you must accept an invoice.',
  vendor: 'In order to mark this project complete and notify your client to submit a review, they must accept an invoice.',
};

const getText = (user, fromValues) => fromValues[user.type];

export default function OutstandingInvoiceQuoteModal({
  active,
  onClose,
}) {
  const user = useContext(CurrentUserContext);

  return (
    <Modal
      active={active}
      className="outstanding-invoice-quote-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title={getText(user, TITLE)}
      />
      <Modal.Body>
        <div className="outstanding-invoice-quote-modal__content">
          <p className="outstanding-invoice-quote-modal__message">{getText(user, DESCRIPTION)}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button onClick={onClose}>OK</Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

OutstandingInvoiceQuoteModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
