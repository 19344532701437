import React from 'react';
import Step from './Step';

export default function Steps() {
  return (
    <section className="section">
      <div className="container container--large">
        <p className="section__title t-sage-heading-2">Here’s how it works</p>
        <div className="sage-row sage-row--valign-center">
          <div className="sage-col-5">
            <div className="step__content">
              <Step
                description="Use our project creation wizard to to create a project brief for experts to respond to."
                title="Post your project"
                icon="circle-1"
              />
              <Step
                description="On average a project started on Kajabi Experts is completed in 2 weeks, ensuring you get results quickly."
                title="Select your Kajabi Expert"
                icon="circle-2"
              />
              <Step
                description="All proposals and invoices are handled in a streamlined interface designed to stay out of the way and ensure your success."
                title="Come to an agreement"
                icon="circle-3"
              />
              <Step
                description="Vetted Experts will begin reaching out. Choose a service provider who fits the needs of your business."
                title="Get real results"
                icon="circle-4"
              />
            </div>
          </div>
          <div className="sage-col-7">
            <img
              alt="How it works"
              className="step__image"
              src="images/how_it_works.jpg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
