import React, {
  useContext,
} from 'react';
import { Badge } from '@kajabi/sage-react';
import IconText from '../../../../shared/IconText';
import Avatar from '../../../../shared/Avatar';
import Rating from '../../../../shared/Rating';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function Bio() {
  const {
    userData,
  } = useContext(UsersShowContext);

  const {
    show_preferred_badge: showPreferredBadge,
  } = userData;

  const resolvedName = (userData.user_type === 'user' || userData.user_type === 'kjadmin')
    ? `${userData.given_name} ${userData.family_name[0]}.`
    : userData.name;

  return (
    <div className="bio">
      <div className="bio__avatar">
        <Avatar
          size={null}
          user={userData}
        />
      </div>
      <div className="bio__information">
        {showPreferredBadge && (
          <div className="bio__preferred">
            <Badge
              className="bio_preferred-badge"
              color="published"
              value={<IconText icon="check" text="Preferred" />}
            />
          </div>
        )}
        <p className="t-sage-heading-4 bio__user-name">{resolvedName}</p>
        {userData.company_name && userData.company_name !== '' && (
          <p className="bio__company-name">{userData.company_name}</p>
        )}
        <div className="bio__stats">
          <Rating
            count={userData.ratings_count}
            total={userData.ratings_total}
            showTotalText
          />
        </div>
      </div>
    </div>
  );
}
