import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Logo from '../Logo';
import { siteFooterData } from '../../../data/shared/siteFooterData';

function FooterList({ children, title }) {
  return (
    <div className="sage-col--sm-6 sage-col--md-3">
      <div className="site-footer__column">
        <p className="t-sage-heading-5 site-footer-list__title">{title}</p>
        <div className="site-footer__list">{children}</div>
      </div>
    </div>
  );
}

FooterList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

function FooterLink({ href, title }) {
  return (
    <a className="site-footer__link" href={href} rel="nofollow">
      {title}
    </a>
  );
}

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default function Footer() {
  return (
    <footer className="site-footer">
      <div className="container container--large">
        <div className="sage-row">
          {siteFooterData.sections.map((section) => (
            <FooterList key={uuid()} title={section.title}>
              {section.links.map((link) => (
                <FooterLink href={link.href} key={uuid()} title={link.title} />
              ))}
            </FooterList>
          ))}
        </div>
        <div className="site-footer__footer">
          <Logo color="white" />
          <FooterLink
            href="https://kajabi.com/policies"
            title="Policies, Terms of Service, and Income Disclosures"
          />
        </div>
      </div>
    </footer>
  );
}
