import React, {
  useContext,
} from 'react';
import IconText from '../../../shared/IconText';
import Location from '../../../shared/Location';
import Rating from '../../../shared/Rating';
import MessagesIndexContext from '../MessagesIndexContext';
import {
  checkSchedulingLink,
  clickSchedulingLink,
} from '../../../../scripts/schedulingLink';

export default function Details() {
  const {
    chatData,
  } = useContext(MessagesIndexContext);

  if (!chatData) return false;

  const {
    scheduling_link: schedulingLink,
  } = chatData.vendor;

  return (
    <section className="details">
      <Location location="United States" />
      {schedulingLink && (
        <IconText
          icon="calendar-schedule"
          onClick={(e) => clickSchedulingLink(e, schedulingLink)}
          text={checkSchedulingLink(schedulingLink)}
        />
      )}
      <IconText
        icon="draft"
        text={`${chatData.vendor.completed_project_count} projects completed`}
      />
      <Rating
        count={chatData.vendor.ratings_count}
        showTotal
        total={chatData.vendor.ratings_total}
      />
    </section>
  );
}
