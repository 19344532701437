export default function userServicesPost({
  callback = () => false,
  subcategories,
  userId,
}) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch(`/users/${userId}/services`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      subcategories,
    }),
  })
    .then((res) => res.text())
    .then(() => callback())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    });
}
