import React, {
  useContext,
} from 'react';
import {
  Link,
} from '@kajabi/sage-react';
import Messages from './Messages';
import Templates from './Templates';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ActionsVendor() {
  const {
    suspended,
  } = useContext(CurrentUserContext);

  if (suspended) return null;

  return (
    <>
      <Link
        href="/projects/browse"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Browse projects
      </Link>
      <Link
        href="/projects"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        My projects
      </Link>
      <Templates />
      <Messages />
    </>
  );
}
