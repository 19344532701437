import React, {
  useContext,
} from 'react';
import {
  Link,
} from '@kajabi/sage-react';
import ConfigContext from '../../../contexts/ConfigContext';

export default function ActionsVendorMobile() {
  const {
    user,
  } = useContext(ConfigContext);
  const initialPath = `${window.location.origin}/users/${user.id}`;

  return (
    <>
      <Link
        href={`${initialPath}/edit`}
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Edit profile
      </Link>
      <Link
        href={initialPath}
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        View profile
      </Link>
    </>
  );
}
