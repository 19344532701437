import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Icon,
} from '@kajabi/sage-react';
import ArchiveModal from './ArchiveModal';
import DeclineModal from './DeclineModal';
import isAnyQuoteAccepted from '../../../../scripts/isAnyQuoteAccepted.ts';
import declineProject from '../../../../scripts/declineProject';
import projectUpdate from '../../../../scripts/projectUpdate';
import undoDeclineProject from '../../../../scripts/undoDeclineProject';

const archiveProject = (project) => {
  projectUpdate({
    fetchPath: `/projects/${project.id}/archive`,
    project,
    redirectPath: '/projects?tab=archived',
  });
};

const unarchiveProject = (project) => {
  projectUpdate({
    fetchPath: `/projects/${project.id}/unarchive`,
    project,
    redirectPath: '/projects',
  });
};

const getItems = ({
  openArchiveModal,
  openDeclineModal,
  project
}) => {
  var items = [];

  if (project.status !== 'Open') { return []; }

  if (project.archived && project.vendor_id === null) {
    items.push({
      id: 'unarchiveProject',
      label: 'Unarchive project',
      icon: 'upload',
      onClick: () => unarchiveProject(project),
    });
  }

  if (project.refused && project.vendor_id === null && project.available_for_hire) {
    items.push({
      id: 'undoDeclineProject',
      label: 'Undo decline',
      icon: 'upload',
      onClick: () => undoDeclineProject(project.refusal_id),
    });
  }

  if (!isAnyQuoteAccepted(project) && !project.archived && !project.refused) {
    items.push({
      id: 'archiveProject',
      label: 'Archive project',
      icon: 'archive',
      onClick: openArchiveModal,
    });
    if (window.globalFeatureFlags.declinedProjects.enabled) {
      items.push({
        id: 'declineProject',
        label: 'Decline project',
        icon: 'trash',
        onClick: openDeclineModal,
      });
    }
  }

  return items;
};

export default function AdditionalActionsDropdown({
  project,
}) {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);
  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeDeclineModal = () => setIsDeclineModalOpen(false);
  const openDeclineModal = () => setIsDeclineModalOpen(true);

  const items = getItems({
    openArchiveModal,
    openDeclineModal,
    project
  });

  if (items.length === 0) {
    return <></>;
  }

  return (
    <>
      <Dropdown
        align="right"
        className="dropdown"
        customTrigger={(
          <div className="actions-dropdown__icon">
            <Icon
              circular
              icon="dot-menu-horizontal"
            />
          </div>
        )}
        label=""
      >
        <Dropdown.ItemList items={items} />
      </Dropdown>
      <ArchiveModal
        active={isArchiveModalOpen}
        onClose={closeArchiveModal}
        onConfirm={() => {
          archiveProject(project);
          closeArchiveModal();
        }}
      />
      <DeclineModal
        active={isDeclineModalOpen}
        onClose={closeDeclineModal}
        onConfirm={() => {
          declineProject(project.id);
          closeDeclineModal();
        }}
      />
    </>
  );
}

AdditionalActionsDropdown.propTypes = {
  project: PropTypes.shape({
    archived: PropTypes.bool,
    status: PropTypes.string,
    vendor_id: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};
