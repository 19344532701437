/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import {
  validationMaximumCharacters,
  validationMinimumCharacters,
  validationStringRequired,
} from '../shared/validations';

export const sharedBusinessStage = {
  id: 'businessStage',
  initialValue: 'Planning to launch',
  validation: Yup.string(),
  options: [
    'Planning to launch',
    'Gaining momentum after launching',
    'Expanding or scaling my business',
    'Optimizing my business',
  ],
  prompt: 'What stage are you at with your business?',
  promptBrief: 'Stage',
  type: 'radio',
};

export const sharedDeliveryTime = {
  id: 'deliveryTime',
  initialValue: 'I’m flexible',
  validation: Yup.string(),
  options: [
    'ASAP',
    'A week or less',
    '2-3 weeks',
    'A month',
    'A few months',
    'I’m flexible',
  ],
  prompt: 'What kind of delivery time are you looking for?',
  promptBrief: 'Desired delivery time',
  type: 'radio',
};

export const sharedDescription = {
  id: 'description',
  initialValue: '',
  validation: Yup.string()
    .required(validationStringRequired)
    .max(5000, validationMaximumCharacters(5000))
    .min(100, validationMinimumCharacters(100)),
  placeholder: 'Enter a description...',
  promptDefault: 'Add any details that will help the expert understand what you need.',
  promptBrief: 'Description',
  type: 'textarea',
};
