import React from 'react';
import {
  Dropdown,
  Icon,
  Link,
} from '@kajabi/sage-react';

export default function Directory() {
  const items = [
    {
      id: 'website',
      label: 'Website setup & design',
      href: '/directory?type=expert&services=site-design%3Bsite-setup%3Bsite-development%3Bcontent-migration%3Bseo',
    },
    {
      id: 'marketing',
      label: 'Marketing & sales',
      href: '/directory?type=expert&services=sales-funnels%3Badvertising%3Bmarketing-consulting%3Bemail-marketing',
    },
    {
      id: 'admin',
      label: 'Admin support',
      href: '/directory?type=expert&services=social-media%3Bva-services%3Baccounting%3Bbusiness-consulting%3Btranscription',
    },
    {
      id: 'content',
      label: 'Content development',
      href: '/directory?type=expert&services=content-creation%3Bvideo-editing%3Bcopywriting-editing%3Bpodcast-audio',
    },
    {
      id: 'visual',
      label: 'Visual design and branding',
      href: '/directory?type=expert&services=branding-logo%3Bebook-document',
    },
    {
      id: 'coaching',
      label: 'Coaching',
      href: '/directory?type=expert&services=implementation%3Bbusiness%3Bsales-marketing%3Bhigh-ticket%3Bnew-business',
    },
  ];

  return (
    <section className="directory">
      <div className="directory__content directory__content--desktop">
        <Dropdown
          className="dropdown directory-dropdown"
          label={(
            <div className="directory-dropdown__trigger">
              Expert Directory
              <Icon
                adjacentType="body"
                icon="caret-down"
              />
            </div>
      )}
          triggerButtonSubtle
        >
          <Dropdown.ItemList
            items={items}
          />
        </Dropdown>
      </div>
      <div className="directory__content directory__content--mobile">
        <Link
          href="https://experts.kajabi.com/directory"
          removeUnderline
          style={Link.COLORS.SECONDARY}
        >
          Expert Directory
        </Link>
      </div>
    </section>
  );
}
