import React, {
  useContext,
} from 'react';
import {
  Dropdown as SageDropdown,
} from '@kajabi/sage-react';
import Avatar from '../Avatar';
import DropdownUser from './DropdownUser';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function Dropdown() {
  const user = useContext(CurrentUserContext);

  const clientItems = [
    {
      borderBefore: true,
      icon: 'user-star',
      id: 'apply',
      label: 'Become a Kajabi Expert',
      onClick: () => { window.location.replace('/apply'); },
    },
  ];

  const vendorItems = [
    {
      borderBefore: true,
      icon: 'pen',
      id: 'edit',
      label: 'Edit profile',
      onClick: () => { window.location.replace(`/users/${user.id}/edit`); },
    },
  ];

  const sharedItems = [
    {
      borderBefore: true,
      icon: 'move-right',
      id: 'logOut',
      label: 'Log out',
      onClick: () => { window.location.replace('/auth/logout'); },
    },
  ];

  function getFinalItems() {
    let finalItems = [];
    if (user.type === 'user') finalItems = [...clientItems, ...sharedItems];
    if (user.type === 'vendor') finalItems = [...vendorItems, ...sharedItems];
    if (user.type === 'kjadmin') finalItems = [...sharedItems];
    if (user.type === 'kjowner') finalItems = [...sharedItems];
    return finalItems;
  }

  return (
    <SageDropdown
      align="right"
      className="dropdown"
      customTrigger={(
        <div className="dropdown__avatar">
          <Avatar user={user} />
        </div>
      )}
      label=""
    >
      <>
        <div className="dropdown__header">
          <DropdownUser />
        </div>
        <SageDropdown.ItemList items={getFinalItems()} />
      </>
    </SageDropdown>
  );
}
