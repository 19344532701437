import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination as SagePagination,
} from '@kajabi/sage-react';

export default function Pagination({
  align,
  currentPage,
  hideCounter,
  itemsNoun,
  itemsPerPage,
  itemsTotal,
  onClickPage,
}) {
  return (
    <section className="pagination">
      <SagePagination
        align={align}
        currentPage={currentPage}
        hideCounter={hideCounter}
        itemsNoun={itemsNoun}
        itemsTotalCount={itemsTotal}
        pageCount={Math.ceil(itemsTotal / itemsPerPage)}
        pageSize={itemsPerPage}
        onClickPage={onClickPage}
      />
    </section>
  );
}

Pagination.defaultProps = {
  align: 'center',
  hideCounter: true,
  itemsNoun: 'Project',
};

Pagination.propTypes = {
  align: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  hideCounter: PropTypes.bool,
  itemsNoun: PropTypes.string,
  itemsPerPage: PropTypes.number.isRequired,
  itemsTotal: PropTypes.number.isRequired,
  onClickPage: PropTypes.func.isRequired,
};
