import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useSearchParams,
} from 'react-router-dom';
import {
  Alert,
  Button,
} from '@kajabi/sage-react';
import MessagingUnvailableModal from '../modals/MessagingUnavailableModal';
import NewProjectMessageModal from '../modals/NewProjectMessageModal';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import UsersShowContext from '../../../../../contexts/UsersShowContext';
import AppContext from '../../../../../AppContext';

export default function Hire() {
  const user = useContext(CurrentUserContext) || {};
  const {
    userData,
    isHireTab,
    setIsHireTab,
  } = useContext(UsersShowContext);
  const {
    id: userId,
  } = user;
  const {
    loginRef,
  } = useContext(AppContext);

  if (userData.id === user.id) return false;
  if (userData.user_type === 'user') return false;

  const [contactExpertUnavailableModalOpen, setContactExpertUnavailableModalOpen] = useState(false);
  const [newProjectMessageModalOpen, setNewProjectMessageModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleContactExpert = useCallback(() => {
    if (userId) {
      const { isClient } = user;
      if (isClient) {
        setNewProjectMessageModalOpen(true);
      } else {
        setContactExpertUnavailableModalOpen(true);
      }
    } else {
      setSearchParams({ openModal: 'contact-expert' });
      loginRef.current.click();
    }
  });

  const handleCloseNewProject = () => {
    setNewProjectMessageModalOpen(false);
    if (isHireTab) setIsHireTab(false);
  };

  useEffect(() => {
    if (isHireTab) handleContactExpert();
    if (searchParams.get('openModal') === 'contact-expert' && userId) {
      const { isClient } = user;
      if (isClient) setNewProjectMessageModalOpen(true);
      else setContactExpertUnavailableModalOpen(true);
    }
  }, []);

  return (
    <>
      <div className="hire">
        {!userData.available_for_chat && (
          <Alert
            className="hire__alert"
            color="warning"
            description="This expert is currently unable to accept new projects."
          />
        )}
        <Button
          className="hire__button"
          color={!userData.available_for_chat ? 'secondary' : 'accent'}
          disabled={!userData.available_for_chat}
          onClick={handleContactExpert}
        >
          Chat about your project
        </Button>
      </div>
      <MessagingUnvailableModal
        active={contactExpertUnavailableModalOpen}
        onClose={() => setContactExpertUnavailableModalOpen(false)}
      />
      <NewProjectMessageModal
        active={newProjectMessageModalOpen}
        onClose={() => handleCloseNewProject()}
      />
    </>
  );
}
