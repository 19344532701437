import React, {
  useContext,
} from 'react';
import ButtonProxy from '../../shared/ButtonProxy';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function Header() {
  const user = useContext(CurrentUserContext);

  return (
    <div className="header">
      <h1 className="t-sage-heading-1 header__title">Projects</h1>
      {user.type === 'user' && (
        <ButtonProxy
          to="/projects/new"
        >
          Create a project
        </ButtonProxy>
      )}
    </div>
  );
}
