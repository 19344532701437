import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSearchParams,
} from 'react-router-dom';
import ExpertsFiltersDrawer from './ExpertsFiltersDrawer';
import ExpertsFiltersTags from './ExpertsFiltersTags';
import ExpertsList from './ExpertsList';
import Header from './Header';
import Subheader from './Subheader';
import ExpertsDirectoryIndexContext from './ExpertsDirectoryIndexContext';

const PER_PAGE = 60;

export default function ExpertsDirectoryIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const services = searchParams.get('services');
  const pageParam = searchParams.get('page');
  const planParam = searchParams.get('plan');
  const languageParam = searchParams.get('language');
  const locationParam = searchParams.get('location');
  const nameParam = searchParams.get('name');

  const preferredParam = searchParams.get('preferred');

  const [experts, setExperts] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState('recommended');
  const [preferred, setPreferred] = useState(Boolean(preferredParam));
  const [categoriesFilter, setCategoriesFilter] = useState(services ? services.split(';') : []);
  const [nameFilter, setNameFilter] = useState(nameParam || '');
  const [locationFilter, setLocationFilter] = useState(locationParam || '');
  const [languageFilter, setLanguageFilter] = useState(languageParam || '');
  const [pageNumber, setPageNumber] = useState(pageParam ? Number(pageParam) : 1);
  const [loading, setLoading] = useState(true);
  const [isFilterDrawerActive, setIsFilterDrawerActive] = useState(false);

  const fetchExperts = useCallback(() => {
    let filters = `sort_by=${sortBy}&page=${pageNumber}&per_page=${PER_PAGE}`;
    if (categoriesFilter.length > 0) filters += `&services=${categoriesFilter.join(';')}`;
    if (preferred) filters += '&preferred=true';
    if (planParam) filters += `&plan=${planParam}`;
    if (nameFilter) filters += `&name=${nameFilter}`;
    if (locationFilter) filters += `&location=${locationFilter}`;
    if (languageFilter) filters += `&language=${languageFilter}`;
    setLoading(true);
    fetch(`${window.location.origin}/directory.json?${filters}`)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        setExperts(data.result);
        setTotal(data.total);
      })
      .catch(() => setLoading(false));
  });

  const updateCategoriesFilter = useCallback((newList) => {
    setCategoriesFilter(newList);
    setPageNumber(1);
  });

  const updateNameFilter = useCallback((newName) => {
    setNameFilter(newName);
    setPageNumber(1);
  });

  const updateLocationFilter = useCallback((newLocation) => {
    setLocationFilter(newLocation);
    setPageNumber(1);
  });

  const updateLanguageFilter = useCallback((newLanguage) => {
    setLanguageFilter(newLanguage);
    setPageNumber(1);
  });

  const removeCategory = useCallback((category) => {
    updateCategoriesFilter(categoriesFilter.filter((cat) => cat !== category));
  });

  const addCategory = useCallback((category) => {
    updateCategoriesFilter([...categoriesFilter, category]);
  });

  useEffect(() => {
    const servicesParam = categoriesFilter.join(';');
    const params = {};
    if (preferred) params.preferred = true;
    if (pageNumber > 1) params.page = pageNumber;
    if (planParam) params.plan = planParam;
    if (nameFilter) params.name = nameFilter;
    if (locationFilter) params.location = locationFilter;
    if (languageFilter) params.language = languageFilter;
    if (servicesParam.length > 0) setSearchParams({ ...params, type: 'expert', services: servicesParam });
    else setSearchParams(params);
    fetchExperts();
  }, [sortBy, categoriesFilter, pageNumber, preferred, nameFilter, locationFilter, languageFilter]);

  const expertsDirectoryIndexData = useMemo(() => ({
    addCategory,
    categoriesFilter,
    experts,
    isFilterDrawerActive,
    languageFilter,
    loading,
    locationFilter,
    nameFilter,
    pageNumber,
    preferred,
    removeCategory,
    setIsFilterDrawerActive,
    setPageNumber,
    setPreferred,
    setSortBy,
    sortBy,
    total,
    updateCategoriesFilter,
    updateLanguageFilter,
    updateLocationFilter,
    updateNameFilter,
  }));

  return (
    <ExpertsDirectoryIndexContext.Provider value={expertsDirectoryIndexData}>
      <section className="section section--xs">
        <div className="container container--large">
          <Header />
          <Subheader />
          <ExpertsFiltersTags />
          <ExpertsList />
          <ExpertsFiltersDrawer />
        </div>
      </section>
    </ExpertsDirectoryIndexContext.Provider>
  );
}
