import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';

export default function Feature({
  description,
  icon,
  title,
}) {
  return (
    <div className="feature">
      <Icon
        backgroundHeight="48px"
        backgroundWidth="48px"
        cardColor="draft"
        icon={icon}
        size="xl"
      />
      <div className="feature__text">
        <p className="t-sage-heading-5">{title}</p>
        <p className="t-sage-body">{description}</p>
      </div>
    </div>
  );
}

Feature.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
