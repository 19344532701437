export const validationCheckboxMinimumOneSelected = 'Check at least one option';
export const validationInvalidEmail = 'Invalid email address';
export const validationInvalidWebsite = 'Invalid website';
export const validationMaximumCharacters = (count) => `Maximum of ${count} characters`;
export const validationMinimumCharacters = (count) => `Minimum of ${count} characters`;
export const validationStringLowercase = 'Must be lowercase';
export const validationStringRequired = 'Required';
export const validationTypeNumber = 'Must be a number';
export const validationURLRegEx = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const validations = {
  matchingInvalidEmail: 'Invalid email address',
  matchingInvalidSlug: 'Only lowercase letters, numbers, and underscores are allowed',
  matchingInvalidWebsite: 'Invalid website',
  matchingRegExSlug: /^[a-z0-9_]*$/,
  matchingRegExURL: /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  numberMinimum: (num) => `Minimum value is ${num}`,
  numberMaximum: (num) => `Maximum value is ${num}`,
  numberRequired: 'Required',
  numberValueMaximum: (num) => `Amount must be equal to or less than ${num}`,
  sizeCheckboxMinimumOne: 'Check at least one option',
  sizeStringMaximum: (count) => `Maximum of ${count} characters`,
  sizeStringMinimum: (count) => `Minimum of ${count} characters`,
  stringLowercase: 'Must be lowercase',
  stringRequired: 'Required',
  typeNumber: 'Must be a number',
};

export default validations;
