/* eslint-disable import/prefer-default-export */

export const categories = [
  {
    active: true,
    id: 'websiteSetupAndDesign',
    slug: 'website-setup-design',
    title: 'Website setup and design',
    description: 'Create a beautiful website for your business that converts, with custom design and development, expert guidance and migration assistance.',
    subcategories: [
      {
        active: true,
        id: 'websiteLandingPageDesign',
        slug: 'site-design',
        title: 'Website and landing page design',
        description: 'Customize the design of your website and landing pages.',
      },
      {
        active: true,
        id: 'expertSiteSetup',
        slug: 'site-setup',
        title: 'Expert Kajabi site setup and guidance',
        description: 'Get an audit of your website, offers and automations, and recommended actions for improving your online business setup.',
      },
      {
        active: true,
        id: 'customCoding',
        slug: 'site-development',
        title: 'Custom coding, website development and troubleshooting',
        description: 'Add features and functionality to your website that require custom code, a custom-built app, or connecting to other systems.',
      },
      {
        active: true,
        id: 'contentMigration',
        slug: 'content-migration',
        title: 'Content migration',
        description: 'Move your existing website, blog, community or course to Kajabi.',
      },
      {
        active: true,
        id: 'searchEngineOptimization',
        slug: 'seo',
        title: 'Search engine optimization',
        description: 'Improve your ranking on search engines like Google so customers find your offerings when searching for related topics.',
      },
    ],
  },
  {
    active: true,
    id: 'marketingAndSales',
    slug: 'marketing-sales',
    title: 'Marketing and sales',
    description: 'Grow your audience and drive sales through effective marketing techniques and proven funnels.',
    subcategories: [
      {
        active: true,
        id: 'salesFunnels',
        slug: 'sales-funnels',
        title: 'Sales funnels and lead magnets',
        description: 'Get expert help setting up effective lead magnets and sales funnels.',
      },
      {
        active: true,
        id: 'advertising',
        slug: 'advertising',
        title: 'Advertising',
        description: 'Get expert help setting up effective ad campaigns.',
      },
      {
        active: true,
        id: 'marketingAndLaunchStrategy',
        slug: 'marketing-consulting',
        title: 'Marketing and launch strategy/consulting',
        description: 'Consult with a Kajabi expert and get detailed strategies for a successful launch.',
      },
      {
        active: true,
        id: 'emailMarketing',
        slug: 'email-marketing',
        title: 'Email marketing and automation',
        description: 'Convert potential leads and recapture missed sales using email automation and targeted email campaigns.',
      },
    ],
  },
  {
    active: true,
    id: 'adminSupport',
    slug: 'admin-support',
    title: 'Admin support',
    description: 'Hire experts to help with social media management, bookkeeping, business strategy, transcription and backoffice tasks.',
    subcategories: [
      {
        active: true,
        id: 'virtualAssistant',
        slug: 'va-services',
        title: 'Virtual assistant services',
        description: 'Get help with day-to-day business tasks from a vetted Kajabi expert.',
      },
      {
        active: true,
        id: 'socialMedia',
        slug: 'social-media',
        title: 'Social media management',
        description: 'Promote your brand and offers on social media platforms by creating shareable content and running ad campaigns.',
      },
      {
        active: true,
        id: 'accounting',
        slug: 'accounting',
        title: 'Accounting',
        description: 'Get accounting and bookkeeping help for your business from a vetted Kajabi expert.',
      },
      {
        active: true,
        id: 'businessStrategy',
        slug: 'business-consulting',
        title: 'Business strategy guidance',
        description: 'Get feedback and guidance for building or growing your business from trusted and experienced business consultants.',
      },
      {
        active: true,
        id: 'transcription',
        slug: 'transcription',
        title: 'Transcription and translation',
        description: 'Have your content transcribed or translated into different languages.',
      },
    ],
  },
  {
    active: true,
    id: 'contentDevelopment',
    slug: 'content-development',
    title: 'Content development',
    description: 'Optimize your content strategy with professionally produced video and audio, course development expertise and effective sales copy. ',
    subcategories: [
      {
        active: true,
        id: 'contentCreation',
        slug: 'content-creation',
        title: 'Content creation/strategy',
        description: 'Work with a Kajabi expert to create professional, engaging content for your customers.',
      },
      {
        active: true,
        id: 'videoEditing',
        slug: 'video-editing',
        title: 'Video editing',
        description: 'Work with a Kajabi expert to create professional, engaging videos.',
      },
      {
        active: true,
        id: 'copywriting',
        slug: 'copywriting-editing',
        title: 'Copywriting or editing',
        description: 'Engage customers with professionally written content that pulls visitors in and converts them.',
      },
      {
        active: true,
        id: 'podcasting',
        slug: 'podcast-audio',
        title: 'Podcast/audio editing',
        description: 'Engage customers with professionally produced audio content.',
      },
    ],
  },
  {
    active: true,
    id: 'visualDesignAndBranding',
    slug: 'visual-design-branding',
    title: 'Visual design and branding',
    description: 'Increase your business’s visual impact through graphical elements that reflect your brand.',
    subcategories: [
      {
        active: true,
        id: 'branding',
        slug: 'branding-logo',
        title: 'Branding and logo design',
        description: 'Create visual assets that speak to your brand identity and make your business stand out.',
      },
      {
        active: true,
        id: 'ebooks',
        slug: 'ebook-document',
        title: 'eBook and document design',
        description: 'Create beautifully designed digital content to offer as downloads.',
      },
    ],
  },
  {
    active: true,
    id: 'coaching',
    slug: 'coaching',
    title: 'Coaching',
    description: 'Discover top coaches who work with you to build and scale your online business.',
    subcategories: [
      {
        active: true,
        id: 'implementation',
        slug: 'implementation',
        title: 'Kajabi implementation coaching',
        description: 'Work with a coach to launch on Kajabi faster.',
      },
      {
        active: true,
        id: 'business',
        slug: 'business',
        title: 'Business coaching',
        description: 'Boost your revenue with coaches who specialize in growth, scaling and online business.',
      },
      {
        active: true,
        id: 'salesMarketing',
        slug: 'sales-marketing',
        title: 'Sales & marketing coaching',
        description: 'Grow your audience and your business with the help of an expert coach.',
      },
      {
        active: true,
        id: 'highTicket',
        slug: 'high-ticket',
        title: 'High ticket coaching',
        description: 'Your custom business deserves custom coaching. Explore our experienced coaches.',
      },
      {
        active: true,
        id: 'newBusiness',
        slug: 'new-business',
        title: 'New business coaching',
        description: 'Jump start your business with guidance from a skilled coach.',
      },
    ],
  },
];
