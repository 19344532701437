import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '../../../shared/Avatar';
import ConversationsShowContext from '../ConversationsShowContext';
import timeAgo from '../../../../scripts/timeAgo';

export default function InboxPaneMessage({
  vendor,
}) {
  const {
    changeVendor,
    project,
    vendorId,
  } = useContext(ConversationsShowContext);
  const {
    message,
  } = vendor;

  const classNames = classnames('inbox-pane-message', {
    'inbox-pane-message--active': vendorId === vendor.id,
  });

  const quote = !message.content
    ? project.filtered_quotes.find((q) => q.id === message.quote_id)
    : null;
  let messageString = message.content;
  if (quote) {
    if (quote.quote_type === 'proposal') messageString = 'Sent a proposal';
    else messageString = 'Sent an invoice';
  }

  return (
    <div
      className={classNames}
      onClick={() => {
        if (vendor.id !== vendorId) changeVendor(vendor);
      }}
      role="presentation"
    >
      <div className="inbox-pane-message__indicator" />
      <div className="inbox-pane-message__content">
        <div className="inbox-pane-message__bio">
          <Avatar
            size="32"
            user={vendor}
          />
          <p className="t-sage-heading-6 inbox-pane-message__name">{vendor.name}</p>
          <p className="t-sage-body-xsmall inbox-pane-message__timestamp">{timeAgo(message.created_at)}</p>
        </div>
        <div className="inbox-pane-message__message">
          {messageString}
        </div>
      </div>
    </div>
  );
}

InboxPaneMessage.propTypes = {
  vendor: PropTypes.shape({
    avatar: PropTypes.string,
    id: PropTypes.number,
    message: PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      quote_id: PropTypes.number,
    }),
    name: PropTypes.string,
    picture: PropTypes.string,
    quote: PropTypes.shape({

    }),
  }).isRequired,
};
