import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import GraphicCallToAction from './GraphicCallToAction';

export default function CallToAction() {
  return (
    <section className="cta">
      <div className="cta__content">
        <div className="cta__graphic">
          <GraphicCallToAction />
        </div>
        <div className="cta__body">
          <div className="cta__text">
            <p className="t-sage-heading-5 cta__title">New to Kajabi?</p>
            <p className="t-sage-body-small cta__desc">Start your free trial to work with this vetted Kajabi Expert.</p>
          </div>
          <div className="cta__actions">
            <Button
              color="secondary"
              href="https://kajabi.com/pricing"
              fullWidth
            >
              Let’s go!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
