import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import Location from './Location';
import Rating from './Rating';

export default function QuoteBio({
  vendor,
}) {
  const linkToProfile = () => {
    window.location.replace(`${window.location.origin}/users/${vendor.id}`);
  };

  return (
    <div className="quote-bio">
      <div
        className="quote-bio__avatar"
        onClick={linkToProfile}
        role="presentation"
      >
        <Avatar
          size="64"
          user={vendor}
        />
      </div>
      <div className="quote-bio__text">
        <p
          className="t-sage-heading-6 quote-bio__name"
          onClick={linkToProfile}
          role="presentation"
        >
          {vendor.name}
        </p>
        <div className="quote-bio__context">
          <Location location={vendor.location} />
          <Rating
            count={vendor.ratings_count}
            total={vendor.ratings_total}
          />
        </div>
      </div>
    </div>
  );
}

QuoteBio.propTypes = {
  vendor: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.string,
    name: PropTypes.string,
    ratings_count: PropTypes.number,
    ratings_total: PropTypes.number,
  }).isRequired,
};
