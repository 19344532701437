import React from 'react';
import FormQuestionnaire from './FormQuestionnaire';

export default function FormPreferences() {
  return (
    <FormQuestionnaire
      subcategory="projectPreferences"
      subtitle="Help the expert determine if they’re a good fit for the project."
      title="Your preferences"
    />
  );
}
