import React from 'react';

export default function GraphicProjectStatusActive() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5409_8600)">
        <g clipPath="url(#clip1_5409_8600)">
          <path d="M-0.56543 52.9301C-0.56543 24.7934 28.106 1.69568 56.3021 1.69568C84.4982 1.69568 101.174 17.561 101.174 45.6977C101.174 73.8344 73.1485 102.408 44.9449 102.408C16.7414 102.408 -0.56543 81.0668 -0.56543 52.9301Z" fill="#DCF0FF" />
          <path d="M40.4083 61.0856V77.7854L14.623 64.8357V48.3188L40.4083 61.0856Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M91.7571 35.3691L40.4082 61.078V77.7777L91.7571 52.0689V35.3691Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.623 48.3188L40.4083 61.078L91.7571 35.3691L65.758 22.7929L14.623 48.3188Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.5116 41.9393C24.4251 41.9393 21.9268 43.2807 21.9268 44.9423V48.3188C21.9268 49.9804 24.4251 51.3219 27.5116 51.3219C30.5982 51.3219 33.0965 49.9804 33.0965 48.3188V44.9423C33.1042 43.2807 30.5982 41.9393 27.5116 41.9393Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.5116 47.9454C30.5961 47.9454 33.0965 46.6009 33.0965 44.9423C33.0965 43.2838 30.5961 41.9393 27.5116 41.9393C24.4272 41.9393 21.9268 43.2838 21.9268 44.9423C21.9268 46.6009 24.4272 47.9454 27.5116 47.9454Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M39.9804 35.5597C36.8938 35.5597 34.3955 36.9012 34.3955 38.5627V41.9393C34.3955 43.6009 36.8938 44.9423 39.9804 44.9423C43.067 44.9423 45.5653 43.6009 45.5653 41.9393V38.5627C45.5653 36.9012 43.0593 35.5597 39.9804 35.5597Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M39.9804 41.5658C43.0648 41.5658 45.5653 40.2213 45.5653 38.5627C45.5653 36.9042 43.0648 35.5597 39.9804 35.5597C36.8959 35.5597 34.3955 36.9042 34.3955 38.5627C34.3955 40.2213 36.8959 41.5658 39.9804 41.5658Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M53.2973 29.1801C50.2107 29.1801 47.7124 30.5216 47.7124 32.1832V35.5597C47.7124 37.2213 50.2107 38.5628 53.2973 38.5628C56.3839 38.5628 58.8822 37.2213 58.8822 35.5597V32.1832C58.8822 30.5216 56.3839 29.1801 53.2973 29.1801Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M53.2973 35.1862C56.3817 35.1862 58.8822 33.8417 58.8822 32.1832C58.8822 30.5246 56.3817 29.1801 53.2973 29.1801C50.2128 29.1801 47.7124 30.5246 47.7124 32.1832C47.7124 33.8417 50.2128 35.1862 53.2973 35.1862Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M66.1933 22.4194C63.1067 22.4194 60.6084 23.7609 60.6084 25.4225V28.799C60.6084 30.4606 63.1067 31.8021 66.1933 31.8021C69.2799 31.8021 71.7782 30.4606 71.7782 28.799V25.4225C71.7782 23.7685 69.2722 22.4194 66.1933 22.4194Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M66.1933 28.4255C69.2777 28.4255 71.7782 27.081 71.7782 25.4225C71.7782 23.7639 69.2777 22.4194 66.1933 22.4194C63.1088 22.4194 60.6084 23.7639 60.6084 25.4225C60.6084 27.081 63.1088 28.4255 66.1933 28.4255Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M40.4081 48.3188C37.3215 48.3188 34.8232 49.6603 34.8232 51.3219V54.6984C34.8232 56.36 37.3215 57.7015 40.4081 57.7015C43.4947 57.7015 45.993 56.36 45.993 54.6984V51.3219C45.993 49.6679 43.4947 48.3188 40.4081 48.3188Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M40.4081 54.325C43.4926 54.325 45.993 52.9804 45.993 51.3219C45.993 49.6634 43.4926 48.3188 40.4081 48.3188C37.3237 48.3188 34.8232 49.6634 34.8232 51.3219C34.8232 52.9804 37.3237 54.325 40.4081 54.325Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M52.8691 41.9393C49.7825 41.9393 47.2842 43.2807 47.2842 44.9423V48.3188C47.2842 49.9804 49.7825 51.3219 52.8691 51.3219C55.9557 51.3219 58.454 49.9804 58.454 48.3188V44.9423C58.454 43.2807 55.9557 41.9393 52.8691 41.9393Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M52.8691 47.9454C55.9535 47.9454 58.454 46.6009 58.454 44.9423C58.454 43.2838 55.9535 41.9393 52.8691 41.9393C49.7846 41.9393 47.2842 43.2838 47.2842 44.9423C47.2842 46.6009 49.7846 47.9454 52.8691 47.9454Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M66.1933 35.5597C63.1067 35.5597 60.6084 36.9012 60.6084 38.5627V41.9393C60.6084 43.6009 63.1067 44.9423 66.1933 44.9423C69.2799 44.9423 71.7782 43.6009 71.7782 41.9393V38.5627C71.7782 36.9012 69.2722 35.5597 66.1933 35.5597Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M66.1933 41.5658C69.2777 41.5658 71.7782 40.2213 71.7782 38.5627C71.7782 36.9042 69.2777 35.5597 66.1933 35.5597C63.1088 35.5597 60.6084 36.9042 60.6084 38.5627C60.6084 40.2213 63.1088 41.5658 66.1933 41.5658Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M79.0824 28.8066C75.9959 28.8066 73.4976 30.1481 73.4976 31.8097V35.1862C73.4976 36.8478 75.9959 38.1893 79.0824 38.1893C82.169 38.1893 84.6673 36.8478 84.6673 35.1862V31.8097C84.6673 30.1481 82.169 28.8066 79.0824 28.8066Z" fill="#EFB484" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M79.0824 34.8051C82.1669 34.8051 84.6673 33.4606 84.6673 31.8021C84.6673 30.1435 82.1669 28.799 79.0824 28.799C75.998 28.799 73.4976 30.1435 73.4976 31.8021C73.4976 33.4606 75.998 34.8051 79.0824 34.8051Z" fill="#FFF1E6" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M40.3091 45.1634V61.8631L53.4194 54.9195V38.4027L40.3091 45.1634Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.6387 32.0231L40.3169 45.1634V61.8631L14.6387 48.7228V32.0231Z" fill="#07265F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M53.4195 38.4027L40.3091 45.1558L14.6309 32.0155L27.9551 25.4454L53.4195 38.4027Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M40.309 32.3966C43.3956 32.3966 45.8939 33.738 45.8939 35.3996V38.7762C45.8939 40.4377 43.3956 41.7792 40.309 41.7792C37.2224 41.7792 34.7241 40.4377 34.7241 38.7762V35.3996C34.7241 33.7457 37.2301 32.3966 40.309 32.3966Z" fill="#07265F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M40.309 38.4027C43.3935 38.4027 45.8939 37.0582 45.8939 35.3996C45.8939 33.7411 43.3935 32.3966 40.309 32.3966C37.2246 32.3966 34.7241 33.7411 34.7241 35.3996C34.7241 37.0582 37.2246 38.4027 40.309 38.4027Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.8481 26.017C30.9347 26.017 33.433 27.3585 33.433 29.0201V32.3966C33.433 34.0582 30.9347 35.3996 27.8481 35.3996C24.7615 35.3996 22.2632 34.0582 22.2632 32.3966V29.0201C22.2632 27.3585 24.7615 26.017 27.8481 26.017Z" fill="#07265F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.8481 32.0231C30.9325 32.0231 33.433 30.6786 33.433 29.0201C33.433 27.3615 30.9325 26.017 27.8481 26.017C24.7636 26.017 22.2632 27.3615 22.2632 29.0201C22.2632 30.6786 24.7636 32.0231 27.8481 32.0231Z" fill="#0072EF" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5409_8600">
          <rect width="104" height="104" fill="white" />
        </clipPath>
        <clipPath id="clip1_5409_8600">
          <rect width="104.646" height="101.739" fill="white" transform="translate(-0.56543 1.13043)" />
        </clipPath>
      </defs>
    </svg>
  );
}
