import React, {
  useContext,
} from 'react';
import Linkifier from '../../../shared/Linkifier';
import SkeletonLoader from '../../../shared/SkeletonLoader';
import ProjectsShowContext from '../ProjectsShowContext';

export default function Details() {
  const {
    brief,
  } = useContext(ProjectsShowContext);

  if (brief) {
    return (
      <section className="details">
        <Linkifier>
          {brief}
        </Linkifier>
      </section>
    );
  }

  return <SkeletonLoader />;
}
