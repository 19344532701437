import React, {
  useContext,
} from 'react';
import UserPaneAboutItem from './UserPaneAboutItem';
import ConversationsShowContext from '../ConversationsShowContext';

export default function UserPaneAbout() {
  const {
    recipient,
  } = useContext(ConversationsShowContext);

  const {
    kajabi_tenure: tenure,
    location,
    schedulink_link: schedulingLink,
    website,
  } = recipient;

  if (recipient.suspended) return null;

  return (
    <section className="user-pane-about">
      <p className="t-sage-heading-6 user-pane-about__title">About</p>
      <UserPaneAboutItem
        entry={website}
        icon="url"
        isLink
      />
      <UserPaneAboutItem
        entry={schedulingLink}
        icon="calendar-simple"
        isLink
      />
      <UserPaneAboutItem
        entry={location}
        icon="location"
      />
      <UserPaneAboutItem
        entry={tenure}
        icon="check-circle"
        string={`${tenure}+ Years using Kajabi`}
      />
    </section>
  );
}
