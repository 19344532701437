import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@kajabi/sage-react';

export default function Step({ description, icon, title }) {
  return (
    <div className="step">
      <h3 className="step__title">
        <pds-box align="center" gap="xs" align-items="center">
          <Icon icon={icon} size="xl" />
          {title}
        </pds-box>
      </h3>
      <p className="step__description">{description}</p>
    </div>
  );
}

Step.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
