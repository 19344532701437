import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Icon,
} from '@kajabi/sage-react';

export default function CustomFormMessage({
  icon,
  message,
  type,
}) {
  const classNames = classnames(
    'custom-form-message',
    `custom-form-message--${type}`,
  );

  return (
    <p className={classNames}>
      <Icon icon={icon} />
      {message}
    </p>
  );
}

CustomFormMessage.defaultProps = {
  icon: 'danger',
};

CustomFormMessage.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
