/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Card,
  Link,
  Tag,
} from '@kajabi/sage-react';
import Avatar from '../../shared/Avatar';
import timeAgo from '../../../scripts/timeAgo';

const BADGES_TEXT = {
  new: 'New Project',
  hot: 'Hot Project',
  low_competition: 'Low Competition',
  time_sensitive: 'Time Sensitive',
  large_budget: 'Large Budget',
};

const IMAGES = {
  new: '/images/svg/project-badges/new.svg',
  hot: '/images/svg/project-badges/hot.svg',
  low_competition: '/images/svg/project-badges/low-competition.svg',
  time_sensitive: '/images/svg/project-badges/time-sensitive.svg',
  large_budget: '/images/svg/project-badges/large-budget.svg',
};

const labelsForBadges = (badges) => {
  const badgeKeys = Object.keys(badges);
  if (badgeKeys.length === 0) return null;

  const labels = Object.keys(badges).map((badge) => (
    badges[badge] ? (
      <Tag
        className={classnames('available-project__badge', `available-project__badge--${badge}`)}
        image={IMAGES[badge]}
        key={badge}
        value={BADGES_TEXT[badge]}
      />
    ) : null
  ));

  return labels;
};

export default function AvailableProject({
  project,
}) {
  const {
    badges,
    brief,
    created_at,
    url,
    user,
    title,
  } = project;

  const {
    description,
    subcategoryTitle,
  } = project.project_data;

  return (
    <Card
      className="available-project"
      interactive
    >
      <Link
        className="sage-card__link--primary"
        href={url}
      >
        <div className="available-project__content">
          <Avatar
            className="available-project__avatar"
            size="32"
            user={user}
          />
          <div className="available-project__info">
            <div className="sage-row">
              <div className="sage-col-5">
                <p className="t-sage-heading-5 available-project__category">{title || subcategoryTitle}</p>
                <p className="t-sage-body-small available-project__name">{user.name}</p>
                <p className="available-project__details">
                  <span className="available-project__timestamp">{timeAgo(created_at)}</span>
                </p>
              </div>
              <div className="sage-col-7">
                <p className="available-project__badges">{labelsForBadges(badges)}</p>
              </div>
            </div>
            <div className="available-project__body">
              <LinesEllipsis
                className="t-sage-body-small"
                text={brief || description}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
}

AvailableProject.propTypes = {
  project: PropTypes.shape({
    brief: PropTypes.string,
    category: PropTypes.string,
    created_at: PropTypes.string,
    title: PropTypes.string,
    badges: PropTypes.shape({
      new: PropTypes.bool,
      hot: PropTypes.bool,
      low_competition: PropTypes.bool,
      time_sensitive: PropTypes.bool,
      large_budget: PropTypes.bool,
    }),
    project_data: PropTypes.shape({
      description: PropTypes.string,
      subcategoryTitle: PropTypes.string,
    }),
    url: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
