import React, {
  useContext,
} from 'react';
import classnames from 'classnames';
import Avatar from '../../../shared/Avatar';
import Rating from '../../../shared/Rating';
import ConversationsShowContext from '../ConversationsShowContext';

export default function UserPaneBio() {
  const {
    recipient,
    user,
  } = useContext(ConversationsShowContext);
  const isRecipientVendor = user.type === 'user';

  const classNames = classnames(
    'user-pane-bio',
    {
      'user-pane-bio--vendor': isRecipientVendor,
    },
  );

  const forNotSuspendedRecipient = (action) => {
    if (recipient.suspended) {
      return null;
    }

    return action;
  };

  const redirectToProfile = () => {
    if (isRecipientVendor) {
      window.location.replace(`${window.location.origin}/users/${recipient.id}`);
    }
  };

  const redirectToRatings = () => {
    window.location.replace(`${window.location.origin}/users/${recipient.id}/reviews`);
  };

  return (
    <section className={classNames}>
      <div
        className="user-pane-bio__avatar"
        onClick={forNotSuspendedRecipient(redirectToProfile)}
        role="presentation"
      >
        <Avatar
          size="96"
          user={recipient}
        />
      </div>
      <p
        className="t-sage-heading-5 user-pane-bio__name"
        onClick={forNotSuspendedRecipient(redirectToProfile)}
        role="presentation"
      >
        {`${recipient.name}`}
      </p>
      {isRecipientVendor && (
        <div
          className="user-pane-bio__rating"
          onClick={forNotSuspendedRecipient(redirectToRatings)}
          role="presentation"
        >
          <Rating
            count={recipient.ratings_count}
            total={recipient.ratings_total}
          />
        </div>
      )}
    </section>
  );
}
