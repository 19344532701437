import { useCallback, useContext } from 'react';
import AppContext from '../AppContext';
import withAuthStatusChecked from './withAuthStatusChecked';

export default function useApiFetch() {
  const { fetchResult404, setFetchResult404 } = useContext(AppContext);

  const apiFetch = useCallback(async (url: RequestInfo | URL) => {
    return fetch(url)
    .then((response) => withAuthStatusChecked(response))
    .then((response) => {
      switch (response.status) {
        case 200:
          return response.json();
        case 404:
          setFetchResult404(true);
          throw new Error('404 error');
        default:
          throw new Error('Unexpected response status');
      }
    });
  }, []);

  return {
    apiFetch,
    fetchResult404,
    setFetchResult404,
  };
}
