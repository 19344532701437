import React, {
  useContext,
} from 'react';
import ReviewsList from './ReviewsList';
import ReviewsStars from './ReviewsStars';
import ReviewsStatistic from './ReviewsStatistic';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function Reviews() {
  const {
    userData,
  } = useContext(UsersShowContext);

  const ratingsCount = userData.ratings_count || 0;
  const ratingsTotal = userData.ratings_total || 0;
  const ratingsAverage = Math.round((ratingsTotal / ratingsCount) * 10) / 10;

  const ratingsAverageString = ratingsCount === 0 ? '–' : ratingsAverage.toFixed(1);
  const ratingsCountHintString = ratingsCount === 0 ? 'No reviews yet' : 'Reviews all time';
  const ratingsCountString = ratingsCount === 0 ? '–' : ratingsCount;

  return (
    <section className="reviews">
      <div className="t-sage-heading-3 reviews__title">Reviews</div>
      <div className="reviews__info">
        <ReviewsStatistic
          content={(<p className="t-sage-heading-1">{ratingsCountString}</p>)}
          hint={ratingsCountHintString}
          title="Total reviews"
        />
        <div className="reviews__vertical-hr" />
        <ReviewsStatistic
          content={(<p className="t-sage-heading-1">{ratingsAverageString}</p>)}
          hint={`Based on ${ratingsCount} lifetime reviews`}
          title="Average rating"
        />
        <div className="reviews__vertical-hr" />
        <ReviewsStars />
      </div>
      <ReviewsList />
    </section>
  );
}
