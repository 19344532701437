import React from 'react';
import Bio from './Bio';
import Details from './Details';
import Hire from './Hire';
import Services from './Services';

export default function Profile() {
  return (
    <section className="profile">
      <div className="profile__panel">
        <Bio />
        <Hire />
        <hr />
        <Details />
        <hr />
        <Services />
      </div>
    </section>
  );
}
