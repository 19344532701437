import React, {
  useContext,
  useState,
} from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import UsersShowContext from '../../../../../contexts/UsersShowContext';

export default function About() {
  const {
    userData,
  } = useContext(UsersShowContext);

  const {
    about,
    headline,
  } = userData;
  const [useEllipses, setUseEllipses] = useState(true);

  return (
    <section className="about">
      <p className="t-sage-heading-3 about__title">{headline || 'About'}</p>
      <div className="about__content">
        {about && useEllipses
          ? (
            <div
              onClick={() => setUseEllipses(false)}
              role="presentation"
            >
              <LinesEllipsis
                text={about}
                maxLine="3"
                ellipsis={<p className="t-sage-heading-6 about__ellipses">Read more</p>}
                trimRight
                basedOn="words"
              />
            </div>
          )
          : about}
        {!about && (<p>Info about this Expert is coming soon!</p>)}
      </div>
    </section>
  );
}
