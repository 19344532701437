import React from 'react';
import PropTypes from 'prop-types';
import UIIconStar from './UIIconStar';

export default function Rating({
  count,
  forDirectory,
  showTotal,
  showTotalText,
  total,
}) {
  if (total && count) {
    const average = (total / count).toFixed(1);

    let rs = average;
    if (showTotal) rs = `${average} (${count})`;
    if (showTotalText) {
      rs = (
        <span className="rating__total-text">
          <span>{average}</span>
          <span className="rating__total-text-count">{`${count} Reviews`}</span>
        </span>
      );
    }
    if (forDirectory) {
      rs = (
        <span className="rating__total-text">
          <span className="rating__card-text">{average}</span>
          <span className="rating__card-total-text">{`(${count})`}</span>
        </span>
      );
    }

    return (
      <div className="rating">
        <UIIconStar />
        <p className="t-sage-body-small rating-text">{rs}</p>
      </div>
    );
  }
}

Rating.defaultProps = {
  count: null,
  forDirectory: false,
  showTotal: true,
  showTotalText: false,
  total: null,
};

Rating.propTypes = {
  count: PropTypes.number,
  forDirectory: PropTypes.bool,
  showTotal: PropTypes.bool,
  showTotalText: PropTypes.bool,
  total: PropTypes.number,
};
