import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Icon,
} from '@kajabi/sage-react';

export default function Breadcrumb({
  onClick,
  showIcon,
  subtle,
  title,
}) {
  const classNames = classnames(
    'breadcrumb',
    {
      'breadcrumb--clickable': onClick,
      'breadcrumb--subtle': subtle,
    },
  );

  return (
    <div
      className={classNames}
      onClick={onClick}
      role="presentation"
    >
      {showIcon && (
        <Icon
          className="breadcrumb__icon"
          icon="caret-right"
          size="sm"
        />
      )}
      <p className="breadcrumb__title">{title}</p>
    </div>
  );
}

Breadcrumb.defaultProps = {
  onClick: null,
  showIcon: true,
  subtle: false,
};

Breadcrumb.propTypes = {
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  subtle: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
