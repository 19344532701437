import React, {
  useContext,
} from 'react';
import AddButton from '../shared/AddButton';
import PlanUpgradeButton from '../../../shared/PlanUpgradeButton';
import WorkContext from './WorkContext';

export default function Info() {
  const {
    portfolioItemLimit,
    portfolioItems,
    userData,
  } = useContext(WorkContext);

  const isOverEntitlementLimit = portfolioItems.length >= portfolioItemLimit;
  const buttonAddProjectDisabled = (
    <div>
      <AddButton
        disabled={isOverEntitlementLimit}
      />
      {isOverEntitlementLimit && (
        <p className="t-sage-body-small info__limit-message">{`Maximum of ${portfolioItemLimit}`}</p>
      )}
    </div>
  );

  let buttonToRender = <AddButton />;
  if (userData.plan) {
    if (isOverEntitlementLimit) {
      if (userData.plan === 'basic') buttonToRender = <PlanUpgradeButton />;
      else buttonToRender = buttonAddProjectDisabled;
    }
  } else {
    buttonToRender = buttonAddProjectDisabled;
  }

  return (
    <div className="info">
      <h3 className="info__title">Your work</h3>
      <p className="info__description">
        {`Share examples of your past work to show Kajabi Heroes
          (and the world) what you’re capable of.`}
      </p>
      {buttonToRender}
    </div>
  );
}
