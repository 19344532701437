import React from 'react';
import GraphicApplicationPending from './GraphicApplicationPending';

export default function VendorApplicationsPending() {
  return (
    <section className="information">
      <GraphicApplicationPending />
      <div className="information__text">
        <p className="t-sage-heading-4">Your application has been sent</p>
        <p className="information__description">Thanks for your interest! We are onboarding experts gradually. You can reach us at experts@kajabi.com.</p>
        <p className="information__description"><a href="/apply">Back</a></p>
      </div>
    </section>
  );
}
