import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Dot,
  Link,
} from '@kajabi/sage-react';
import { useSnapshot } from 'valtio'
import AppContext from '../../../AppContext';
import useApiFetch from '../../../scripts/useApiFetch.tsx';

export default function Messages() {
  if (!window.globalFeatureFlags.messagesIndex.enabled) return false;
  const [unread, setUnread] = useState(null);

  const { globalState } = useContext(AppContext);
  const globalStateSnapshot = useSnapshot(globalState);

  const {
    apiFetch,
  } = useApiFetch();

  useEffect(() => {
    if (!globalStateSnapshot.checkForUnreadMessages) return;
    apiFetch(`${window.location.origin}/api/v1/project_conversations/any_unread.json`)
      .then((data) => {
        const { anyUnread } = data;
        setUnread(anyUnread);
        globalState.checkForUnreadMessages = false;
      }).catch(() => { });
  }, [globalStateSnapshot.checkForUnreadMessages]);

  return (
    <div className="messages">
      <Link
        href="/messages"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Messages
      </Link>
      {unread && (
        <Dot
          className="messages__indicator"
          color="red"
        />
      )}
    </div>
  );
}
