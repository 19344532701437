import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  flatten,
  uniq,
} from 'underscore';
import NullState from './NullState';
import ProjectCard from './ProjectCard';
import SkeletonLoader from '../../shared/SkeletonLoader';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ProjectList({
  filter,
}) {
  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const [projects, setProjects] = useState(null);
  const [allVendors, setAllVendors] = useState(null);
  const [resolvedProjects, setResolvedProjects] = useState(null);

  useEffect(() => {
    if (!projects) {
      fetch(`${window.location.origin}/projects.json?scoped_to=${filter}`)
        .then((response) => response.json())
        .then((data) => setProjects(data.result));
    }
  }, [filter]);

  useEffect(() => {
    if (projects) {
      const allVendorIds = [];
      projects.forEach((project) => {
        project.filtered_messages.forEach((message) => {
          if (message.sender_id && message.sender_id !== userId) {
            allVendorIds.push(message.sender_id);
          }
        });
      });
      const resolvedVendorIds = flatten(uniq(allVendorIds));
      const vendorItems = [];
      if (resolvedVendorIds.length === 0) {
        setAllVendors([]);
      } else {
        resolvedVendorIds.forEach((vendorId) => {
          fetch(`${window.location.origin}/users/${vendorId}.json`)
            .then((response) => response.json())
            .then((data) => {
              vendorItems.push(data);
              if (vendorItems.length === resolvedVendorIds.length) {
                setAllVendors(vendorItems);
              }
            });
        });
      }
    }
  }, [projects]);

  useEffect(() => {
    if (allVendors) {
      const newProjects = [];
      projects.forEach((project) => {
        const allVendorIds = [];
        project.filtered_messages.forEach((message) => {
          if (message.sender_id !== userId) allVendorIds.push(message.sender_id);
        });
        const unique = uniq(allVendorIds);
        const thisProjectVendors = [];
        unique.forEach((id) => {
          allVendors.forEach((ven) => {
            if (id === ven.id) {
              thisProjectVendors.push(ven);
            }
          });
        });
        newProjects.push({
          ...project,
          vendors: thisProjectVendors,
        });
      });
      setResolvedProjects(newProjects);
    } else {
      setResolvedProjects(projects);
    }
  }, [allVendors]);

  return (
    <section className="project-list">
      {resolvedProjects ? resolvedProjects.map((project) => (
        <ProjectCard
          key={uuid()}
          project={project}
        />
      )) : <SkeletonLoader />}
      {resolvedProjects && resolvedProjects.length === 0 && (
        <NullState filter={filter} />
      )}
    </section>
  );
}

ProjectList.propTypes = {
  filter: PropTypes.string.isRequired,
};
