import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Alert } from '@kajabi/sage-react';
import PropTypes from 'prop-types';
import Actions from './Actions';
import Hamburger from './Hamburger';
import Logo from '../Logo';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import SiteHeaderContext from './SiteHeaderContext';

export default function Header({
  size,
}) {
  const headerActionsRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuHeight, setMobileMenuHeight] = useState('auto');

  const {
    suspended,
  } = useContext(CurrentUserContext);

  function isMobile() {
    return (window.innerWidth < 768);
  }

  const handleClick = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      if (isMobile()) setMobileMenuHeight(0);
      else setMobileMenuHeight('auto');
    } else {
      setMobileMenuOpen(true);
      if (isMobile()) setMobileMenuHeight(headerActionsRef.current?.offsetHeight);
      else setMobileMenuHeight('auto');
    }
  };

  const adjustMobileMenu = () => {
    if (isMobile()) {
      if (mobileMenuOpen) setMobileMenuHeight(headerActionsRef.current?.offsetHeight);
      else setMobileMenuHeight(0);
    } else {
      setMobileMenuHeight('auto');
    }
  };

  window.addEventListener('resize', () => {
    adjustMobileMenu();
  });

  useEffect(() => {
    adjustMobileMenu();
  }, []);

  const siteHeaderValues = useMemo(() => ({
    headerActionsRef,
  }));

  return (
    <SiteHeaderContext.Provider value={siteHeaderValues}>
      <header className="site-header">
        <div className={`container container--${size}`}>
          <div className="site-header__sizer">
            <div className="site-header__content">
              <a
                className="site-header__logo"
                href="/"
              >
                <Logo />
              </a>
              <Hamburger
                onClick={handleClick}
                open={mobileMenuOpen}
              />
            </div>
            <Actions
              menuHeight={mobileMenuHeight}
            />
          </div>
        </div>
      </header>
      {suspended && (
        <div className={`container container--${size}`}>
          <Alert
            small
            color="danger"
            description={(
              <>
                Your Kajabi Experts account has been suspended. Contact
                {' '}
                <a href="mailto:experts@kajabi.com">experts@kajabi.com</a>
                {' '}
                with any questions.
              </>
            )}
          />
        </div>
      )}
    </SiteHeaderContext.Provider>
  );
}

Header.propTypes = {
  size: PropTypes.string.isRequired,
};
