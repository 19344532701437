// Entry point for the build script in your package.json
import './controllers';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import App from './App';
import ConfigContext from './contexts/ConfigContext';
import CurrentUserContext from './contexts/CurrentUserContext';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app');
  const config = JSON.parse(container.getAttribute('data-config'));
  const root = createRoot(container);
  const { user } = config;

  const currentUser = user ? {
    ...user,
    isClient: user.type === 'user',
    isVendor: user.type === 'vendor',
    mutedAt: user.muted_at,
    isLoggedIn: true,
  } : { isLoggedIn: false };

  root.render(
    <ConfigContext.Provider value={config}>
      <CurrentUserContext.Provider value={currentUser}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </CurrentUserContext.Provider>
    </ConfigContext.Provider>,
  );
});
