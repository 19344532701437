import React from 'react';
import ChatPaneContent from './ChatPaneContent';
import ChatPaneInput from './ChatPaneInput';
import ChatPaneHeader from './ChatPaneHeader';

export default function ChatPane() {
  return (
    <section className="pane chat-pane">
      <ChatPaneHeader />
      <ChatPaneContent />
      <ChatPaneInput />
    </section>
  );
}
