export default function declineProject(
  projectId
) {
  const app = document.getElementById('app');
  const config = JSON.parse(app.getAttribute('data-config'));

  fetch('/api/v1/project_refusals', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': config.authenticityToken,
    },
    body: JSON.stringify({
      project_refusal: {project_id: projectId}
    }),
  })
    .then((res) => res.text())
    .then(() => window.location.replace('/projects'));
}
