import React from 'react';
import PropTypes from 'prop-types';
import SkeletonLoader from './SkeletonLoader';

export default function WithLoadingEffect({ loading, children, lines }) {
  if (loading) {
    return (<SkeletonLoader lines={lines} />);
  }
  return children;
}

WithLoadingEffect.defaultProps = {
  lines: 1,
};

WithLoadingEffect.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  lines: PropTypes.number,
};
