import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  escape,
} from 'underscore';
import {
  KjUploader,
} from '@kajabi/kj-uploader';
import {
  Button,
} from '@kajabi/sage-react';

export default function Uploader({
  asset,
  config,
  onSuccess,
}) {
  const classNames = classnames(
    'uploader',
    {
      'uploader--has-asset': asset,
    },
  );

  const open = () => {
    const uploader = new KjUploader({
      ...config,
      parentNode: document.body,
      onSuccess,
      onComplete: () => {
        uploader.close();
      },
    });
    uploader.open();
  };

  return (
    <div className={classNames}>
      <p className="uploader__label t-sage-heading-5">Avatar</p>
      <div className="uploader__frame">
        <div className="uploader__preview-frame">
          <img
            alt=""
            className="uploader__preview"
            src={escape(asset)}
          />
        </div>
        <div className="uploader__content">
          <p className="uploader__info">
            {'At least '}
            <strong>630x630</strong>
            {' pixels is recommended'}
            <br />
            {'Max file size is '}
            <strong>3MB.</strong>
          </p>
          <Button
            color="secondary"
            onClick={open}
          >
            Select image
          </Button>
        </div>
      </div>
    </div>
  );
}

Uploader.defaultProps = {
  asset: null,
};

Uploader.propTypes = {
  asset: PropTypes.string,
  config: PropTypes.shape({}).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
