import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';
import ModalAlert from '../../../shared/ModalAlert';
import Input from '../../../shared/formik/Input';
import Textarea from '../../../shared/formik/Textarea';
import AppContext from '../../../../AppContext';
import MessagesIndexContext from '../MessagesIndexContext';
import quotePost from '../scripts/quotePost';
import validations from '../../../../data/shared/validations';

export default function QuoteSubmitModal({
  active,
  onClose,
  project,
  quoteType,
}) {
  const {
    globalState,
  } = useContext(AppContext);

  const {
    getMessagesData,
    setCurrentMessageQuoteId,
  } = useContext(MessagesIndexContext);

  const isInvoiceType = quoteType === 'invoice';
  const textHeader = isInvoiceType ? 'Submit an invoice' : 'Submit a proposal';
  const textAlertTitle = isInvoiceType ? 'How do invoices work?' : 'Tip: Sending proposals';
  const textAlertDescription = isInvoiceType ? 'When your client accepts your invoice, they will receive an email from Kajabi with a link to pay it. You will receive your payout from Kajabi.' : 'This is your contract with the client. Increase your chances of success by including a description of the work you will do, and when you intend to invoice them.';
  const textAmount = isInvoiceType ? 'Amount (USD)' : 'Total price (USD)';
  const textDue = isInvoiceType ? 'Payment due date' : 'Estimated completion date';
  const textDescription = isInvoiceType ? 'Description' : 'Scope of work';
  const placeholderDue = isInvoiceType ? 'Enter a due date...' : 'Enter a completion date...';

  return (
    <Modal
      active={active}
      allowScroll
      className="modal-border-scroll quote-submit-modal"
      size="lg"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title={textHeader}
      />
      <Formik
        initialValues={{
          price: '',
          completionDate: '',
          scopeOfWork: '',
        }}
        onSubmit={
          (values, { setSubmitting }) => {
            setSubmitting(false);
            quotePost(
              {
                project_id: project.id,
                price: values.price,
                estimated_delivery: values.completionDate,
                description: values.scopeOfWork,
                quote_type: quoteType,
              },
              (res) => {
                // callback
                const parsedResponse = JSON.parse(res);
                setCurrentMessageQuoteId(parsedResponse.id);
                getMessagesData();
                globalState.checkForUnreadMessages = true;
              },
            );
            onClose();
          }
        }
        validationSchema={Yup.object({
          price: Yup.number()
            .lessThan(1000001, validations.numberValueMaximum(1000000))
            .required(validations.stringRequired)
            .typeError(validations.typeNumber),
          scopeOfWork: Yup.string()
            .min(25, validations.sizeStringMinimum(25))
            .required(validations.stringRequired),
          completionDate: Yup.string()
            .required(validations.stringRequired),
        })}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <Modal.Body>
              <ModalAlert
                description={textAlertDescription}
                title={textAlertTitle}
              />
              <Input
                errors={errors.price}
                handleChange={handleChange}
                idName="price"
                label={textAmount}
                placeholder="Enter price"
                touched={touched.price}
                value={values.price}
              />
              <Input
                errors={errors.completionDate}
                handleChange={handleChange}
                idName="completionDate"
                label={textDue}
                placeholder={placeholderDue}
                touched={touched.completionDate}
                value={values.completionDate}
              />
              <Textarea
                className="quote-submit-modal__textarea"
                errors={errors.scopeOfWork}
                handleChange={handleChange}
                idName="scopeOfWork"
                label={textDescription}
                placeholder="Enter a description..."
                touched={touched.scopeOfWork}
                value={values.scopeOfWork}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button.Group gap="sm">
                <Button
                  color="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Button.Group>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

QuoteSubmitModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number,
    vendor_id: PropTypes.number,
  }).isRequired,
  quoteType: PropTypes.string.isRequired,
};
