/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

const urlRegExp = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const defaultRequiredString = 'Required';
const defaultCheckboxString = 'Check at least one option';

export const validationSchema = Yup.object({
  firstName: Yup.string().required(defaultRequiredString),
  lastName: Yup.string().required(defaultRequiredString),
  email: Yup.string().email('Invalid email address').required(defaultRequiredString),
  companyName: Yup.string().required(defaultRequiredString),
  website: Yup.string().matches(urlRegExp, 'Invalid website').required(defaultRequiredString),
  location: Yup.string(),
  services: Yup.array(),
  otherServices: Yup.string(),
  languages: Yup.array().min(1, defaultCheckboxString),
  templatesForSale: Yup.string(),
  experience: Yup.number().required(defaultRequiredString),
  typicalClients: Yup.array().min(1, defaultCheckboxString),
  averagePrice: Yup.string(),
  additionalInfo: Yup.string().required(defaultRequiredString),
  pitch: Yup.string().required(defaultRequiredString),
});
