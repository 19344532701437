import React, {
  useContext,
} from 'react';
import Bio from './Bio';
import CurrentProjectContext from '../../../../contexts/CurrentProjectContext';

export default function Header() {
  const project = useContext(CurrentProjectContext);
  const {
    project_data: projectData,
    title: title,
  } = project;
  const {
    subcategoryTitle,
  } = projectData;

  return (
    <header className="header">
      <h1>{title ? title : subcategoryTitle}</h1>
      <Bio />
    </header>
  );
}
