import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  Button,
} from '@kajabi/sage-react';
import ConfigContext from '../../../contexts/ConfigContext';
import AppContext from '../../../AppContext';

export default function LogIn() {
  const {
    authenticityToken,
    loginPath,
  } = useContext(ConfigContext);

  const {
    setLoginRef,
  } = useContext(AppContext);

  const loginLinkRef = useRef(null);

  useEffect(() => {
    setLoginRef(loginLinkRef);
  }, []);

  return (
    <form action={loginPath} method="post">
      <Button
        color="secondary"
        subtle
        type="submit"
        ref={loginLinkRef}
      >
        Log in
      </Button>
      <input
        name="authenticity_token"
        type="hidden"
        value={authenticityToken}
      />
    </form>
  );
}
