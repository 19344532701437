import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Formik,
} from 'formik';
import uuid from 'react-uuid';
import {
  Alert,
  Button,
  FormSection,
} from '@kajabi/sage-react';
import CheckboxGroup from '../../shared/formik/CheckboxGroup';
import CustomFormMessage from '../../shared/CustomFormMessage';
import PlanUpgradeButton from '../../shared/PlanUpgradeButton';
import ConfigContext from '../../../contexts/ConfigContext';
import UsersEditContext from '../../../contexts/UsersEditContext';
import {
  validationCheckboxMinimumOneSelected,
} from '../../../data/shared/validations';

export default function Services() {
  const {
    user,
  } = useContext(ConfigContext);

  const {
    categories,
    userCategories,
    onServicesSave,
  } = useContext(UsersEditContext);

  const [resolvedCategories, setResolvedCategories] = useState(null);
  const [checkedCategories, setCheckedCategories] = useState(null);
  const [inactiveServicesWarning, setInactiveServicesWarning] = useState(false);

  const userPlan = user.plan || '';
  let displayPlanName = 'Approved';
  if (userPlan === 'growth') displayPlanName = 'Expert+';

  useEffect(() => {
    if (categories && userCategories) {
      setResolvedCategories(categories.map((category) => {
        const options = [];
        category.subcategories.forEach((subcategory) => {
          let isDisabled = false;
          userCategories.forEach((userCategory) => {
            if (userCategory.subcategory.id === subcategory.id) {
              if (userCategory.status !== 'approved') isDisabled = true;
            }
          });
          options.push({
            disabled: isDisabled,
            name: subcategory.name,
          });

          let isWarning = false;
          userCategories.forEach((userCategory) => {
            if (userCategory.status === 'pending' && userCategory.active) {
              isWarning = true;
            }
          });
          setInactiveServicesWarning(isWarning);
        });
        return (
          <CheckboxGroup
            key={uuid()}
            name="subcategoryGroup"
            optionsWithStatuses={options}
            subtitle={category.name}
          />
        );
      }));
    }
  }, [categories, userCategories]);

  useEffect(() => {
    if (categories && userCategories) {
      const checkedCats = [];
      userCategories.forEach((userCategory) => {
        if (userCategory.active) {
          checkedCats.push(userCategory.subcategory.name);
        }
      });
      setCheckedCategories(checkedCats);
    }
  }, [categories, userCategories]);

  const servicesLimit = window.entitlements.services.limit;
  let errorMessage = validationCheckboxMinimumOneSelected;
  let isError = false;
  const isTriedSubmit = false;

  const validateCount = (values) => {
    const len = values.length;
    if (len === 0 && isTriedSubmit) {
      isError = true;
    } else if (len !== 0 && len > servicesLimit) {
      isError = true;
      errorMessage = `Maximum of ${servicesLimit}`;
    } else {
      isError = false;
    }
  };

  if (checkedCategories) {
    return (
      <section className="services">
        {
          inactiveServicesWarning
          && (
            <Alert
              color={Alert.COLORS.WARNING}
              description="The inactive selected options are waiting for approval."
              small
            />
          )
        }
        <Formik
          initialValues={{
            subcategoryGroup: checkedCategories || [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            validateCount(values.subcategoryGroup);
            if (!isError) onServicesSave(values.subcategoryGroup);
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <FormSection
                title="What services do you offer your clients?"
                subtitle="This will determine the types of projects and clients you are matched with."
              >
                {userPlan === 'basic' && (
                  <Alert
                    actions={<PlanUpgradeButton />}
                    color="info"
                    description="If you'd like to increase the amount of services you can offer your customers, you will need to upgrade your plan"
                    title={`Your current ${displayPlanName} plan allows you to offer ${servicesLimit} service${servicesLimit > 1 ? 's' : ''}`}
                  />
                )}
                {resolvedCategories}
                {isError && (
                  <CustomFormMessage
                    message={errorMessage}
                    type="danger"
                  />
                )}
              </FormSection>
              <Button.Group
                align="end"
              >
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Button.Group>
            </>
          )}
        </Formik>
      </section>
    );
  }
}
