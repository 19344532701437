export default function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  const thisEl = (typeof el === 'string') ? document.querySelector(el) : el;

  const styles = window.getComputedStyle(thisEl);
  const margin = parseFloat(styles.marginTop)
    + parseFloat(styles.marginBottom);

  return Math.ceil(thisEl.offsetHeight + margin);
}
