import React from 'react';

export default function PackageNull() {
  return (
    <div className="package-null">
      <p className="t-sage-heading-5 package-null__title">No packages.</p>
      <p className="package-null__desc">It looks like this Expert hasn’t added any packages to their profile yet.</p>
    </div>
  );
}
