/* eslint-disable no-console */
import React, {
  useContext,
} from 'react';
import Action from '../shared/Action';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Actions() {
  const {
    chatData,
    setDocumentHistoryModalOpen,
  } = useContext(MessagesIndexContext);

  return (
    <section className="actions">
      <Action
        iconLeft="file-money"
        onClick={() => setDocumentHistoryModalOpen(true)}
        text="View proposals & invoices"
      />
      <Action
        iconLeft="file"
        iconRight="launch"
        onClick={() => { window.location = `${window.location.origin}/projects/${chatData.project.id}`; }}
        text="Go to project details"
      />
    </section>
  );
}
