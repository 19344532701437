import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  Icon,
} from '@kajabi/sage-react';
import Timestamp from '../../../../shared/Timestamp';

export default function TimelineCard({
  children,
  classNames,
  icon,
  iconBackgroundColor,
  iconColor,
  message,
  recipientName,
  timestamp,
  userAvatar,
  userName,
}) {
  return (
    <section className={`timeline-card ${classNames}`}>
      <Card>
        <div className="timeline-card__content">
          {userAvatar && (
            <Avatar
              image={{
                alt: userName,
                src: userAvatar,
              }}
              size="40px"
            />
          )}
          {!userAvatar && (
            <Icon
              backgroundHeight="40px"
              cardColor={iconBackgroundColor}
              circular
              color={iconColor}
              icon={icon}
            />
          )}
          <div className="timeline-card__detail">
            <div className="timeline-card__header">
              <p className="timeline-card__message">
                {userName && (
                  <span>
                    <span className="t-sage-heading-6">{userName}</span>
                    <span className="timeline-card__message-text">{` ${message}`}</span>
                  </span>
                )}
                {recipientName && (
                  <span className="t-sage-heading-6">{` ${recipientName}`}</span>
                )}
                {!userName && !recipientName && (
                  <span className="t-sage-heading-6">{message}</span>
                )}
              </p>
              <Timestamp timestamp={timestamp} />
            </div>
            {children}
          </div>
        </div>
      </Card>
    </section>
  );
}

TimelineCard.defaultProps = {
  classNames: '',
  children: null,
  iconBackgroundColor: 'draft',
  iconColor: 'grey-500',
  message: null,
  recipientName: null,
  timestamp: null,
  userAvatar: null,
  userName: null,
};

TimelineCard.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  iconBackgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  message: PropTypes.string,
  recipientName: PropTypes.string,
  timestamp: PropTypes.string,
  userAvatar: PropTypes.string,
  userName: PropTypes.string,
};
