import React from 'react';
import PropTypes from 'prop-types';

export default function Package({
  image,
  onClick,
  price,
  title,
}) {
  return (
    <div
      className="package"
      onClick={onClick}
      role="presentation"
    >
      {image && (
        <div className="package__image-frame">
          <img
            alt={title}
            className="package__image"
            src={image}
          />
        </div>
      )}
      <p className="t-sage-heading-6 package__title">{title}</p>
      <p className="t-sage-heading-6 package__price">
        $
        {price}
      </p>
    </div>
  );
}

Package.propTypes = {
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

Package.defaultProps = {
  image: null,
};
