import React from 'react';
import {Checkbox} from '@kajabi/sage-react';
import uuid from "react-uuid";

export default function TermsCheckbox({termsAccepted, setTermsAccepted, forVendors = false}) {
  const termsCheckboxLabel = (
    <p>
      {'I have read and agree to the '}
      <a
        href={forVendors ? "https://kajabi.com/policies/experts-marketplace-agreement" : "https://legal.kajabi.com/policies/experts-marketplace-terms"}
        target="_blank"
        rel="noopener noreferrer"
        className="t-sage--color-charcoal-400"
      >
        {forVendors ? 'Experts Marketplace Agreement' : 'Experts Marketplace Terms'}
      </a>
    </p>
  );

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Checkbox
      id={uuid()}
      label={termsCheckboxLabel}
      name="terms-checkbox"
      checked={termsAccepted}
      onChange={handleTermsChange}
    />
  )
}