import React, {
  useContext,
} from 'react';
import {
  Link,
} from '@kajabi/sage-react';
import Directory from './Directory';
import Messages from './Messages';
import Templates from './Templates';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

export default function ActionsUser() {
  const { suspended } = useContext(CurrentUserContext);

  if (suspended) return null;

  return (
    <>
      <Directory />
      <Templates />
      <Link
        href="/projects/new"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Create a project
      </Link>
      <Link
        href="/projects"
        removeUnderline
        style={Link.COLORS.SECONDARY}
      >
        Projects
      </Link>
      <Messages />
    </>
  );
}
