import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from '@kajabi/sage-react';

export default function ArchiveModal({
  active,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      active={active}
      className="archiving-modal"
    >
      <Modal.Header
        aside={(
          <Button
            color="secondary"
            icon="remove"
            iconOnly
            onClick={onClose}
            subtle
          />
        )}
        title="Complete Project"
      />
      <Modal.Body>
        <div className="archiving-modal__content">
          <p className="archiving-modal__message">Archiving a project will remove it from your dashboard. If the client messages you on the project, it will become active again.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group gap="sm">
          <Button
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button icon="archive" onClick={onConfirm}>
            Archive
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );
}

ArchiveModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
