import React, {
  useContext,
} from 'react';
import AddButton from './AddButton';
import PlanUpgradeButton from '../../../shared/PlanUpgradeButton';
import TestimonialsContext from './TestimonialsContext';

export default function Info() {
  const isEntitled = window.entitlements.testimonialCreate.enabled
  const {
    testimonials,
  } = useContext(TestimonialsContext);

  return (
    <div className="info">
      <h3 className="info__title">Your testimonials</h3>
      <p className="info__description">
        {isEntitled && testimonials.length === 0 &&
          'Share your valuable experience with us! By posting a testimonial of your recent work, you help potential clients discover the exceptional quality of your contributions.'}

        {isEntitled && testimonials.length > 0 &&
          'Published testimonials showcase your expertise, build trust, and enhance your reputation, making you a top choice for discerning clients.'}

        {!isEntitled && testimonials.length > 0 &&
          'Unlock visibility for your testimonials and showcase them to our valued clients by upgrading your subscription plan.'}

        {!isEntitled && testimonials.length === 0 &&
          'Share your valuable feedback! Upgrade your subscription plan to unlock testimonial posting and enjoy expanded features and enhanced opportunities.'}
      </p>
      {isEntitled && <AddButton />}
      {!isEntitled && <PlanUpgradeButton />}
    </div>
  );
}
