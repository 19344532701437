import React from 'react';
import PropTypes from 'prop-types';

export default function Hamburger({
  onClick,
  open,
}) {
  const openClass = open ? 'hamburger--open' : '';
  return (
    <div className={`hamburger ${openClass}`} onClick={() => onClick()} role="presentation">
      <div className="hamburger__sizer">
        <div className="hamburger__slice hamburger__slice--1" />
        <div className="hamburger__slice hamburger__slice--2" />
        <div className="hamburger__slice hamburger__slice--3" />
        <div className="hamburger__slice hamburger__slice--4" />
      </div>
    </div>
  );
}

Hamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
