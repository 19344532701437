/* eslint-disable react/forbid-prop-types */
import React, {} from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@kajabi/sage-react';
import PlanEntitlement from '../../shared/PlanEntitlement';

export default function Plan({
  data,
}) {
  const {
    badge,
    blurb,
    displayName,
    entitlements,
    price,
    svg,
  } = data;

  return (
    <div className="plan">
      <div className="plan__header">
        <div className="plan__svg">{svg}</div>
        {badge && <Badge value={badge} />}
      </div>
      <div className="plan__info">
        <p className="t-sage-heading-5 plan__name">
          {displayName}
        </p>
        <p className="t-sage-body-xsmall plan__blurb">{blurb}</p>
      </div>
      <p className="t-sage-heading-3 plan__price">
        {typeof price === 'number' ? (
          <>
            {`$${price}`}
            <span className="plan__per-month">/mo</span>
          </>
        ) : price}
      </p>
      <ul className="plan__entitlements">
        {entitlements.map((entitlement) => (
          <PlanEntitlement
            boldText={entitlement.boldText}
            text={entitlement.text}
            textColor={entitlement.textColor}
          />
        ))}
      </ul>
    </div>
  );
}

Plan.propTypes = {
  data: PropTypes.shape({
    badge: PropTypes.string,
    blurb: PropTypes.string,
    entitlements: PropTypes.array,
    displayName: PropTypes.string,
    price: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    svg: PropTypes.func,
  }).isRequired,
};
