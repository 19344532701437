import React from 'react';

export default function Header() {
  return (
    <div className="sage-row experts-page__header">
      <div className="sage-col experts-page__header--left">
        <h1>Find a vetted Kajabi Expert</h1>
      </div>
    </div>
  );
}
