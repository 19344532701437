import React from 'react';
import PackagesList from './PackagesList';

export default function Packages() {
  return (
    <section className="packages">
      <p className="t-sage-heading-3 packages__title">Offered Packages</p>
      <PackagesList />
    </section>
  );
}
