import React, {
  useContext,
} from 'react';
import {
  Alert,
} from '@kajabi/sage-react';
import GraphicThreadsNull from '../GraphicThreadsNull';
import ConfigContext from '../../../../../contexts/ConfigContext';
import CurrentProjectContext from '../../../../../contexts/CurrentProjectContext';

export default function MessagesDeclinedAlert() {
  return (
    <Alert
      className="view-vendor-prompt__alert"
      color="info"
      description="If you would like to resume sending messages regarding this project, choose “Undo decline” from the project's menu. This will move the project back to your prospective projects list."
      title="You have declined this project"
    />
  );
}
