import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar as SageAvatar,
} from '@kajabi/sage-react';

export default function Avatar({
  className,
  size,
  user,
}) {
  const avatarProps = {
    alt: user.name,
    src: user.avatar || user.picture,
  };

  return (
    <SageAvatar
      className={className}
      image={avatarProps}
      size={`${size}px`}
    />
  );
}

Avatar.defaultProps = {
  className: null,
  size: '40',
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
};
