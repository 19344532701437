import React from 'react';

export default function GraphicApplicationPending() {
  return (
    <svg width="104" height="103" viewBox="0 0 104 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4621_4430)">
        <g clipPath="url(#clip1_4621_4430)">
          <path d="M2.0972 47.8288C4.89917 20.3708 35.2111 0.728511 62.7623 3.53999C90.3135 6.35147 104.687 30.5755 101.887 58.0119C99.0872 85.4483 69.2019 103.426 41.6434 100.613C14.0849 97.8011 -0.702554 75.2652 2.0972 47.8288Z" fill="#DCF0FF" />
          <path d="M77.4412 71.0192C78.4857 70.2705 78.8924 69.0495 78.3495 68.2923C77.8066 67.535 76.5198 67.5281 75.4753 68.2769C74.4307 69.0257 74.0241 70.2466 74.5669 71.0039C75.1098 71.7611 76.3966 71.768 77.4412 71.0192Z" fill="#C6893F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M69.0408 71.5676C69.0408 71.5676 71.9207 68.9787 74.306 68.1933C76.6913 67.437 77.4185 67.9024 77.884 68.8042C78.2912 69.5605 78.2621 70.9277 75.6732 72.8767C73.0842 74.8256 70.9607 75.8729 69.3026 76.9492C68.5172 77.4728 61.4194 81.9816 59.4995 83.4361C57.4342 85.0069 57.4924 78.4036 57.4924 78.4036L69.0408 71.5676Z" fill="#C6893F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M69.3026 76.9491C68.5172 77.4728 61.4194 81.9816 59.4995 83.4361C57.4342 85.0069 57.4924 78.4036 57.4924 78.4036L69.0117 71.5676" fill="#A0692D" />
          <path d="M69.3026 76.9491C68.5172 77.4728 61.4194 81.9816 59.4995 83.4361C57.4342 85.0069 57.4924 78.4036 57.4924 78.4036L69.0117 71.5676" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M72.6777 53.2123C72.6777 53.2123 73.4922 52.6014 74.394 52.2524C75.2958 51.9324 76.3721 52.3396 76.9248 53.0378C77.4775 53.7359 77.8556 54.725 77.0702 55.7722C76.2848 56.8194 75.2667 57.4594 75.2667 57.4594" fill="#A0692D" />
          <path d="M72.6777 53.2123C72.6777 53.2123 73.4922 52.6014 74.394 52.2524C75.2958 51.9324 76.3721 52.3396 76.9248 53.0378C77.4775 53.7359 77.8556 54.725 77.0702 55.7722C76.2848 56.8194 75.2667 57.4594 75.2667 57.4594" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M64.1543 46.2018C64.1543 46.2018 66.8305 44.4856 67.7323 44.1656C68.634 43.8456 69.7103 44.2528 70.263 44.951C70.8157 45.6491 71.1939 46.6382 70.4085 47.6854C69.6231 48.7326 66.8596 51.1179 66.8596 51.1179" fill="#A0692D" />
          <path d="M64.1543 46.2018C64.1543 46.2018 66.8305 44.4856 67.7323 44.1656C68.634 43.8456 69.7103 44.2528 70.263 44.951C70.8157 45.6491 71.1939 46.6382 70.4085 47.6854C69.6231 48.7326 66.8596 51.1179 66.8596 51.1179" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M22.7894 28.37L23.6039 26.6247L42.9483 18.596C44.7227 17.8106 47.1371 18.4797 48.3007 20.1087L79.7463 63.9754C79.8917 64.179 80.0081 64.3826 80.0953 64.5863L80.9971 65.0226L80.1244 66.8552C79.8626 67.4952 79.339 68.0188 78.5827 68.3388L59.9946 76.5711C58.2202 77.3565 55.8057 76.6874 54.7876 75.233L23.3421 31.3953C22.5857 30.3481 22.4403 29.2427 22.7894 28.37Z" fill="#C8BFAD" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M59.7323 55.8885L79.7457 63.9754C79.8912 64.179 80.0075 64.3826 80.0948 64.5863L80.9966 65.0226L80.1239 66.8552C79.8621 67.4952 79.3385 68.0188 78.5822 68.3388L59.9941 76.5711C58.2196 77.3565 55.8052 76.6874 54.7871 75.233" fill="#AFA490" />
          <path d="M59.7323 55.8885L79.7457 63.9754C79.8912 64.179 80.0075 64.3826 80.0948 64.5863L80.9966 65.0226L80.1239 66.8552C79.8621 67.4952 79.3385 68.0188 78.5822 68.3388L59.9941 76.5711C58.2196 77.3565 55.8052 76.6874 54.7871 75.233" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M43.7039 16.9379L25.1158 25.1702C23.3414 25.9556 22.8178 27.9337 24.1268 29.7373L55.5724 73.604C56.5905 75.0294 59.0049 75.7275 60.7794 74.9421L79.3674 66.7098C81.1419 65.9244 81.6655 63.9463 80.4728 62.3173L49.0563 18.4797C47.8637 16.8507 45.4784 16.1525 43.7039 16.9379Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M79.5711 61.0373L49.0563 18.4796C47.8928 16.8506 45.4784 16.1816 43.7039 16.967L25.1158 25.1993C23.3414 25.9847 22.8178 27.9628 24.1268 29.7663L54.5251 72.1494L79.5711 61.0373Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M39.5148 53.9395L43.0927 59.0593L47.4561 64.5862C47.4561 64.5862 48.2997 65.6334 50.6269 66.477C52.954 67.3206 53.7104 67.0879 54.7867 66.6225C55.8921 66.1571 57.6956 64.8189 58.9755 63.859C60.2555 62.899 62.1753 61.2119 63.0771 60.3683C63.9789 59.5247 66.5678 57.8957 67.3242 57.4884C68.7205 56.7903 69.9713 56.6739 70.3204 56.5867C70.5822 56.5285 73.2584 58.6229 72.6184 60.0483C72.1821 60.9791 70.9603 64.0626 69.6804 65.3135C68.6623 66.3025 67.1787 66.7679 65.0261 70.0259C63.3971 72.4985 64.2989 74.6802 64.9388 78.6655C65.4624 81.9526 65.2588 86.316 63.3098 90.5339C62.699 91.8429 73.1129 113.282 73.1129 113.282L52.2268 113.893C52.2268 113.893 44.5181 92.7156 43.4709 89.0213C42.4528 85.3269 37.1003 67.2043 36.984 65.9825C36.8676 64.7608 36.6058 60.9501 35.8495 58.7975C35.0641 56.6448 34.0751 52.3978 34.1041 51.3797C34.1332 50.3906 34.9768 47.569 34.9768 47.569L39.5148 53.9395Z" fill="#C6893F" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M68.6038 58.4484C67.9348 58.1866 67.9639 57.7212 68.9529 57.1394C69.7383 56.6449 70.8437 56.4413 71.5418 56.7613C72.24 57.0812 73.0836 57.8667 72.9381 59.0593C72.8218 60.0193 72.2982 60.6011 71.92 61.0083C71.5418 61.4156 71.3382 61.4446 71.0473 61.2701C70.7564 61.0956 70.8437 60.4265 70.0292 59.4666C69.4474 58.7684 68.6038 58.4484 68.6038 58.4484Z" fill="#D89E60" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M68.7792 62.7245C68.7792 62.7245 67.9647 60.9209 66.3066 60.3101" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M36.1592 22.6794C36.1999 22.5075 36.0555 22.3261 35.8366 22.2743C35.6178 22.2225 35.4074 22.3199 35.3667 22.4918C35.326 22.6638 35.4704 22.8452 35.6893 22.897C35.9081 22.9488 36.1186 22.8514 36.1592 22.6794Z" fill="#C8BFAD" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M63.3114 40.9657L41.5235 50.7688C41.2326 50.9143 40.8254 50.7979 40.6217 50.5361L32.4767 39.2495C32.2731 38.9877 32.3604 38.6677 32.6804 38.5222L54.4683 28.7191C54.7592 28.5737 55.1664 28.69 55.37 28.9518L63.515 40.2385C63.6896 40.5003 63.6023 40.8494 63.3114 40.9657Z" fill="#BEF0EE" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M33.0293 39.4822L49.6975 42.7984C50.0175 42.8565 50.3374 42.7111 50.4247 42.4784L55.3408 29.4755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M43.9094 41.9839L40.7969 50.2162" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M63.0792 40.1803L52.5488 38.115" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.245 34.1878L39.4571 43.9909C39.1662 44.1363 38.759 44.02 38.5553 43.7582L30.4103 32.4715C30.2067 32.2097 30.294 31.8897 30.6139 31.7443L52.4019 21.9412C52.6927 21.7957 53.1 21.9121 53.3036 22.1739L61.4486 33.4605C61.6232 33.7223 61.5359 34.0423 61.245 34.1878Z" fill="#B4F2CD" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M30.9629 32.7042L47.6311 36.0204C47.9511 36.0786 48.271 35.9332 48.3583 35.7005L53.2744 22.6975" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M41.843 35.2059L38.7305 43.4382" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.0421 33.4024L50.5117 31.308" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.9423 32.4134L40.1544 42.2165C39.8635 42.3619 39.4562 42.2456 39.2526 41.9838L31.1076 30.6971C30.904 30.4353 30.9912 30.1153 31.3112 29.9699L53.0991 20.1668C53.39 20.0213 53.7973 20.1377 54.0009 20.3995L62.1459 31.6861C62.3204 31.9479 62.2332 32.2679 61.9423 32.4134Z" fill="#B4F2CD" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M31.6602 30.9008L48.3283 34.217C48.6483 34.2751 48.9683 34.1297 49.0556 33.897L53.9717 20.894" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M42.5403 33.4315L39.4277 41.6638" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.7393 31.628L51.209 29.5336" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.4774 23.7448L39.6895 33.5479C39.3986 33.6933 38.9914 33.577 38.7878 33.3152L30.6427 22.0285C30.4391 21.7667 30.5264 21.4467 30.8464 21.3013L52.6343 11.4982C52.9252 11.3527 53.3324 11.4691 53.536 11.7309L61.6811 23.0176C61.8556 23.2794 61.7683 23.6284 61.4774 23.7448Z" fill="#F7CC97" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M31.1953 22.2612L47.8635 25.5774C48.1835 25.6356 48.5035 25.4902 48.5907 25.2574L53.5068 12.2545" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M42.0755 24.7629L38.9629 32.9952" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M61.2745 22.9594L50.7441 20.894" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4621_4430">
          <rect width="104" height="104" fill="white" transform="translate(0 -1)" />
        </clipPath>
        <clipPath id="clip1_4621_4430">
          <rect width="104" height="102.349" fill="white" transform="translate(0 0.695679)" />
        </clipPath>
      </defs>
    </svg>
  );
}
