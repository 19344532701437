import React, {
  useContext,
} from 'react';
import IconText from '../../../../shared/IconText';
import UsersShowContext from '../../../../../contexts/UsersShowContext';
import {
  checkSchedulingLink,
  clickSchedulingLink,
} from '../../../../../scripts/schedulingLink';

export default function Details() {
  const {
    userData,
  } = useContext(UsersShowContext);

  const {
    projects_count: projectsCount,
    scheduling_link: schedulingLink,
  } = userData;

  let projectsCountText = `${projectsCount} Projects`;
  if (projectsCount === 1) projectsCountText = `${projectsCount} Project`;
  else if (projectsCount === 0 || projectsCount === null) projectsCountText = 'No projects yet';

  return (
    <div className="details">
      {window.entitlements.websiteLinkShow.enabled && userData.website && (
        <IconText
          icon="world"
          text={userData.website}
        />
      )}
      {userData.location && (
        <IconText
          icon="location"
          text={userData.location}
        />
      )}
      {window.entitlements.schedulingLinkShow.enabled && schedulingLink && (
        <IconText
          icon="calendar-schedule"
          onClick={(e) => clickSchedulingLink(e, schedulingLink)}
          text={checkSchedulingLink(schedulingLink)}
        />
      )}
      {projectsCount > 0 && (
        <IconText
          icon="file"
          text={projectsCountText}
        />
      )}
      {userData.kajabi_tenure && (
        <IconText
          icon="check-circle"
          text={`${userData.kajabi_tenure}+ years using Kajabi`}
        />
      )}
    </div>
  );
}
