import React from 'react';
import Feature from '../../shared/Feature';

export default function Features() {
  return (
    <section className="section section--sm section--blue">
      <div className="container container--large">
        <div className="sage-row">
          <div className="sage-col">
            <Feature
              description="Meet Kajabi customers who are actively looking to hire and be first-in-line with solutions."
              icon="sequences"
              title="High-quality leads"
            />
          </div>
          <div className="sage-col">
            <Feature
              description=" Chat with clients, deliver assets, and collect payment without ever leaving the platform."
              icon="payout"
              title="Smooth transactions"
            />
          </div>
          <div className="sage-col">
            <Feature
              description="Showcase your star ratings, reviews, and Kajabi’s seal of approval."
              icon="user-star"
              title="All-star credentials"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
