/* eslint-disable consistent-return */
import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import UIIconStar from './UIIconStar';

export default function RatingScaleWithDefault({
  rating,
  onSetRating,
}) {
  useEffect(() => {
    const icons = Array.from(document.getElementsByClassName('rating-scale__icon'));
    const iconContainers = Array.from(document.getElementsByClassName('rating-scale'));

    function handleMouseOver(e) {
      icons.forEach((icon) => {
        const thisSVGPath = icon.querySelector('svg path');
        if (e.currentTarget.getAttribute('id') >= icon.getAttribute('id')) {
          thisSVGPath.style.fill = '#FFC505';
        } else {
          thisSVGPath.style.fill = 'grey';
        }
      });
    }

    function handleMouseLeave() {
      icons.forEach((icon) => {
        const thisSVGPath = icon.querySelector('svg path');
        if (icon.getAttribute('id') < rating) {
          thisSVGPath.style.fill = '#FFC505';
        } else {
          thisSVGPath.style.fill = 'grey';
        }
      });
    }

    function handleClick(e) {
      const val = parseInt(e.currentTarget.getAttribute('id'), 10) + 1;
      onSetRating(val);
    }

    iconContainers.forEach((iconContainer) => {
      iconContainer.addEventListener('mouseleave', handleMouseLeave);
    });

    icons.forEach((icon) => {
      icon.addEventListener('mouseover', handleMouseOver);
      icon.addEventListener('click', handleClick);
    });

    return () => {
      icons.forEach((icon) => {
        icon.removeEventListener('mouseover', handleMouseOver);
        icon.removeEventListener('click', handleClick);
      });
      iconContainers.forEach((iconContainer) => {
        iconContainer.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [rating]);

  const defaultColor = (id) => {
    if (id <= rating) {
      return '#FFC505';
    }
    return 'grey';
  };

  return (
    <div className="rating-scale">
      <div className="rating-scale__icon" id={0}>
        <UIIconStar color="gray" size="24" styleColor={defaultColor(1)} />
      </div>
      <div className="rating-scale__icon" id={1}>
        <UIIconStar color="gray" size="24" styleColor={defaultColor(2)} />
      </div>
      <div className="rating-scale__icon" id={2}>
        <UIIconStar color="gray" size="24" styleColor={defaultColor(3)} />
      </div>
      <div className="rating-scale__icon" id={3}>
        <UIIconStar color="gray" size="24" styleColor={defaultColor(4)} />
      </div>
      <div className="rating-scale__icon" id={4}>
        <UIIconStar color="gray" size="24" styleColor={defaultColor(5)} />
      </div>
    </div>
  );
}

RatingScaleWithDefault.propTypes = {
  rating: PropTypes.number,
  onSetRating: PropTypes.func.isRequired,
};

RatingScaleWithDefault.defaultProps = {
  rating: null,
};
