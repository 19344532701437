/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  ErrorMessage,
  Field,
} from 'formik';
import {
  Icon,
} from '@kajabi/sage-react';
import uuid from 'react-uuid';
import CheckboxGroupItem from './CheckboxGroupItem';

export default function CheckboxGroup({
  className,
  name,
  onChange,
  options,
  optionsWithStatuses,
  subtitle,
  title,
}) {
  const classNames = classnames(
    'checkbox-group',
    className,
  );

  return (
    <div className={classNames}>
      <div className="checkbox-group__header">
        {title && (<p className="t-sage-heading-5 checkbox-group__title">{title}</p>)}
        {subtitle && (<p className="t-sage-heading-6 checkbox-group__subtitle">{subtitle}</p>)}
      </div>
      <Field
        name={name}
      >
        {({ field }) => {
          if (options) {
            return options.map(
              (option, index) => (
                <CheckboxGroupItem
                  field={field}
                  index={index}
                  key={uuid()}
                  onChange={onChange}
                  optionString={option}
                />
              ),
            );
          }
          if (optionsWithStatuses) {
            return optionsWithStatuses.map(
              (option, index) => (
                <CheckboxGroupItem
                  disabled={option.disabled}
                  field={field}
                  index={index}
                  key={uuid()}
                  onChange={onChange}
                  optionString={option.name}
                />
              ),
            );
          }
          return false;
        }}
      </Field>
      <ErrorMessage name={name}>
        {(msg) => (
          <p className="checkbox-group__error">
            <Icon icon="danger" />
            {msg}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

CheckboxGroup.defaultProps = {
  className: null,
  options: null,
  optionsWithStatuses: null,
  onChange: null,
  subtitle: null,
  title: null,
};

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionsWithStatuses: PropTypes.array,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
