import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import classnames from 'classnames';
import ChatPane from './chat-pane/ChatPane';
import DocumentHistoryModal from './document-history-modal/DocumentHistoryModal';
import InboxPane from './inbox-pane/InboxPane';
import NullPane from './null-pane/NullPane';
import QuoteSubmitModal from './shared/QuoteSubmitModal';
import UserPane from './user-pane/UserPane';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import MessagesIndexContext from './MessagesIndexContext';
import useApiFetch from '../../../scripts/useApiFetch.tsx';

export default function MessagesIndex() {
  const [chatData, setChatData] = useState(null);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [currentInboxProjectId, setCurrentInboxProjectId] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentMessageQuoteId, setCurrentMessageQuoteId] = useState(null);
  const [documentHistoryModalOpen, setDocumentHistoryModalOpen] = useState(false);
  const [messagesData, setMessagesData] = useState(null);
  const [paneOpenInbox, setPaneOpenInbox] = useState(false);
  const [paneOpenUser, setPaneOpenUser] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [quoteSubmitModalOpen, setQuoteSubmitModalOpen] = useState(false);
  const [quoteType, setQuoteType] = useState('');

  const {
    apiFetch,
  } = useApiFetch();

  const {
    type: userType,
  } = useContext(CurrentUserContext);

  const resolvedPath = window.location.href.split('?')[0].replace(/messages\/?$/, '');

  const getMessagesData = () => {
    apiFetch(`${resolvedPath}/api/v1/messages.json`).then((data) => setMessagesData(data)).catch(() => {});
  };

  // Use these constants to search against already fetched data,
  // as the fetched data contains conversations that are specific
  // to the user. This prevents snooping via inputting arbitrary
  // URL params.
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const projectId = parseInt(queryParams.get('project'), 10);
  const vendorId = parseInt(queryParams.get('vendor'), 10);

  // If the above query params exist and are valid, set the current
  // message in the inbox pane to active and render the current
  // conversation in the chat pane. If either of the constants are not
  // found, remove the query string and render /messages as normal.
  const checkQueryString = () => {
    let removeQueryString = true;
    for (let i = 0; i < messagesData.length; i += 1) {
      const message = messagesData[i];
      if (message.latest_message.vendor_id === vendorId && message.project.id === projectId) {
        removeQueryString = false;
        setCurrentMessage(message.latest_message);
        setCurrentConversation({
          projectId,
          vendorId,
        });
        break;
      }
    }
    if (removeQueryString) {
      navigate('/messages');
    }
  };

  useEffect(() => {
    getMessagesData();
  }, []);

  useEffect(() => {
    if (chatData) {
      apiFetch(`${window.location.origin}/projects/${chatData.project.id}.json`).then((data) => setProjectData(data)).catch(() => {});
    }
  }, [chatData]);

  useEffect(() => {
    if (messagesData) checkQueryString();
  }, [projectId, vendorId]);

  useEffect(() => {
    if (messagesData && !currentConversation) checkQueryString();
  }, [currentConversation, messagesData]);

  const messagesIndexContextValues = useMemo(() => ({
    chatData,
    currentConversation,
    currentInboxProjectId,
    currentMessage,
    currentMessageQuoteId,
    getMessagesData,
    messagesData,
    paneOpenInbox,
    paneOpenUser,
    projectData,
    quoteSubmitModalOpen,
    quoteType,
    setChatData,
    setCurrentConversation,
    setCurrentInboxProjectId,
    setCurrentMessage,
    setCurrentMessageQuoteId,
    setDocumentHistoryModalOpen,
    setMessagesData,
    setPaneOpenInbox,
    setPaneOpenUser,
    setQuoteSubmitModalOpen,
    setQuoteType,
  }));

  if (!messagesData) return false;

  const classNames = classnames(
    'layout',
    {
      'layout--conversation-chosen': currentMessage,
    },
  );

  const paneInboxClassNames = classnames(
    'layout__pane layout__pane--inbox',
    {
      'layout__pane--open': paneOpenInbox,
    },
  );

  const paneUserClassNames = classnames(
    'layout__pane layout__pane--user',
    {
      'layout__pane--open': paneOpenUser,
    },
  );

  return (
    <MessagesIndexContext.Provider value={messagesIndexContextValues}>
      <section className={classNames}>
        <section className={paneInboxClassNames}>
          <InboxPane />
        </section>
        {currentMessage && (
          <>
            <section className="layout__pane layout__pane--chat">
              <ChatPane />
            </section>
            <section className={paneUserClassNames}>
              <UserPane />
            </section>
          </>
        )}
        {!currentMessage && (
          <section className="layout__pane layout__pane--null">
            <NullPane />
          </section>
        )}
      </section>
      {chatData && userType === 'vendor' && (
        <QuoteSubmitModal
          active={quoteSubmitModalOpen}
          onClose={() => setQuoteSubmitModalOpen(false)}
          project={{
            id: chatData.project.id,
            vendor_id: chatData.project.vendor_id,
          }}
          redirectUrl="/messages"
          quoteType={quoteType}
        />
      )}
      {chatData && projectData && (
        <DocumentHistoryModal
          active={documentHistoryModalOpen}
          onClose={() => setDocumentHistoryModalOpen(false)}
        />
      )}
    </MessagesIndexContext.Provider>
  );
}
