import React, {
  useContext,
} from 'react';
import {
  Icon,
} from '@kajabi/sage-react';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Header() {
  const {
    chatData,
    setPaneOpenUser,
  } = useContext(MessagesIndexContext);

  if (!chatData) return false;

  const userType = chatData.correspondent.user_type === 'vendor' ? 'Expert' : 'Client';

  return (
    <header
      className="header"
    >
      <p
        className="t-sage-heading-5"
      >
        {`${userType} info`}
      </p>
      <div
        className="header__close"
        onClick={() => setPaneOpenUser(false)}
        role="presentation"
      >
        <Icon
          icon="move-right"
        />
      </div>
    </header>
  );
}
