import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import Info from '../testimonials/Info';
import List from '../testimonials/List';
import TestimonialModal from './TestimonialModal';
import TestimonialsContext from './TestimonialsContext';
import { testimonialsList } from '../../scripts/testimonial';

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialModalData, setTestimonialModalData] = useState({
    active: false,
    type: 'add',
  });
  const [currentTestimonial, setCurrentTestimonial] = useState(null);

  const fetchTestimonials = () => {
    testimonialsList({ onFetched: (list) => setTestimonials(list) });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const testimonialContextValues = useMemo(() => ({
    currentTestimonial,
    fetchTestimonials,
    testimonialModalData,
    testimonials,
    setCurrentTestimonial,
    setTestimonialModalData,
  }));

  return (
    <TestimonialsContext.Provider value={testimonialContextValues}>
      <section className="testimonials">
        <div className="sage-row">
          <div className="sage-col-4">
            <Info />
          </div>
          <div className="sage-col-8">
            <List />
          </div>
        </div>
      </section>
      <TestimonialModal
        active={testimonialModalData.active}
        onClose={() => setTestimonialModalData({
          active: false,
          type: null,
        })}
      />
    </TestimonialsContext.Provider>
  );
}
