import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import messageMarkAsRead from '../../../../scripts/messageMarkAsRead';
import timeAgo from '../../../../scripts/timeAgo';
import truncated from '../../../../scripts/truncated';
import Avatar from '../../../shared/Avatar';
import AppContext from '../../../../AppContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import MessagesIndexContext from '../MessagesIndexContext';

export default function Message({
  active,
  message,
  onUpdate,
  project,
  userToRender,
}) {
  const {
    globalState,
  } = useContext(AppContext);

  const {
    id: userId,
  } = useContext(CurrentUserContext);

  const {
    currentMessage,
    setCurrentConversation,
    setCurrentInboxProjectId,
    setCurrentMessage,
    setPaneOpenInbox,
  } = useContext(MessagesIndexContext);

  const projectData = project.project_data;

  const {
    subcategoryTitle,
  } = projectData;

  const classNames = classnames('message', {
    'message--active': active,
    'message--unread': !message.read && userId !== message.sender.id,
  });

  const navigate = useNavigate();

  let messageString = message.content;
  const { quote } = message;
  if (quote) {
    if (quote.quote_type === 'proposal') messageString = 'Sent a proposal';
    else messageString = 'Sent an invoice';
  }

  const [isCurrentMessageIdSame, setIsCurrentMessageIdSame] = useState(false);
  useEffect(() => {
    if (currentMessage && currentMessage.id === message.id) setIsCurrentMessageIdSame(true);
  }, [currentMessage]);

  return (
    <div
      className={classNames}
      onClick={() => {
        if (!isCurrentMessageIdSame) {
          if (userId !== message.sender.id) {
            messageMarkAsRead(
              message.id,
              onUpdate,
            );
          }
          globalState.checkForUnreadMessages = true;
          setCurrentMessage(message);
          setCurrentConversation({
            projectId: project.id,
            vendorId: message.vendor_id,
          });
          setCurrentInboxProjectId(project.id);
          setPaneOpenInbox(false);
          navigate(`/messages?project=${project.id}&vendor=${message.vendor_id}`);
        }
      }}
      role="presentation"
    >
      <div className="message__indicator message__indicator--active" />
      <div className="message__indicator message__indicator--unread" />
      <div className="message__container">
        <div className="message__avatar">
          <Avatar
            size="42"
            user={userToRender}
          />
        </div>
        <div className="message__content">
          <p className="message__header">
            <p className="t-sage-heading-5 message__creator-name">{userToRender.name}</p>
            <p className="t-sage-body-xsmall message__timestamp">{timeAgo(message.created_at)}</p>
          </p>
          <div className="t-sage-heading-6 message__category">
            {project.title || subcategoryTitle}
          </div>
          <div className="message__message">
            {truncated(messageString, { maxLength: 42, position: 'end' })}
          </div>
        </div>
      </div>
    </div>
  );
}

Message.defaultProps = {
  active: false,
};

Message.propTypes = {
  active: PropTypes.bool,
  message: PropTypes.shape({
    content: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.number,
    quote: PropTypes.shape({
      quote_type: PropTypes.string,
    }),
    read: PropTypes.bool,
    sender: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    system: PropTypes.bool.isRequired,
    vendor_id: PropTypes.number,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  project: PropTypes.shape({
    creatorName: PropTypes.string,
    id: PropTypes.number,
    project_data: PropTypes.shape({
      subcategoryTitle: PropTypes.string,
    }).isRequired,
    title: PropTypes.string,
  }).isRequired,
  userToRender: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
};
