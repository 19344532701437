import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useParams,
} from 'react-router-dom';
import CategorySelect from './CategorySelect';
import Form from './Form';
import Hire from './Hire';
import ProjectNewContext from '../../../contexts/ProjectNewContext';
import { categories } from '../../../data/categories/categories';
import withActiveCategoriesOnly from '../../../scripts/withActiveCategoriesOnly';

export default function ProjectsNew() {
  const [category, setCategory] = useState(null);
  const [newProjectUrl, setNewProjectUrl] = useState(null);
  const [subcategory, setSubcategory] = useState(null);

  const projectData = useMemo(() => ({
    category,
    newProjectUrl,
    setCategory,
    setNewProjectUrl,
    setSubcategory,
    subcategory,
  }));

  const {
    cat,
    subcat,
  } = useParams();

  useEffect(() => {
    setCategory(null);
    setSubcategory(null);

    if (cat) {
      withActiveCategoriesOnly(categories).forEach((thisCategory) => {
        if (thisCategory.slug === cat) setCategory(thisCategory);
      });
    }

    if (subcat) {
      withActiveCategoriesOnly(categories).forEach((thisCategory) => {
        withActiveCategoriesOnly(thisCategory.subcategories).forEach((thisSubcategory) => {
          if (thisSubcategory.slug === subcat) setSubcategory(thisSubcategory);
        });
      });
    }
  });

  return (
    <ProjectNewContext.Provider value={projectData}>
      {!newProjectUrl && <Hire />}
      {!newProjectUrl && !subcategory && <CategorySelect />}
      {!newProjectUrl && subcategory && <Form />}
    </ProjectNewContext.Provider>
  );
}
