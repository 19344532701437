import React from 'react';
import FAQCallToAction from '../../shared/landing-pages/FAQCallToAction';

export default function FAQ() {
  return (
    <section className="container container--large">
      <div className="faq__content">
        <FAQCallToAction />
      </div>
    </section>
  );
}
