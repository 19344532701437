import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from '@kajabi/sage-react';

export default function Location({
  location,
}) {
  if (location) {
    return (
      <div className="location">
        <Icon
          adjacentType="body-sm"
          icon="location"
        />
        <p className="t-sage-body-small location-text">{location}</p>
      </div>
    );
  }
}

Location.defaultProps = {
  location: null,
};

Location.propTypes = {
  location: PropTypes.string,
};
