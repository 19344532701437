import React from 'react';
import PropTypes from 'prop-types';
import {
  Textarea as SageTextarea,
} from '@kajabi/sage-react';

export default function Textarea({
  className,
  customMessage,
  errors,
  handleChange,
  idName,
  label,
  placeholder,
  rows,
  touched,
  value,
}) {
  return (
    <SageTextarea
      className={className}
      hasError={!!errors && !customMessage && touched}
      id={idName}
      label={label}
      message={customMessage || (errors && touched && errors)}
      name={idName}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      rows={rows}
    />
  );
}

Textarea.defaultProps = {
  className: null,
  customMessage: null,
  errors: null,
  placeholder: null,
  rows: null,
  touched: null,
};

Textarea.propTypes = {
  className: PropTypes.string,
  customMessage: PropTypes.node,
  errors: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  idName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  touched: PropTypes.bool,
  value: PropTypes.string.isRequired,
};
