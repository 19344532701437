import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function ButtonProxy({
  children,
  className,
  color,
  subtle,
  to,
}) {
  const classNames = classnames(
    'sage-btn',
    `sage-btn--${color}`,
    {
      'sage-btn--subtle': subtle,
    },
    className,
  );

  return (
    <Link
      className={classNames}
      to={to}
    >
      {children}
    </Link>
  );
}

ButtonProxy.defaultProps = {
  className: null,
  color: 'primary',
  subtle: false,
};

ButtonProxy.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  subtle: PropTypes.bool,
  to: PropTypes.string.isRequired,
};
