import React, {
  useContext,
  useState,
} from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Tabs as SageTabs,
} from '@kajabi/sage-react';
import ProjectList from './ProjectList';
import CurrentUserContext from '../../../contexts/CurrentUserContext';

const defaultTab = () => {
  const { isVendor } = useContext(CurrentUserContext);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  if (tab) return tab;

  return isVendor ? 'prospective' : 'active';
}

export default function Tabs() {
  const { isVendor } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState(defaultTab());

  const tabs = [];
  if (isVendor) {
    tabs.push({
      id: 'prospective',
      label: 'Prospective',
      content: <ProjectList filter="prospective" />,
    });
  }
  tabs.push({
    id: 'active',
    label: 'Active',
    content: <ProjectList filter="active" />,
  });
  if (!isVendor) {
    tabs.push({
      id: 'closed',
      label: 'Closed',
      content: <ProjectList filter="closed" />,
    });
  }
  tabs.push({
    id: 'completed',
    label: 'Completed',
    content: <ProjectList filter="completed" />,
  });
  if (isVendor) {
    tabs.push({
      id: 'archived',
      label: 'Archived',
      content: <ProjectList filter="archived" />,
    });
  }
  if (isVendor && window.globalFeatureFlags.declinedProjects.enabled) {
    tabs.push({
      id: 'declined',
      label: 'Declined',
      content: <ProjectList filter="declined" />,
    });
  }

  return (
    <SageTabs
      className="tabs"
      initialActiveId={activeTabId}
      onClickTab={(e) => {
        if (e !== activeTabId) {
          setActiveTabId(e);
          navigate(`?tab=${e}`);
        }
      }}
      tabs={tabs}
    />
  );
}
