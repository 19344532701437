import React from 'react';
import { Hero as HeroTemplate } from '../../shared/landing-pages/Hero';

export default function Hero() {
  return (
    <HeroTemplate
      buttonText="Become a Kajabi Expert"
      className="vendor-application"
      descriptionDesktop={(
        <div>
          {'Calling all '}
          <strong>Kajabi service providers</strong>
          {`! We’re growing a new marketplace called Kajabi
          Experts where experienced Kajabi service providers
          and Kajabi customers can easily connect—and we’re
          looking for designers, developers and marketers to
          help us kick things off.`}
          <br />
          <br />
          <strong>Interested? Apply to become one of our first Kajabi Experts!</strong>
        </div>
      )}
      descriptionMobile={(
        <div>
          {'Calling all '}
          <strong>Kajabi service providers</strong>
          {`! We’re growing a new marketplace called Kajabi
          Experts where experienced Kajabi service providers
          and Kajabi customers can easily connect—and we’re
          looking for web designers to help us kick things
          off.`}
          <br />
          <br />
          <strong>Interested? Apply to become one of our first Kajabi Experts!</strong>
        </div>
      )}
      imgSrc="images/apply_hero.png?v=2"
      scrollButton
      secondaryLinkHref={`${window.location.origin}`}
      secondaryLinkText="Hire a Kajabi Expert"
      titleDesktop={(
        <div className="hero__title--large">
          Apply to become a <span>Kajabi Expert</span> or <span>Coach</span> today
        </div>
      )}
      titleMobile={(
        <div>
          Apply to become a <span>Kajabi Expert</span> or <span>Coach</span> today
        </div>
      )}
    />
  );
}
