import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import Chart from './Chart';
import FAQ from './FAQ';
import Hero from './Hero';
import Indicator from './Indicator';
import PlansList from './PlansList';
import PlansContext from './PlansContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

export default function Plans() {
  const {
    plan: currentPlan,
  } = useContext(CurrentUserContext);

  const [isDisplayPlanMatrix, setIsDisplayPlansMatrix] = useState(false);

  const plansContextValues = useMemo(() => ({
    currentPlan,
    isDisplayPlanMatrix,
    setIsDisplayPlansMatrix,
  }));

  return (
    <PlansContext.Provider value={plansContextValues}>
      <section className="plans">
        <Hero />
        <PlansList />
        <Indicator />
        <Chart />
        <FAQ />
      </section>
    </PlansContext.Provider>
  );
}
